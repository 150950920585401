import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import EmailDesignElements  from "../Canvas/EmailDesignElements";



// Styles
const Container = Styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;
`;

const Column = Styled.div`
    width: 50%;
`;



/**
 * The Email Design Columns Element
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ColumnsElement(props) {
    const { elem, setDropElement } = props;


    // Do the Render
    return <Container>
        <Column>
            <EmailDesignElements
                parentElement={elem.elementNumber}
                parentColumn={0}
                setDropElement={setDropElement}
            />
        </Column>
        <Column>
            <EmailDesignElements
                parentElement={elem.elementNumber}
                parentColumn={1}
                setDropElement={setDropElement}
            />
        </Column>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ColumnsElement.propTypes = {
    elem           : PropTypes.object.isRequired,
    setDropElement : PropTypes.func.isRequired,
};

export default ColumnsElement;
