import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Facebook             from "Utils/Facebook";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import Html                 from "Dashboard/Components/Common/Html";
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";
import HyperLink            from "Dashboard/Components/Link/HyperLink";



/**
 * The WhatsApp Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WhatsAppInstall(props) {
    const { isHidden, provider, onInstall } = props;


    // The Current State
    const [ isLoading,   setLoading     ] = React.useState(false);
    const [ isLoggedIn,  setLoggedIn    ] = React.useState(false);
    const [ accessToken, setAccessToken ] = React.useState("");
    const [ accountID,   setAccountID   ] = React.useState("");
    const [ accounts,    setAccounts    ] = React.useState([]);


    // Load the Data
    React.useEffect(() => {
        if (!isHidden) {
            handleLoad();
        }
    }, [ isHidden ]);

    // Handles the Load
    const handleLoad = async () => {
        if (process.env.NODE_ENV === "development") {
            return;
        }
        Facebook.initialize(process.env.REACT_APP_WHATSAPP_ID, "v19.0");
    };

    // Handles the Login
    const handleLogin = async () => {
        if (process.env.NODE_ENV === "development") {
            return;
        }

        setLoading(true);
        if (isLoggedIn) {
            await Facebook.logout();
            setAccessToken("");
            setLoggedIn(false);
        }

        const code = await Facebook.login({
            redirect_uri : process.env.REACT_APP_HOOK,
            config_id : process.env.REACT_APP_WHATSAPP_CONFIG,
            response_type : "code",
            override_default_response_type : true,
        });
        if (!code) {
            setLoading(false);
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_HOOK}m/whatsapp?token_code=${code}`);
        const data     = await response.json();

        setLoading(false);
        if (!data.accessToken) {
            return;
        }

        setAccessToken(data.accessToken);
        setAccounts(data.accounts);
        setLoggedIn(true);
        setLoading(false);

        if (data.accounts.length === 1) {
            setAccountID(data.accounts[0].key);
            handleInstall(data.accessToken, data.accounts[0].key);
        }
    };

    // Handles the Change
    const handleContinue = () => {
        if (accessToken && accountID) {
            handleInstall(accessToken, accountID);
        }
    };

    // Handles the Install
    const handleInstall = (accessToken, accountID) => {
        const token = JSON.stringify({ accessToken, accountID });
        onInstall(token, accountID);
    };



    // Do the Render
    const hasToken    = Boolean(accessToken);
    const hasAccounts = Boolean(hasToken && accounts.length);

    if (isHidden) {
        return <React.Fragment />;
    }

    if (isLoading) {
        return <>
            <ProviderButton
                logo={provider.logo}
                color={provider.color}
                message={provider.fullName}
            />
            <CircularLoader top={24} />
        </>;
    }

    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_WHATSAPP_BEFORE")}</p>
            <ul>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO1")}</li>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO2")}</li>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO3")}</li>
            </ul>
            <Html
                isHidden={hasToken}
                variant="p"
                message="CHANNELS_WHATSAPP_START"
            />
            <Html
                isHidden={!hasAccounts}
                variant="p"
                message="CHANNELS_WHATSAPP_SELECT"
            />
            <Html
                isHidden={!hasToken || hasAccounts}
                variant="p"
                message="CHANNELS_WHATSAPP_NONE"
            />
        </section>

        <section>
            <Button
                isHidden={hasToken}
                className="top-space"
                variant="outlined"
                message="CHANNELS_WHATSAPP_LOGIN"
                onClick={handleLogin}
            />
        </section>

        <section>
            <InputField
                isHidden={!hasAccounts}
                type="select"
                name="accountID"
                label="CHANNELS_WHATSAPP_ACCOUNT"
                options={accounts}
                value={accountID}
                onChange={(name, value) => setAccountID(value)}
                isRequired
            />
            <Button
                isHidden={!hasToken}
                className="top-space"
                variant="outlined"
                message="GENERAL_CONTINUE"
                onClick={handleContinue}
                isDisabled={!accountID}
            />
        </section>

        <HyperLink
            isHidden={!hasToken}
            className="user-link"
            message="CHANNELS_OTHER_USER"
            onClick={() => setAccessToken("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WhatsAppInstall.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default WhatsAppInstall;
