import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";



// The initial State
const initialState = {
    action           : Action.get(),
    createPosition   : -1,
    positionToCreate : -1,

    hasChanges       : false,
    inActivate       : false,
    inPublish        : false,
    viewErrors       : false,

    actionToCopy     : 0,
    actionToSelect   : -1,
    selectedAction   : 0,
};



// The Actions
const actions = {
    /**
     * Sets the Automation action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "AUTOMATION_ACTION", action });
    },

    /**
     * Sets the Automation Create
     * @param {Function} dispatch
     * @param {Number}   createPosition
     * @returns {Void}
     */
    setCreate(dispatch, createPosition) {
        dispatch({ type : "AUTOMATION_CREATE", createPosition });
    },

    /**
     * Sets the Automation Create
     * @param {Function} dispatch
     * @param {Number}   positionToCreate
     * @returns {Void}
     */
    setToCreate(dispatch, positionToCreate) {
        dispatch({ type : "AUTOMATION_TO_CREATE", positionToCreate });
    },

    /**
     * Sets the Automation has changes
     * @param {Function} dispatch
     * @param {Boolean}  hasChanges
     * @returns {Void}
     */
    setHasChanges(dispatch, hasChanges) {
        dispatch({ type : "AUTOMATION_HAS_CHANGES", hasChanges });
    },

    /**
     * Sets the Automation in Activate mode
     * @param {Function} dispatch
     * @param {Boolean}  inActivate
     * @returns {Void}
     */
    setInActivate(dispatch, inActivate) {
        dispatch({ type : "AUTOMATION_IN_ACTIVATE", inActivate });
    },

    /**
     * Sets the Automation in Publish mode
     * @param {Function} dispatch
     * @param {Boolean}  inPublish
     * @returns {Void}
     */
    setInPublish(dispatch, inPublish) {
        dispatch({ type : "AUTOMATION_IN_PUBLISH", inPublish });
    },

    /**
     * Sets the Automation Action to Copy
     * @param {Function} dispatch
     * @param {Number}   actionToCopy
     * @returns {Void}
     */
    setActionToCopy(dispatch, actionToCopy) {
        dispatch({ type : "AUTOMATION_COPY_ACTION", actionToCopy });
    },

    /**
     * Sets the Automation Action to Select
     * @param {Function} dispatch
     * @param {Number}   actionToSelect
     * @returns {Void}
     */
    setActionToSelect(dispatch, actionToSelect) {
        dispatch({ type : "AUTOMATION_SELECT_ACTION", actionToSelect });
    },

    /**
     * Sets the selected Automation Action
     * @param {Function} dispatch
     * @param {Number}   selectedAction
     * @returns {Void}
     */
    setSelectedAction(dispatch, selectedAction) {
        dispatch({ type : "AUTOMATION_SELECTED_ACTION", selectedAction });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "AUTOMATION_ACTION":
        return {
            ...state,
            action           : Action.get(action.action),
        };

    case "AUTOMATION_CREATE":
        return {
            ...state,
            action           : Action.get("CREATE"),
            createPosition   : action.createPosition,
            positionToCreate : -1,
            selectedAction   : 0,
        };

    case "AUTOMATION_TO_CREATE":
        return {
            ...state,
            positionToCreate : action.positionToCreate,
        };

    case "AUTOMATION_HAS_CHANGES":
        return {
            ...state,
            hasChanges       : action.hasChanges,
        };

    case "AUTOMATION_IN_ACTIVATE":
        return {
            ...state,
            inActivate       : action.inActivate,
            viewErrors       : action.inActivate,
            action           : action.inActivate ? Action.get("ERROR") : Action.get(),
        };

    case "AUTOMATION_IN_PUBLISH":
        return {
            ...state,
            inPublish        : action.inPublish,
            viewErrors       : action.inPublish,
            action           : action.inPublish ? Action.get("ERROR") : Action.get(),
        };

    case "AUTOMATION_COPY_ACTION":
        return {
            ...state,
            actionToCopy     : action.actionToCopy || 0,
        };

    case "AUTOMATION_SELECT_ACTION":
        return {
            ...state,
            actionToSelect   : action.actionToSelect || 0,
        };

    case "AUTOMATION_SELECTED_ACTION":
        return {
            ...state,
            selectedAction   : action.selectedAction || 0,
            actionToSelect   : -1,
        };

    case "AUTOMATION_STATE_RESET":
        return {
            ...state,
            actionToSelect   : -1,
            selectedAction   : 0,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
