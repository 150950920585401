import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import AutomationCreate     from "../Dialogs/AutomationCreate";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Automation Editor Dialogs
 * @returns {React.ReactElement}
 */
function AutomationDialogs() {
    const { openDetails, closeDetails } = Store.useAction("core");
    const { automationID } = Store.useState("automationEditor");
    const { copyAction, deleteAction } = Store.useAction("automationEditor");

    const {
        action, createPosition, positionToCreate,
        actionToCopy, actionToSelect, selectedAction,
    } = Store.useState("automationState");

    const {
        setAction, setHasChanges,
        setCreate, setActionToCopy, setSelectedAction,
    } = Store.useAction("automationState");


    // Handles the Action Create
    const handleCreate = () => {
        setAction();
        openDetails();
    };

    // Handles the Action Deletes
    const handleDeleteAction = () => {
        if (selectedAction) {
            deleteAction(selectedAction);
            setSelectedAction(0);
            setHasChanges(false);
            closeDetails();
        }
        setAction();
    };

    // Handles the Discard
    const handleDiscard = async () => {
        await setAction();
        if (positionToCreate >= 0) {
            setCreate(positionToCreate);
        } else if (actionToCopy) {
            setActionToCopy(0);
            copyAction(selectedAction);
        } else if (actionToSelect >= 0) {
            setSelectedAction(actionToSelect);
        }
        setHasChanges(false);
    };

    // Handles the Close
    const handleClose = () => {
        setAction();
    };


    // Do the Render
    return <>
        <AutomationCreate
            open={action.isCreate}
            automationID={automationID}
            createPosition={createPosition}
            onSubmit={handleCreate}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isRemove}
            title="AUTOMATIONS_ACTIONS_DELETE_TITLE"
            message="AUTOMATIONS_ACTIONS_DELETE_TEXT"
            onSubmit={handleDeleteAction}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isWarning}
            icon="status"
            title="AUTOMATIONS_ACTIONS_WARNING_TITLE"
            message="AUTOMATIONS_ACTIONS_WARNING_TEXT"
            primary="GENERAL_DISCARD"
            onSubmit={handleDiscard}
            onClose={handleClose}
        />
    </>;
}

export default AutomationDialogs;
