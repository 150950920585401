import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.header`
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    gap: 8px;
    overflow: auto;
    z-index: 1;

    @media screen and (max-width: 450px) {
        .btn {
            padding: 4px 8px;
        }
        .btn-content {
            display: none;
        }
    }
`;

const Actions = Styled.section`
    display: flex;
    gap: 8px;
`;



/**
 * The Email Design Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignActions(props) {
    const { addElementRef } = props;

    const { elementTypes } = Store.useState("emailDesignEditor");
    const { selectedElement, history, currentHistory } = Store.useState("emailDesignState");

    const {
        setAction, setElementMoving,
        copyElement, removeElement,
        undoHistory, redoHistory,
    } = Store.useAction("emailDesignState");


    // Toggles the Elements Box
    const toggleElements = () => {
        setAction("CREATE");
    };

    // Handles the Copy
    const handleCopy = () => {
        if (selectedElement) {
            copyElement(selectedElement);
        }
    };

    // Handles the Move
    const handleMove = (e) => {
        if (selectedElement) {
            const element     = history[currentHistory][selectedElement];
            const elementType = elementTypes[element.elementType];
            setElementMoving({
                elementNumber : selectedElement,
                icon          : elementType.icon,
                message       : elementType.message,
                isLayout      : elementType.isLayout,
                startX        : e.clientX,
                startY        : e.clientY,
            });
        }
    };

    // Handles the Delete
    const handleDelete = () => {
        if (selectedElement) {
            removeElement(selectedElement);
        }
    };

    // Handles the Undo
    const handleUndo = () => {
        if (currentHistory > 0) {
            undoHistory();
        }
    };

    // Handles the Redo
    const handleRedo = () => {
        if (currentHistory < history.length - 1) {
            redoHistory();
        }
    };

    // Handles the Preview
    const handlePreview = () => {
        setAction("PREVIEW");
    };


    // Variables
    const canEdit = Boolean(selectedElement);
    const canUndo = currentHistory > 0;
    const canRedo = currentHistory < history.length - 1;


    // Do the Render
    return <Container className="no-scrollbars">
        <Actions>
            <Button
                passedRef={addElementRef}
                variant="primary"
                message="EMAIL_DESIGNS_ELEMENTS_SINGULAR"
                icon="add"
                onClick={toggleElements}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="outlined"
                message="GENERAL_COPY"
                icon="copy"
                onClick={handleCopy}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="outlined"
                message="GENERAL_MOVE"
                icon="move-drag"
                onClick={handleMove}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="outlined"
                message="GENERAL_DELETE"
                icon="delete"
                onClick={handleDelete}
                isSmall
            />
        </Actions>

        <Actions>
            <Button
                variant="outlined"
                icon="undo"
                onClick={handleUndo}
                isDisabled={!canUndo}
                isSmall
            />
            <Button
                variant="outlined"
                icon="redo"
                onClick={handleRedo}
                isDisabled={!canRedo}
                isSmall
            />
            <Button
                variant="outlined"
                message="GENERAL_PREVIEW"
                icon="view"
                onClick={handlePreview}
                isSmall
            />
        </Actions>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignActions.propTypes = {
    addElementRef : PropTypes.object.isRequired,
};

export default EmailDesignActions;
