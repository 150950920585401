import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Components
import ScheduleHeader       from "./View/ScheduleHeader";
import ScheduleEditor       from "./View/ScheduleEditor";
import SchedulePreview      from "./View/SchedulePreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Schedule Page
 * @returns {React.ReactElement}
 */
function SchedulePage() {
    const { scheduleID } = Navigate.useParams();

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(scheduleID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, timeZone } = Store.useState("schedule");
    const { fetchElem, fetchEditData, editSchedule, deleteSchedule } = Store.useAction("schedule");


    // The Fields
    const fields = {
        main    : [ "name", "timeZone", "showTimeZone", "isDefault" ],
        periods : [ "periods" ],
        assigns : [ "teams" ],
    };

    // The Current State
    const [ step,       setStep       ] = React.useState("main");
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        clientID     : 0,
        scheduleID   : 0,
        name         : "",
        timeZone     : 0,
        showTimeZone : 0,
        isDefault    : 0,
        periods      : "[{}]",
        teamIDs      : "[]",
    };


    // Handles the Edit
    const handleEdit = () => {
        return editSchedule({ ...data, clientID });
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteSchedule(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange,
        resetErrors, handleSubmit,
    } = useForm("schedule", initialData, handleEdit, handleClose);


    // Load the Data
    React.useEffect(() => {
        if (scheduleID) {
            fetchElem(scheduleID);
        } else {
            fetchEditData(clientID);
        }
    }, [ scheduleID ]);

    // Store the Data
    React.useEffect(() => {
        if (elem.id) {
            const scheduleID = isEdit ? elem.scheduleID : 0;
            setElem({ ...elem, scheduleID, periods : JSON.stringify(elem.periods) });
        } else {
            setElem({ ...initialData, timeZone });
            resetErrors();
        }
    }, [ edition ]);


    // Returns the Error Count in the Step
    const getErrorCount = (step) => {
        return Commons.getErrorCount(fields, errors, step);
    };



    // Do the Render
    return <>
        <Main withNavigation withDetails>
            <ScheduleHeader
                data={data}
                href={goto}
                isEdit={isEdit}
            />

            <Content isLoading={loading || charging}>
                <ScheduleEditor
                    loading={loading}
                    isEdit={isEdit}
                    step={step}
                    setStep={setStep}
                    data={data}
                    errors={errors}
                    getErrorCount={getErrorCount}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </Content>
        </Main>

        <SchedulePreview
            data={data}
        />

        <DeleteDialog
            open={showDelete}
            title="SCHEDULES_DELETE_TITLE"
            message="SCHEDULES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

export default SchedulePage;
