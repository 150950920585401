import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { EmailDesign }      from "Utils/API";



// The initial State
const initialState = {
    readying      : false,
    error         : false,
    edition       : 0,
    canEdit       : false,
    emailDesignID : 0,
    elements      : [],
    totalChanges  : 0,
    publishErrors : [],
    languages     : [],
    variables     : [],
    channelLinks  : [],
    elementTypes  : [],
    selects       : {},
};



// The Actions
const actions = {
    /**
     * Fetches a single EmailDesign
     * @param {Function} dispatch
     * @param {Number}   emailDesignID
     * @returns {Promise}
     */
    async fetchEditor(dispatch, emailDesignID) {
        dispatch({ type : "EMAIL_DESIGN_EDITOR_READYING" });
        const data = await EmailDesign.getEditor({ emailDesignID });
        dispatch({ type : "EMAIL_DESIGN_STATE_INIT", data });
        dispatch({ type : "EMAIL_DESIGN_EDITOR_READY" });
        dispatch({ type : "EMAIL_DESIGN_EDITOR_ELEM", data });
    },

    /**
     * Fetches a single EmailDesign
     * @param {Function} dispatch
     * @param {Number}   emailDesignID
     * @returns {Promise}
     */
    async fetchUpdate(dispatch, emailDesignID) {
        const data = await EmailDesign.getEditor({ emailDesignID });
        dispatch({ type : "EMAIL_DESIGN_EDITOR_ELEM", data });
    },

    /**
     * Saves the Elements of an Email Design
     * @param {Function} dispatch
     * @param {Number}   emailDesignID
     * @param {Object[]} actions
     * @returns {Promise}
     */
    saveElements(dispatch, emailDesignID, actions) {
        return EmailDesign.save({ emailDesignID, actions : JSON.stringify(actions) });
    },
};



/**
 * Parses the Elements
 * @param {Object[]} elements
 * @param {Object}   elementTypes
 * @returns {Object[]}
 */
function parseElements(elements, elementTypes) {
    return Utils.parseList(elements, (elem) => {
        const elementType = elementTypes[elem.elementType] ?? elementTypes.ElementInvalid;
        for (const [ key, value ] of Object.entries(elementType)) {
            if (!Utils.hasProp(elem, key)) {
                elem[key] = value;
            }
        }
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "EMAIL_DESIGN_EDITOR")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "EMAIL_DESIGN_EDITOR_READYING":
        return {
            ...state,
            readying      : true,
        };

    case "EMAIL_DESIGN_EDITOR_READY":
        return {
            ...state,
            readying      : false,
            error         : false,
            edition       : state.edition + 1,
        };

    case "EMAIL_DESIGN_EDITOR_ELEM":
        return {
            ...state,
            canEdit       : action.data.canEdit,
            emailDesignID : action.data.emailDesignID,
            elements      : parseElements(action.data.elements, action.data.elementTypes),
            totalChanges  : action.data.elements.filter((element) => element.hasChanges).length,
            languages     : action.data.languages,
            publishErrors : action.data.publishErrors,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            elementTypes  : action.data.elementTypes,
            selects       : action.data.selects,
        };

    case "EMAIL_DESIGN_EDITOR_PUBLISH":
        return {
            ...state,
            publishErrors : action.data.publishErrors,
        };


    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
