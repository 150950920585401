import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";
import MessageDay           from "Components/App/Client/Conversation/Message/MessageDay";



// Styles
const Container = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 260px;
    padding-bottom: 16px;
    overflow: auto;
`;

const Content = Styled.section.attrs(({ isMine }) => ({ isMine }))`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.isMine ? "flex-end" : "flex-start"};
`;

const User = Styled.h3`
    margin: 0;
    padding: 2px 24px;
    font-size: 14px;
`;



/**
 * The Flow Sim Chat
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimChat(props) {
    const { submits, onOption } = props;

    const { items, lastUpdate } = Store.useState("flowSimulation");


    // The References
    const containerRef = React.useRef(null);

    // The Current State
    const [ firstScroll, setFirstScroll ] = React.useState(true);
    const [ atBottom,    setAtBottom    ] = React.useState(true);


    // A message was submitted
    React.useEffect(() => {
        setAtBottom(true);
    }, [ submits ]);

    // Scroll container
    React.useEffect(() => {
        if (atBottom && containerRef.current) {
            window.setTimeout(() => Utils.scrollToBottom(containerRef, firstScroll), 100);
            setFirstScroll(false);
        }
    }, [ lastUpdate ]);


    // Handles the Scroll
    const handleScroll = async (e) => {
        let atBottom = false;
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 50) {
            atBottom = true;
        }
        setAtBottom(atBottom);
    };



    // Do the Render
    return <Container
        ref={containerRef}
        onScroll={handleScroll}
    >
        {items.map((elem, key) => <React.Fragment key={key}>
            <MessageDay dayName={elem.dayName} />
            {!!elem.list.length && <Content isMine={elem.isMine}>
                <User>{elem.userName}</User>
                {elem.list.map((item) => <MessageBubble
                    key={item.id}
                    id={`conversation-${item.id}`}
                    isMine={elem.isMine}
                    item={item}
                    onOption={onOption}
                />)}
            </Content>}
        </React.Fragment>)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimChat.propTypes = {
    submits  : PropTypes.number.isRequired,
    onOption : PropTypes.func,
};

export default FlowSimChat;
