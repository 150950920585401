import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowInputs           from "./FlowInputs";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Form                 from "Dashboard/Components/Form/Form";
import Alert                from "Dashboard/Components/Form/Alert";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const DetailEditor = Styled(DetailList)`
    .details-content {
        padding-bottom: 0;
    }
`;

const Content = Styled(Form)`
    padding: 8px 4px;
`;

const Footer = Styled.footer`
    position: sticky;
    bottom: 0;
    padding: 8px 4px 0 4px;
    background-color: var(--content-color);
    z-index: 2;

    @media (max-width: 1200px) {
        padding-bottom: 16px;
    }
`;



/**
 * The Flow Edit Node Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowEditNode(props) {
    const { open, hasInternalTabs } = props;

    const { nodes, publishErrors, languages } = Store.useState("flowEditor");
    const { editNode } = Store.useAction("flowEditor");

    const { inPublish, selectedNode } = Store.useState("flowState");
    const { setHasChanges } = Store.useAction("flowState");


    // The Current State
    const [ data,     setData     ] = React.useState({ name : "" });
    const [ errors,   setErrors   ] = React.useState({ form : "" });
    const [ language, setLanguage ] = React.useState(languages > 0 ? languages[0].key : "es");

    // The Current Node
    const node = selectedNode ? Utils.getValue(nodes, "id", selectedNode) : {};


    // Updates the Data
    React.useEffect(() => {
        if (selectedNode) {
            const values = { name : node.name };
            for (const input of node.inputs) {
                if (input.type === "view") {
                    continue;
                }
                if (input.type === "texts") {
                    values[input.name] = node.options?.[input.name] ?? [];
                } else if (input.type === "double") {
                    for (const field of input.fields) {
                        if (node.options && node.options[field.name] !== undefined) {
                            values[field.name] = node.options[field.name];
                        } else {
                            const initialValue = [ "toggle", "select" ].includes(field.type) ? 0 : "";
                            values[field.name] = field.startValue || initialValue;
                        }
                    }
                } else if (node.options && node.options[input.name] !== undefined) {
                    values[input.name] = node.options[input.name];
                } else {
                    const initialValue = [ "toggle", "select" ].includes(input.type) ? 0 : "";
                    values[input.name] = input.startValue || initialValue;
                }
            }

            const errors = Utils.getValue(publishErrors, "id", selectedNode, "errors");
            if (inPublish && errors) {
                setErrors({ ...errors, form : "" });
            } else {
                setErrors({ form : "" });
            }

            setData(values);
        }
    }, [ selectedNode ]);


    // Show the Publish Errors
    React.useEffect(() => {
        if (inPublish) {
            const errors = Utils.getValue(publishErrors, "id", selectedNode, "errors");
            if (errors) {
                setErrors({ ...errors, form : "" });
            }
        }
    }, [ inPublish ]);


    // Handles the Input Change
    const handleChange = (name, value, newChange = true) => {
        setData({ ...data, [name] : value });
        if (newChange) {
            setHasChanges(true);
        }
    };

    // Handles the Submit
    const handleSubmit = async () => {
        setErrors({ form : "" });
        try {
            const options = { ...data };
            delete options.name;
            await editNode(node.id, data.name, JSON.stringify(options));
            setHasChanges(false);
        } catch (errors) {
            setErrors(errors);
            window.setTimeout(() => {
                if (!Utils.scrollIntoView(".form-error", "center")) {
                    Utils.scrollIntoView(".inputfield-error", "center");
                }
            }, 200);
        }
    };


    // Variables
    const showLanguages = Boolean(node.hasLanguages && languages.length > 1);


    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    return <DetailEditor
        icon={node.icon}
        message={node.message}
        hasInternalTabs={hasInternalTabs}
        collapsible="flow-edit"
    >
        {showLanguages && <TabList
            variant="lined"
            selected={language}
            onClick={setLanguage}
        >
            {languages.map(({ key, value }) => <TabItem
                key={key}
                message={value}
                value={key}
            />)}
        </TabList>}

        <Content
            error={errors.form}
            onSubmit={handleSubmit}
            noAutoFocus
        >
            <Alert
                isHidden={node.isAllowed}
                variant="warning"
                message="FLOWS_NODES_NOT_ALLOWED"
            />

            <InputField
                name="name"
                label="FLOWS_NODES_NAME"
                value={data.name}
                onChange={handleChange}
            />
            <FlowInputs
                data={data}
                errors={errors}
                language={language}
                onChange={handleChange}
            />
        </Content>
        <Footer>
            <Button
                variant="primary"
                message="GENERAL_SAVE"
                onClick={handleSubmit}
                fullWidth
            />
        </Footer>
    </DetailEditor>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowEditNode.propTypes = {
    open            : PropTypes.bool,
    hasInternalTabs : PropTypes.bool,
};

export default FlowEditNode;
