import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Plans                from "Utils/Plans";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Plan Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PlanEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editPlan } = Store.useAction("plan");


    // The Initial Data
    const initialData = {
        planID        : 0,
        planType      : "",
        name          : "",
        description   : "",
        contactAmount : "",
        planPrice     : "",
        roomPrice     : "",
        contactPrice  : "",
        setupPrice    : "",
        observations  : "",
        position      : 0,
        isDefault     : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("plan", initialData, editPlan, onSubmit);

    // Load the Data
    const { loading } = useDialog("plan", open, elemID, null, setElem);


    // Variables
    const isHotel   = Plans.isHotel(data.planType);
    const isContact = Plans.isContact(data.planType);


    // Do the Render
    return <EditDialog
        open={open}
        icon="plan"
        title={elemID ? "PLANS_EDIT_TITLE" : "PLANS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            onChange={handleChange}
            error={errors.name}
            isRequired
        />
        <InputField
            type="select"
            name="planType"
            label="GENERAL_TYPE"
            options="SELECT_PLAN_TYPES"
            value={data.planType}
            onChange={handleChange}
            error={errors.planType}
            isRequired
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />

        <InputField
            type="number"
            name="planPrice"
            icon="money"
            label="PLANS_PRICE"
            value={data.planPrice}
            error={errors.planPrice}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isHotel}
            type="number"
            name="roomPrice"
            icon="money"
            label="PLANS_ROOM_PRICE"
            value={data.roomPrice}
            error={errors.roomPrice}
            onChange={handleChange}
        />

        <InputField
            isHidden={!isContact}
            type="number"
            name="contactAmount"
            label="PLANS_CONTACT_AMOUNT"
            value={data.contactAmount}
            error={errors.contactAmount}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isContact}
            type="number"
            name="contactPrice"
            icon="money"
            label="PLANS_CONTACT_PRICE"
            value={data.contactPrice}
            error={errors.contactPrice}
            onChange={handleChange}
        />

        <InputField
            type="number"
            name="setupPrice"
            icon="money"
            label="PLANS_SETUP_PRICE"
            value={data.setupPrice}
            error={errors.setupPrice}
            onChange={handleChange}
        />

        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="isDefault"
            label="GENERAL_IS_DEFAULT"
            value={data.isDefault}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PlanEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PlanEdit;
