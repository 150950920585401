import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";



// Styles
const Container = Styled.div.attrs(({ isSelected, isDragging }) => ({ isSelected, isDragging }))`
    ${(props) => props.isSelected && `
        outline: 2px solid var(--primary-color);
    `}

    ${(props) => !props.isSelected && !props.isDragging && `
        :hover {
            outline: 2px solid var(--primary-color);
        }
    `}
`;



/**
 * The Email Design Base Element
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignBaseElem(props) {
    const { elementNumber, children } = props;

    const { selectedElement, isDragging } = Store.useState("emailDesignState");
    const { setSelectedElement } = Store.useAction("emailDesignState");


    // Handles the Click
    const handleClick = (e) => {
        setSelectedElement(elementNumber);
        e.stopPropagation();
    };


    // Do the Render
    return <Container
        isSelected={elementNumber === selectedElement}
        isDragging={isDragging}
        onClick={handleClick}
    >
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignBaseElem.propTypes = {
    elementNumber : PropTypes.number.isRequired,
    children      : PropTypes.any,
};

export default EmailDesignBaseElem;
