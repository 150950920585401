import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Components
import FlowNodeIO           from "./FlowNodeIO";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Output = Styled(FlowNodeIO)`
    flex-direction: row-reverse;
    margin-right: -6px;
`;

const Link = Styled(IconLink)`
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: auto;
    margin-right: 4px;
`;



/**
 * The Flow Node Output
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowNodeOutput(props) {
    const { node } = props;

    const navigate = Hooks.useGoto();

    const { elem } = Store.useState("flow");
    const { selectedEdge } = Store.useState("flowState");


    // Returns true if the Edge is Selected
    const isSelected = (nodeOut) => {
        return (
            selectedEdge && selectedEdge.fromNodeID === node.id &&
            selectedEdge.fromNodeOut === String(nodeOut)
        );
    };

    // Handles the Jump
    const handleJump = (e) => {
        switch (node.options.jump) {
        case "Node":
            Commons.scrollToNode(node.options.nodeID);
            break;
        case "Flow":
            navigate("SETTINGS", "FLOWS", node.options.flowID, "EDIT");
            break;
        case "Return":
            navigate("SETTINGS", "FLOWS", elem.parentFlowID, "EDIT");
            break;
        default:
        }
        e.stopPropagation();
        e.preventDefault();
    };

    // Stops the Link
    const stopLink = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };



    // Do the Render
    const canJump = node.isJump && node.options && (node.options.jump !== "Return" || elem.parentFlowID);

    return <div>
        {node.fromNodeOuts.map(({ fromNodeOut, name }) => <Output
            key={fromNodeOut}
            message={name}
            action="grabEdge"
            id={node.id}
            type={fromNodeOut}
            isSelected={isSelected(fromNodeOut)}
        />)}
        <Link
            isHidden={!canJump}
            variant="black"
            icon="link"
            onClick={stopLink}
            onMouseDown={stopLink}
            onMouseUp={handleJump}
            isSmall
        />
    </div>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowNodeOutput.propTypes = {
    node : PropTypes.object.isRequired,
};

export default FlowNodeOutput;
