import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Plans                from "Utils/Plans";
import { Client }           from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canApprove   : false,
    canImport    : false,
    canExport    : false,
    canFilter    : false,
    list         : [],
    total        : 0,
    elem         : { id : 0 },
    partners     : [],
    countries    : [],
    provinces    : {},
    defPlanID    : 0,
    plans        : [],
    addons       : [],
    applications : [],
    statuses     : [],
    filters      : {},
    hasFilters   : false,
    sort         : {
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CLIENT_LOADING" });
    },

    /**
     * Fetches the Client List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "PARTNER") {
            params.partnerID = elemID;
        }
        const data   = await Client.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CLIENT_LIST", data });
    },

    /**
     * Fetches a single Client
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchElem(dispatch, clientID) {
        const data = await Client.getOne({ clientID });
        dispatch({ type : "CLIENT_ELEM", data });
    },

    /**
     * Fetches the Client Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Client.getEditData();
        dispatch({ type : "CLIENT_EDIT", data });
    },

    /**
     * Exports the Clients
     * @param {Function} dispatch
     * @param {Object}   filters
     * @returns {String}
     */
    exportClients(dispatch, filters) {
        return Client.export(filters);
    },

    /**
     * Edits/Creates a Client
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editClient(dispatch, data) {
        return Client.edit(data);
    },

    /**
     * Approves a Client
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    approveClient(dispatch, clientID) {
        return Client.approve({ clientID });
    },

    /**
     * Deletes a Client
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    deleteClient(dispatch, clientID) {
        return Client.delete({ clientID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    const hasApplications = Commons.hasApplications(elem.applications);

    elem.createdDate          = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime      = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.contact              = Utils.concat(" ", elem.firstName, elem.lastName);
    elem.addressText          = Utils.concat(" - ", elem.countryName, elem.provinceName, elem.locality, elem.address, elem.zipCode);
    elem.nameClass            = elem.pendingApproval ? "text-yellow" : "";
    elem.campaignText         = elem.addons.Marketing?.isActive ? `${elem.campaignDispatches} / ${elem.campaignLimit}` : "-";
    elem.automationText       = hasApplications ? `${elem.automationExecutions} / ${elem.automationLimit}` : "-";
    elem.addonPriceText       = Commons.formatPrice(elem.discountAddonPrice);
    elem.applicationPriceText = Commons.formatPrice(elem.discountApplicationPrice);
    elem.totalPriceText       = Commons.formatPrice(elem.totalPrice);
    elem.basePriceText        = Plans.getBasePrice(elem.planType, elem);
    elem.chargeStartDate      = DateTime.formatIf(elem.chargeStartTime, "dashesReverse");
    elem.chargeStartText      = elem.dontCharge ? NLS.get("CLIENTS_DONT_CHARGE") : DateTime.formatIf(elem.chargeStartTime, "dashes");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CLIENT_LIST", "CLIENT_ELEM", "CLIENT_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CLIENT_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "CLIENT_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canApprove   : action.data.canApprove,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            canFilter    : action.data.canFilter,
            list         : Utils.parseList(action.data.list, parseElem),
            total        : action.data.total,
            filters      : action.data.filters,
            hasFilters   : !Utils.isEmpty(action.data.filters),
            sort         : action.data.sort,
        };

    case "CLIENT_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : parseElem(action.data.elem),
            partners     : action.data.partners,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
            defPlanID    : action.data.defPlanID,
            plans        : action.data.plans,
            addons       : action.data.addons,
            applications : action.data.applications,
            statuses     : action.data.statuses,
        };

    case "CLIENT_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            partners     : action.data.partners,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
            defPlanID    : action.data.defPlanID,
            plans        : action.data.plans,
            addons       : action.data.addons,
            applications : action.data.applications,
            statuses     : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
