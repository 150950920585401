import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { StoreOption }      from "Utils/API";



// The initial Data
const initialData = {
    fontFamily           : "",
    logoFile             : "",
    logoHeight           : 0,
    headerLeftAlign      : 0,
    headerBgColor        : "",
    headerTextColor      : "",

    showSearch           : 0,
    rightSearch          : 0,
    searchBgColor        : "",
    searchTextColor      : "",

    showBanners          : 0,
    autoSlideBanners     : 0,

    totalCategories      : 0,
    rowsPerCategory      : 0,
    productsPerCategory  : 0,

    categoriesInHome     : 0,
    categoriesInProducts : 0,
    categoriesAsSquares  : 0,
    categoriesTwoRows    : 0,

    pricesInLists        : 0,
    pricesInDialogs      : 0,
    pricesInCart         : 0,

    buttonBgColor        : "",
    buttonTextColor      : "",
    buyBgColor           : "",
    buyTextColor         : "",
};
const initialTexts = {
    headerText         : {},
    searchPlaceholder  : {},
    searchResultsTitle : {},
    searchNoneResults  : {},

    noCategory         : {},

    orderTitle         : {},
    orderEmpty         : {},
    orderNote          : {},
    confirmOrder       : {},
    cancelOrder        : {},
};
const initialErrors = {
    ...initialData,
};

// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canEdit      : false,
    elem         : {},
    options      : {},
    optionsDraft : {},
    tongues      : [],

    data         : { ...initialData },
    errors       : { ...initialErrors },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_OPTION_LOADING" });
    },

    /**
     * Fetches the Store Options
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchData(dispatch, storeID) {
        dispatch({ type : "STORE_OPTION_LOADING" });
        const data = await StoreOption.getAll({ storeID });
        dispatch({ type : "STORE_OPTION_ELEM", data });
        return data;
    },

    /**
     * Edits a Store Option
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @param {String}   option
     * @param {*}        value
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    async edit(dispatch, storeID, option, value, tongueID) {
        const data = await StoreOption.edit({ storeID, option, value, tongueID });
        if (!data.errors) {
            dispatch({ type : "STORE_EDITED", data });
        }
        return data;
    },


    /**
     * Sets the Store Option initial errors
     * @param {Function} dispatch
     * @returns {Void}
     */
    setInitialErrors(dispatch) {
        dispatch({ type : "STORE_OPTION_INITIAL_ERRORS" });
    },

    /**
     * Sets the Store Option data
     * @param {Function} dispatch
     * @param {String}   name
     * @param {*}        value
     * @param {Number}   tongueID
     * @returns {Void}
     */
    setData(dispatch, name, value, tongueID) {
        dispatch({ type : "STORE_OPTION_DATA", name, value, tongueID });
    },

    /**
     * Sets the Store Option errors
     * @param {Function} dispatch
     * @param {Object}   errors
     * @returns {Void}
     */
    setErrors(dispatch, errors) {
        dispatch({ type : "STORE_OPTION_ERRORS", errors });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_OPTION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_OPTION_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "STORE_OPTION_ELEM":
        return {
            ...state,
            loading      : false,
            error        : false,
            edition      : state.edition + 1,
            options      : action.data.options,
            optionsDraft : action.data.optionsDraft,
            tongues      : action.data.tongues,

            data         : { ...initialData, ...initialTexts, ...action.data.optionsDraft },
            errors       : { ...initialErrors },
        };


    case "STORE_OPTION_INITIAL_ERRORS":
        return {
            ...state,
            errors       : { ...initialErrors },
        };

    case "STORE_OPTION_DATA":
        if (Utils.hasProp(initialTexts, action.name)) {
            return {
                ...state,
                data     : {
                    ...state.data,
                    [action.name] : {
                        ...state.data[action.name],
                        [action.tongueID] : action.value,
                    },
                },
            };
        }
        return {
            ...state,
            data     : {
                ...state.data,
                [action.name] : action.value,
            },
        };

    case "STORE_OPTION_ERRORS":
        return {
            ...state,
            errors   : { ...action.errors },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
