import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import EmailDesignHeader    from "./View/EmailDesignHeader";
import EmailDesignDialogs   from "./View/EmailDesignDialogs";
import EmailDesignEditor    from "./Editor/EmailDesignEditor";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



/**
 * The Email Design Page
 * @returns {React.ReactElement}
 */
function EmailDesignPage() {
    const { emailDesignID } = Navigate.useParams();

    const { charging } = Store.useState("emailDesign");

    const { fetchElem } = Store.useAction("emailDesign");
    // const { setInPublish } = Store.useAction("emailDesignState");


    // Load the Data
    React.useEffect(() => {
        fetchElem(emailDesignID);
        // setInPublish(false);
    }, [ emailDesignID ]);


    // Do the Render
    return <>
        <Main withNavigation>
            <EmailDesignHeader levels={2} />

            <Content isLoading={charging}>
                <EmailDesignEditor
                    emailDesignID={emailDesignID}
                />
            </Content>
        </Main>

        <EmailDesignDialogs />
    </>;
}

export default EmailDesignPage;
