import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";



// The initial State
const initialState = {
    action           : Action.get(),
    hasChanges       : false,
    inPublish        : false,

    isDragging       : false,
    isDraggingLayout : false,
    elementCreating  : null,
    elementMoving    : null,
    selectedElement  : "",

    elementNumber    : 1,
    prevHistory      : 0,
    currentHistory   : 0,
    history          : [{}],
    actions          : {},
};



// The Actions
const actions = {
    /**
     * Sets the Email Design action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "EMAIL_DESIGN_ACTION", action });
    },

    /**
     * Sets the Email Design has changes
     * @param {Function} dispatch
     * @param {Boolean}  hasChanges
     * @returns {Void}
     */
    setHasChanges(dispatch, hasChanges) {
        dispatch({ type : "EMAIL_DESIGN_HAS_CHANGES", hasChanges });
    },

    /**
     * Sets the Email Design in Publish mode
     * @param {Function} dispatch
     * @param {Boolean}  inPublish
     * @returns {Void}
     */
    setInPublish(dispatch, inPublish) {
        dispatch({ type : "EMAIL_DESIGN_IN_PUBLISH", inPublish });
    },


    /**
     * Sets the Email Design Element Creating
     * @param {Function} dispatch
     * @param {Object}   element
     * @returns {Void}
     */
    setElementCreating(dispatch, element) {
        dispatch({ type : "EMAIL_DESIGN_ELEMENT_CREATING", element });
    },

    /**
     * Sets the Email Design Element Moving
     * @param {Function} dispatch
     * @param {Object}   element
     * @returns {Void}
     */
    setElementMoving(dispatch, element) {
        dispatch({ type : "EMAIL_DESIGN_ELEMENT_MOVING", element });
    },

    /**
     * Sets the selected Email Design Element
     * @param {Function} dispatch
     * @param {Number}   selectedElement
     * @returns {Void}
     */
    setSelectedElement(dispatch, selectedElement) {
        dispatch({ type : "EMAIL_DESIGN_SELECTED_ELEMENT", selectedElement });
    },


    /**
     * Undoes a step in the History
     * @param {Function} dispatch
     * @returns {Void}
     */
    undoHistory(dispatch) {
        dispatch({ type : "EMAIL_DESIGN_UNDO_HISTORY" });
    },

    /**
     * Redoes a step in the History
     * @param {Function} dispatch
     * @returns {Void}
     */
    redoHistory(dispatch) {
        dispatch({ type : "EMAIL_DESIGN_REDO_HISTORY" });
    },

    /**
     * Adds an Element to the Email Design
     * @param {Function} dispatch
     * @param {String}   elementType
     * @param {Number}   parentElement
     * @param {Number}   parentColumn
     * @param {Number}   afterElement
     * @returns {Void}
     */
    addElement(dispatch, elementType, parentElement, parentColumn, afterElement) {
        const element = { elementType, parentElement, parentColumn, afterElement };
        dispatch({ type : "EMAIL_DESIGN_ADD_ELEMENT", element });
    },

    /**
     * Copies an Element in the Email Design
     * @param {Function} dispatch
     * @param {Number}   elementNumber
     * @returns {Void}
     */
    copyElement(dispatch, elementNumber) {
        dispatch({ type : "EMAIL_DESIGN_COPY_ELEMENT", elementNumber });
    },

    /**
     * Moves an Element in the Email Design
     * @param {Function} dispatch
     * @param {Number}   elementNumber
     * @param {Number}   parentElement
     * @param {Number}   parentColumn
     * @param {Number}   afterElement
     * @returns {Void}
     */
    moveElement(dispatch, elementNumber, parentElement, parentColumn, afterElement) {
        const element = { elementNumber, parentElement, parentColumn, afterElement };
        dispatch({ type : "EMAIL_DESIGN_MOVE_ELEMENT", element });
    },

    /**
     * Removes an Element from the Email Design
     * @param {Function} dispatch
     * @param {Number}   elementNumber
     * @returns {Void}
     */
    removeElement(dispatch, elementNumber) {
        dispatch({ type : "EMAIL_DESIGN_REMOVE_ELEMENT", elementNumber });
    },
};



/**
 * Pushes the History
 * @param {Object} state
 * @param {Object} elements
 * @returns {Object}
 */
function pushHistory(state, elements) {
    let { prevHistory, currentHistory } = state;
    const history = Utils.clone(state.history);

    prevHistory     = currentHistory;
    currentHistory += 1;

    if (currentHistory !== history.length) {
        history.splice(currentHistory, history.length - currentHistory);
    }
    if (history.length >= 50) {
        history.shift();
    }
    history.push(elements);

    return { ...state, prevHistory, currentHistory, history };
}

/**
 * Adds an Action to the State
 * @param {Object}  state
 * @param {String}  action
 * @param {Number}  elementNumber
 * @param {Object}  data
 * @param {Boolean} isForward
 * @returns {Void}
 */
function addAction(state, action, elementNumber, data, isForward) {
    const id = isForward ? `${state.prevHistory}-${state.currentHistory}` : `${state.currentHistory}-${state.prevHistory}`;
    if (!state.actions[id]) {
        state.actions[id] = [];
    }
    state.actions[id].push({ action, elementNumber, ...data });
}

/**
 * Finds the Element
 * @param {Object} state
 * @param {Number} parentElement
 * @param {Number} parentColumn
 * @param {Number} afterElement
 * @returns {(Object|null)}
 */
function findElement(state, parentElement, parentColumn, afterElement) {
    const currHistory = Object.values(state.history[state.currentHistory]);
    for (const elem of currHistory) {
        if (elem.parentElement === parentElement &&
            elem.parentColumn === parentColumn &&
            elem.afterElement === afterElement
        ) {
            return elem;
        }
    }
    return null;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "EMAIL_DESIGN_STATE_INIT": {
        const items     = {};
        let   maxNumber = 0;
        for (const elem of action.data.elements) {
            items[elem.elementNumber] = {
                elementNumber : elem.elementNumber,
                elementType   : elem.elementType,
                parentElement : elem.parentElement,
                parentColumn  : elem.parentColumn,
                afterElement  : elem.afterElement,
            };
            if (elem.elementNumber > maxNumber) {
                maxNumber = elem.elementNumber;
            }
        }

        return {
            ...initialState,
            elementNumber    : maxNumber + 1,
            history          : [ items ],
        };
    }

    case "EMAIL_DESIGN_ACTION":
        return {
            ...state,
            action           : Action.get(action.action),
        };

    case "EMAIL_DESIGN_HAS_CHANGES":
        return {
            ...state,
            hasChanges       : action.hasChanges,
        };

    case "EMAIL_DESIGN_IN_PUBLISH":
        return {
            ...state,
            inPublish        : action.inPublish,
            action           : action.inPublish ? Action.get("ERROR") : Action.get(),
        };

    case "EMAIL_DESIGN_ELEMENT_CREATING":
        return {
            ...state,
            isDragging       : action.element !== null,
            isDraggingLayout : action.element?.isLayout || false,
            elementCreating  : action.element,
        };

    case "EMAIL_DESIGN_ELEMENT_MOVING":
        return {
            ...state,
            isDragging       : action.element !== null,
            isDraggingLayout : action.element?.isLayout || false,
            elementMoving    : action.element,
        };

    case "EMAIL_DESIGN_SELECTED_ELEMENT":
        return {
            ...state,
            selectedElement  : action.selectedElement || 0,
        };


    case "EMAIL_DESIGN_UNDO_HISTORY":
        return {
            ...state,
            selectedElement  : 0,
            prevHistory      : state.currentHistory,
            currentHistory   : state.currentHistory - 1,
        };

    case "EMAIL_DESIGN_REDO_HISTORY":
        return {
            ...state,
            selectedElement  : 0,
            prevHistory      : state.currentHistory,
            currentHistory   : state.currentHistory + 1,
        };

    case "EMAIL_DESIGN_ADD_ELEMENT": {
        const elements      = Utils.clone(state.history[state.currentHistory]);
        const elementNumber = state.elementNumber;
        const newElement    = { elementNumber, ...action.element };
        const prevElem      = findElement(state, newElement.parentElement, newElement.parentColumn, newElement.afterElement);

        elements[elementNumber] = newElement;
        if (prevElem !== null) {
            elements[prevElem.elementNumber].afterElement = elementNumber;
        }

        const result = pushHistory(state, elements);
        addAction(result, "Create", elementNumber, newElement, true);
        addAction(result, "Delete", elementNumber, {}, false);

        return {
            ...result,
            isDragging       : false,
            isDraggingLayout : false,
            elementCreating  : null,
            selectedElement  : elementNumber,
            elementNumber    : state.elementNumber + 1,
        };
    }

    case "EMAIL_DESIGN_COPY_ELEMENT": {
        const elements      = Utils.clone(state.history[state.currentHistory]);
        const elementNumber = state.elementNumber;
        const element       = elements[action.elementNumber];
        const newElement    = { ...element, elementNumber, afterElement : action.elementNumber };
        const prevElem      = findElement(state, element.parentElement, element.parentColumn, action.elementNumber);
        const children      = Object.values(elements).filter((elem) => elem.parentElement === action.elementNumber);
        const newChildren   = [];
        const newNumbers    = {};
        let   nextNumber    = elementNumber + 1;

        for (const child of children) {
            newNumbers[child.elementNumber] = nextNumber;
            nextNumber += 1;
        }

        elements[elementNumber] = newElement;
        for (const child of children) {
            const childNumber  = newNumbers[child.elementNumber];
            const afterElement = newNumbers[child.afterElement] || 0;
            const newChild     = { ...child, elementNumber : childNumber, parentElement : elementNumber, afterElement };
            elements[childNumber] = newChild;
            newChildren.push(newChild);
        }
        if (prevElem !== null) {
            elements[prevElem.elementNumber].afterElement = elementNumber;
        }

        const result = pushHistory(state, elements);
        addAction(result, "Create", elementNumber, newElement, true);
        addAction(result, "Delete", elementNumber, {}, false);
        for (const child of newChildren) {
            addAction(result, "Create", child.elementNumber, child, true);
            addAction(result, "Delete", child.elementNumber, {}, false);
        }

        return {
            ...result,
            selectedElement  : elementNumber,
            elementNumber    : nextNumber,
        };
    }

    case "EMAIL_DESIGN_MOVE_ELEMENT": {
        const { elementNumber, parentElement, parentColumn, afterElement } = action.element;
        const elements   = Utils.clone(state.history[state.currentHistory]);
        const oldElement = elements[elementNumber];
        const newElement = { ...oldElement, ...action.element };
        const nextElem   = findElement(state, oldElement.parentElement, oldElement.parentColumn, elementNumber);
        const prevElem   = findElement(state, parentElement, parentColumn, afterElement);

        elements[elementNumber] = newElement;
        if (nextElem !== null) {
            elements[nextElem.elementNumber].afterElement = oldElement.afterElement;
        }
        if (prevElem !== null) {
            elements[prevElem.elementNumber].afterElement = elementNumber;
        }

        const result = pushHistory(state, elements);
        addAction(result, "Delete", elementNumber, {}, true);
        addAction(result, "Create", elementNumber, newElement, true);
        addAction(result, "Delete", elementNumber, {}, false);
        addAction(result, "Create", elementNumber, oldElement, false);

        return {
            ...result,
            isDragging       : false,
            isDraggingLayout : false,
            elementMoving    : null,
        };
    }

    case "EMAIL_DESIGN_REMOVE_ELEMENT": {
        const elements      = Utils.clone(state.history[state.currentHistory]);
        const elementNumber = action.elementNumber;
        const element       = elements[elementNumber];
        const nextElem      = findElement(state, element.parentElement, element.parentColumn, elementNumber);
        const children      = Object.values(elements).filter((elem) => elem.parentElement === elementNumber);

        delete elements[elementNumber];
        for (const child of children) {
            delete elements[child.elementNumber];
        }
        if (nextElem !== null) {
            elements[nextElem.elementNumber].afterElement = element.afterElement;
        }

        const result = pushHistory(state, elements);
        addAction(result, "Delete", elementNumber, {}, true);
        addAction(result, "Create", elementNumber, element, false);
        for (const child of children) {
            addAction(result, "Delete", child.elementNumber, {}, true);
            addAction(result, "Create", child.elementNumber, child, false);
        }

        return {
            ...result,
            selectedElement  : 0,
        };
    }

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
