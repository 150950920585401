import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import EmailDesignInputs    from "./EmailDesignInputs";
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Alert                from "Dashboard/Components/Form/Alert";
import Form                 from "Dashboard/Components/Form/Form";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(Details)`
    padding-left: calc(var(--main-gap) * 2);
`;

const DetailEditor = Styled(DetailList)`
    .details-content {
        padding-bottom: 0;
    }
`;

const Content = Styled(Form)`
    padding: 8px;
`;

const Footer = Styled.footer`
    position: sticky;
    bottom: 0;
    padding: 8px 4px 0 4px;
    background-color: var(--content-color);
    z-index: 2;
`;



/**
 * The Email Design Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignDetails(props) {
    const { hasExternalTabs } = props;

    const { clientID, elementTypes, publishErrors, languages } = Store.useState("emailDesignEditor");
    const { viewErrors, selectedElement, history, currentHistory } = Store.useState("emailDesignState");

    const { editAction } = Store.useAction("emailDesignEditor");
    const { setHasChanges, setSelectedElement } = Store.useAction("emailDesignState");


    // The References
    const dataRef = React.useRef({ isDisabled : 0 });

    // The Current State
    const [ errors,   setErrors   ] = React.useState({ form : "" });
    const [ language, setLanguage ] = React.useState(languages > 0 ? languages[0].key : "es");

    // The Current Element
    const element     = selectedElement     ? history[currentHistory][selectedElement] : {};
    const elementType = element.elementType ? elementTypes[element.elementType] : {};

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();


    // Updates the Data
    React.useEffect(() => {
        if (selectedElement) {
            const values = { isDisabled : element.isDisabled ? 1 : 0 };
            for (const input of elementType.inputs) {
                if (input.type === "view") {
                    continue;
                }
                if (element.options && element.options[input.name] !== undefined) {
                    values[input.name] = element.options[input.name];
                } else {
                    const initialValue = [ "toggle", "select" ].includes(input.type) ? 0 : "";
                    values[input.name] = input.startValue || initialValue;
                }
            }

            const errors = Utils.getValue(publishErrors, "id", selectedElement, "errors");
            if (viewErrors && errors) {
                setErrors({ form : "", ...errors });
            } else {
                setErrors({ form : "" });
            }

            dataRef.current = values;
        }
    }, [ selectedElement ]);

    // Show the Publish Errors
    React.useEffect(() => {
        if (viewErrors) {
            const errors = Utils.getValue(publishErrors, "id", selectedElement, "errors");
            if (errors) {
                setErrors({ form : "", ...errors });
            }
        } else {
            setErrors({ form : "" });
        }
    }, [ viewErrors ]);


    // Handles the Select
    const handleSelect = (elementNumber) => {
        setSelectedElement(elementNumber);
        Utils.scrollIntoView(`.emailDesign-element-${elementNumber}`);
    };

    // Handles the Input Change
    const handleChange = (name, value, newChange = true) => {
        dataRef.current = { ...dataRef.current, [name] : value };
        if (newChange) {
            setHasChanges(true);
        }
    };

    // Handles the Submit
    const handleSubmit = async () => {
        setErrors({ form : "" });
        try {
            const options = { ...dataRef.current };
            delete options.isDisabled;
            await editAction({
                elementNumber : element.elementNumber,
                options       : JSON.stringify(options),
            });
            setHasChanges(false);
        } catch (errors) {
            setErrors(errors);
            window.setTimeout(() => {
                if (!Utils.scrollIntoView(".form-error", "center")) {
                    Utils.scrollIntoView(".inputfield-error");
                }
            }, 200);
        }
    };

    // Returns the Error Data
    const getErrorData = (elem) => {
        const name = elem.name || NLS.get(elem.message);
        const text = NLS.pluralize("GENERAL_ERROR_OPTIONS", Utils.count(elem.errors));
        elem.error = NLS.format("GENERAL_ERROR_FORMAT", name, text);
        return elem;
    };


    // Variables
    const showErrors    = Boolean(viewErrors && publishErrors.length > 0);
    const showLanguages = Boolean(elementType.hasLanguages && languages.length > 1);
    const showContainer = Boolean(showErrors || selectedElement);


    // Do the Render
    if (!showContainer) {
        return <React.Fragment />;
    }
    return <>
        <Container
            hasExternalTabs={hasExternalTabs}
            isInside
            isLarge
            stickyBottom
        >
            {showErrors && <DetailList
                icon="error"
                message="EMAIL_DESIGNS_ELEMENTS_WITH_ERRORS"
            >
                {publishErrors.map((elem) => {
                    const { id, error, icon } = getErrorData(elem);
                    return <DetailItem
                        key={id}
                        icon={icon}
                        message={error}
                        isSelected={id === selectedElement}
                        onClick={() => handleSelect(id)}
                    />;
                })}
            </DetailList>}

            {!!selectedElement && <DetailEditor
                icon={elementType.icon}
                message={NLS.get(elementType.message)}
            >
                <Content
                    error={errors.form}
                    onSubmit={handleSubmit}
                    noAutoFocus
                >
                    {showLanguages && <TabList
                        variant="lined"
                        selected={language}
                        onClick={setLanguage}
                    >
                        {languages.map(({ key, value }) => <TabItem
                            key={key}
                            message={value}
                            value={key}
                        />)}
                    </TabList>}

                    <Alert
                        isHidden={elementType.isAllowed}
                        variant="warning"
                        message="EMAIL_DESIGNS_ELEMENTS_NOT_ALLOWED"
                    />
                    <EmailDesignInputs
                        data={dataRef.current}
                        errors={errors}
                        language={language}
                        onChange={handleChange}
                        onMedia={handleMediaOpen}
                    />
                </Content>
                <Footer>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={handleSubmit}
                        fullWidth
                    />
                </Footer>
            </DetailEditor>}
        </Container>

        <MediaDialog
            open={showMedia}
            clientID={clientID}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignDetails.propTypes = {
    hasExternalTabs : PropTypes.bool,
};

export default EmailDesignDetails;
