import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import AutomationAction     from "./AutomationAction";
import AutomationEdge       from "./AutomationEdge";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    padding: 12px;
    transform-origin: top center;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: var(--automations-height-final);
    overflow: auto;
`;



/**
 * The Automation Canvas
 * @returns {React.ReactElement}
 */
function AutomationCanvas() {
    const { hasTrigger, actions } = Store.useState("automationEditor");
    const { hasChanges, selectedAction } = Store.useState("automationState");

    const { openDetails } = Store.useAction("core");

    const {
        setAction, setCreate, setToCreate,
        setSelectedAction, setActionToSelect,
    } = Store.useAction("automationState");


    // Handles the Create
    const handleCreate = (e, createPosition) => {
        if (hasChanges) {
            setAction("WARNING");
            setToCreate(createPosition);
        } else {
            setCreate(createPosition);
        }
        e.stopPropagation();
    };

    // Handles the Select
    const handleSelect = (e, actionID) => {
        e.stopPropagation();
        if (actionID === selectedAction) {
            return;
        }
        if (hasChanges) {
            setAction("WARNING");
            setActionToSelect(actionID);
        } else {
            setSelectedAction(actionID);
            openDetails();
        }
    };

    // Handles the Unselect
    const handleUnselect = (e) => {
        if (hasChanges) {
            setAction("WARNING");
            setActionToSelect(0);
        } else {
            setSelectedAction(0);
        }
        e.stopPropagation();
    };


    // Variables
    const hasAction = actions.length > 1 || (actions.length > 0 && !hasTrigger);
    const increment = !hasTrigger ? 1 : 0;


    // Do the Render
    return <Container onClick={handleUnselect}>
        {!hasTrigger && <>
            <AutomationAction
                actionID="trigger"
                icon="action"
                position="1"
                title="AUTOMATIONS_ACTIONS_TRIGGER_TITLE"
                message="AUTOMATIONS_ACTIONS_TRIGGER_TEXT"
                onClick={(e) => handleCreate(e, 0)}
                isAllowed
            />
            <AutomationEdge
                onClick={(e) => handleCreate(e, 1)}
            />
        </>}

        {actions.map((item) => <React.Fragment key={item.id}>
            <AutomationAction
                actionID={item.id}
                position={item.position + increment}
                icon={item.icon}
                color={item.color}
                title={item.title}
                message={item.description}
                isAllowed={item.isAllowed}
                isDisabled={item.isDisabled}
                hasChanges={item.hasChanges}
                onClick={(e) => handleSelect(e, item.id)}
            />
            <AutomationEdge
                isLast={hasAction && actions.length === item.position}
                onClick={(e) => handleCreate(e, item.position + 1)}
            />
        </React.Fragment>)}

        {!hasAction && <AutomationAction
            actionID="action"
            position="2"
            icon="action"
            title="GENERAL_ACTION"
            message="AUTOMATIONS_ACTIONS_ACTION_TEXT"
            onClick={(e) => handleCreate(e, 2)}
            isAllowed
        />}
    </Container>;
}

export default AutomationCanvas;
