import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";



// Styles
const Bubble = Styled(MessageBubble)`
    --bubble-padding: 0px !important;
    width: calc(100% + 12px);

    .bubble-content {
        margin-right: -12px;
    }
`;



/**
 * The Message Template Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MsgTemplatePreview(props) {
    const { clientID, getValue } = props;


    // Variables
    const message = getValue("message");
    const file    = getValue("file");
    const media   = file ? {
        fileID   : 1,
        fileText : file,
        fileUrl  : `${process.env.REACT_APP_FILES}${clientID}/${file}`,
    } : null;


    // Do the Render
    return <Details isWide>
        <DetailList
            icon="view"
            message="GENERAL_PREVIEW"
        >
            <Bubble
                message={message}
                file={media}
                time="6:45"
                isDelivered
                isMine
            />
        </DetailList>
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MsgTemplatePreview.propTypes = {
    clientID : PropTypes.number.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default MsgTemplatePreview;
