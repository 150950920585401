import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.div`
    padding: 10px;
`;



/**
 * The Email Design Button Element
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ButtonElement(props) {
    const { elem } = props;


    // Do the Render
    return <Container>
        {elem.elementNumber} - {elem.elementType}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ButtonElement.propTypes = {
    elem : PropTypes.object,
};

export default ButtonElement;
