import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Facebook             from "Utils/Facebook";
import Hooks                from "Utils/Hooks";

// Components
import ChannelSelect        from "./Options/ChannelSelect";
import ChannelInstall       from "./Options/ChannelInstall";
import ChannelConfig        from "./Options/ChannelConfig";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";



/**
 * The Channel Page
 * @returns {React.ReactElement}
 */
function ChannelPage() {
    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();

    const { loading } = Store.useState("channel");
    const { fetchEditData, addChannel } = Store.useAction("channel");


    // The Initial Data
    const initialData = {
        clientID     : clientID,
        providerCode : "",
        accessToken  : "",
        elemID       : "",
        name         : "",
        flowID       : "",
        showNames    : "None",
    };

    // The Current State
    const [ step,     setStep     ] = React.useState("select");
    const [ provider, setProvider ] = React.useState({ providerCode : "" });

    // Handles the Edit
    const handleEdit = (response) => {
        if (response.success) {
            navigate(parent);
        }
    };

    // The Form State
    const {
        loading : charging, data, errors, setData, handleChange, handleSubmit,
    } = useForm("channel", initialData, addChannel, handleEdit, false);


    // Load the Data
    React.useEffect(() => {
        fetchEditData(clientID);
        Facebook.load();
    }, []);


    // Handles the Select
    const handleSelect = (provider) => {
        setData({ providerCode : provider.providerCode, accessToken : "", elemID : "" });
        setProvider(provider);
        setStep("install");
    };

    // Handles the Install
    const handleInstall = async (accessToken, elemID = "") => {
        setData({ accessToken, elemID });
        setStep("config");
    };

    // Handles the Close
    const handleClose = () => {
        navigate(parent);
    };



    // Do the Render
    return <Main withNavigation>
        <Header
            message="CHANNELS_ADD_TITLE"
            icon="channel"
            href={parent}
        />

        <Content isLoading={loading || charging}>
            <PageContainer error={errors.form}>
                <PageAccordion
                    selected={step}
                    onChange={setStep}
                    noClose
                >
                    <AccordionItem
                        value="select"
                        message="CHANNELS_SINGULAR"
                        description="CHANNELS_CHANNEL_TEXT"
                    >
                        <ChannelSelect
                            selected={provider.providerCode}
                            onSelect={handleSelect}
                        />
                    </AccordionItem>

                    <AccordionItem
                        value="install"
                        message="CHANNELS_INSTALL_TITLE"
                        description="CHANNELS_INSTALL_TEXT"
                        isDisabled={!data.providerCode}
                    >
                        <ChannelInstall
                            data={data}
                            provider={provider}
                            onInstall={handleInstall}
                        />
                    </AccordionItem>

                    <AccordionItem
                        value="config"
                        message="CHANNELS_CONFIG_TITLE"
                        description="CHANNELS_CONFIG_TEXT"
                        isDisabled={!data.accessToken}
                    >
                        <ChannelConfig
                            data={data}
                            errors={errors}
                            onChange={handleChange}
                        />
                    </AccordionItem>
                </PageAccordion>

                <PageButtons
                    isDisabled={!data.accessToken}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                />
            </PageContainer>
        </Content>
    </Main>;
}

export default ChannelPage;
