import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Addon }            from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ADDON_LOADING" });
    },

    /**
     * Fetches the Addon List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Addon.getAll(params);
        data.sort = params;
        dispatch({ type : "ADDON_LIST", data });
    },

    /**
     * Fetches a single Addon
     * @param {Function} dispatch
     * @param {Number}   addonCode
     * @returns {Promise}
     */
    async fetchElem(dispatch, addonCode) {
        const data = await Addon.getOne({ addonCode });
        dispatch({ type : "ADDON_ELEM", data });
    },

    /**
     * Edits an Addon
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editAddon(dispatch, data) {
        return Addon.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.priceText       = Commons.formatPrice(elem.price);
    elem.hasQuantityText = Utils.toYesNo(elem.hasQuantity);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ADDON_LIST", "ADDON_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ADDON_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ADDON_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "ADDON_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
