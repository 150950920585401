import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import EmailDesignActions   from "./EmailDesignActions";
import EmailDesignAddElem   from "./EmailDesignAddElem";
import EmailDesignDetails   from "./EmailDesignDetails";
import EmailDesignCanvas    from "../Canvas/EmailDesignCanvas";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Content = Styled.main`
    flex-grow: 2;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    @media (max-width: 1200px) {
        width: auto;
    }
`;



/**
 * The Email Design Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignEditor(props) {
    const { emailDesignID } = props;

    const { readying } = Store.useState("emailDesignEditor");
    const { actions, prevHistory, currentHistory } = Store.useState("emailDesignState");

    const { fetchEditor, fetchUpdate, saveElements } = Store.useAction("emailDesignEditor");
    const { setHasChanges } = Store.useAction("emailDesignState");


    // The References
    const timerRef      = React.useRef(0);
    const addElementRef = React.useRef(null);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Remove the Auto-update
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Load the Data
    React.useEffect(() => {
        if (emailDesignID) {
            fetchEditor(emailDesignID);
            setHasChanges(false);
            setAutoUpdate();
        }
    }, [ emailDesignID ]);

    // Handle the Update
    React.useEffect(() => {
        fetchUpdate(emailDesignID);
        setAutoUpdate();
    }, [ update ]);

    // Edit de data
    React.useEffect(() => {
        const id = `${prevHistory}-${currentHistory}`;
        if (actions[id]) {
            saveElements(emailDesignID, actions[id]);
        }
    }, [ currentHistory ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };


    // Do the Render
    if (readying) {
        return <CircularLoader />;
    }
    return <Container>
        <EmailDesignActions addElementRef={addElementRef} />
        <EmailDesignAddElem addElementRef={addElementRef} />
        <Content>
            <EmailDesignCanvas />
            <EmailDesignDetails />
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignEditor.propTypes = {
    emailDesignID : PropTypes.number,
};

export default EmailDesignEditor;
