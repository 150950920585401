import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import FlowEdit             from "./Dialogs/FlowEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Flow List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowList(props) {
    const { type } = props;
    const { fetch, load } = useList("flow", type);

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const data = Store.useState("flow");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteFlow } = Store.useAction("flow");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Goes to the Flow
    const gotoFlow = (elemID, tabUrl) => {
        navigate("SETTINGS", "FLOWS", elemID, tabUrl);
    };

    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isView) {
            gotoFlow(elemID, "EDIT");
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = (response) => {
        if ((action.isCreate || action.isCopy) && response.flowID) {
            gotoFlow(response.flowID, "EDIT");
        } else {
            fetch();
        }
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteFlow(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "flowID", elemID, "name");

    return <Main>
        <Header message="FLOWS_NAME" icon="flow">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="FLOWS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"         message="GENERAL_NAME"     isTitle />
                    <TableHeader field="parentName"   message="GENERAL_PARENT"   />
                    <TableHeader field="status"       message="GENERAL_STATUS"   maxWidth="100" />
                    <TableHeader field="modifiedTime" message="GENERAL_MODIFIED" minWidth="160" maxWidth="160" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.flowID} elemID={elem.flowID}>
                        <TableCell message={elem.name}             />
                        <TableCell message={elem.parentName}       />
                        <TableCell message={elem.statusName}       textColor={elem.statusColor} />
                        <TableCell message={elem.modifiedDateTime} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="FLOWS_VIEW_TITLE"   />
                    <TableAction action="EDIT"   message="FLOWS_EDIT_TITLE"   />
                    <TableAction action="COPY"   message="FLOWS_COPY_TITLE"   />
                    <TableAction action="DELETE" message="FLOWS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <FlowEdit
            open={action.isCCE}
            isCopy={action.isCopy}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="FLOWS_DELETE_TITLE"
            message="FLOWS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default FlowList;
