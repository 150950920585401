import React                from "react";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Components
import EmailDesignEdit      from "../Dialogs/EmailDesignEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Email Design Dialogs
 * @returns {React.ReactElement}
 */
function EmailDesignDialogs() {
    const navigate = Hooks.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("emailDesign");
    const { fetchElem, publishEmailDesign, clearEmailDesign, deleteEmailDesign } = Store.useAction("emailDesign");

    const { totalChanges, publishErrors } = Store.useState("emailDesignEditor");
    const { fetchEditor } = Store.useAction("emailDesignEditor");

    const { action } = Store.useState("emailDesignState");
    const { setAction, setInPublish, setDetailsTab } = Store.useAction("emailDesignState");


    // Handles the Edit
    const handleEdit = (response) => {
        setAction();
        if (response.emailDesignID !== elem.id) {
            navigate("SETTINGS", "EMAIL_DESIGNS", response.emailDesignID);
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        if (elem.needsPublish) {
            const result = await publishEmailDesign(elem.id);
            if (result.success) {
                setInPublish(false);
                fetchEditor(elem.id);
            }
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearEmailDesign(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteEmailDesign(elem.id);
        if (result.success) {
            navigate("SETTINGS", "EMAIL_DESIGNS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        openDetails();
        setDetailsTab("editor");
    };

    // Publish Text
    const publishText = React.useMemo(() => {
        if (!elem.needsPublish) {
            return NLS.format("EMAIL_DESIGNS_PUBLISH_NO_CHANGES", elem.name);
        }
        if (totalChanges > 0) {
            return NLS.pluralize("EMAIL_DESIGNS_PUBLISH_CHANGES", totalChanges, elem.name);
        }
        return NLS.format("EMAIL_DESIGNS_PUBLISH_CHANGES", elem.name);
    }, [ elem.name, elem.needsPublish, totalChanges ]);


    // Variables
    const totalPublishErrors = publishErrors?.length || 0;


    // Do the Render
    return <>
        <EmailDesignEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishText}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="EMAIL_DESIGNS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="EMAIL_DESIGNS_DELETE_TITLE"
            message="EMAIL_DESIGNS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={totalPublishErrors > 1 ? "EMAIL_DESIGNS_ERRORS_TEXT" : "EMAIL_DESIGNS_ERROR_TEXT"}
            content={String(totalPublishErrors)}
            onClose={handleErrors}
        />
    </>;
}

export default EmailDesignDialogs;
