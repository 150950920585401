import React                from "react";
import PropTypes            from "prop-types";
import Plans                from "Utils/Plans";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableFoot            from "Dashboard/Components/Table/TableFoot";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Subscription Items View
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionItems(props) {
    const { isHidden, elem } = props;


    // Variables
    const isHotel      = Plans.isHotel(elem.planType);
    const isContact    = Plans.isContact(elem.planType);
    const addons       = elem.addons       || [];
    const applications = elem.applications || [];


    // Generates the Items
    const items = React.useMemo(() => {
        const result = [];

        if (isHotel) {
            result.push(<TableRow key="plan" elemID="plan">
                <TableCell message={elem.planName}           />
                <TableCell message={elem.roomAmount}         />
                <TableCell message={elem.roomPriceText}      />
                <TableCell message={elem.roomTotalPriceText} />
            </TableRow>);
        }
        if (isContact) {
            result.push(<TableRow key="plan" elemID="plan">
                <TableCell message={elem.planName}      />
                <TableCell message={1}                  />
                <TableCell message={elem.planPriceText} />
                <TableCell message={elem.planPriceText} />
            </TableRow>);
        }
        if (isContact && elem.extraContacts) {
            result.push(<TableRow key="contacts" elemID="contacts">
                <TableCell message="PLANS_CONTACT_PRICE_LONG" />
                <TableCell message={elem.extraContacts}       />
                <TableCell message={elem.contactPriceText}    />
                <TableCell message={elem.contactTotalText}    />
            </TableRow>);
        }

        for (const addon of addons) {
            result.push(<TableRow key={addon.key} elemID={addon.key}>
                <TableCell message={addon.name}     />
                <TableCell message={addon.quantity} />
                <TableCell message={addon.price}    />
                <TableCell message={addon.total}    />
            </TableRow>);
        }
        for (const application of applications) {
            result.push(<TableRow key={application.key} elemID={application.key}>
                <TableCell message={application.name}     />
                <TableCell message={application.quantity} />
                <TableCell message={application.price}    />
                <TableCell message={application.total}    />
            </TableRow>);
        }

        if (elem.isInitial) {
            result.push(<TableRow key="daysDiscount" elemID="daysDiscount">
                <TableCell message={elem.daysDiscountText} />
                <TableCell message={1}                     />
                <TableCell message={elem.daysPriceText}    />
                <TableCell message={elem.daysPriceText}    />
            </TableRow>);
            result.push(<TableRow key="setup" elemID="setup">
                <TableCell message="PLANS_SETUP_PRICE"   />
                <TableCell message={1}                   />
                <TableCell message={elem.setupPriceText} />
                <TableCell message={elem.setupPriceText} />
            </TableRow>);
        }
        if (elem.discount) {
            result.push(<TableRow key="discount" elemID="discount">
                <TableCell message={elem.discountText}      />
                <TableCell message={1}                      />
                <TableCell message={elem.discountPriceText} />
                <TableCell message={elem.discountPriceText} />
            </TableRow>);
        }

        return result;
    }, [ JSON.stringify(elem) ]);


    // Do the Render
    if (isHidden || !elem.id) {
        return <React.Fragment />;
    }
    return <Table noSorting notFixed>
        <TableHead>
            <TableHeader field="name"     message="GENERAL_NAME"     isTitle />
            <TableHeader field="quantity" message="GENERAL_QUANTITY" maxWidth="100" align="center" />
            <TableHeader field="price"    message="GENERAL_PRICE"    maxWidth="100" align="right"  />
            <TableHeader field="total"    message="GENERAL_TOTAL"    maxWidth="100" align="right"  />
        </TableHead>
        <TableBody>
            {items}
        </TableBody>
        <TableFoot>
            <TableHeader message="GENERAL_TOTAL" />
            <TableHeader />
            <TableHeader />
            <TableHeader message={elem.totalPriceText} />
        </TableFoot>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionItems.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    elem     : PropTypes.object.isRequired,
};

export default SubscriptionItems;
