import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Email Design Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignHeader(props) {
    const { levels } = props;

    const parent = Navigate.useParent(levels);

    const { canEdit, elem } = Store.useState("emailDesign");
    const { totalChanges, publishErrors } = Store.useState("emailDesignEditor");
    const { setAction, setInPublish } = Store.useAction("emailDesignState");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isPublish && publishErrors.length) {
            setInPublish(true);
        } else {
            setAction(action);
        }
    };


    // Do the Render
    return <Header
        message={elem.name || "EMAIL_DESIGNS_SINGULAR"}
        icon="email"
        href={parent}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                variant={totalChanges > 0 ? "primary" : "outlined"}
                action="PUBLISH"
                badge={totalChanges}
            />

            <ActionItem
                isHidden={!canEdit}
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="EDIT"
                    message="EMAIL_DESIGNS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!elem.isDraft}
                    action="COPY"
                    message="EMAIL_DESIGNS_COPY_TITLE"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="EMAIL_DESIGNS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignHeader.propTypes = {
    levels : PropTypes.number.isRequired,
};

export default EmailDesignHeader;
