import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";



// Styles
const Container = Styled.div`
    padding: 8px 0;
    text-align: center;
    color: var(--darkest-gray);
    background: var(--lighter-gray);
    border: 1px dashed var(--dark-gray);
    cursor: pointer;

    :hover {
        background: rgb(234, 248, 241);
        border-color: rgb(2, 188, 78);
    }
`;



/**
 * The Email Design Drop
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignDrop(props) {
    const { parentElement, parentColumn, afterElement, setDropElement } = props;

    const { isDragging, isDraggingLayout } = Store.useState("emailDesignState");


    // Handles the Mouse Enter
    const handleMouseEnter = () => {
        setDropElement({ parentElement, parentColumn, afterElement });
    };

    // Handles the Mouse Leave
    const handleMouseLeave = () => {
        setDropElement(null);
    };


    // Do the Render
    if (!isDragging || (isDraggingLayout && parentElement !== 0)) {
        return <React.Fragment />;
    }
    return <Container
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        {NLS.get("EMAIL_DESIGNS_DROP_ELEMENTS")}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignDrop.propTypes = {
    parentElement  : PropTypes.number.isRequired,
    parentColumn   : PropTypes.number.isRequired,
    afterElement   : PropTypes.number.isRequired,
    setDropElement : PropTypes.func.isRequired,
};

export default EmailDesignDrop;
