import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Application Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ApplicationTypeEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editApplication } = Store.useAction("applicationType");


    // The Initial Data
    const initialData = {
        applicationCode : "",
        name            : "",
        price           : 0,
        position        : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("applicationType", initialData, editApplication, onSubmit);

    // Load the Data
    const { loading } = useDialog("applicationType", open, elemCode, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="application"
        title="APPLICATIONS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <ViewField
            label="GENERAL_NAME"
            value={data.name}
        />

        <InputField
            type="number"
            name="price"
            icon="money"
            label="GENERAL_PRICE"
            value={data.price}
            error={errors.price}
            onChange={handleChange}
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ApplicationTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default ApplicationTypeEdit;
