import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { ApplicationLog }   from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : {},
    clients      : [],
    applications : [],
    filters      : {
        search          : "",
        clientID        : 0,
        applicationCode : "",
        fromDate        : "",
        toDate          : "",
    },
    sort         : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "APPLICATION_LOG_LOADING" });
    },

    /**
     * Fetches the Application Log
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        let data = null;

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await ApplicationLog.getAllForClient(params);
            break;
        case "FLOW":
            params.flowID = elemID;
            data = await ApplicationLog.getAllForFlow(params);
            break;
        case "AUTOMATION":
            params.automationID = elemID;
            data = await ApplicationLog.getAllForAutomation(params);
            break;
        case "USER":
            data = await ApplicationLog.getAllForOwner(params);
            break;
        default:
            data = await ApplicationLog.getAll(params);
            break;
        }
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "APPLICATION_LOG_LIST", data });
    },

    /**
     * Fetches a single Application Log
     * @param {Function} dispatch
     * @param {Number}   logID
     * @returns {Promise}
     */
    async fetchElem(dispatch, logID) {
        const data = await ApplicationLog.getOne({ logID });
        dispatch({ type : "APPLICATION_LOG_ELEM", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdTimeText  = DateTime.formatDate(elem.createdTime, "dashesSeconds");
    elem.errorText        = elem.isError ? NLS.get("GENERAL_FAIL") : NLS.get("GENERAL_SUCCESS");
    elem.errorClass       = elem.isError ? "text-red" : "text-green";
    elem.conversationText = elem.flowID ? `${elem.conversationID} (#${elem.conversationNumber})` : "-";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "APPLICATION_LOG_LIST", "APPLICATION_LOG_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "APPLICATION_LOG_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "APPLICATION_LOG_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : Utils.parseList(action.data.list, parseElem),
            total        : action.data.total,
            clients      : action.data.clients,
            applications : action.data.applications,
            filters      : action.data.filters,
            sort         : action.data.sort,
        };

    case "APPLICATION_LOG_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
