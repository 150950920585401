import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import EmailDesignElements  from "./EmailDesignElements";
import EmailDesignElemIcon  from "../Editor/EmailDesignElemIcon";



// Styles
const Container = Styled.section`
    flex-grow: 2;
    position: relative;
    box-sizing: border-box;
    transform-origin: top center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: var(--email-height);
    overflow: auto;
    background-color: var(--lightest-gray);
    border-radius: var(--border-radius);
`;

const Content = Styled.div`
    width: var(--email-width);
    margin: 24px auto;
    background-color: var(--content-color);
`;

const Element = Styled.div.attrs(({ isVisible }) => ({ isVisible }))`
    display: ${(props) => props.isVisible ? "block" : "none"};
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 5px;
    background-color: var(--white-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    pointer-events: none;
    transform: translate(0, 0);
    z-index: 10;
    cursor: grabbing;
`;



/**
 * The Email Design Canvas
 * @returns {React.ReactElement}
 */
function EmailDesignCanvas() {
    const { isDragging, elementCreating, elementMoving } = Store.useState("emailDesignState");
    const {
        setElementCreating, setElementMoving, setSelectedElement,
        addElement, moveElement,
    } = Store.useAction("emailDesignState");


    // The References
    const containerRef = React.useRef(null);
    const elementRef   = React.useRef(null);

    // The Current State
    const initialState = {
        isMoving      : false,
        elementType   : "",
        elementNumber : 0,
        startX        : 0,
        startY        : 0,
        diffX         : 0,
        diffY         : 0,
        posX          : 0,
        posY          : 0,
        scrollX       : 0,
        scrollY       : 0,
        dropElement   : null,
        timer         : null,
    };
    const stateRef = React.useRef({ ...initialState });

    const [ icon,    setIcon    ] = React.useState("");
    const [ message, setMessage ] = React.useState("");


    // Start the Element Create
    React.useEffect(() => {
        if (elementCreating) {
            const { elementType, icon, message, startX, startY } = elementCreating;
            const diffX = elementCreating.diffX + 5;
            const diffY = elementCreating.diffY + 10;
            startDrag({ elementType, icon, message, startX, startY, diffX, diffY });
        }
    }, [ elementCreating ]);

    // Start the Element Move
    React.useEffect(() => {
        if (elementMoving) {
            const { elementNumber, icon, message, startX, startY } = elementMoving;
            const diffX = 64 / 2 + 5;
            const diffY = 60 / 2 + 10;
            startDrag({ elementNumber, icon, message, startX, startY, diffX, diffY });
        }
    }, [ elementMoving ]);

    // Starts the Drag
    const startDrag = ({ elementType = "", elementNumber = 0, icon, message, startX, startY, diffX, diffY }) => {
        const posX = startX - diffX;
        const posY = startY - diffY;

        stateRef.current = {
            elementType, elementNumber,
            diffX, diffY, posX, posY,
            isMoving    : true,
            startX      : posX,
            startY      : posY,
            scrollX     : containerRef.current.scrollLeft,
            scrollY     : containerRef.current.scrollTop,
            dropElement : null,
            timer       : null,
        };
        setIcon(icon);
        setMessage(message);

        window.addEventListener("mousemove", handleDrag);
        window.addEventListener("mouseup",   handleDrop);
        elementRef.current.style.transform = `translate(${posX}px, ${posY}px)`;
    };

    // Handles the Drag
    const handleDrag = (e) => {
        const { isMoving, diffX, diffY } = stateRef.current;
        if (!isMoving) {
            return;
        }

        const posX = e.clientX - diffX;
        const posY = e.clientY - diffY;
        elementRef.current.style.transform = `translate(${posX}px, ${posY}px)`;
    };

    // Handles the Drop
    const handleDrop = () => {
        const { elementType, elementNumber, dropElement, timer } = stateRef.current;
        if (timer) {
            clearTimeout(timer);
        }

        window.removeEventListener("mousemove", handleDrag);
        window.removeEventListener("mouseup",   handleDrop);

        stateRef.current = { ...initialState };
        if (elementType !== "") {
            if (dropElement !== null) {
                addElement(elementType, dropElement.parentElement, dropElement.parentColumn, dropElement.afterElement);
            } else {
                setElementCreating(null);
            }
        } else if (elementNumber !== 0) {
            if (dropElement !== null) {
                moveElement(elementNumber, dropElement.parentElement, dropElement.parentColumn, dropElement.afterElement);
            } else {
                setElementMoving(null);
            }
        }
    };

    // Sets the Drop Element
    const setDropElement = (dropElement) => {
        stateRef.current.dropElement = dropElement;
    };

    // Handles the Unselect
    const handleUnselect = (e) => {
        setSelectedElement(0);
        e.stopPropagation();
    };


    // Do the Render
    return <Container
        ref={containerRef}
        onClick={handleUnselect}
    >
        <Element ref={elementRef} isVisible={isDragging}>
            <EmailDesignElemIcon
                icon={icon}
                message={message}
            />
        </Element>

        <Content>
            <EmailDesignElements
                parentElement={0}
                parentColumn={0}
                setDropElement={setDropElement}
            />
        </Content>
    </Container>;
}

export default EmailDesignCanvas;
