const strings = {

    TITLE                                   : "Conversana",
    LANGUAGES                               : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ACCEPT                          : "Accept",
    GENERAL_ACTION                          : "Action",
    GENERAL_ACTIONS                         : "Actions",
    GENERAL_ACCESS                          : "Role",
    GENERAL_ACTIVATE                        : "Activate",
    GENERAL_ADD                             : "Add",
    GENERAL_ADD_BUTTON                      : "Add button",
    GENERAL_ADD_CONDITION                   : "Add condition",
    GENERAL_ADD_EMAIL                       : "Add email",
    GENERAL_ADD_EVENT                       : "Add event",
    GENERAL_ADD_FIELD                       : "Add field",
    GENERAL_ADD_ITEM                        : "Add item",
    GENERAL_ADD_OPTION                      : "Add option",
    GENERAL_ADDRESS                         : "Address",
    GENERAL_ALL                             : "All",
    GENERAL_ALL_FEM                         : "All",
    GENERAL_AMOUNT                          : "Amount",
    GENERAL_AND                             : "and",
    GENERAL_ANY                             : "Anyone",
    GENERAL_APPLY                           : "Apply",
    GENERAL_ASSIGN                          : "Assign",
    GENERAL_BEFORE_OR_AFTER                 : "Before or after",
    GENERAL_BUTTONS                         : "Buttons",
    GENERAL_CANCEL                          : "Cancel",
    GENERAL_CANCEL_REASON                   : "Cancel reason",
    GENERAL_CELLPHONE                       : "Cellphone",
    GENERAL_CHARACTERS                      : "Characters: {0}",
    GENERAL_CHARACTERS_MAX                  : "Characters: {0}/{1}",
    GENERAL_CHAT                            : "Chat",
    GENERAL_CLEAR                           : "Clear",
    GENERAL_CLEAR_CHANGES                   : "Clear changes",
    GENERAL_CLOSE                           : "Close",
    GENERAL_CODE                            : "Code",
    GENERAL_COLOR                           : "Color",
    GENERAL_COMPLETE                        : "Complete",
    GENERAL_CONDITION                       : "Condition",
    GENERAL_CONDITION_TITLE                 : "Condition {0}",
    GENERAL_CONTINUE                        : "Continue",
    GENERAL_COPY                            : "Copy",
    GENERAL_CREATE                          : "Create",
    GENERAL_CREATED                         : "Created on",
    GENERAL_CREATED_FEM                     : "Created on",
    GENERAL_CREATED_TIME                    : "Creation date",
    GENERAL_DATE                            : "Date",
    GENERAL_DAY                             : "Day",
    GENERAL_DAYS                            : "Days",
    GENERAL_DELETE                          : "Delete",
    GENERAL_DESCRIPTION                     : "Description",
    GENERAL_DETAILS                         : "Details",
    GENERAL_DISCARD                         : "Discard",
    GENERAL_DOWNLOAD                        : "Download",
    GENERAL_DURATION                        : "Duration",
    GENERAL_EDIT                            : "Edit",
    GENERAL_EDIT_COLUMNS                    : "Edit columns",
    GENERAL_EDITOR                          : "Editor",
    GENERAL_EMAIL                           : "Email",
    GENERAL_EMAIL_CHANNEL                   : "Email channel",
    GENERAL_END                             : "End",
    GENERAL_ENVIRONMENT                     : "Environment",
    GENERAL_EXECUTE_AFTER                   : "Execute after",
    GENERAL_EXECUTIONS                      : "Executions",
    GENERAL_EXPORT                          : "Export",
    GENERAL_EXTERNAL_ID                     : "Code",
    GENERAL_FAIL                            : "Failed",
    GENERAL_FANTASY_NAME                    : "Fantasy name",
    GENERAL_FIELD                           : "Field",
    GENERAL_FIELD_SEARCH                    : "Field to search",
    GENERAL_FIELD_TITLE                     : "Field {0}",
    GENERAL_FILE                            : "File",
    GENERAL_FILTER                          : "Filter",
    GENERAL_FIRST_NAME                      : "First Name",
    GENERAL_FORMAT                          : "Format text",
    GENERAL_FORMAT_BOLD                     : "Bold",
    GENERAL_FORMAT_ITALIC                   : "Italic",
    GENERAL_FORMAT_STRIKETHROUGH            : "Strikethrough",
    GENERAL_FORMAT_CODE                     : "Code",
    GENERAL_FREE                            : "Rewarded",
    GENERAL_FROM                            : "From",
    GENERAL_FROM_DATE                       : "From date",
    GENERAL_FROM_HOUR                       : "From hour",
    GENERAL_FULL_NAME                       : "First and last name",
    GENERAL_GET_CODE                        : "Get code",
    GENERAL_HELP                            : "Help",
    GENERAL_HIDE                            : "Hide",
    GENERAL_HOUR                            : "Hour",
    GENERAL_HOURS                           : "Hours",
    GENERAL_ID                              : "ID",
    GENERAL_IMAGE                           : "Image",
    GENERAL_IMPORT                          : "Import",
    GENERAL_IMPORTING                       : "Importando: <b>{0}</b> de <b>{1}</b>",
    GENERAL_IMPORT_LINES                    : "Rows from <b>{0}</b> &nbsp; to &nbsp; <b>{1}</b> will be imported.",
    GENERAL_INFO                            : "Information",
    GENERAL_IS_DEFAULT                      : "Use by default",
    GENERAL_ISO_CODE                        : "ISO Code",
    GENERAL_KEY                             : "Key",
    GENERAL_LAST_EXECUTION                  : "Last execution",
    GENERAL_LAST_NAME                       : "Last Name",
    GENERAL_LINK                            : "Link",
    GENERAL_LOADING                         : "Loading",
    GENERAL_LOADING_DOTS                    : "Loading ...",
    GENERAL_LOG                             : "Log",
    GENERAL_LOGIN_AS                        : "Login as user",
    GENERAL_LOGIN_TIME                      : "Last login",
    GENERAL_LOGOUT                          : "Logout",
    GENERAL_LOGS                            : "Logs",
    GENERAL_LOCALITY                        : "Locality / Neighborhood",
    GENERAL_MAIN                            : "Main",
    GENERAL_MANAGE                          : "Manage",
    GENERAL_MATCH                           : "Match",
    GENERAL_MESSAGE                         : "Message",
    GENERAL_MERGE                           : "Merge",
    GENERAL_MINUTES                         : "Minutes",
    GENERAL_MODIFIED                        : "Modified on",
    GENERAL_MODIFIED_FEM                    : "Modified on",
    GENERAL_MODULE                          : "Module",
    GENERAL_MOVE                            : "Move",
    GENERAL_NAME                            : "Name",
    GENERAL_NAMES                           : "Names",
    GENERAL_NEXT_EXECUTION                  : "Next execution",
    GENERAL_NEW_PASSWORD                    : "New password",
    GENERAL_NO                              : "No",
    GENERAL_NO_NAME                         : "No name",
    GENERAL_NOBODY                          : "Nobody",
    GENERAL_NONE                            : "None",
    GENERAL_NONE_FEM                        : "None",
    GENERAL_NONE_RESULTS                    : "No search results found",
    GENERAL_OBSERVATIONS                    : "Observations",
    GENERAL_ONE_ERROR                       : "Error",
    GENERAL_ONLY_MINE                       : "Only mine",
    GENERAL_OPEN                            : "Open",
    GENERAl_OPERATOR                        : "Operator",
    GENERAL_OPTION                          : "Option",
    GENERAL_OPTION_TITLE                    : "Option {0}",
    GENERAL_OPTIONS                         : "Options",
    GENERAL_ORIGIN                          : "Origin",
    GENERAL_ORIGINS                         : "Origins",
    GENERAL_OTHER                           : "Other",
    GENERAL_PAGE_OF                         : "{0}-{1} of {2}",
    GENERAL_PAUSE                           : "Pause",
    GENERAL_PASSWORD                        : "Password",
    GENERAL_PASSWORD_CONFIRM                : "Confirm password",
    GENERAL_PARENT                          : "Parent",
    GENERAL_PENDING                         : "Pending",
    GENERAL_PLATFORM                        : "Platform",
    GENERAL_POSITION                        : "Position",
    GENERAL_PREVIEW                         : "Preview",
    GENERAL_PRICE                           : "Price",
    GENERAL_PRINT                           : "Print",
    GENERAL_PROVIDER                        : "Provider",
    GENERAL_PROVINCE                        : "Province / State",
    GENERAL_PUBLISH                         : "Publish",
    GENERAL_PUBLISH_CHANGES                 : "Publish changes",
    GENERAL_QUANTITY                        : "Quantity",
    GENERAL_REQ_PASS_CHANGE                 : "Require a password change after logging in",
    GENERAL_REMOVE                          : "Remove",
    GENERAL_REMOVE_FILTER                   : "Remove filter",
    GENERAL_REPORT                          : "Report",
    GENERAL_RESPONSE                        : "Response",
    GENERAL_RESULT                          : "Result",
    GENERAL_REQUEST                         : "Request",
    GENERAL_ROWS_PER_PAGE                   : "Rows to display                      :",
    GENERAL_SAVE                            : "Save",
    GENERAL_SEARCH                          : "Search",
    GENERAL_SEARCH_ONE                      : "Search...",
    GENERAL_SEARCHING                       : "Searching ...",
    GENERAL_SECONDS                         : "{0} seconds",
    GENERAL_SELECT                          : "Select",
    GENERAL_SELECT_FILE                     : "Select a file",
    GENERAL_SELECT_IMAGE                    : "Select an image",
    GENERAL_SELECT_PDF                      : "Select a PDF",
    GENERAL_SELECT_VIDEO                    : "Select a video",
    GENERAL_SEND                            : "Send",
    GENERAL_SEND_AT                         : "Send on",
    GENERAL_SEND_EMAILS                     : "Send emails",
    GENERAL_SEND_EMAIL_NOTIS                : "Send notifications by email",
    GENERAL_SEND_TICKETS                    : "Send new tickets",
    GENERAL_SEND_TO                         : "Send to",
    GENERAL_SEND_TO_EMAILS                  : "Emails to whom to send",
    GENERAL_SENT                            : "Sent",
    GENERAL_SENT_AT                         : "Sent on",
    GENERAL_SENT_AT_FEM                     : "Sent on",
    GENERAL_SETTINGS                        : "Settings",
    GENERAL_SMS_CHANNEL                     : "SMS channel",
    GENERAL_SOCIAL_REASON                   : "Company reason",
    GENERAL_SUCCESS                         : "Successful",
    GENERAL_SUPPORT                         : "Support",
    GENERAL_SUBJECT                         : "Subject",
    GENERAL_SUMMARY                         : "Summary",
    GENERAL_START                           : "Start",
    GENERAL_STATUS                          : "Status",
    GENERAL_TAX_ID                          : "Tax ID",
    GENERAL_TEMPLATE                        : "Template",
    GENERAL_TIME                            : "Time",
    GENERAL_TIME_ZONE                       : "Time zone",
    GENERAL_TITLE                           : "Title",
    GENERAL_TYPE                            : "Type",
    GENERAL_TO                              : "Until",
    GENERAL_TO_DATE                         : "Until date",
    GENERAL_TO_HOUR                         : "Until hour",
    GENERAL_TOTAL                           : "Total",
    GENERAL_TOTAL_DURATION                  : "Total duration",
    GENERAL_UPLOAD                          : "Upload",
    GENERAL_URL                             : "URL",
    GENERAL_USER                            : "User",
    GENERAL_VALUE                           : "Value",
    GENERAL_VARIABLES                       : "Variables",
    GENERAL_VIEW                            : "View",
    GENERAL_VIEW_MORE                       : "View more",
    GENERAL_VIEW_REFERRAL                   : "View referral",
    GENERAL_WHATSAPP_CHANNEL                : "WhatsApp channel",
    GENERAL_YES                             : "Yes",
    GENERAL_ZIP_CODE                        : "Zip Code",

    GENERAL_ERROR                           : "An unexpected error occurred. We are working on resolving it.",
    GENERAL_ERROR_FORMAT                    : "<b class='text-red'>{0}:</b> {1}",
    GENERAL_ERROR_PATH                      : "Incorrect path.",

    GENERAL_ERROR_ACCESS_EMPTY              : "You must indicate a role.",
    GENERAL_ERROR_ACCESS_INVALID            : "The indicated role is incorrect.",
    GENERAL_ERROR_BEFORE_OR_AFTER           : "You must indicate before or after.",
    GENERAL_ERROR_COLOR                     : "The color is incorrect.",
    GENERAL_ERROR_CONDITION                 : "You must indicate a valid condition.",
    GENERAL_ERROR_CONDITIONS                : "You must indicate at least one condition.",
    GENERAL_ERROR_CONDITION_MATCH           : "You must indicate to match all or any condition.",
    GENERAL_ERROR_DATE                      : "The date is incorrect.",
    GENERAL_ERROR_DATE_AMOUNT_TYPE          : "You must indicate a valid amount type.",
    GENERAL_ERROR_DATE_FUTURE               : "The date must be in the future.",
    GENERAL_ERROR_DATE_PERIOD               : "The to date must be after the from date.",
    GENERAL_ERROR_DAYS                      : "The number of days must be greater than 0.",
    GENERAL_ERROR_EMAIL_EMPTY               : "You must complete the email.",
    GENERAL_ERROR_EMAIL_INVALID             : "The indicated email is incorrect.",
    GENERAL_ERROR_EMAIL_USED                : "The indicated email is being used by another account.",
    GENERAL_ERROR_FIELD                     : "The indicated field does not exist.",
    GENERAL_ERROR_FILE                      : "The indicated file does not exist.",
    GENERAL_ERROR_FILE_EXCEL                : "The indicated file must be an Excel.",
    GENERAL_ERROR_FILE_IMAGE                : "The indicated file must be an image.",
    GENERAL_ERROR_FILE_IMPORT               : "You must include the file to import.",
    GENERAL_ERROR_FILE_READ                 : "Could not read the indicated file.",
    GENERAL_ERROR_FILE_EMPTY                : "The file must have a row for the header and a row for each contact.",
    GENERAL_ERROR_FILE_PATH                 : "You must indicate the file path.",
    GENERAL_ERROR_FILE_PDF                  : "The indicated file must be a PDF.",
    GENERAL_ERROR_FILE_SIZE                 : "The file is too big.",
    GENERAL_ERROR_FILE_VIDEO                : "The indicated file must be a video.",
    GENERAL_ERROR_FIRST_NAME                : "You must indicate the first name.",
    GENERAL_ERROR_FROM_DATE_EMPTY           : "You must indicate the from date.",
    GENERAL_ERROR_FROM_DATE_INVALID         : "The from date is invalid.",
    GENERAL_ERROR_FROM_HOUR_EMPTY           : "You must indicate the from hour.",
    GENERAL_ERROR_FROM_HOUR_INVALID         : "The from hour is invalid.",
    GENERAL_ERROR_HOURS                     : "The number of hours must be greater than 0.",
    GENERAL_ERROR_IMPORT_COLUMNS            : "You must indicate the columns to import.",
    GENERAL_ERROR_LANGUAGE_SINGULAR         : "There are errors in the language: {0}.",
    GENERAL_ERROR_LANGUAGE_PLURAL           : "There are errors in the languages: {0}.",
    GENERAL_ERROR_LAST_NAME                 : "You must indicate the last name.",
    GENERAL_ERROR_MESSAGE_LENGTH            : "The message must not exceed {0} characters.",
    GENERAL_ERROR_MINUTES                   : "The number of minutes must be greater than 0.",
    GENERAL_ERROR_MODULE                    : "The indicated module is incorrect.",
    GENERAL_ERROR_OPERATOR                  : "You must complete a valid operator.",
    GENERAL_ERROR_OPTIONS_SINGULAR          : "Incorrectly configured option",
    GENERAL_ERROR_OPTIONS_PLURAL            : "{0} incorrectly configured options",
    GENERAL_ERROR_PASSWORD_EMPTY            : "You must indicate the password.",
    GENERAL_ERROR_PASSWORD_INVALID          : "Password must be between 6 and 20 characters and contain at least one number and one letter.",
    GENERAL_ERROR_PROVINCE                  : "The indicated province/state does not exist.",
    GENERAL_ERROR_POSITION                  : "The position must be a number greater than zero.",
    GENERAL_ERROR_PRICE                     : "The indicated price is invalid.",
    GENERAL_ERROR_SECTION_SINGULAR          : "There is 1 error in the section",
    GENERAL_ERROR_SECTION_PLURAL            : "There are {0} errors in the section",
    GENERAL_ERROR_STATUS                    : "The indicated status is incorrect.",
    GENERAL_ERROR_TIME                      : "You must indicate a valid time.",
    GENERAL_ERROR_TIME_ZONE                 : "The indicated time zone is invalid.",
    GENERAL_ERROR_TO_DATE_EMPTY             : "You must indicate the to date.",
    GENERAL_ERROR_TO_DATE_INVALID           : "The to date is invalid.",
    GENERAL_ERROR_TO_HOUR_EMPTY             : "You must indicate the to hour.",
    GENERAL_ERROR_TO_HOUR_INVALID           : "The to hour is invalid.",
    GENERAL_ERROR_URL                       : "The url must start with 'https://'.",
    GENERAL_ERROR_VALUE                     : "You must complete the value.",



    // Selects
    GENERAL_YES_NO                          : {
        0 : "No",
        1 : "Yes",
    },
    GENERAL_YES_BLANK                       : {
        0 : "",
        1 : "Yes",
    },
    SELECT_ORGANIZATION                     : {
        "client"  : "Client",
        "partner" : "Partner",
    },
    SELECT_FONT_FAMILIES                    : {
        "Inter"      : "Inter",
        "Lato"       : "Lato",
        "Roboto"     : "Roboto",
        "Open Sans"  : "Open Sans",
        "Montserrat" : "Montserrat",
        "Poppins"    : "Poppins",
        "Raleway"    : "Raleway",
    },
    SELECT_INPUT_TYPES                      : {
        "text"     : "Text",
        "textarea" : "Textarea",
        "number"   : "Number",
        "email"    : "Email",
        "tel"      : "Phone",
        "date"     : "Date",
        "time"     : "Time",
        "select"   : "Select",
        "list"     : "List",
        "fields"   : "JSON List",
        "file"     : "File",
        "password" : "Password",
        "toggle"   : "Toggle",
    },
    SELECT_FILE_TYPES                       : {
        "image" : "Image",
        "video" : "Video",
        "audio" : "Audio",
        "pdf"   : "PDF",
    },
    SELECT_SHOW_NAMES                       : {
        "None"      : "Don't show",
        "FirstName" : "Show only name",
        "FullName"  : "Show name and surname",
    },
    SELECT_DATE_AMOUNT_TYPES                : {
        "Instant" : "In this instant",
        "Minutes" : "Minutes",
        "Hours"   : "Hours",
        "Days"    : "Days",
    },

    SELECT_OPERATORS                        : {
        "HasValue"    : "Has a value",
        "EqualTo"     : "Is equal to",
        "Contains"    : "Contains",
        "StartsWith"  : "Starts with",
        "EndsWith"    : "Ends with",
    },
    SELECT_OPERATORS_LIST                   : {
        "HasValue"    : "Has a value",
        "Contains"    : "Contains",
        "LengthIs"    : "Amount of elements",
    },
    SELECT_OPERATORS_DATE                   : {
        "HasValue"    : "Has a value",
        "EqualTo"     : "Is equal to",
        "LowerThan"   : "Is before than",
        "GreaterThan" : "Is after than",
    },
    SELECT_OPERATORS_BEFORE_OR_AFTER        : {
        "LowerThan"   : "Before the date",
        "GreaterThan" : "After the date",
    },
    SELECT_OPERATORS_NUMBER                 : {
        "HasValue"    : "Has a value",
        "EqualTo"     : "Is equal to",
        "GreaterThan" : "Is greater than",
        "LowerThan"   : "Is lower than",
    },
    SELECT_OPERATORS_TOGGLE                 : {
        "HasValue"    : "Has a value",
        "IsTrue"      : "Is true",
        "IsFalse"     : "Is false",
    },
    SELECT_CONDITION_MATCHES                : {
        "all" : "Match all conditions",
        "any" : "Match any condition",
    },

    SELECT_CONVERSATION_ORDERS              : {
        "lastMsgDesc" : "Last message: new to old ↑",
        "lastMsgAsc"  : "Last message: old to new ↓",
        "createdDesc" : "Created Date: new to old ↑",
        "createdAsc"  : "Created Date: old to new ↓",
    },
    SELECT_CONVERSATION_ACTIONS             : {
        "Edit"            : "<b>{0}</b> edited the conversation at <b>{2}</b>",
        "Accept"          : "<b>{0}</b> accepted the conversation at <b>{2}</b>",
        "Return"          : "<b>{0}</b> returned the conversation at <b>{2}</b>",
        "AssignUser"      : "<b>{0}</b> assigned user <b>{1}</b> at <b>{2}</b>",
        "AssignTeam"      : "<b>{0}</b> assigned team <b>{1}</b> at <b>{2}</b>",
        "UnassignTeam"    : "<b>{0}</b> deleted the team <b>{1}</b> at <b>{2}</b>",
        "AssignTag"       : "<b>{0}</b> assigned the tag <b>{1}</b> to <b>{2}</b>",
        "UnassignTag"     : "<b>{0}</b> deleted the <b>{1}</b> tag at <b>{2}</b>",
        "AssignTongue"    : "<b>{0}</b> assigned language <b>{1}</b> at <b>{2}</b>",
        "AssignHotel"     : "<b>{0}</b> assigned hotel <b>{1}</b> at <b>{2}</b>",
        "UnassignHotel"   : "<b>{0}</b> deleted the hotel at <b>{2}</b>",
        "AssignFlow"      : "<b>{0}</b> assigned the flow <b>{1}</b> at <b>{2}</b>",
        "EditContact"     : "<b>{0}</b> edited contact <b>{1}</b> at <b>{2}</b>",
        "MergeContact"    : "<b>{0}</b> merged contact to <b>{1}</b> at <b>{2}</b>",
        "AddContactTag"   : "<b>{0}</b> assigned the tag <b>{1}</b> to the contact at <b>{2}</b>",
        "EditHospitality" : "<b>{0}</b> edited hospitality <b>{1}</b> at <b>{2}</b>",
        "EditOrder"       : "<b>{0}</b> edited the order <b>#{1}</b> at <b>{2}</b>",
        "Followup"        : "<b>{0}</b> followed up the conversation at <b>{2}</b>",
        "FollowupReason"  : "<b>{0}</b> followed up the conversation at <b>{2}</b> with the reason <b>{1}</b>",
        "Progress"        : "<b>{0}</b> continued the management of the conversation at <b>{2}</b>",
        "Resolve"         : "<b>{0}</b> resolved the conversation at <b>{2}</b>",
        "Open"            : "<b>{0}</b> opened the conversation at <b>{2}</b>",
        "ClaimOpened"     : "The claim <b>#{1}</b> was started at <b>{2}</b>",
        "ClaimClosed"     : "The claim <b>#{1}</b> was closed at <b>{2}</b>",
    },
    SELECT_CONVERSATION_FOLLOWUPS           : {
        "Queue"    : "Return conversation",
        "Progress" : "Continue managing",
        "Resolved" : "Resolve conversation",
    },
    SELECT_CONVERSATION_NOTIFICATIONS       : {
        "None"    : "Do not notify",
        "Desktop" : "Send desktop notification",
        "Email"   : "Send email notification",
        "All"     : "Send desktop and email notification",
    },
    SELECT_CONVERSATION_QUALIFICATIONS      : {
        "bad"       : "Bad",
        "good"      : "Good",
        "excellent" : "Excellent",
    },
    SELECT_STORE_LINK_TYPES                 : {
        "Category" : "Category",
        "Url"      : "Url",
    },
    SELECT_CAMPAIGN_REPORTS                 : {
        "campaign" : "Campaign report",
        "flow"     : "Flow report",
    },
    SELECT_CAMPAIGN_PRIORITIES              : {
        "first"  : "First priority",
        "second" : "Second priority",
        "third"  : "Third priority",
    },
    SELECT_ASSISTANT_MODEL_TYPES            : {
        "Text"  : "Text",
        "Audio" : "Audio",
        "Image" : "Image",
    },
    SELECT_WABA_TEMPLATES_CATEGORIES        : {
        "UTILITY"        : "Utility",
        "MARKETING"      : "Marketing",
        "AUTHENTICATION" : "Authentication",
    },
    SELECT_WABA_TEMPLATES_CATEGORIES_DESC   : {
        "UTILITY"   : "<b>Utility</b> - For messages about orders or the account",
        "MARKETING" : "<b>Marketing</b> - For promotions or information about products and services",
    },
    SELECT_WABA_TEMPLATES_HEADER_TYPES      : {
        "NONE"     : "No header",
        "TEXT"     : "Text header",
        "IMAGE"    : "Image header",
        "VIDEO"    : "Video header",
        "DOCUMENT" : "Document header",
        "LOCATION" : "Location header",
    },
    SELECT_WABA_TEMPLATES_LINK_TYPES        : {
        "PHONE_NUMBER" : "Call phone number",
        "URL"          : "Visit website",
    },
    SELECT_FLOWS_ACCOUNTS                   : {
        "1" : "Only one",
        "2" : "More than one",
    },
    SELECT_FLOWS_COMBINATORS                : {
        "And" : "Has all the phrases",
        "Or"  : "Has any of the phrases",
    },
    SELECT_FLOWS_OPERATIONS                 : {
        "Select" : "Select a value",
        "Add"    : "Add a value",
        "Remove" : "Remove a value",
        "Clear"  : "Clear and add a value",
    },
    SELECT_FLOWS_JUMPS                      : {
        "Node"   : "To a node in the flow",
        "Flow"   : "To a different flow",
        "Return" : "To the previous flow",
    },
    SELECT_FLOWS_EVENTS                     : {
        "ElapsedTime"     : "Elapsed x time",
        "QueueTime"       : "Elapsed x time in queue",
        "MessageTime"     : "Elapsed x time from last message",
        "ContactTime"     : "Elapsed x time without a contact response",
        "UserTime"        : "Elapsed x time without a user response",
        "MessageReceived" : "A new message arrived",
        "MessageSent"     : "A new message was sent",
        "Assigned"        : "The conversation was assigned",
    },
    SELECT_AUTOMATIONS_EVENTS               : {
        "Create" : "Created",
        "Edit"   : "Edited",
        "Delete" : "Deleted",
    },
    SELECT_AUTOMATIONS_ACTIONS              : {
        "Create" : "Get or create",
        "Edit"   : "Create or edit",
        "Delete" : "Delete",
    },
    SELECT_AUTOMATIONS_HOSPITALITY_DATE     : {
        "AfterBooking"   : "After a booking",
        "BeforeCheckIn"  : "Before a Check IN",
        "AfterCheckIn"   : "After a Check IN",
        "BeforeCheckOut" : "Before a Check OUT",
        "AfterCheckOut"  : "After a Check OUT",
    },
    SELECT_APPLICATIONS_NUBILA_API          : {
        "EditExtension"     : "Edit the extension",
        "MoveRoomExtension" : "Move room in the extension",
        "ReleaseExtension"  : "Release the extension",
        "ReturnPhoneCalls"  : "Return the phone calls",
    },
    SELECT_APPLICATIONS_OMNITEC_HOOK        : {
        "EKey"     : "EKEY",
        "PassCode" : "PASSCODE",
    },
    SELECT_APPLICATIONS_FORZE_API           : {
        "CheckIn"  : "Generate PIN on Check IN",
        "Update"   : "Update room and/or dates",
        "CheckOut" : "Invalidate PIN on Check OUT",
    },
    SELECT_APPLICATIONS_GESTION_BOS_API     : {
        "GetContact"  : "Get the data of a contact",
        "EditContact" : "Edit the data of a contact",
    },
    SELECT_APPLICATIONS_GESTION_BOS_FIELDS  : {
        "contactCode"  : "Code",
        "socialReason" : "Social reason",
        "fantasyName"  : "Fantasy name",
        "firstName"    : "First name",
        "lastName"     : "Last name",
        "cuit"         : "CUIT",
        "email"        : "Email",
        "phone"        : "Phone",
    },
    SELECT_APPLICATIONS_TIENDA_NUBE_EVENTS  : {
        "abandoned" : "Abandoned checkout",
        "created"   : "Created sale",
        "updated"   : "Updated sale",
        "paid"      : "Paid sale",
        "packed"    : "Packed sale",
        "fulfilled" : "Fulfilled sale",
        "cancelled" : "Cancelled sale",
    },
    SELECT_PLAN_TYPES                       : {
        "Hotel"   : "By hotel room",
        "Contact" : "By contact",
    },
    SELECT_EMAIL_RESULTS                    : {
        "Sent"            : "Email sent",
        "NotProcessed"    : "Email not processed",
        "InactiveSend"    : "Inactive send",
        "NoEmails"        : "No emails to send",
        "WhiteListFilter" : "Filtered by WhiteList",
        "InvalidEmail"    : "Invalid email",
        "ProviderError"   : "Error in the provider",
    },
    SELECT_NOTIFICATION_RESULTS              : {
        "Sent"            : "Notification sent",
        "NotProcessed"    : "Notification not processed",
        "InactiveSend"    : "Inactive send",
        "NoDevices"       : "No devices to send",
        "ProviderError"   : "Error in the provider",
    },



    // Auth: Login
    LOGIN_TITLE                             : "Login to continue",
    LOGIN_RECOVER                           : "Forgot your password?",
    LOGIN_BUTTON                            : "Login",
    LOGIN_SOCIAL                            : "Or you can login with",
    LOGIN_GOOGLE                            : "Login with Google",
    LOGIN_MICROSOFT                         : "Login with Microsoft",
    LOGIN_FACEBOOK                          : "Login with Facebook",
    LOGIN_TERMS                             : "Operating in Conversana implies accepting<br> the <a class='simple-link' href='{0}' target='_blank'>terms and conditions</a> and <a class='simple-link' href='{1}' target='_blank'>privacy policy</a><br> in which the service is offered.",
    LOGIN_ERROR_SPAM                        : "You have tried too many times.",
    LOGIN_ERROR_CREDENTIALS                 : "The email or password is incorrect.",
    LOGIN_ERROR_DISABLED                    : "The login is disabled.",
    LOGIN_ERROR_GOOGLE                      : "Unable to sign in with the indicated Google account.",
    LOGIN_ERROR_MICROSOFT                   : "Unable to sign in with the indicated Microsoft account.",
    LOGIN_ERROR_FACEBOOK                    : "Unable to sign in with the indicated Facebook account.",

    // Auth: Recover Password
    RECOVER_TITLE                           : "Recover password",
    RECOVER_HELP                            : "Use the following form to recover your password. An email will be sent to the indicated email address with instructions on how to complete the process.",
    RECOVER_ACCOUNT                         : "Login with your account",
    RECOVER_BUTTON                          : "Send Email",
    RECOVER_SUCCESS                         : "Email was sent successfully.",

    // Auth: Code
    CODE_TITLE                              : "Complete code",
    CODE_HELP                               : "An email has been sent to the indicated email address with a link that you must click to generate a new password for your account, or copy and paste the code below.",
    CODE_ACCOUNT                            : "Login with your account",
    CODE_BUTTON                             : "Verify code",
    CODE_ERROR_EMPTY                        : "You must indicate the code.",
    CODE_ERROR_INVALID                      : "The given code is incorrect or was created more than 3 hours ago. You must request another one.",
    CODE_SUCCESS                            : "The code has been successfully verified.",

    // Auth: Reset Password
    RESET_TITLE                             : "Reset password",
    RESET_HELP                              : "Enter the password you want to use to access your account.",
    RESET_ACCOUNT                           : "Login with your account",
    RESET_BUTTON                            : "Reset",
    RESET_ERROR_CODE                        : "You must indicate a code.",
    RESET_ERROR_INVALID                     : "The given code is incorrect or has expired.",
    RESET_ERROR_PASSWORD                    : "You must indicate the new password.",
    RESET_ERROR_CONFIRM                     : "You must confirm the new password.",
    RESET_ERROR_MATCH                       : "Passwords do not match.",
    RESET_SUCCESS                           : "Your password was successfully reset.",

    // Auth: Change Password
    CHANGE_TITLE                            : "Change password",
    CHANGE_HELP                             : "You must change your password to a new one.",
    CHANGE_OLD_PASSWORD                     : "Current password",
    CHANGE_NEW_PASSWORD                     : "New password",
    CHANGE_BUTTON                           : "Change password",
    CHANGE_ERROR_INVALID                    : "The indicated email is incorrect or does not require changes.",
    CHANGE_ERROR_OLD                        : "You must indicate your current password.",
    CHANGE_ERROR_NEW                        : "You must indicate the new password.",
    CHANGE_ERROR_CONFIRM                    : "You must confirm the new password.",
    CHANGE_ERROR_PASSWORD                   : "The current password is incorrect.",
    CHANGE_ERROR_DIFFERENT                  : "The new password must be different from the current one.",
    CHANGE_ERROR_MATCH                      : "The passwords do not match.",
    CHANGE_SUCCESS                          : "Your password was successfully reset.",

    // Auth: Join Organization
    JOIN_TITLE                              : "Sign Up",
    JOIN_HELP                               : "Enter the email you were invited with and your password to join {0}.",
    JOIN_BUTTON                             : "Join",
    JOIN_SOCIAL                             : "Or you can signup with",
    JOIN_GOOGLE                             : "Sign Up with Google",
    JOIN_MICROSOFT                          : "Sign Up with Microsoft",
    JOIN_FACEBOOK                           : "Sign Up with Facebook",
    JOIN_ERROR_CODE                         : "You must indicate a code.",
    JOIN_ERROR_TEAM                         : "The given code is incorrect or has expired after more than 72 hours. You can request a new one be sent to you.",
    JOIN_ERROR_EMAIL                        : "The email must be the same as the one the invitation was sent to.",
    JOIN_ERROR_GOOGLE                       : "We could not get the information from Google.",
    JOIN_ERROR_MICROSOFT                    : "We could not get the information from Microsoft.",
    JOIN_ERROR_FACEBOOK                     : "We could not get the information from Facebook.",

    // Auth: Authorized
    AUTHORIZED_CLOUDBEDS_TITLE              : "Integration with Cloudbeds",
    AUTHORIZED_CLOUDBEDS_TEXT               : "The integration with Cloudbeds was successful. Thank you for completing the authorization process. You can now close this window or go to Conversana.",
    AUTHORIZED_BUTTON                       : "Go to Conversana",
    AUTHORIZED_CLOSE                        : "Close window",



    // Profile: Organizations
    ORGANIZATIONS_NAME                      : "Organizations",
    ORGANIZATIONS_SINGULAR                  : "Organization",
    ORGANIZATIONS_MEMBERS_SINGULAR          : "{0} member",
    ORGANIZATIONS_MEMBERS_PLURAL            : "{0} members",
    ORGANIZATIONS_ACCESS                    : "Access",
    ORGANIZATIONS_PENDING                   : "You have a pending invitation to this organization.",
    ORGANIZATIONS_CURRENT                   : "You are viewing this organization",
    ORGANIZATIONS_ACTIVE                    : "You can access this organization.",
    ORGANIZATIONS_NONE_AVAILABLE            : "No organizations available.",
    ORGANIZATIONS_ERROR_EXISTS              : "The indicated organization does not exist.",
    ORGANIZATIONS_ERROR_ACCEPT              : "Unable to accept the indicated invitation.",
    ORGANIZATIONS_ERROR_ACCESS              : "Unable to access the indicated organization.",
    ORGANIZATIONS_SUCCESS_ACCEPT            : "You are a member of the organization!",

    // Profile: Account
    ACCOUNT_NAME                            : "Account",
    ACCOUNT_MINE                            : "My account",
    ACCOUNT_CHANGE_AVATAR                   : "Change photo",
    ACCOUNT_CONNECTIONS_TITLE               : "Connected devices",
    ACCOUNT_CONNECTIONS_BUTTON              : "Disconnect",
    ACCOUNT_APPEARANCE_TITLE                : "Appearance",
    ACCOUNT_APPEARANCE_LIGHT                : "Light mode",
    ACCOUNT_APPEARANCE_DARK                 : "Dark mode",
    ACCOUNT_DELETE_TITLE                    : "Delete my account",
    ACCOUNT_DELETE_TEXT                     : "Do you really want to delete your account? After deleting your account you will not be able to log in to any client again and your data will be deleted.",
    ACCOUNT_DELETE_HELP                     : "After deleting the account you will not be able to enter the system again in any client and your data will be deleted.",
    ACCOUNT_ERROR_CONFIRM                   : "You need to confirm your new password.",
    ACCOUNT_ERROR_MATCH                     : "The passwords are different.",
    ACCOUNT_ERROR_AVATAR_EMPTY              : "You must indicate an image.",
    ACCOUNT_ERROR_AVATAR_SIZE               : "The image is too big, please try a smaller one.",
    ACCOUNT_ERROR_AVATAR_TYPE               : "The indicated file is not an image.",
    ACCOUNT_SUCCESS_EDIT                    : "The account was successfully edited.",
    ACCOUNT_SUCCESS_UPLOAD                  : "The avatar was successfully uploaded.",

    // Profile: Notifications
    NOTIFICATIONS_TITLE                     : "Desktop notifications",
    NOTIFICATIONS_INFO                      : "If you want to enable desktop notifications, click the following button and then click accept on the popup.",
    NOTIFICATIONS_ACTIVATE                  : "Activate notifications",
    NOTIFICATIONS_GRANTED                   : "The desktop notifications are active. You can manually disable them from the site configurations in the browser.",
    NOTIFICATIONS_DENIED                    : "The desktop notifications are denied. You can manually enable them from the site configurations in the browser.",
    NOTIFICATIONS_BAR_TEXT                  : "Do you want to receive notifications of conversations?",
    NOTIFICATIONS_BAR_ACTIVATE              : "Activate",
    NOTIFICATIONS_SUCCESS_ACTIVATE          : "The notifications were successfully activated.",

    // Profile: Email Signatures
    EMAIL_SIGNATURES_NAME                   : "Email signatures",
    EMAIL_SIGNATURES_SINGULAR               : "Email signature",
    EMAIL_SIGNATURES_TEXT                   : "Select an organization and then edit yor email signature:",
    EMAIL_SIGNATURES_NONE_AVAILABLE         : "No organizations with an email channel available.",
    EMAIL_SIGNATURES_ERROR_EDIT             : "It is not possible to save the email signature.",
    EMAIL_SIGNATURES_SUCCESS_EDIT           : "The email signature was successfully saved.",



    // Dashboard
    DASHBOARD_NAME                          : "Dashboard",
    DASHBOARD_FILTER_TITLE                  : "Filter the dashboard",
    DASHBOARD_PERSONALIZE_TITLE             : "Personalize the dashboard",
    DASHBOARD_REPORTS                       : "Reports",
    DASHBOARD_TOTAL_QUEUE                   : "In queue",
    DASHBOARD_TOTAL_QUEUE_NOW               : "In queue now",
    DASHBOARD_TOTAL_PROGRESS                : "In progress",
    DASHBOARD_TOTAL_PROGRESS_NOW            : "In progress now",
    DASHBOARD_TOTAL_FOLLOWUP                : "In followup",
    DASHBOARD_TOTAL_FOLLOWUP_NOW            : "In followup now",
    DASHBOARD_TOTAL_RESOLVED                : "Resolved",
    DASHBOARD_TOTAL_RESOLVED_NOW            : "Resolved now",
    DASHBOARD_AVG_FIRST_MESSAGE             : "First reply time",
    DASHBOARD_AVG_MESSAGE                   : "Response time",
    DASHBOARD_AVG_RESOLVED                  : "Resolution time",

    // Dashboard: Monitoring
    MONITORING_NAME                         : "Monitoring",
    MONITORING_CHANGES                      : "Changes",
    MONITORING_UPDATES                      : "Updates",
    MONITORING_LAST_UPDATE                  : "Last update",
    MONITORING_NONE_AVAILABLE               : "There is no data available.",



    // Clients
    CLIENTS_NAME                            : "Clients",
    CLIENTS_SINGULAR                        : "Client",
    CLIENTS_FILTER_TITLE                    : "Filter clients",
    CLIENTS_VIEW_TITLE                      : "View client",
    CLIENTS_CREATE_TITLE                    : "Create client",
    CLIENTS_EDIT_TITLE                      : "Edit Client",
    CLIENTS_APPROVE_TITLE                   : "Approve client",
    CLIENTS_APPROVE_TEXT                    : "Do you really want to approve the client <b>{0}</b>?",
    CLIENTS_DELETE_TITLE                    : "Delete client",
    CLIENTS_DELETE_TEXT                     : "Do you really want to delete the client <b>{0}</b>?",
    CLIENTS_CONTACT                         : "Administrative contact",
    CLIENTS_FIRST_NAME                      : "Contact name",
    CLIENTS_LAST_NAME                       : "Contact last name",
    CLIENTS_EMAIL                           : "Administrative email",
    CLIENTS_PHONE                           : "Administrative phone",
    CLIENTS_PLAN                            : "Client plan",
    CLIENTS_PLAN_VALUE_TIP                  : "You can override the plan value",
    CLIENTS_DISCOUNT_PERCENT                : "Discount percentage",
    CLIENTS_DONT_CHARGE                     : "Do not charge",
    CLIENTS_CHARGE_START_DATE               : "Charge start date",
    CLIENTS_CHARGE_START_SHORT              : "Charge start",
    CLIENTS_CAN_EDIT_HOSPITALITY            : "Can edit hospitality",
    CLIENTS_CAMPAIGN_LIMIT                  : "Notify after sending campaigns",
    CLIENTS_CAMPAIGN_DISPATCHES             : "Campaign dispatches",
    CLIENTS_AUTOMATION_LIMIT                : "Notify after executing automations",
    CLIENTS_AUTOMATION_EXECUTIONS           : "Automation executions",
    CLIENTS_BASE_PRICE                      : "<b>No plan</b> + <b>{0}</b> of addons + <b>{1}</b> of applications =<br/> <b>{3}</b> /month + <b>{2}</b> of setup",
    CLIENTS_HOTEL_PRICE                     : "<b>{0}</b> x {1} rooms + <b>{2}</b> of addons + <b>{3}</b> of applications =<br/> <b>{5}</b> /month + <b>{4}</b> of setup",
    CLIENTS_CONTACT_PRICE                   : "<b>{0}</b> ({1} /ad.) + <b>{2}</b> of addons + <b>{3}</b> of applications =<br/> <b>{5}</b> /month + <b>{4}</b> of setup",
    CLIENTS_NONE_AVAILABLE                  : "No clients available.",
    CLIENTS_ERROR_SINGULAR                  : "There is {0} error in a field. Check all tabs.",
    CLIENTS_ERROR_PLURAL                    : "There are {0} errors in the fields. Please review all tabs.",
    CLIENTS_ERROR_EXISTS                    : "The indicated client does not exist.",
    CLIENTS_ERROR_NAME                      : "You must indicate the client's name.",
    CLIENTS_ERROR_PLAN                      : "You must indicate the client's plan.",
    CLIENTS_ERROR_DISCOUNT                  : "The discount value is incorrect.",
    CLIENTS_ERROR_CHARGE_START_DATE         : "The date to start charging is invalid.",
    CLIENTS_ERROR_QUANTITY                  : "You must indicate a valid quantity.",
    CLIENTS_ERROR_APPROVE                   : "The indicated client cannot be approved.",
    CLIENTS_SUCCESS_CREATE                  : "The client was created successfully.",
    CLIENTS_SUCCESS_EDIT                    : "The client was successfully edited.",
    CLIENTS_SUCCESS_APPROVE                 : "The client was successfully approved.",
    CLIENTS_SUCCESS_DELETE                  : "The client was successfully deleted.",

    // Partners
    PARTNERS_NAME                           : "Partners",
    PARTNERS_SINGULAR                       : "Partner",
    PARTNERS_FILTER_TITLE                   : "Filter partners",
    PARTNERS_CREATE_TITLE                   : "Create partner",
    PARTNERS_EDIT_TITLE                     : "Edit partner",
    PARTNERS_VIEW_TITLE                     : "View partner",
    PARTNERS_DELETE_TITLE                   : "Delete partner",
    PARTNERS_DELETE_TEXT                    : "Do you really want to delete partner <b>{0}</b>?",
    PARTNERS_COMMISSION                     : "Commission",
    PARTNERS_CLIENTS_SINGULAR               : "1 client",
    PARTNERS_CLIENTS_PLURAL                 : "{0} clients",
    PARTNERS_CONTACT                        : "Administrative contact",
    PARTNERS_FIRST_NAME                     : "Contact name",
    PARTNERS_LAST_NAME                      : "Contact last name",
    PARTNERS_EMAIL                          : "Administrative email",
    PARTNERS_PHONE                          : "Administrative phone",
    PARTNERS_HIDE_TICKETS                   : "Hide tickets on clients",
    PARTNERS_NONE_AVAILABLE                 : "There are no partners available.",
    PARTNERS_ERROR_EXISTS                   : "The indicated partner does not exist.",
    PARTNERS_ERROR_NAME                     : "You must indicate the name of the partner.",
    PARTNERS_ERROR_COMMISSION               : "Commission must be a value between 0 and 100.",
    PARTNERS_SUCCESS_CREATE                 : "The partner was successfully created.",
    PARTNERS_SUCCESS_EDIT                   : "The partner was successfully edited.",
    PARTNERS_SUCCESS_DELETE                 : "The partner was successfully deleted.",

    // Subscriptions
    SUBSCRIPTIONS_NAME                      : "Subscriptions",
    SUBSCRIPTIONS_FILTER_TITLE              : "Filter subscriptions",
    SUBSCRIPTIONS_VIEW_TITLE                : "View subscription",
    SUBSCRIPTIONS_DELETE_TITLE              : "Delete subscription",
    SUBSCRIPTIONS_DELETE_TEXT               : "Do you really want to delete the indicated subscription?",
    SUBSCRIPTIONS_ROOMS                     : "Rooms",
    SUBSCRIPTIONS_ADDON                     : "Addon: {0}",
    SUBSCRIPTIONS_APPLICATION               : "Application: {0}",
    SUBSCRIPTIONS_DAYS_DISCOUNT_SINGULAR    : "Discount for using {0} day",
    SUBSCRIPTIONS_DAYS_DISCOUNT_PLURAL      : "Discount for using {0} days",
    SUBSCRIPTIONS_CLIENT_DISCOUNT           : "Client discount of {0}%",
    SUBSCRIPTIONS_MARKETING_CONVERSATIONS   : "Marketing Conv.",
    SUBSCRIPTIONS_UTILITY_CONVERSATIONS     : "Utility Conv.",
    SUBSCRIPTIONS_REFERRAL_CONVERSATIONS    : "Referral Conv.",
    SUBSCRIPTIONS_SERVICE_CONVERSATIONS     : "User Conv.",
    SUBSCRIPTIONS_TOTAL_MESSAGES            : "Number of messages",
    SUBSCRIPTIONS_TOTAL_SEGMENTS            : "Number of segments",
    SUBSCRIPTIONS_TOTAL_COST                : "Total cost",
    SUBSCRIPTIONS_TOTAL_RUNS                : "Executions",
    SUBSCRIPTIONS_INPUT_TOKENS              : "Input tokens",
    SUBSCRIPTIONS_OUTPUT_TOKENS             : "Output tokens",
    SUBSCRIPTIONS_TOKENS_COST               : "{0} for {1} tokens",
    SUBSCRIPTIONS_NONE_AVAILABLE            : "No subscriptions available.",
    SUBSCRIPTIONS_ERROR_EXISTS              : "The indicated subscription does not exist.",
    SUBSCRIPTIONS_SUCCESS_DELETE            : "The subscription was successfully deleted.",



    // Conversations
    CONVERSATIONS_NAME                      : "Conversations",
    CONVERSATIONS_SINGULAR                  : "Conversation",
    CONVERSATIONS_FILTER_TITLE              : "Filter conversations",
    CONVERSATIONS_VIEW_TITLE                : "View conversation",
    CONVERSATIONS_GOTO_TITLE                : "Go to conversation",
    CONVERSATIONS_CREATE_TITLE              : "Create Conversation",
    CONVERSATIONS_EDIT_TITLE                : "Edit conversation",
    CONVERSATIONS_RETURN_TITLE              : "Return conversation",
    CONVERSATIONS_FOLLOWUP_TITLE            : "Followup conversation",
    CONVERSATIONS_FOLLOWUP_TEXT             : "What do you want to do in the time indicated with the conversation?",
    CONVERSATIONS_RESOLVE_TITLE             : "Resolve conversation",
    CONVERSATIONS_DELETE_TITLE              : "Delete conversation",
    CONVERSATIONS_DELETE_TEXT               : "Do you really want to delete the conversation with the contact <b>{0}</b>?",
    CONVERSATIONS_FORWARD_TITLE             : "Forward message",
    CONVERSATIONS_FORWARD_TEXT              : "You can forward the selected message to whoever you want.",
    CONVERSATIONS_GENERATE_REPLY_TITLE      : "Generate response",
    CONVERSATIONS_GENERATE_NOTE_TITLE       : "Generate note",
    CONVERSATIONS_SUMMARIZE_TITLE           : "Summarize conversation",
    CONVERSATIONS_SELECT_ONE                : "Select a conversation from the list to be able to talk to the contact or create a new conversation to start talking to a contact.",
    CONVERSATIONS_SELECT_EMPTY              : "When contacts communicate through a channel, you can select a conversation from the list and talk to the contact. You can also create a conversation with a new or already created contact.",
    CONVERSATIONS_SELECT_CONFIGURE          : "Set up your first channel to be able to receive messages from contacts and view them here.",
    CONVERSATIONS_SELECT_NONE               : "There are no channels configured. When there is at least one channel, you can select a conversation from the list or create a new one and talk to the contact.",
    CONVERSATIONS_NUMBER                    : "Conversation number",
    CONVERSATIONS_LAST_MESSAGE              : "Last message",
    CONVERSATIONS_LAST_CONTA_MESSAGE        : "Last contact message",
    CONVERSATIONS_LAST_CONTACT_MESSAGE      : "Last contact message",
    CONVERSATIONS_LAST_USER_MESSAGE         : "Last user message",
    CONVERSATIONS_LAST_FILE                 : "Last file url",
    CONVERSATIONS_EXTERNAL_AD_ID            : "Ad ID",
    CONVERSATIONS_QUALIFICATION             : "Qualification",
    CONVERSATIONS_QUALIFICATIONS            : "Qualifications",
    CONVERSATIONS_QUALIFICATION_BAD         : "Bad",
    CONVERSATIONS_QUALIFICATION_GOOD        : "Good",
    CONVERSATIONS_QUALIFICATION_EXCELLENT   : "Excellent",
    CONVERSATIONS_CURRENT_DATE              : "Today date",
    CONVERSATIONS_NEXT_DATE                 : "Tomorrow date",
    CONVERSATIONS_ORDER_BY                  : "Order by",
    CONVERSATIONS_MARK_UNREAD               : "Mark as unread",
    CONVERSATIONS_TRANSFER_USER             : "Transfer to user",
    CONVERSATIONS_TRANSFER_TEAM             : "Transfer to team",
    CONVERSATIONS_ASSIGN_TAG                : "Assign tag",
    CONVERSATIONS_ASSIGN_LANGUAGE           : "Assign language",
    CONVERSATIONS_ASSIGN_HOTEL              : "Assign hotel",
    CONVERSATIONS_ASSIGN_FLOW               : "Assign flow",
    CONVERSATIONS_RETURN                    : "Return",
    CONVERSATIONS_PROGRESS                  : "Manage",
    CONVERSATIONS_RESOLVE                   : "Resolve",
    CONVERSATIONS_FOLLOWUP                  : "Followup",
    CONVERSATIONS_FOLLOWUP_HOURS            : "Followup in hours",
    CONVERSATIONS_FOLLOWUP_REASON           : "Followup reason",
    CONVERSATIONS_SEND_TEMPLATE             : "Send template",
    CONVERSATIONS_SEND_TEMPLATE_HELP        : "More than 24 hours have passed since the contact's last message and to continue the conversation you must send a WhatsApp-approved template.",
    CONVERSATIONS_SEND_TEMPLATE_CREATE      : "There are no available WhatsApp-approved template. You can create one using the following button:",
    CONVERSATIONS_REPLYING_TO               : "Replying to message:",
    CONVERSATIONS_ATTACHMENT                : "Attachment:",
    CONVERSATIONS_SENDING_LOCATION          : "Sending location",
    CONVERSATIONS_WRITE_TEXT                : "Write a message",
    CONVERSATIONS_NEW_FILE                  : "New file",
    CONVERSATIONS_OLD_FILE                  : "Existing file",
    CONVERSATIONS_REACT_TO                  : "React",
    CONVERSATIONS_REPLY_TO                  : "Reply",
    CONVERSATIONS_FORWARD_TO                : "Forward",
    CONVERSATIONS_FORWARD_MESSAGE           : "Additional message",
    CONVERSATIONS_GENERATE                  : "Generate",
    CONVERSATIONS_GENERATE_REPLY            : "Explain how to generate a reply",
    CONVERSATIONS_GENERATE_NOTE             : "Explain how to generate a note",
    CONVERSATIONS_GENERATE_INCLUDE_MESSAGES : "Include messages from the conversation",
    CONVERSATIONS_GENERATE_INCLUDE_NOTES    : "Include notes from the conversation",
    CONVERSATIONS_GENERATED_REPLY           : "Reply generated",
    CONVERSATIONS_GENERATED_NOTE            : "Note generated",
    CONVERSATIONS_USE_REPLY                 : "Use reply",
    CONVERSATIONS_USE_NOTE                  : "Use note",
    CONVERSATIONS_UNREAD_SINGULAR           : "{0} unread message",
    CONVERSATIONS_UNREAD_PLURAL             : "{0} unread messages",
    CONVERSATIONS_CHANNEL_ERROR             : "The contact does not have the required data to send a message.",
    CONVERSATIONS_SMS_WARNING               : "The SMS is sent to a short number that the contact cannot reply to directly. We advise you to add a message with a url telling the contact to reply from another channel.",
    CONVERSATIONS_SMS_COST                  : "SMS cost: ${0}",
    CONVERSATIONS_SMS_COST_INFO             : "Each SMS is sent and charged by segment. If the text does not contain emojis or special characters, each segment contains 153 characters, if not, only 67 characters.",
    CONVERSATIONS_BOT                       : "Bot",
    CONVERSATIONS_MESSAGE_SCHEDULED         : "Message not sent",
    CONVERSATIONS_MESSAGE_SENT              : "Message sent",
    CONVERSATIONS_MESSAGE_DELIVERED         : "Message received",
    CONVERSATIONS_MESSAGE_READ              : "Message read",
    CONVERSATIONS_MESSAGE_ERROR             : "Message with error",
    CONVERSATIONS_SUMMARY                   : "Conversation summary",
    CONVERSATIONS_NONE_AVAILABLE            : "No conversations available.",
    CONVERSATIONS_ERROR_FILTER              : "You must indicate a valid filter.",
    CONVERSATIONS_ERROR_REPLY               : "You must complete the message.",
    CONVERSATIONS_ERROR_CREATE              : "You do not have permission to create a conversation.",
    CONVERSATIONS_ERROR_CONTACT             : "You must indicate the contact with whom to start a conversation.",
    CONVERSATIONS_ERROR_CHANNEL             : "You must indicate the channel through which to start a conversation.",
    CONVERSATIONS_ERROR_EXTERNAL_ID         : "You must indicate which field to send the message to.",
    CONVERSATIONS_ERROR_EXTERNAL_AD_ID      : "You must indicate the ad ID of the Ad.",
    CONVERSATIONS_ERROR_EXISTS              : "The indicated conversation does not exist.",
    CONVERSATIONS_ERROR_LIMIT               : "You cannot have more than one conversation with the same contact in the same channel.",
    CONVERSATIONS_ERROR_ADD                 : "You do not have permission to reply to the conversation.",
    CONVERSATIONS_ERROR_SEND                : "Unable to send the indicated message.",
    CONVERSATIONS_ERROR_REACTION_ADD        : "Unable to send the indicated reaction.",
    CONVERSATIONS_ERROR_REACTION_REMOVE     : "You cannot delete the indicated reaction.",
    CONVERSATIONS_ERROR_TITLE               : "You must indicate the subject to send.",
    CONVERSATIONS_ERROR_EMAILS_EMPTY        : "You must indicate at least one email.",
    CONVERSATIONS_ERROR_EMAILS_INVALID      : "One of the indicated emails is invalid.",
    CONVERSATIONS_ERROR_MESSAGE             : "You must indicate the message to send.",
    CONVERSATIONS_ERROR_FILE_TYPE           : "File type must be image, audio, or video.",
    CONVERSATIONS_ERROR_REPLY_TO            : "The message you want to reply to does not exist.",
    CONVERSATIONS_ERROR_TEMPLATE_EMPTY      : "You must indicate a WhatsApp template.",
    CONVERSATIONS_ERROR_TEMPLATE_INVALID    : "The indicated WhatsApp template is invalid.",
    CONVERSATIONS_ERROR_TEMPLATE_SEND       : "The WhatsApp template could not be sent.",
    CONVERSATIONS_ERROR_FOLLOWUP            : "Unable to follow up on the indicated conversation.",
    CONVERSATIONS_ERROR_FOLLOWUP_HOURS      : "You must indicate the number of hours to follow up.",
    CONVERSATIONS_ERROR_FOLLOWUP_TYPE       : "You must indicate a valid followup type.",
    CONVERSATIONS_ERROR_FOLLOWUP_NOTIFY     : "You must indicate a valid notification type.",
    CONVERSATIONS_ERROR_FORWARD             : "Unable to forward the indicated message.",
    CONVERSATIONS_ERROR_FORWARD_SEND_TO     : "You must indicate who you want to send the message to.",
    CONVERSATIONS_ERROR_FORWARD_EMAIL       : "One of the indicated emails is invalid.",
    CONVERSATIONS_ERROR_FORWARD_TITLE       : "You must indicate the subject of the message.",
    CONVERSATIONS_ERROR_PROMPT              : "You must indicate how to generate the text.",
    CONVERSATIONS_ERROR_GENERATE            : "The text could not be generated. Check that the AI Assistant has no problems.",
    CONVERSATIONS_ERROR_SUMMARIZE           : "The summary could not be generated. Check that the AI Assistant has no problems.",
    CONVERSATIONS_ERROR_DELETE              : "You do not have permission to delete the indicated conversation.",
    CONVERSATIONS_SUCCESS_CREATE            : "The conversation was created successfully.",
    CONVERSATIONS_SUCCESS_RETURN            : "The conversation was returned successfully.",
    CONVERSATIONS_SUCCESS_RESOLVE           : "The conversation was resolved successfully.",
    CONVERSATIONS_SUCCESS_DELETE            : "The conversation was successfully deleted.",
    CONVERSATIONS_SUCCESS_FORWARD           : "The message was successfully forwarded.",

    // Conversations: Notes
    CONVERSATIONS_NOTES_NAME                : "Notes",
    CONVERSATIONS_NOTES_SINGULAR            : "Note",
    CONVERSATIONS_NOTES_CREATE_TITLE        : "Create note",
    CONVERSATIONS_NOTES_EDIT_TITLE          : "Edit note",
    CONVERSATIONS_NOTES_DELETE_TITLE        : "Delete note",
    CONVERSATIONS_NOTES_DELETE_TEXT         : "Do you really want to delete the note <b>{0}</b>?",
    CONVERSATIONS_NOTES_WRITE               : "Write a note",
    CONVERSATIONS_NOTES_MESSAGE             : "Content of the note",
    CONVERSATIONS_NOTES_USE_FILE            : "Use a new file",
    CONVERSATIONS_NOTES_ERROR_ADD           : "You do not have permission to create a note.",
    CONVERSATIONS_NOTES_ERROR_EDIT          : "You do not have permission to edit the indicated note.",
    CONVERSATIONS_NOTES_ERROR_DELETE        : "You do not have permission to delete the indicated note.",
    CONVERSATIONS_NOTES_ERROR_EXISTS        : "The indicated note does not exist.",
    CONVERSATIONS_NOTES_ERROR_MESSAGE       : "You must indicate the content of the note.",
    CONVERSATIONS_NOTES_SUCCESS_CREATE      : "The note was created successfully.",
    CONVERSATIONS_NOTES_SUCCESS_EDIT        : "The note was successfully edited.",
    CONVERSATIONS_NOTES_SUCCESS_DELETE      : "The note was successfully deleted.",



    // Stores
    STORES_NAME                             : "Stores",
    STORES_SINGULAR                         : "Store",
    STORES_VIEW_TITLE                       : "View store",
    STORES_CREATE_TITLE                     : "Create store",
    STORES_EDIT_TITLE                       : "Edit store",
    STORES_ACTIVATE_TITLE                   : "Activate store",
    STORES_ACTIVATE_TEXT                    : "Do you really want to activate the store <b>{0}</b>?",
    STORES_PAUSE_TITLE                      : "Pause store",
    STORES_PAUSE_TEXT                       : "Do you really want to pause the store <b>{0}</b>?",
    STORES_DELETE_TITLE                     : "Delete store",
    STORES_DELETE_TEXT                      : "Do you really want to delete the store <b>{0}</b>?",
    STORES_PUBLISH_NO_CHANGES               : "There are no changes to publish in the store <b>{0}</b>.",
    STORES_PUBLISH_CHANGES                  : "Do you really want to publish the changes to store <b>{0}</b>?",
    STORES_CLEAR_CHANGES                    : "Do you really want to clear the changes to store <b>{0}</b>?",
    STORES_ITS_NAME                         : "Store name",
    STORES_URL                              : "Store url",
    STORES_NONE_AVAILABLE                   : "There are no stores available.",
    STORES_ERROR_CREATE                     : "You do not have permission to create the indicated store.",
    STORES_ERROR_EDIT                       : "You do not have permission to edit the indicated store.",
    STORES_ERROR_EMPTY                      : "You must indicate a store.",
    STORES_ERROR_EXISTS                     : "The indicated store does not exist.",
    STORES_ERROR_NAME                       : "You must indicate the name of the store.",
    STORES_SUCCESS_CREATE                   : "The store was successfully created.",
    STORES_SUCCESS_EDIT                     : "The store was successfully edited.",
    STORES_SUCCESS_ACTIVATE                 : "The store was successfully activated.",
    STORES_SUCCESS_PUBLISH                  : "The store changed were successfully published.",
    STORES_SUCCESS_CLEAR                    : "The store changed were successfully cleared.",
    STORES_SUCCESS_PAUSED                   : "The store was successfully paused.",
    STORES_SUCCESS_DELETE                   : "The store was successfully deleted.",

    // Stores: Orders
    ORDERS_NAME                             : "Orders",
    ORDERS_SINGULAR                         : "Order",
    ORDERS_FILTER_TITLE                     : "Filter orders",
    ORDERS_VIEW_TITLE                       : "View order",
    ORDERS_EDIT_TITLE                       : "Edit order",
    ORDERS_ITS_NAME                         : "Order #{0}",
    ORDERS_NUMBER                           : "Order number",
    ORDERS_ITEMS                            : "Items",
    ORDERS_ITEMS_JSON                       : "Items (JSON)",
    ORDERS_TOTAL_PRODUCTS                   : "Total products",
    ORDERS_TOTAL_QUANTITY                   : "Total quantity",
    ORDERS_TOTAL_PRICE                      : "Total price",
    ORDERS_NONE_AVAILABLE                   : "There are no orders available.",
    ORDERS_ERROR_EXISTS                     : "The indicated order does not exist.",
    ORDERS_ERROR_EDIT                       : "You do not have permission to edit the indicated order.",
    ORDERS_ERROR_CANCEL_REASON              : "You must indicate the cancel reason.",
    ORDERS_SUCCESS_EDIT                     : "The order was successfully edited.",

    // Stores: Products
    PRODUCTS_NAME                           : "Products",
    PRODUCTS_CREATE_TITLE                   : "Create product",
    PRODUCTS_EDIT_TITLE                     : "Edit product",
    PRODUCTS_COPY_TITLE                     : "Copy product",
    PRODUCTS_DELETE_TITLE                   : "Delete product",
    PRODUCTS_DELETE_TEXT                    : "Do you really want to delete the product <b>{0}</b>?",
    PRODUCTS_ID                             : "Product ID",
    PRODUCTS_IMAGE_TIP                      : "Recommended size: 500 x 500px",
    PRODUCTS_STOCK                          : "Stock",
    PRODUCTS_UNLIMITED                      : "Unlimited",
    PRODUCTS_UNLIMITED_STOCK                : "Unlimited stock",
    PRODUCTS_NONE_AVAILABLE                 : "There are no products available.",
    PRODUCTS_ERROR_EDIT                     : "You do not have permission to edit the indicated product.",
    PRODUCTS_ERROR_DELETE                   : "You do not have permission to delete the indicated product.",
    PRODUCTS_ERROR_EXISTS                   : "The indicated product does not exist.",
    PRODUCTS_ERROR_NAME                     : "You must indicate the name of the product.",
    PRODUCTS_ERROR_EXTERNAL_ID              : "A product with the indicated code already exists.",
    PRODUCTS_ERROR_STOCK                    : "The product stock must be a number greater or equal to 0.",
    PRODUCTS_SUCCESS_CREATE                 : "The product was successfully created.",
    PRODUCTS_SUCCESS_EDIT                   : "The product was successfully edited.",
    PRODUCTS_SUCCESS_DELETE                 : "The product was successfully deleted.",

    // Stores: Categories
    CATEGORIES_NAME                         : "Categories",
    CATEGORIES_SINGULAR                     : "Category",
    CATEGORIES_CREATE_TITLE                 : "Create category",
    CATEGORIES_EDIT_TITLE                   : "Edit category",
    CATEGORIES_DELETE_TITLE                 : "Delete category",
    CATEGORIES_DELETE_TEXT                  : "Do you really want to delete the category <b>{0}</b>?",
    CATEGORIES_NONE_AVAILABLE               : "There are no categories available.",
    CATEGORIES_ERROR_EDIT                   : "You do not have permission to edit the indicated category.",
    CATEGORIES_ERROR_DELETE                 : "You do not have permission to delete the indicated category.",
    CATEGORIES_ERROR_EXISTS                 : "The indicated category does not exist.",
    CATEGORIES_ERROR_NAME                   : "You must indicate the name of the category.",
    CATEGORIES_ERROR_EXTERNAL_ID            : "A category with the indicated code already exists.",
    CATEGORIES_SUCCESS_CREATE               : "The category was successfully created.",
    CATEGORIES_SUCCESS_EDIT                 : "The category was successfully edited.",
    CATEGORIES_SUCCESS_DELETE               : "The category was successfully deleted.",

    // Stores: Banners
    BANNERS_NAME                            : "Banners",
    BANNERS_SINGULAR                        : "Banner",
    BANNERS_CREATE_TITLE                    : "Create banner",
    BANNERS_EDIT_TITLE                      : "Edit banner",
    BANNERS_DELETE_TITLE                    : "Delete banner",
    BANNERS_DELETE_TEXT                     : "Do you really want to delete the banner <b>{0}</b>?",
    BANNERS_IMAGE_MOBILE                    : "Mobile image",
    BANNERS_IMAGE_MOBILE_TIP                : "Recommended size: 600 x 400px",
    BANNERS_IMAGE_DESKTOP                   : "Desktop Image",
    BANNERS_IMAGE_DESKTOP_TIP               : "Recommended size: 800 x 250px",
    BANNERS_LINK_TYPE                       : "Link type",
    BANNERS_NONE_AVAILABLE                  : "There are no banners available.",
    BANNERS_ERROR_EXISTS                    : "The indicated banner does not exist.",
    BANNERS_ERROR_EDIT                      : "You do not have permission to edit the indicated banner.",
    BANNERS_ERROR_DELETE                    : "You do not have permission to delete the indicated banner.",
    BANNERS_ERROR_NAME                      : "You must indicate the name of the banner.",
    BANNERS_ERROR_IMAGE_MOBILE              : "You must indicate the mobile image of the banner.",
    BANNERS_ERROR_IMAGE_DESKTOP             : "You must indicate the desktop image of the banner.",
    BANNERS_ERROR_LINK_TYPE_EMPTY           : "You must indicate the link type of the banner.",
    BANNERS_ERROR_LINK_TYPE_INVALID         : "The banner link type is invalid.",
    BANNERS_ERROR_CATEGORY                  : "You must indicate the category of the banner.",
    BANNERS_ERROR_URL                       : "You must indicate the url of the banner.",
    BANNERS_SUCCESS_CREATE                  : "The banner was successfully created.",
    BANNERS_SUCCESS_EDIT                    : "The banner was successfully edited.",
    BANNERS_SUCCESS_DELETE                  : "The banner was successfully deleted.",

    // Stores: Personalize
    PERSONALIZE_NAME                        : "Personalize",
    PERSONALIZE_MAIN_TITLE                  : "Main",
    PERSONALIZE_MAIN_DESC                   : "Main design options",
    PERSONALIZE_FONT_FAMILY                 : "Font",
    PERSONALIZE_HEADER_TITLE                : "Header",
    PERSONALIZE_HEADER_DESC                 : "Header diseño options",
    PERSONALIZE_HEADER_LEFT_ALIGN           : "Align left the header",
    PERSONALIZE_HEADER_LOGO_FILE            : "Logo image",
    PERSONALIZE_HEADER_LOGO_HEIGHT          : "Logo height",
    PERSONALIZE_HEADER_TEXT                 : "Header text",
    PERSONALIZE_HEADER_BG_COLOR             : "Header background color",
    PERSONALIZE_HEADER_TEXT_COLOR           : "Header text color",
    PERSONALIZE_SEARCH_TITLE                : "Search",
    PERSONALIZE_SEARCH_DESC                 : "Search design options",
    PERSONALIZE_SHOW_SEARCH                 : "Show search",
    PERSONALIZE_RIGHT_SEARCH                : "Search on the right side",
    PERSONALIZE_SEARCH_BG_COLOR             : "Search background color",
    PERSONALIZE_SEARCH_TEXT_COLOR           : "Search text color",
    PERSONALIZE_SEARCH_PLACEHOLDER          : "Search text",
    PERSONALIZE_SEARCH_RESULTS_TITLE        : "Search results title",
    PERSONALIZE_SEARCH_NONE_RESULTS         : "Text when there are no results",
    PERSONALIZE_HOME_TITLE                  : "Home",
    PERSONALIZE_HOME_DESC                   : "Home design options",
    PERSONALIZE_SHOW_BANNERS                : "Show banners",
    PERSONALIZE_AUTO_SLIDE_BANNERS          : "Auto slide the banners",
    PERSONALIZE_TOTAL_CATEGORIES            : "Total categories to show",
    PERSONALIZE_TOTAL_CATEGORIES_TIP        : "Use 0 to show all.",
    PERSONALIZE_ROWS_PER_CATEGORY           : "Rows per category",
    PERSONALIZE_ROWS_PER_CATEGORY_TIP       : "Use 0 to show all.",
    PERSONALIZE_PRODUCTS_PER_CATEGORY       : "Products per category",
    PERSONALIZE_PRODUCTS_PER_CATEGORY_TIP   : "Use 0 to show all.",
    PERSONALIZE_CATEGORY_TITLE              : "Categories",
    PERSONALIZE_CATEGORY_DESC               : "Options for the categories bar",
    PERSONALIZE_CATEGORIES_IN_HOME          : "Show in home",
    PERSONALIZE_CATEGORIES_IN_PRODUCTS      : "Show in products",
    PERSONALIZE_CATEGORIES_AS_SQUARES       : "Show as squares",
    PERSONALIZE_CATEGORIES_TWO_ROWS         : "Show in two rows",
    PERSONALIZE_PRICES_TITLE                : "Prices",
    PERSONALIZE_PRICES_DESC                 : "Options for the prices display",
    PERSONALIZE_PRICES_IN_LISTS             : "Show in lists",
    PERSONALIZE_PRICES_IN_DIALOGS           : "Show in dialogs",
    PERSONALIZE_PRICES_IN_CART              : "Show in cart",
    PERSONALIZE_BUTTONS_TITLE               : "Buttons",
    PERSONALIZE_BUTTONS_TEXT                : "Buttons design options",
    PERSONALIZE_BUTTON_BG_COLOR             : "Button background color",
    PERSONALIZE_BUTTON_TEXT_COLOR           : "Button text color",
    PERSONALIZE_BUY_BG_COLOR                : "Buy button background color",
    PERSONALIZE_BUY_TEXT_COLOR              : "Buy button text color",
    PERSONALIZE_ORDER_TITLE                 : "Order",
    PERSONALIZE_ORDER_DESC                  : "Order design options",
    PERSONALIZE_ORDER_ITS_TITLE             : "Order title",
    PERSONALIZE_ORDER_EMPTY                 : "Text when the order is empty",
    PERSONALIZE_ORDER_NOTE                  : "Note when the order has products",
    PERSONALIZE_CONFIRM_ORDER               : "Text to confirm the order",
    PERSONALIZE_CANCEL_ORDER                : "Text to cancel the order",



    // Contacts
    CONTACTS_NAME                           : "Contacts",
    CONTACTS_SINGULAR                       : "Contact",
    CONTACTS_FILTER_TITLE                   : "Filter contacts",
    CONTACTS_EXPORT_TITLE                   : "Export contacts",
    CONTACTS_IMPORT_TITLE                   : "Import contacts",
    CONTACTS_CREATE_TITLE                   : "Create contact",
    CONTACTS_VIEW_TITLE                     : "View contact",
    CONTACTS_EDIT_TITLE                     : "Edit contact",
    CONTACTS_MERGE_TITLE                    : "Merge contacts",
    CONTACTS_MERGE_TEXT                     : "Select the fields you want for each contact",
    CONTACTS_COMBINE_TITLE                  : "Contacts to combine",
    CONTACTS_COMBINE_TEXT                   : "Select the contacts you want to combine with the current contact.",
    CONTACTS_BLOCK_TITLE                    : "Block contact",
    CONTACTS_BLOCK_TEXT                     : "Do you really want to block the contact <b>{0}</b>?<br><br>If the contact is blocked, you will not receive their messages nor can you send them messages.",
    CONTACTS_UNBLOCK_TITLE                  : "Unblock contact",
    CONTACTS_UNBLOCK_TEXT                   : "Do you really want to unblock the contact <b>{0}</b>?",
    CONTACTS_DELETE_TITLE                   : "Delete contact",
    CONTACTS_DELETE_TEXT                    : "Do you really want to delete the contact <b>{0}</b>?",
    CONTACTS_MULTI_DELETE_TITLE             : "Delete Contacts",
    CONTACTS_MULTI_DELETE_TEXT_SINGULAR     : "Do you really want to delete <b>{0}</b> contact?",
    CONTACTS_MULTI_DELETE_TEXT_PLURAL       : "Do you really want to delete <b>{0}</b> contacts?",
    CONTACTS_ID                             : "Contact ID",
    CONTACTS_ADD_EMAIL                      : "Add email",
    CONTACTS_ADD_CELLPHONE                  : "Add cellphone",
    CONTACTS_INSTAGRAM                      : "Instagram",
    CONTACTS_TELEGRAM                       : "Telegram",
    CONTACTS_ADDRESS                        : "Address",
    CONTACTS_LATITUDE                       : "Latitude",
    CONTACTS_LONGITUDE                      : "Longitude",
    CONTACTS_DONT_SEND_CAMPAIGNS            : "Do not send campaigns",
    CONTACTS_BLOCKED                        : "Blocked contact",
    CONTACTS_IMPORT_FILE                    : "• Contacts can be imported from an Excel file (.xlsx).<br>• The data must be in the first sheet of the excel.<br>• The first row must contain the column headers and then have one row per contact.",
    CONTACTS_IMPORT_COLUMNS                 : "For each contact field indicate the column of the file.",
    CONTACTS_IMPORT_WARNING                 : "<b>{0}</b> contacts were imported out of <b>{1}</b> indicated. The following contacts could not be imported due to duplicate email or cellphone.",
    CONTACTS_IMPORT_SUCCESS                 : "<b>{0}</b> contacts were successfully imported.",
    CONTACTS_IMPORT_CANCELED                : "The import was canceled after importing <b>{0}</b> of <b>{1}</b> contacts.",
    CONTACTS_NONE_AVAILABLE                 : "No contacts available.",
    CONTACTS_ERROR_IMPORT                   : "You do not have permission to import contacts.",
    CONTACTS_ERROR_COLUMNS_NAME             : "You must indicate a column as the name.",
    CONTACTS_ERROR_EDIT                     : "You do not have permission to edit the indicated contact.",
    CONTACTS_ERROR_EXISTS                   : "The indicated contact does not exist.",
    CONTACTS_ERROR_NAME                     : "You must indicate a first or last name.",
    CONTACTS_ERROR_EMAIL                    : "There is already another contact with the indicated email.",
    CONTACTS_ERROR_CELLPHONE_NUMBER         : "The cellphone must be numeric.",
    CONTACTS_ERROR_CELLPHONE_REPEAT         : "There is already another contact with the indicated cell phone.",
    CONTACTS_ERROR_COUNTRY_EMPTY            : "You must indicate the code for the cellphone.",
    CONTACTS_ERROR_COUNTRY_INVALID          : "The code for the indicated cellphone does not exist.",
    CONTACTS_ERROR_ORIGIN                   : "The indicated origin does not exist.",
    CONTACTS_ERROR_EXTERNAL_ID              : "There is already a contact with the indicated code.",
    CONTACTS_ERROR_MERGE                    : "Unable to merge the indicated contacts.",
    CONTACTS_ERROR_BLOCK                    : "Unable to block a blocked contact.",
    CONTACTS_ERROR_UNBLOCK                  : "Unable to unblock a not blocked contact.",
    CONTACTS_ERROR_DELETE                   : "You do not have permission to delete the indicated contact.",
    CONTACTS_ERROR_MULTI_DELETE             : "Unable to delete the indicated contacts.",
    CONTACTS_WARNING_MULTI_DELETE           : "Some but not all of the indicated contacts have been deleted.",
    CONTACTS_SUCCESS_CREATE                 : "The contact was created successfully.",
    CONTACTS_SUCCESS_EDIT                   : "The contact was successfully edited.",
    CONTACTS_SUCCESS_MERGE                  : "The contacts were successfully merged.",
    CONTACTS_SUCCESS_BLOCK                  : "The contact was successfully blocked.",
    CONTACTS_SUCCESS_UNBLOCK                : "The contact was successfully unblocked.",
    CONTACTS_SUCCESS_DELETE                 : "The contact was successfully deleted.",
    CONTACTS_SUCCESS_MULTI_DELETE           : "The contacts were successfully deleted.",

    // Hospitality
    HOSPITALITY_NAME                        : "Hospitality",
    HOSPITALITY_SINGULAR                    : "Hospitality",
    HOSPITALITY_FILTER_TITLE                : "Filter hospitality",
    HOSPITALITY_VIEW_TITLE                  : "View hospitality",
    HOSPITALITY_CREATE_TITLE                : "Create hospitality",
    HOSPITALITY_EDIT_TITLE                  : "Edit hospitality",
    HOSPITALITY_DELETE_TITLE                : "Delete hospitality",
    HOSPITALITY_DELETE_TEXT                 : "Do you really want to delete the hospitality <b>{0}</b>?",
    HOSPITALITY_IMPORT_TITLE                : "Import hospitality",
    HOSPITALITY_EXPORT_TITLE                : "Export hospitality",
    HOSPITALITY_ID                          : "Hospitality ID",
    HOSPITALITY_STATUS                      : "Hospitality status",
    HOSPITALITY_BOOKING                     : "Booking",
    HOSPITALITY_CHECK_IN                    : "Check IN",
    HOSPITALITY_CHECK_OUT                   : "Check OUT",
    HOSPITALITY_ITS_NAME                    : "{0} from {1}",
    HOSPITALITY_EXTERNAL_ID                 : "PMS record ID",
    HOSPITALITY_GUEST_EXTERNAL_ID           : "Guest record ID",
    HOSPITALITY_FIRST_NAME                  : "Guest name",
    HOSPITALITY_LAST_NAME                   : "Guest last name",
    HOSPITALITY_EMAIL                       : "Guest email",
    HOSPITALITY_CELLPHONE                   : "Guest cellphone",
    HOSPITALITY_LANGUAGE                    : "Guest language",
    HOSPITALITY_ROOM                        : "Room",
    HOSPITALITY_ROOM_NUMBER                 : "Room number",
    HOSPITALITY_ARRIVAL                     : "Arrival",
    HOSPITALITY_ARRIVAL_DATE                : "Arrival date",
    HOSPITALITY_DEPARTURE                   : "Departure",
    HOSPITALITY_DEPARTURE_DATE              : "Departure date",
    HOSPITALITY_PIN_EXTERNAL_ID             : "External PIN ID",
    HOSPITALITY_PIN_MAIN_NUMBER             : "Main lock PIN",
    HOSPITALITY_PIN_SEC_NUMBER              : "Secondary lock PIN",
    HOSPITALITY_NIGHTS_AMOUNT               : "Number of nights",
    HOSPITALITY_NIGHTS_SINGULAR             : "{0} night",
    HOSPITALITY_NIGHTS_PLURAL               : "{0} nights",
    HOSPITALITY_IMPORT_FILE                 : "• Hospitalities can be imported from an Excel file (.xlsx).<br>• The data must be in the first sheet of the excel.<br>• The first row must contain the column headers and then have one row per hospitality.<br>• Each hospitality must include some data of the guest (contact).",
    HOSPITALITY_IMPORT_COLUMNS              : "For each hospitality field indicate the column of the file.",
    HOSPITALITY_IMPORT_SUCCESS              : "<b>{0}</b> hospitalities were successfully imported.",
    HOSPITALITY_IMPORT_CANCELED             : "The import was canceled after importing <b>{0}</b> of <b>{1}</b> hospitalities.",
    HOSPITALITY_NONE_AVAILABLE              : "There are no hospitalities available.",
    HOSPITALITY_ERROR_IMPORT                : "You do not have permission to import hospitalities.",
    HOSPITALITY_ERROR_EDIT                  : "You do not have permission to edit the indicated hospitality.",
    HOSPITALITY_ERROR_EXISTS                : "The indicated hospitality does not exist.",
    HOSPITALITY_ERROR_CONTACT               : "You must indicate a contact.",
    HOSPITALITY_ERROR_ARRIVAL_DATE          : "You must indicate a correct arrival date.",
    HOSPITALITY_ERROR_DEPARTURE_DATE        : "You must indicate a correct departure date.",
    HOSPITALITY_ERROR_PERIOD                : "The departure date must be after the arrival date.",
    HOSPITALITY_ERROR_DELETE                : "You do not have permission to delete the indicated hospitality.",
    HOSPITALITY_SUCCESS_CREATE              : "The hospitality was successfully created.",
    HOSPITALITY_SUCCESS_EDIT                : "The hospitality was successfully edited.",
    HOSPITALITY_SUCCESS_DELETE              : "The hospitality was successfully deleted.",

    // Quotations
    QUOTATIONS_SINGULAR                     : "Booking",
    QUOTATIONS_ARRIVAL_DATE                 : "Arrival",
    QUOTATIONS_DEPARTURE_DATE               : "Departure",
    QUOTATIONS_GUEST_AMOUNT                 : "Guests",
    QUOTATIONS_REGULAR_PRICE                : "Price",
    QUOTATIONS_OFFER_PRICE                  : "Offer",
    QUOTATIONS_BOOKING_URL                  : "Booking url",

    // Sales
    SALES_NAME                              : "Sales",
    SALES_SINGULAR                          : "Sale",
    SALES_VIEW_TITLE                        : "View sale",
    SALES_FILTER_TITLE                      : "Filter sales",
    SALES_ITS_NAME                          : "Sale {0}",
    SALES_NUMBER                            : "Sale number",
    SALES_DISCOUNT                          : "Discount",
    SALES_PAYMENT                           : "Payment",
    SALES_PAYMENT_STATUS                    : "Payment status",
    SALES_SHIPPING                          : "Shipping",
    SALES_SHIPPING_STATUS                   : "Shipping status",
    SALES_CHECKOUT_URL                      : "Checkout url",
    SALES_NONE_AVAILABLE                    : "There are no sales available.",



    // Accounts
    ACCOUNTS_NAME                           : "Accounts",
    ACCOUNTS_SINGULAR                       : "Account",
    ACCOUNTS_FILTER_TITLE                   : "Filter accounts",
    ACCOUNTS_VIEW_TITLE                     : "View account",
    ACCOUNTS_CREATE_TITLE                   : "Create account",
    ACCOUNTS_EDIT_TITLE                     : "Edit account",
    ACCOUNTS_DELETE_TITLE                   : "Delete account",
    ACCOUNTS_DELETE_TEXT                    : "Do you really want to delete the account <b>{0}</b>?",
    ACCOUNTS_IMPORT_TITLE                   : "Import accounts",
    ACCOUNTS_EXPORT_TITLE                   : "Export accounts",
    ACCOUNTS_ASSIGN_ACCOUNT_TITLE           : "Assign account",
    ACCOUNTS_ASSIGN_ACCOUNT_TEXT            : "Find an account to assign to the contact: <b>{0}</b>",
    ACCOUNTS_ASSIGN_CONTACT_TITLE           : "Assign contact",
    ACCOUNTS_ASSIGN_CONTACT_TEXT            : "Find a contact to assign to account: <b>{0}</b>",
    ACCOUNTS_UNASSIGN_ACCOUNT_TITLE         : "Remove account",
    ACCOUNTS_UNASSIGN_ACCOUNT_TEXT          : "Do you really want to remove the contact's account?",
    ACCOUNTS_UNASSIGN_CONTACT_TITLE         : "Remove contact",
    ACCOUNTS_UNASSIGN_CONTACT_TEXT          : "Do you really want to remove the contact from the account?",
    ACCOUNTS_ID                             : "Account ID",
    ACCOUNTS_EXTERNAL_ID                    : "Account number",
    ACCOUNTS_ACTIVE_ID                      : "Active account",
    ACCOUNTS_AMOUNT                         : "Accounts amount",
    ACCOUNTS_CONTACTS_SINGULAR              : "{0} contact",
    ACCOUNTS_CONTACTS_PLURAL                : "{0} contacts",
    ACCOUNTS_IMPORT_FILE                    : "• Accounts can be imported from an Excel file (.xlsx).<br>• The data must be in the first excel sheet.<br>• The first row must contain the column headers and then have one row per account.",
    ACCOUNTS_IMPORT_COLUMNS                 : "For each account field, indicate the column of the file.",
    ACCOUNTS_IMPORT_SUCCESS                 : "<b>{0}</b> accounts were successfully imported.",
    ACCOUNTS_IMPORT_CANCELED                : "The import was canceled after importing <b>{0}</b> of <b>{1}</b> accounts.",
    ACCOUNTS_NONE_AVAILABLE                 : "There are no accounts available.",
    ACCOUNTS_ERROR_EXISTS                   : "The indicated account does not exist.",
    ACCOUNTS_ERROR_EDIT                     : "You do not have permission to edit the indicated account.",
    ACCOUNTS_ERROR_NAME                     : "You must indicate the name of the account.",
    ACCOUNTS_ERROR_EXTERNAL_ID              : "An account with the indicated code already exists.",
    ACCOUNTS_ERROR_DELETE                   : "You do not have permission to delete the indicated account.",
    ACCOUNTS_ERROR_IMPORT                   : "You do not have permission to import accounts.",
    ACCOUNTS_ERROR_COLUMNS_NAME             : "You must indicate a column as the name.",
    ACCOUNTS_ERROR_AMOUNT                   : "You must indicate a valid amount of accounts.",
    ACCOUNTS_SUCCESS_CREATE                 : "The account was successfully created.",
    ACCOUNTS_SUCCESS_EDIT                   : "The account was successfully edited.",
    ACCOUNTS_SUCCESS_ASSIGN                 : "The account was successfully assigned.",
    ACCOUNTS_SUCCESS_UNASSIGN               : "The account was successfully unassigned.",
    ACCOUNTS_SUCCESS_DELETE                 : "The account was successfully deleted.",



    // Campaigns
    CAMPAIGNS_NAME                          : "Campaigns",
    CAMPAIGNS_SINGULAR                      : "Campaign",
    CAMPAIGNS_VIEW_TITLE                    : "View campaign",
    CAMPAIGNS_CREATE_TITLE                  : "Create campaign",
    CAMPAIGNS_CREATE_TEXT                   : "Indicate the name of the campaign to start the creation.",
    CAMPAIGNS_EDIT_TITLE                    : "Edit campaign",
    CAMPAIGNS_ACTIVATE_TITLE                : "Activate campaign",
    CAMPAIGNS_ACTIVATE_TEXT                 : "Do you really want to activate the campaign <b>{0}</b>?",
    CAMPAIGNS_ACTIVATE_SEND_MULTI           : "<br><br>Please note that this campaign will be sent multiple times to a total of <b>{0}</b> contacts.",
    CAMPAIGNS_ACTIVATE_SEND_NOW             : "<br><br>Please note that this campaign will be sent only once to a total of <b>{0}</b> contacts.",
    CAMPAIGNS_ACTIVATE_SEND_SMS             : "<br><br>This campaign will have an additional cost for each <b>SMS</b> sent.",
    CAMPAIGNS_ACTIVATE_SEND_WHATSAPP        : "<br><br>This campaign will have an additional cost for each <b>WhatsApp</b> sent.",
    CAMPAIGNS_PAUSE_TITLE                   : "Pause campaign",
    CAMPAIGNS_PAUSE_TEXT                    : "Do you really want to pause the campaign <b>{0}</b>?",
    CAMPAIGNS_COMPLETE_TITLE                : "Complete campaign",
    CAMPAIGNS_COMPLETE_TEXT                 : "Do you really want to complete the campaign <b>{0}</b>?",
    CAMPAIGNS_DELETE_TITLE                  : "Delete campaign",
    CAMPAIGNS_DELETE_TEXT                   : "Do you really want to delete the campaign <b>{0}</b>?",
    CAMPAIGNS_ERRORS_TITLE                  : "Errors in the campaign",
    CAMPAIGNS_ERRORS_TEXT                   : "There are errors in the campaign. Please fix the problems before continuing.",
    CAMPAIGNS_ERRORS_FLOW                   : "There are errors in the campaign flow. Please fix the problems before continuing.",
    CAMPAIGNS_PUBLISH_NO_CHANGES            : "There are no changes to publish in the campaign <b>{0}</b>.",
    CAMPAIGNS_PUBLISH_CHANGES               : "Do you really want to publish the changes of the campaign <b>{0}</b>?",
    CAMPAIGNS_PUBLISH_FLOW_CHANGES          : "Do you really want to publish the changes of the <b>flow</b> of the campaign <b>{0}</b>?",
    CAMPAIGNS_CLEAR_CHANGES                 : "Do you really want to clear the changes of the campaign <b>{0}</b>?",
    CAMPAIGNS_CLEAR_FLOW_CHANGES            : "Do you really want to clear the changes of the <b>flow</b> of the campaign <b>{0}</b>?",
    CAMPAIGNS_MAIN_TEXT                     : "Name and start and end dates",
    CAMPAIGNS_START_TIME                    : "Start date",
    CAMPAIGNS_END_TIME                      : "End date",
    CAMPAIGNS_CHANNELS_TITLE                : "Channels",
    CAMPAIGNS_CHANNELS_TEXT                 : "How is the campaign sent?",
    CAMPAIGNS_EMAIL_TITLE                   : "Email",
    CAMPAIGNS_EMAIL_TEXT                    : "Allows sending the campaign by Email to the contacts that have at least one email.",
    CAMPAIGNS_EMAIL_WAIT                    : "When an Email channel is configured, you will be able to send the Email campaign to contacts who have at least one email.",
    CAMPAIGNS_EMAIL_CONFIGURE               : "Configure your first Email channel to send the Email campaign to contacts who have at least one email.",
    CAMPAIGNS_EMAIL_ACTIVATE                : "Activate the dispatch by Email",
    CAMPAIGNS_SMS_TITLE                     : "SMS",
    CAMPAIGNS_SMS_TEXT                      : "Allows sending the campaign by SMS to the contacts that have at least one cellphone.",
    CAMPAIGNS_SMS_WAIT                      : "When an SMS channel is configured, you will be able to send the SMS campaign to contacts who have at least one cellphone.",
    CAMPAIGNS_SMS_CONFIGURE                 : "Configure your first SMS channel to send the SMS campaign to contacts who have at least one cellphone.",
    CAMPAIGNS_SMS_ACTIVATE                  : "Activate the dispatch by SMS",
    CAMPAIGNS_WHATSAPP_TITLE                : "WhatsApp",
    CAMPAIGNS_WHATSAPP_TEXT                 : "Allows sending the campaign by WhatsApp to the contacts that have at least one cellphone.",
    CAMPAIGNS_WHATSAPP_WAIT                 : "When an WhatsApp channel is configured, you will be able to send the WhatsApp campaign to contacts who have at least one cellphone.",
    CAMPAIGNS_WHATSAPP_CONFIGURE            : "Configure your first WhatsApp channel to send the WhatsApp campaign to contacts who have at least one cellphone.",
    CAMPAIGNS_WHATSAPP_ACTIVATE             : "Activate the dispatch by WhatsApp",
    CAMPAIGNS_PRIORITY                      : "Send priority",
    CAMPAIGNS_NONE_AVAILABLE                : "There are no campaigns available.",
    CAMPAIGNS_ERROR_EDIT                    : "You do not have permission to edit the indicated campaign.",
    CAMPAIGNS_ERROR_EXISTS                  : "The indicated campaign does not exist.",
    CAMPAIGNS_ERROR_NAME                    : "You must indicate the name of the campaign.",
    CAMPAIGNS_ERROR_END_TIME_NOW            : "The end date must be after today.",
    CAMPAIGNS_ERROR_END_TIME_START          : "The end date must be after the start date.",
    CAMPAIGNS_ERROR_CHANNELS                : "You must activate sending by at least one channel.",
    CAMPAIGNS_ERROR_PRIORITY                : "You must indicate a valid priority.",
    CAMPAIGNS_ERROR_MESSAGE                 : "You must indicate a message for each language.",
    CAMPAIGNS_ERROR_FILE                    : "You must indicate a file for each language.",
    CAMPAIGNS_ERROR_EMAIL_SUBJECT           : "You must indicate the subject of the email for each language.",
    CAMPAIGNS_ERROR_EMAIL_BODY              : "You must indicate the body of the email for each language.",
    CAMPAIGNS_ERROR_WABA_TEMPLATE_ID        : "You must indicate a WhatsApp template for each language.",
    CAMPAIGNS_SUCCESS_CREATE                : "The campaign was created successfully.",
    CAMPAIGNS_SUCCESS_ACTIVATE              : "The campaign was successfully activated.",
    CAMPAIGNS_SUCCESS_PAUSE                 : "The campaign was successfully paused.",
    CAMPAIGNS_SUCCESS_COMPLETE              : "The campaign was successfully completed.",
    CAMPAIGNS_SUCCESS_PUBLISH               : "The campaign changes were published successfully.",
    CAMPAIGNS_SUCCESS_CLEAR                 : "The campaign changes were successfully cleared.",
    CAMPAIGNS_SUCCESS_DELETE                : "The campaign was successfully deleted.",

    // Campaigns: Executions
    CAMPAIGNS_EXECUTIONS_NAME               : "Dispatches",
    CAMPAIGNS_EXECUTIONS_VIEW_TITLE         : "View dispatch",
    CAMPAIGNS_EXECUTIONS_DELETE_TITLE       : "Delete dispatch",
    CAMPAIGNS_EXECUTIONS_DELETE_TEXT        : "Do you really want to delete the dispatch to contact <b>{0}</b>?",
    CAMPAIGNS_EXECUTIONS_DELIVERED_AT       : "Delivered at",
    CAMPAIGNS_EXECUTIONS_READ_AT            : "Read at",
    CAMPAIGNS_EXECUTIONS_RESPONDED_AT       : "Responded at",
    CAMPAIGNS_EXECUTIONS_DONT_SEND          : "Do not send request at",
    CAMPAIGNS_EXECUTIONS_ERROR_MESSAGE      : "Error message",
    CAMPAIGNS_EXECUTIONS_NONE_AVAILABLE     : "There are no dispatches available.",
    CAMPAIGNS_EXECUTIONS_ERROR_EXISTS       : "The indicated dispatch does not exist.",
    CAMPAIGNS_EXECUTIONS_ERROR_DELETE       : "You can't delete a dispatch already done.",
    CAMPAIGNS_EXECUTIONS_SUCCESS_DELETE     : "The dispatch was successfully deleted.",

    // Campaigns: Triggers
    CAMPAIGNS_TRIGGERS_NAME                 : "Triggers",
    CAMPAIGNS_TRIGGERS_SINGULAR             : "Trigger",
    CAMPAIGNS_TRIGGERS_TEXT                 : "When is the campaign sent?",
    CAMPAIGNS_TRIGGERS_ADD_TITLE            : "Add trigger",
    CAMPAIGNS_TRIGGERS_DAYS_TIME            : "In x days",
    CAMPAIGNS_TRIGGERS_NONE_AVAILABLE       : "There are no triggers available.",
    CAMPAIGNS_TRIGGERS_ERROR_EXISTS         : "The indicated trigger does not exist.",
    CAMPAIGNS_TRIGGERS_ERROR_EMPTY          : "You must indicate at least one trigger.",
    CAMPAIGNS_TRIGGERS_ERROR_TRIGGER        : "You must indicate a valid trigger.",
    CAMPAIGNS_TRIGGERS_ERROR_DAY            : "You must indicate a valid day.",
    CAMPAIGNS_TRIGGERS_ERROR_DELETE         : "You do not have permission to delete the indicated trigger.",

    // Campaigns: Conditions
    CAMPAIGNS_CONDITIONS_NAME               : "Conditions",
    CAMPAIGNS_CONDITIONS_TEXT               : "Who is the campaign sent to?",
    CAMPAIGNS_CONDITIONS_CONTACTS           : "The indicated conditions add up to a set of <b>{0}</b> contacts",
    CAMPAIGNS_CONDITIONS_ADD_GROUP_TITLE    : "Add Group",
    CAMPAIGNS_CONDITIONS_NONE_AVAILABLE     : "There are no conditions available.",
    CAMPAIGNS_CONDITIONS_ERROR_EXISTS       : "The indicated condition does not exist.",
    CAMPAIGNS_CONDITIONS_ERROR_EMPTY        : "You must indicate at least one condition.",
    CAMPAIGNS_CONDITIONS_ERROR_EXISTS_GROUP : "The indicated group of conditions does not exist.",
    CAMPAIGNS_CONDITIONS_ERROR_DELETE       : "You do not have permission to delete the indicated condition.",
    CAMPAIGNS_CONDITIONS_ERROR_DELETE_GROUP : "You do not have permission to delete the indicated group of conditions.",



    // Users
    USERS_NAME                              : "Users",
    USERS_SINGULAR                          : "User",
    USERS_FILTER_TITLE                      : "Filter users",
    USERS_VIEW_TITLE                        : "View user",
    USERS_CREATE_TITLE                      : "Create user",
    USERS_INVITE_TITLE                      : "Invite user",
    USERS_INVITED_TITLE                     : "User invited",
    USERS_INVITED_TEXT                      : "An email was sent to the user with an URL that they must access to complete the invitation.<br><br>The invitation lasts <b>7 days</b>. When it expires the user will not be able to use the invitation link, but you can send a new one.",
    USERS_EDIT_TITLE                        : "Edit User",
    USERS_EDIT_TEXT                         : "You are editing the user: {0}",
    USERS_DELETE_TITLE                      : "Delete User",
    USERS_DELETE_TEXT                       : "Do you really want to delete the user <b>{0}</b>?",
    USERS_RESEND_TITLE                      : "Resend invitation",
    USERS_RESEND_TEXT                       : "Do you really want to resend the invitation to the user <b>{0}</b>?",
    USERS_EXPORT_TITLE                      : "Export users",
    USERS_SEARCH                            : "Search user",
    USERS_NEW                               : "Create a new one",
    USERS_FULL_NAME                         : "User name and last name",
    USERS_FIRST_NAME                        : "User name",
    USERS_LAST_NAME                         : "User last name",
    USERS_STATUS                            : "Deactivate the user",
    USERS_RANDOM_USER                       : "Random user",
    USERS_NONE_AVAILABLE                    : "There are no users available.",
    USERS_ERROR_EDIT                        : "You do not have permission to edit the indicated user.",
    USERS_ERROR_EXISTS                      : "The indicated user does not exist.",
    USERS_ERROR_EMPTY                       : "You must indicate a user.",
    USERS_ERROR_EMAIL_USED                  : "A user with the indicated email already exists.",
    USERS_ERROR_EMAIL_ADMIN                 : "Unable to send the invitation to the indicated user.",
    USERS_ERROR_CREDENTIAL_EMPTY            : "You must indicate an user or create one.",
    USERS_ERROR_CREDENTIAL_USED             : "A user with the indicated credential already exists.",
    USERS_ERROR_CREDENTIAL_ADMIN            : "You cannot associate an admin to a user.",
    USERS_ERROR_FLOW_INACTIVE               : "You cannot mark the user as inactive while being used in a flow.",
    USERS_ERROR_FLOW_DELETE                 : "You cannot delete the user while it is used in a flow.",
    USERS_ERROR_DELETE                      : "You do not have permission to delete the indicated user.",
    USERS_SUCCESS_CREATE                    : "The user was successfully created.",
    USERS_SUCCESS_INVITE                    : "The user was successfully invited.",
    USERS_SUCCESS_RESEND_INVITE             : "The invitation was successfully sent to the user.",
    USERS_SUCCESS_EDIT                      : "The user was successfully edited.",
    USERS_SUCCESS_DELETE                    : "The user was successfully deleted.",

    // Teams
    TEAMS_NAME                              : "Teams",
    TEAMS_SINGULAR                          : "Team",
    TEAMS_VIEW_TITLE                        : "View team",
    TEAMS_CREATE_TITLE                      : "Create team",
    TEAMS_EDIT_TITLE                        : "Edit team",
    TEAMS_USER_TITLE                        : "Update teams",
    TEAMS_USER_TEXT                         : "Update user teams: {0}",
    TEAMS_DELETE_TITLE                      : "Delete team",
    TEAMS_DELETE_TEXT                       : "Do you really want to delete the team <b>{0}</b>?",
    TEAMS_USERS                             : "Team users",
    TEAMS_USERS_SINGULAR                    : "{0} user",
    TEAMS_USERS_PLURAL                      : "{0} users",
    TEAMS_TEAMS_SINGULAR                    : "{0} team",
    TEAMS_TEAMS_PLURAL                      : "{0} teams",
    TEAMS_NONE_AVAILABLE                    : "There are no teams available.",
    TEAMS_ERROR_EDIT                        : "You do not have permission to edit the indicated team.",
    TEAMS_ERROR_EXISTS                      : "The indicated team does not exist.",
    TEAMS_ERROR_SOME_EXISTS                 : "Some of the indicated teams does not exist.",
    TEAMS_ERROR_NAME                        : "You must indicate the team name.",
    TEAMS_ERROR_USER_EXISTS                 : "Some of the indicated users does not exist.",
    TEAMS_ERROR_FLOW_INACTIVE               : "You cannot mark the team as inactive while being used in a flow.",
    TEAMS_ERROR_FLOW_DELETE                 : "You cannot delete the team while it is used in a flow.",
    TEAMS_ERROR_DELETE                      : "You do not have permission to delete the indicated team.",
    TEAMS_SUCCESS_CREATE                    : "The team was successfully created.",
    TEAMS_SUCCESS_EDIT                      : "The team was successfully edited.",
    TEAMS_SUCCESS_USER                      : "User's teams were successfully updated.",
    TEAMS_SUCCESS_DELETE                    : "The team was successfully deleted.",

    // Tags
    TAGS_NAME                               : "Tags",
    TAGS_SINGULAR                           : "Tag",
    TAGS_ADD_TITLE                          : "Add tag",
    TAGS_CREATE_TITLE                       : "Create tag",
    TAGS_EDIT_TITLE                         : "Edit tag",
    TAGS_DELETE_TITLE                       : "Delete tag",
    TAGS_DELETE_TEXT                        : "Do you really want to delete the tag <b>{0}</b>?",
    TAGS_NONE_AVAILABLE                     : "There are no tags available.",
    TAGS_ERROR_EDIT                         : "You do not have permission to edit the indicated tag.",
    TAGS_ERROR_EXISTS                       : "The indicated tag does not exist.",
    TAGS_ERROR_NAME                         : "You must indicate the name of the tag.",
    TAGS_ERROR_DELETE                       : "You do not have permission to delete the indicated tag.",
    TAGS_ERROR_FLOW                         : "You cannot delete a tag while it is used in a flow.",
    TAGS_SUCCESS_CREATE                     : "The tag was successfully created.",
    TAGS_SUCCESS_EDIT                       : "The tag was successfully edited.",
    TAGS_SUCCESS_DELETE                     : "The tag was successfully deleted.",

    // Schedules
    SCHEDULES_NAME                          : "Business hours",
    SCHEDULES_SINGULAR                      : "Business hours",
    SCHEDULES_SHORT                         : "Schedule",
    SCHEDULES_CREATE_TITLE                  : "Create business hours",
    SCHEDULES_EDIT_TITLE                    : "Edit business hours",
    SCHEDULES_COPY_TITLE                    : "Copy business hours",
    SCHEDULES_DELETE_TITLE                  : "Delete business hours",
    SCHEDULES_DELETE_TEXT                   : "Do you really want to delete the business hours <b>{0}</b>?",
    SCHEDULES_MAIN_TEXT                     : "Name and use as default",
    SCHEDULES_SHOW_TIME_ZONE                : "Show time zone in the variable",
    SCHEDULES_PERIODS_TITLE                 : "Periods",
    SCHEDULES_PERIODS_TEXT                  : "Days and times",
    SCHEDULES_ASSIGNS_TITLE                 : "Assignments",
    SCHEDULES_ASSIGNS_TEXT                  : "Assign teams",
    SCHEDULES_PERIOD_TITLE                  : "Period {0}",
    SCHEDULES_ADD_PERIOD                    : "Add period",
    SCHEDULES_DATE_TIME                     : "{0} from {1} to {2}",
    SCHEDULES_NONE_AVAILABLE                : "There are no business hours available.",
    SCHEDULES_ERROR_EDIT                    : "You do not have permission to edit the indicated business hours.",
    SCHEDULES_ERROR_EXISTS                  : "The indicated service hours do not exist.",
    SCHEDULES_ERROR_NAME                    : "You must indicate the name of the business hours.",
    SCHEDULES_ERROR_DAYS_EMPTY              : "You must indicate at least one day.",
    SCHEDULES_ERROR_DAYS_INVALID            : "One of the indicated days is incorrect.",
    SCHEDULES_ERROR_FROM_TIME               : "The indicated since time is incorrect.",
    SCHEDULES_ERROR_TO_TIME                 : "The indicated time to is incorrect.",
    SCHEDULES_ERROR_PERIOD                  : "The time from must be before the time to.",
    SCHEDULES_ERROR_REPEAT                  : "Some of the indicated teams are using a different schedule.",
    SCHEDULES_ERROR_FLOW                    : "You cannot delete business hours when used in a flow.",
    SCHEDULES_ERROR_LAST                    : "You cannot delete last business hours.",
    SCHEDULES_ERROR_DELETE                  : "You do not have permission to delete the indicated business hours.",
    SCHEDULES_SUCCESS_CREATE                : "The business hours were successfully created.",
    SCHEDULES_SUCCESS_EDIT                  : "The business hours were successfully edited.",
    SCHEDULES_SUCCESS_DELETE                : "The business hours were successfully deleted.",

    // Message Templates
    MSG_TEMPLATES_NAME                      : "Message templates",
    MSG_TEMPLATES_SINGULAR                  : "Message template",
    MSG_TEMPLATES_CREATE_TITLE              : "Create message template",
    MSG_TEMPLATES_EDIT_TITLE                : "Edit message template",
    MSG_TEMPLATES_COPY_TITLE                : "Copy message template",
    MSG_TEMPLATES_DELETE_TITLE              : "Delete message template",
    MSG_TEMPLATES_DELETE_TEXT               : "Do you really want to delete the message template <b>{0}</b>?",
    MSG_TEMPLATES_MAIN_TEXT                 : "Name, position & status.",
    MSG_TEMPLATES_MESSAGE_TEXT              : "Message & image.",
    MSG_TEMPLATES_ASSIGN_TITLE              : "Assignment",
    MSG_TEMPLATES_ASSIGN_TEXT               : "Teams assigned.",
    MSG_TEMPLATES_ASSIGN_HOTEL              : "Teams & hotels assigned.",
    MSG_TEMPLATES_NONE_AVAILABLE            : "There are no message templates available.",
    MSG_TEMPLATES_ERROR_EDIT                : "You do not have permission to edit the indicated message template.",
    MSG_TEMPLATES_ERROR_EXISTS              : "The indicated message template does not exist.",
    MSG_TEMPLATES_ERROR_NAME                : "You must indicate the name of the message template.",
    MSG_TEMPLATES_ERROR_MESSAGE             : "You must indicate the message of the message template.",
    MSG_TEMPLATES_ERROR_LENGTH              : "The maximum message length is <b>{0} characters</b> due to social channel limits.",
    MSG_TEMPLATES_ERROR_DELETE              : "You do not have permission to delete the indicated message template.",
    MSG_TEMPLATES_ERROR_FLOW                : "You cannot delete message template when used in a flow.",
    MSG_TEMPLATES_SUCCESS_CREATE            : "The message template was successfully created.",
    MSG_TEMPLATES_SUCCESS_EDIT              : "The message template was successfully edited.",
    MSG_TEMPLATES_SUCCESS_DELETE            : "The message template was successfully deleted.",

    // WABA Templates
    WABA_TEMPLATES_NAME                     : "WhatsApp templates",
    WABA_TEMPLATES_SINGULAR                 : "WhatsApp template",
    WABA_TEMPLATES_TEMPLATE                 : "Template",
    WABA_TEMPLATES_CREATE_TITLE             : "Create WhatsApp template",
    WABA_TEMPLATES_EDIT_TITLE               : "Edit WhatsApp template",
    WABA_TEMPLATES_COPY_TITLE               : "Copy WhatsApp template",
    WABA_TEMPLATES_IMPORT_TITLE             : "Import WhatsApp template",
    WABA_TEMPLATES_IMPORT_TEXT              : "You can import a WhatsApp template from the Meta account that is no here.",
    WABA_TEMPLATES_DELETE_TITLE             : "Delete WhatsApp template",
    WABA_TEMPLATES_DELETE_TEXT              : "Do you really want to delete the WhatsApp template <b>{0}</b>?",
    WABA_TEMPLATES_CANT_EDIT                : "You can only edit the variables while the template is being reviewed by WhatsApp.",
    WABA_TEMPLATES_CANT_HOURS               : "An approved template can only be edited once every 24 hours. You can only edit the assigned variables at this time.",
    WABA_TEMPLATES_REJECTED                 : "<b>The template was rejected for the reason:</b> {0}.",
    WABA_TEMPLATES_MAIN_TEXT                : "Channel, category and name",
    WABA_TEMPLATES_FOR_AUTOMATIONS          : "Only for automations",
    WABA_TEMPLATES_HEADER_TITLE             : "Message header",
    WABA_TEMPLATES_HEADER_TEXT              : "Add a title or choose a media for the header",
    WABA_TEMPLATES_BODY_TITLE               : "Message body",
    WABA_TEMPLATES_BODY_TEXT                : "Enter the text for your message",
    WABA_TEMPLATES_FOOTER_TITLE             : "Message footer",
    WABA_TEMPLATES_FOOTER_TEXT              : "Add a short line of text to the bottom of the message",
    WABA_TEMPLATES_BUTTONS_TITLE            : "Message buttons",
    WABA_TEMPLATES_BUTTONS_TEXT             : "Create buttons that let users respond to your message",
    WABA_TEMPLATES_HEADER_TYPE              : "Header type",
    WABA_TEMPLATES_HEADER_FILE              : "Header file",
    WABA_TEMPLATES_HEADER_LATITUDE          : "Location latitude",
    WABA_TEMPLATES_HEADER_LONGITUDE         : "Location longitude",
    WABA_TEMPLATES_HEADER_LOCATION          : "Location name",
    WABA_TEMPLATES_HEADER_ADDRESS           : "Location address",
    WABA_TEMPLATES_HEADER_MY_LOCATION       : "Use my location",
    WABA_TEMPLATES_SAMPLE                   : "Sample for variable {{{0}}}",
    WABA_TEMPLATES_VALUE                    : "Value for variable {{{0}}}",
    WABA_TEMPLATES_LINKS                    : "Button links",
    WABA_TEMPLATES_LINK_TITLE               : "Link {0}",
    WABA_TEMPLATES_ADD_LINK                 : "Add link",
    WABA_TEMPLATES_BUTTON_TEXT              : "Link texto",
    WABA_TEMPLATES_PHONE_NUMBER             : "Phone number",
    WABA_TEMPLATES_URL                      : "Website url",
    WABA_TEMPLATES_SHOW_LINKS_FIRST         : "Show the links first",
    WABA_TEMPLATES_ADD_DONT_SEND_BUTTON     : "Add no send button",
    WABA_TEMPLATES_DONT_SEND_BUTTON_TEXT    : "No send button text",
    WABA_TEMPLATES_DONT_SEND_TEXT           : "Not interested",
    WABA_TEMPLATES_SEE_ALL_OPTIONS          : "Show all options",
    WABA_TEMPLATES_SAVE_DRAFT               : "Save draft",
    WABA_TEMPLATES_EMPTY_IMPORT             : "There are no WhatsApp templates that can be imported to the channel.",
    WABA_TEMPLATES_EMPTY_SEND               : "There are no approved WhatsApp templates available. To send a new message you must use a WhatsApp template. These can be created from settings.",
    WABA_TEMPLATES_NONE_AVAILABLE           : "There are no WhatsApp templates available.",
    WABA_TEMPLATES_ERROR_EDIT               : "You do not have permission to edit the indicated WhatsApp template.",
    WABA_TEMPLATES_ERROR_EXISTS             : "The indicated WhatsApp template does not exist.",
    WABA_TEMPLATES_ERROR_NAME_EMPTY         : "You must indicate the name of the WhatsApp template.",
    WABA_TEMPLATES_ERROR_NAME_INVALID       : "The name can only contain lowercase letters, numbers, and underscores.",
    WABA_TEMPLATES_ERROR_NAME_REPEATED      : "You cannot repeat template names within a channel.",
    WABA_TEMPLATES_ERROR_CATEGORY           : "You must indicate the category of the WhatsApp template.",
    WABA_TEMPLATES_ERROR_CHANNEL            : "You must indicate the WhatsApp template channel.",
    WABA_TEMPLATES_ERROR_COMPONENTS         : "You must indicate a message for at least one language.",
    WABA_TEMPLATES_ERROR_HEADER_TEXT_EMPTY  : "You must indicate the header of the message.",
    WABA_TEMPLATES_ERROR_HEADER_TEXT_LENGTH : "The header of the message must contain less than 60 characters.",
    WABA_TEMPLATES_ERROR_HEADER_FILE        : "You must indicate a file for the header.",
    WABA_TEMPLATES_ERROR_HEADER_LATITUDE    : "You must indicate the latitude of the location.",
    WABA_TEMPLATES_ERROR_HEADER_LONGITUDE   : "You must indicate the longitude of the location.",
    WABA_TEMPLATES_ERROR_HEADER_LOCATION    : "You must indicate the name of the location.",
    WABA_TEMPLATES_ERROR_HEADER_ADDRESS     : "You must indicate the address of the location.",
    WABA_TEMPLATES_ERROR_BODY_TEXT_EMPTY    : "You must indicate the body of the message.",
    WABA_TEMPLATES_ERROR_BODY_TEXT_LENGTH   : "The body of the message must contain less than 1024 characters.",
    WABA_TEMPLATES_ERROR_FOOTER_TEXT_LENGTH : "The footer of the message must contain less than 60 characters.",
    WABA_TEMPLATES_ERROR_SAMPLE             : "You must indicate an example for the variable.",
    WABA_TEMPLATES_ERROR_VARIABLE_EMPTY     : "You must indicate a value for the variable.",
    WABA_TEMPLATES_ERROR_VARIABLE_INVALID   : "The value used for the variable is incorrect.",
    WABA_TEMPLATES_ERROR_BUTTONS_MAX        : "The maximum amount of buttons is 3.",
    WABA_TEMPLATES_ERROR_BUTTONS_AMOUNT     : "The amount of buttons must be the same in all the languages.",
    WABA_TEMPLATES_ERROR_BUTTONS_LENGTH     : "Each button must contain less than 24 characters.",
    WABA_TEMPLATES_ERROR_BUTTONS_EMOJI      : "The buttons cant have Emojis.",
    WABA_TEMPLATES_ERROR_LINKS_TYPE         : "You must indicate the valid button type.",
    WABA_TEMPLATES_ERROR_LINKS_PHONES       : "You can only add 1 button of the indicated type.",
    WABA_TEMPLATES_ERROR_LINKS_PHONE        : "You must indicate the phone number to call with the country entry.",
    WABA_TEMPLATES_ERROR_LINKS_URLS         : "You can only add 2 buttons of the indicated type.",
    WABA_TEMPLATES_ERROR_LINKS_URL          : "You must indicate the url to use starting with http.",
    WABA_TEMPLATES_ERROR_LINKS_TEXT         : "You must indicate the button text.",
    WABA_TEMPLATES_ERROR_DELETE             : "You do not have permission to delete the indicated WhatsApp template.",
    WABA_TEMPLATES_ERROR_TEMPLATE_EMPTY     : "You must indicate a WhatsApp template.",
    WABA_TEMPLATES_ERROR_TEMPLATE_INVALID   : "Could not get the indicated WhatsApp template.",
    WABA_TEMPLATES_WARNING_CREATE           : "The template was created, but could not be created in WhatsApp.",
    WABA_TEMPLATES_WARNING_EDIT             : "The template was edited, but could not be edited in WhatsApp.",
    WABA_TEMPLATES_SUCCESS_CREATE           : "The WhatsApp template was successfully created.",
    WABA_TEMPLATES_SUCCESS_EDIT             : "The WhatsApp template was successfully edited.",
    WABA_TEMPLATES_SUCCESS_IMPORT           : "The WhatsApp template was successfully imported.",
    WABA_TEMPLATES_SUCCESS_DELETE           : "The WhatsApp template was successfully deleted.",

    // Email Designs
    EMAIL_DESIGNS_NAME                      : "Email designs",
    EMAIL_DESIGNS_SINGULAR                  : "Email design",
    EMAIL_DESIGNS_VIEW_TITLE                : "View email design",
    EMAIL_DESIGNS_CREATE_TITLE              : "Create email design",
    EMAIL_DESIGNS_EDIT_TITLE                : "Edit email design",
    EMAIL_DESIGNS_COPY_TITLE                : "Copy email design",
    EMAIL_DESIGNS_DELETE_TITLE              : "Delete email design",
    EMAIL_DESIGNS_DELETE_TEXT               : "Do you really want to delete the email design <b>{0}</b>?",
    EMAIL_DESIGNS_PUBLISH_NO_CHANGES        : "There are no changes to publish for email design <b>{0}</b>.",
    EMAIL_DESIGNS_PUBLISH_CHANGES           : "Do you really want to publish the changes to the email design <b>{0}</b>?",
    EMAIL_DESIGNS_PUBLISH_CHANGES_SINGULAR  : "Do you really want to publish the element with changes in the email design <b>{1}</b>?",
    EMAIL_DESIGNS_PUBLISH_CHANGES_PLURAL    : "Do you really want to publish the <b>{0} elements</b> with changes in the email design <b>{1}</b>?",
    EMAIL_DESIGNS_CLEAR_CHANGES             : "Do you really want to clear the changes to the email design <b>{0}</b>?",
    EMAIL_DESIGNS_START_HERE                : "Drag an element here to start",
    EMAIL_DESIGNS_DROP_ELEMENTS             : "Drag the element here",
    EMAIL_DESIGNS_NONE_AVAILABLE            : "There are no email designs available.",
    EMAIL_DESIGNS_ERROR_EDIT                : "You do not have permission to edit the indicated email design.",
    EMAIL_DESIGNS_ERROR_EXISTS              : "The indicated email design does not exist.",
    EMAIL_DESIGNS_ERROR_NAME                : "You must indicate the name of the email design.",
    EMAIL_DESIGNS_SUCCESS_CREATE            : "The email design was successfully created.",
    EMAIL_DESIGNS_SUCCESS_COPY              : "The email design was successfully copied.",
    EMAIL_DESIGNS_SUCCESS_EDIT              : "The email design was successfully edited.",
    EMAIL_DESIGNS_SUCCESS_DELETE            : "The email design was successfully deleted.",

    EMAIL_DESIGNS_ELEMENTS_SINGULAR         : "Element",
    EMAIL_DESIGNS_ELEMENTS_WITH_ERRORS      : "Elements with errors",
    EMAIL_DESIGNS_ELEMENTS_NOT_ALLOWED      : "Element disabled for not having enough permissions.",
    EMAIL_DESIGNS_ELEMENTS_COLUMNS          : "Columns",
    EMAIL_DESIGNS_ELEMENTS_TEXT             : "Text",
    EMAIL_DESIGNS_ELEMENTS_IMAGE            : "Image",
    EMAIL_DESIGNS_ELEMENTS_BUTTON           : "Button",
    EMAIL_DESIGNS_ELEMENTS_DIVIDER          : "Divider",
    EMAIL_DESIGNS_ELEMENTS_SPACER           : "Spacer",
    EMAIL_DESIGNS_ELEMENTS_SOCIAL           : "Social networks",
    EMAIL_DESIGNS_ELEMENTS_UNSUBSCRIBE      : "Unsubscribe",

    // Questionnaires
    QUESTIONNAIRES_NAME                     : "Frequent questions",
    QUESTIONNAIRES_SINGULAR                 : "Frequent question",
    QUESTIONNAIRES_CREATE_TITLE             : "Create frequent questions",
    QUESTIONNAIRES_EDIT_TITLE               : "Edit frequent questions",
    QUESTIONNAIRES_COPY_TITLE               : "Copy frequent questions",
    QUESTIONNAIRES_DELETE_TITLE             : "Delete frequent questions",
    QUESTIONNAIRES_DELETE_TEXT              : "Do you really want to delete the frequent questions for <b>{0}</b>?",
    QUESTIONNAIRES_MAIN_TEXT                : "Name and status.",
    QUESTIONNAIRES_QUESTION_TITLE           : "Question {0}",
    QUESTIONNAIRES_ADD_QUESTION             : "Add question",
    QUESTIONNAIRES_QUESTIONS                : "Questions",
    QUESTIONNAIRES_QUESTION                 : "Question",
    QUESTIONNAIRES_ANSWER                   : "Answer",
    QUESTIONNAIRES_NONE_AVAILABLE           : "There are no frequent questions available.",
    QUESTIONNAIRES_ERROR_EDIT               : "You do not have permission to edit the indicated frequent questions.",
    QUESTIONNAIRES_ERROR_EXISTS             : "The indicated frequent questions does not exist.",
    QUESTIONNAIRES_ERROR_NAME               : "You must indicate the name of the frequent questions.",
    QUESTIONNAIRES_ERROR_EMPTY              : "You must indicate the frequent questions.",
    QUESTIONNAIRES_ERROR_TITLE_EMPTY        : "You must complete the question title.",
    QUESTIONNAIRES_ERROR_TITLE_LENGTH       : "The question title must not exceed 20 characters.",
    QUESTIONNAIRES_ERROR_QUESTION_LENGTH    : "The question must not exceed 72 characters.",
    QUESTIONNAIRES_ERROR_ANSWER_EMPTY       : "You must complete the answer to the question.",
    QUESTIONNAIRES_ERROR_ANSWER_LENGTH      : "The question answer must not exceed {0} characters.",
    QUESTIONNAIRES_ERROR_DELETE             : "You do not have permission to delete the indicated frequent questions.",
    QUESTIONNAIRES_ERROR_FLOW               : "You cannot delete frequent questions when they are used in a flow.",
    QUESTIONNAIRES_SUCCESS_CREATE           : "The frequent questions were successfully created.",
    QUESTIONNAIRES_SUCCESS_EDIT             : "The frequent questions were successfully edited.",
    QUESTIONNAIRES_SUCCESS_DELETE           : "The frequent questions were successfully deleted.",

    // Custom Fields
    CUSTOM_FIELDS_NAME                      : "Custom fields",
    CUSTOM_FIELDS_SINGULAR                  : "Custom field",
    CUSTOM_FIELDS_CREATE_TITLE              : "Add custom field",
    CUSTOM_FIELDS_EDIT_TITLE                : "Edit custom field",
    CUSTOM_FIELDS_DELETE_TITLE              : "Delete custom field",
    CUSTOM_FIELDS_DELETE_TEXT               : "Do you really want to delete the custom field <b>{0}</b>?",
    CUSTOM_FIELDS_FILE_TYPES                : "File types",
    CUSTOM_FIELDS_MIN_VALUE                 : "Minimum value",
    CUSTOM_FIELDS_MAX_VALUE                 : "Maximum value",
    CUSTOM_FIELDS_MINUTE_INTERVAL           : "Minute interval",
    CUSTOM_FIELDS_MIN_HOUR                  : "Minimum hour",
    CUSTOM_FIELDS_MAX_HOUR                  : "Maximum hour",
    CUSTOM_FIELDS_HIDDEN                    : "Hidden",
    CUSTOM_FIELDS_IS_HIDDEN                 : "Is it hidden?",
    CUSTOM_FIELDS_REQUIRED                  : "Required",
    CUSTOM_FIELDS_IS_REQUIRED               : "Is it required?",
    CUSTOM_FIELDS_NONE_AVAILABLE            : "There are no custom fields available.",
    CUSTOM_FIELDS_ERROR_EDIT                : "You do not have permission to edit the indicated custom field.",
    CUSTOM_FIELDS_ERROR_EXISTS              : "The indicated custom field does not exist.",
    CUSTOM_FIELDS_ERROR_NAME                : "You must indicate the name of the custom field.",
    CUSTOM_FIELDS_ERROR_INPUT_TYPE_EMPTY    : "You must indicate the type of the custom field.",
    CUSTOM_FIELDS_ERROR_INPUT_TYPE_INVALID  : "The type of the custom field is incorrect.",
    CUSTOM_FIELDS_ERROR_OPTIONS             : "You must indicate the options of the indicated type.",
    CUSTOM_FIELDS_ERROR_FILE_TYPES          : "One of the indicated file types is invalid.",
    CUSTOM_FIELDS_ERROR_MIN_VALUE           : "The minimum value must be a number.",
    CUSTOM_FIELDS_ERROR_MAX_VALUE           : "The maximum value must be a number.",
    CUSTOM_FIELDS_ERROR_MINUTE_INTERVAL     : "The minute interval must be a number.",
    CUSTOM_FIELDS_ERROR_MIN_HOUR            : "The minimum hour must be a number between 0 and 23.",
    CUSTOM_FIELDS_ERROR_MAX_HOUR            : "The maximum value must be a number between 0 and 23.",
    CUSTOM_FIELDS_ERROR_HIDDEN_REQUIRED     : "The custom field cant be hidden and required at the same time.",
    CUSTOM_FIELDS_ERROR_DELETE              : "You do not have permission to delete the indicated custom field.",
    CUSTOM_FIELDS_ERROR_FLOW                : "You cannot delete custom field when used in a flow.",
    CUSTOM_FIELDS_ERROR_REQUIRED            : "You must indicate the value.",
    CUSTOM_FIELDS_ERROR_NUMBER              : "You must indicate a valid number.",
    CUSTOM_FIELDS_ERROR_NUMBER_MIN          : "You must indicate a number greater or equal to {0}.",
    CUSTOM_FIELDS_ERROR_NUMBER_MAX          : "You must indicate a number lower or equal to {0}.",
    CUSTOM_FIELDS_ERROR_EMAIL               : "You must indicate a valid email.",
    CUSTOM_FIELDS_ERROR_PHONE               : "You must indicate a valid phone.",
    CUSTOM_FIELDS_ERROR_DATE                : "You must indicate a valid date.",
    CUSTOM_FIELDS_ERROR_TIME                : "You must indicate a valid time.",
    CUSTOM_FIELDS_ERROR_SELECT              : "You must indicate a valid option.",
    CUSTOM_FIELDS_ERROR_LIST                : "You must indicate a valid list.",
    CUSTOM_FIELDS_ERROR_FILE_TYPE           : "The type of the file must be one of: {0}.",
    CUSTOM_FIELDS_SUCCESS_CREATE            : "The custom field was successfully created.",
    CUSTOM_FIELDS_SUCCESS_EDIT              : "The custom field was successfully edited.",
    CUSTOM_FIELDS_SUCCESS_DELETE            : "The custom field was successfully deleted.",



    // Hotels
    HOTELS_NAME                             : "Hotels",
    HOTELS_SINGULAR                         : "Hotel",
    HOTELS_VIEW_TITLE                       : "View hotel",
    HOTELS_ADD_TITLE                        : "Add hotel",
    HOTELS_EDIT_TITLE                       : "Edit hotel",
    HOTELS_DELETE_TITLE                     : "Delete hotel",
    HOTELS_DELETE_TEXT                      : "Do you really want to delete the hotel <b>{0}</b>?",
    HOTELS_MAIN_TEXT                        : "Name and status",
    HOTELS_PMS_TITLE                        : "Integration with the PMS",
    HOTELS_PMS_TEXT                         : "What is the integration with the PMS?",
    HOTELS_FIELDS_TITLE                     : "Fields for the PMS",
    HOTELS_FIELDS_TEXT                      : "For each field indicate the name in the PMS.",
    HOTELS_FIELDS_ERRORS                    : "The fields are not configured correctly according to the data obtained from the report.",
    HOTELS_BOOKING_TITLE                    : "Integration with booking engine",
    HOTELS_BOOKING_TEXT                     : "What is the booking engine?",
    HOTELS_ID                               : "Hotel ID",
    HOTELS_ITS_NAME                         : "Hotel name",
    HOTELS_SITE_URL                         : "Site url",
    HOTELS_PMS_PROVIDER                     : "PMS provider",
    HOTELS_PMS_ID                           : "PMS internal ID",
    HOTELS_PMS_LOG_ALL                      : "Log all events",
    HOTELS_PMS_IS_DISABLED                  : "Disable integration",
    HOTELS_PMS_IS_LINKED                    : "Integration linked",
    HOTELS_PMS_HAS_ERROR                    : "Fields error",
    HOTELS_USE_FTP                          : "Use FTP",
    HOTELS_FTP_TITLE                        : "FTP Data",
    HOTELS_FTP_HOSTNAME                     : "Host / Url",
    HOTELS_FTP_PORT                         : "Port",
    HOTELS_FTP_DIRECTORY                    : "Directory",
    HOTELS_FTP_USERNAME                     : "FTP username",
    HOTELS_FTP_PASSWORD                     : "FTP password",
    HOTELS_BOOKING_ENGINE                   : "Booking engine",
    HOTELS_BOOKING_TOKEN                    : "Token or key",
    HOTELS_BOOKING_REFRESH                  : "Refresh token",
    HOTELS_BOOKING_HOTEL                    : "Hotel ID",
    HOTELS_BOOKING_URL                      : "Url to make a reservation",
    HOTELS_BOOKING_PROMO_CODE               : "Promo code",
    HOTELS_HOTELS_SINGULAR                  : "{0} hotel",
    HOTELS_HOTELS_PLURAL                    : "{0} hotels",
    HOTELS_NONE_AVAILABLE                   : "There are no hotels available.",
    HOTELS_ERROR_EDIT                       : "You do not have permission to edit the indicated hotel.",
    HOTELS_ERROR_EMPTY                      : "You must indicate a hotel.",
    HOTELS_ERROR_EXISTS                     : "The indicated hotel does not exist.",
    HOTELS_ERROR_SOME_EXISTS                : "Some of the indicated hotel does not exist.",
    HOTELS_ERROR_NAME                       : "You must indicate the name of the hotel.",
    HOTELS_ERROR_SITE_URL                   : "The site url must start with http or https.",
    HOTELS_ERROR_PROVIDER_ADD               : "You do not have permission to add a PMS provider.",
    HOTELS_ERROR_PROVIDER_TYPE              : "The indicated PMS provider is incorrect.",
    HOTELS_ERROR_PMS_ID_EMPTY               : "You must indicate the PMS internal ID.",
    HOTELS_ERROR_PMS_ID_INVALID             : "The PMS internal ID must be unique for the PMS type.",
    HOTELS_ERROR_FTP_USERNAME               : "You must indicate the FTP username using only letters and numbers and starting in a letter.",
    HOTELS_ERROR_FTP_PASSWORD               : "You must indicate the FTP password with at least 6 characters, a letter and a number.",
    HOTELS_ERROR_FIELD_EMPTY                : "You must indicate the field name.",
    HOTELS_ERROR_BOOKING_ADD                : "You do not have permission to add a booking engine.",
    HOTELS_ERROR_BOOKING_ENGINE             : "The booking engine is incorrect.",
    HOTELS_ERROR_BOOKING_TOKEN              : "You must indicate the token for the booking engine.",
    HOTELS_ERROR_BOOKING_REFRESH            : "You must indicate the refresh token for the booking engine.",
    HOTELS_ERROR_BOOKING_HOTEL              : "You must indicate the ID of the hotel.",
    HOTELS_ERROR_BOOKING_URL_EMPTY          : "You must indicate the url for making reservations.",
    HOTELS_ERROR_BOOKING_URL_INVALID        : "The url must start with http or https.",
    HOTELS_ERROR_DELETE                     : "You do not have permission to delete the indicated hotel.",
    HOTELS_ERROR_FLOW_INACTIVE              : "You cannot mark the hotel as inactive when it is used in a flow.",
    HOTELS_ERROR_FLOW_DELETE                : "You cannot delete the hotel when it is used in a flow.",
    HOTELS_SUCCESS_ADD                      : "The hotel was successfully added.",
    HOTELS_SUCCESS_EDIT                     : "The hotel was successfully edited.",
    HOTELS_SUCCESS_DELETE                   : "The hotel was successfully deleted.",

    // Hotels: Opera
    HOTELS_OPERA_CLIENT_ID                  : "Client ID",
    HOTELS_OPERA_CLIENT_SECRET              : "Client secret",
    HOTELS_OPERA_GATEWAY_URL                : "Gateway URL",
    HOTELS_OPERA_USERNAME                   : "Integration ID",
    HOTELS_OPERA_PASSWORD                   : "Integration password",
    HOTELS_OPERA_EXTERNAL_SYSTEM            : "External system code",
    HOTELS_OPERA_ERROR_CLIENT_ID            : "You must indicate the client ID.",
    HOTELS_OPERA_ERROR_CLIENT_SECRET        : "You must indicate the client secret.",
    HOTELS_OPERA_ERROR_GATEWAY_URL          : "You must indicate the gateway URL.",
    HOTELS_OPERA_ERROR_USERNAME             : "You must indicate the integration ID.",
    HOTELS_OPERA_ERROR_PASSWORD             : "You must indicate the integration password.",
    HOTELS_OPERA_ERROR_EXTERNAL_SYSTEM      : "You must indicate the external system code.",



    // Channels
    CHANNELS_NAME                           : "Channels",
    CHANNELS_SINGULAR                       : "Channel",
    CHANNELS_ADD_TITLE                      : "Add channel",
    CHANNELS_EDIT_TITLE                     : "Edit channel",
    CHANNELS_DELETE_TITLE                   : "Delete channel",
    CHANNELS_DELETE_TEXT                    : "Do you really want to delete the channel <b>{0}</b>?",
    CHANNELS_PROFILE_TITLE                  : "Edit profile",
    CHANNELS_VIEW_TITLE                     : "Go to channel",
    CHANNELS_CHANNEL_TEXT                   : "Which channel would you like to add?",
    CHANNELS_INSTALL_TITLE                  : "Install",
    CHANNELS_INSTALL_TEXT                   : "Install the selected channel",
    CHANNELS_CONFIG_TITLE                   : "Configure",
    CHANNELS_CONFIG_TEXT                    : "Configure the name and flow",
    CHANNELS_SELECT_TEXT                    : "Click on a platform to add it as a channel.",
    CHANNELS_WHATSAPP_BEFORE                : "Before you can install WhatsApp, you need to have:",
    CHANNELS_WHATSAPP_INFO1                 : "Access to Facebook Business Manager.",
    CHANNELS_WHATSAPP_INFO2                 : "A viable phone number for WhatsApp.",
    CHANNELS_WHATSAPP_INFO3                 : "Your business legal address and details.",
    CHANNELS_WHATSAPP_START                 : "Once you are ready you can click the <b>Install WhatsApp</b> button and follow the steps in the dialog.",
    CHANNELS_WHATSAPP_LOGIN                 : "Continue to WhatsApp",
    CHANNELS_WHATSAPP_SELECT                : "Select the WhatsApp account you want to add or you can create another account.",
    CHANNELS_WHATSAPP_NONE                  : "There are no available WhatsApp accounts. Try to login with another user.",
    CHANNELS_WHATSAPP_ACCOUNT               : "WhatsApp account",
    CHANNELS_INSTAGRAM_BEFORE               : "Before you can install Instagram, you need to have:",
    CHANNELS_INSTAGRAM_INFO1                : "An Instagram account linked to a Facebook page.",
    CHANNELS_INSTAGRAM_INFO2                : "A Facebook page with admin access.",
    CHANNELS_INSTAGRAM_START                : "Once you are ready you can click the <b>Continue with Facebook</b> button and follow the steps in the dialog.",
    CHANNELS_INSTAGRAM_LOGIN                : "Continue with Facebook",
    CHANNELS_INSTAGRAM_SELECT               : "Select the Instagram account you want to add or you can login with another user.",
    CHANNELS_INSTAGRAM_NONE                 : "We did not find any available Instagram account. You can try entering the ID of the Facebook page associated with the Instagram account or join with another user.",
    CHANNELS_INSTAGRAM_ACCOUNT              : "Instagram account",
    CHANNELS_MESSENGER_BEFORE               : "Before you can install Facebook Messenger, you need to have:",
    CHANNELS_MESSENGER_INFO1                : "A Facebook account linked to a Facebook page.",
    CHANNELS_MESSENGER_INFO2                : "A Facebook page with admin access.",
    CHANNELS_MESSENGER_START                : "Once you are ready you can click the <b>Continue with Facebook</b> button and follow the steps in the dialog.",
    CHANNELS_MESSENGER_LOGIN                : "Continue with Facebook",
    CHANNELS_MESSENGER_SELECT               : "Select the Facebook page you want to add or you can login with another user.",
    CHANNELS_MESSENGER_NONE                 : "We did not find any available Facebook page. You can try entering the Facebook Page ID or log in with another user.",
    CHANNELS_MESSENGER_PAGE                 : "Facebook page",
    CHANNELS_MESSENGER_PAGE_ID              : "Facebook page ID",
    CHANNELS_SMS                            : "SMS",
    CHANNELS_SMS_SELECT                     : "Select a phone number to add as SMS channel:",
    CHANNELS_SMS_NONE_AVAILABLE             : "There are no phone numbers available to use as an SMS channel.",
    CHANNELS_SMS_BUY_TEXT                   : "Would you like to buy a new phone number to use with SMS?",
    CHANNELS_SMS_BUY_BUTTON                 : "Buy phone number",
    CHANNELS_SMS_TICKET_TEXT                : "You can submit a Ticket to us to allow you to purchase a new phone number.",
    CHANNELS_SMS_TICKET_BUTTON              : "Submit ticket",
    CHANNELS_GMAIL_TEXT                     : "Before you can install Google Gmail, you must have an email account with Gmail.",
    CHANNELS_GMAIL_START                    : "Once you are ready you can click the <b>Continue with Google</b> button and follow the steps in the dialog.",
    CHANNELS_GMAIL_LOGIN                    : "Continue with Google",
    CHANNELS_GMAIL_TERMS_PRE                : "Conversana's use and transfer of information received from Google APIs to any other app will adhere to ",
    CHANNELS_GMAIL_TERMS_LINK               : "Google API Services User Data Policy",
    CHANNELS_GMAIL_TERMS_POST               : ", including the Limited Use requirements.",
    CHANNELS_OUTLOOK_TEXT                   : "Before you can install Microsoft Outlook, you must have an email account with Microsoft.",
    CHANNELS_OUTLOOK_START                  : "Once you are ready you can click the <b>Continue with Microsoft</b> button and follow the steps in the dialog.",
    CHANNELS_OUTLOOK_PERMISSION             : "You must accept the requested permissions to use this channel.",
    CHANNELS_OUTLOOK_LOGIN                  : "Continue with Microsoft",
    CHANNELS_TELEGRAM_BEFORE                : "Before you can install Telegram, you need to have:",
    CHANNELS_TELEGRAM_INFO1                 : "A bot that can be created by sending a message to <a class='simple-link' href='https://t.me/botfather' target='_blank'>@BotFather</a>.",
    CHANNELS_TELEGRAM_INFO2                 : "The access token of the bot that can be obtained after creating the bot or by sending a message to <a class='simple-link' href='https://t.me/botfather' target='_blank'>@BotFather</a>.",
    CHANNELS_TELEGRAM_START                 : "Once you have the access token of the bot, you can fill it bellow.",
    CHANNELS_TELEGRAM_LABEL                 : "Access token",
    CHANNELS_MERCADO_LIBRE_TEXT             : "Before you can install MercadoLibre, you must have a user with administrator permissions.",
    CHANNELS_MERCADO_LIBRE_START            : "Once you are ready, you can click the <b>Continue with Mercado Libre</b> button and follow the steps in the dialog.",
    CHANNELS_MERCADO_LIBRE_LOGIN            : "Continue with Mercado Libre",
    CHANNELS_MERCADO_LIBRE_PERMISSION       : "You must accept the requested permissions to use this channel.",
    CHANNELS_OTHER_USER                     : "Login with another user",
    CHANNELS_HELP_NAME                      : "If left blank, it will be obtained from the channel.",
    CHANNELS_ADD_USER_NAME                  : "Add the user name to the messages",
    CHANNELS_PROFILE_PROVIDER_ERROR         : "We were unable to obtain the provider's data. We are working to resolve the issue.",
    CHANNELS_PROFILE_CURRENT_IMAGE          : "Current profile image",
    CHANNELS_PROFILE_NEW_IMAGE              : "New profile image",
    CHANNELS_PROFILE_IMAGE_HELP             : "Image must be square and larger than 192 x 192 px.",
    CHANNELS_PROFILE_CATEGORY               : "Company category",
    CHANNELS_PROFILE_NAME                   : "Company name",
    CHANNELS_PROFILE_ABOUT                  : "About the company",
    CHANNELS_PROFILE_DESCRIPTION            : "Description of the company",
    CHANNELS_PROFILE_ADDRESS                : "Company address",
    CHANNELS_PROFILE_EMAIL                  : "Company email",
    CHANNELS_PROFILE_WEBSITES               : "Urls associated with the company",
    CHANNELS_PROFILE_ADD_WEBSITE            : "Add url",
    CHANNELS_NONE_AVAILABLE                 : "There are no channels available.",
    CHANNELS_ERROR_ADD                      : "Unable to add the indicated channel.",
    CHANNELS_ERROR_EDIT                     : "You do not have permission to edit the indicated channel.",
    CHANNELS_ERROR_CLIENT                   : "The channel belongs to another client.",
    CHANNELS_ERROR_EXISTS                   : "The indicated channel does not exist.",
    CHANNELS_ERROR_TYPE_EMPTY               : "You must indicate the channel type.",
    CHANNELS_ERROR_TYPE_INVALID             : "The channel type is incorrect.",
    CHANNELS_ERROR_NAME                     : "You must indicate the channel name.",
    CHANNELS_ERROR_PROFILE_CANT             : "You cannot edit the profile of the indicated channel.",
    CHANNELS_ERROR_PROFILE_IMAGE            : "The image must be bigger than 190px by 190px.",
    CHANNELS_ERROR_PROFILE_EDIT             : "An error occurred while editing the profile of the indicated channel.",
    CHANNELS_ERROR_DELETE                   : "You do not have permission to delete the indicated channel.",
    CHANNELS_SUCCESS_ADD                    : "The channel was successfully configured.",
    CHANNELS_SUCCESS_EDIT                   : "The channel was successfully edited.",
    CHANNELS_SUCCESS_EDIT_PROFILE           : "The channel profile was successfully edited.",
    CHANNELS_SUCCESS_DELETE                 : "The channel was successfully deleted.",

    // Phone Numbers
    PHONE_NUMBERS_NAME                      : "Phone numbers",
    PHONE_NUMBERS_SINGULAR                  : "Phone number",
    PHONE_NUMBERS_ADD_TITLE                 : "Add phone number",
    PHONE_NUMBERS_IMPORT_TITLE              : "Import phone number",
    PHONE_NUMBERS_EDIT_TITLE                : "Edit phone number",
    PHONE_NUMBERS_DELETE_TITLE              : "Delete phone number",
    PHONE_NUMBERS_DELETE_TEXT               : "Do you really want to delete the phone number <b>{0}</b>?<br>Note that the number is not deleted from Twilio and channels associated with the phone number are not deleted.",
    PHONE_NUMBERS_SELECT_PHONE              : "Select a number to buy",
    PHONE_NUMBERS_PHONE_NUMBER              : "Descriptive name",
    PHONE_NUMBERS_FRIENDLY_NAME             : "Friendly name",
    PHONE_NUMBERS_DESCRIPTIVE_NAME          : "Descriptive name",
    PHONE_NUMBERS_LOCATION                  : "Location",
    PHONE_NUMBERS_EMPTY                     : "There are no phone numbers available to import. All phone numbers are used by the clients.",
    PHONE_NUMBERS_NONE_AVAILABLE            : "There are no phone numbers available.",
    PHONE_NUMBERS_ERROR_ADD                 : "Unable to add more phone numbers.",
    PHONE_NUMBERS_ERROR_EDIT                : "You do not have permission to edit the given phone number.",
    PHONE_NUMBERS_ERROR_EXISTS              : "The indicated phone number does not exist.",
    PHONE_NUMBERS_ERROR_SELECT              : "You must select a phone number from the list.",
    PHONE_NUMBERS_ERROR_USED                : "The indicated phone number is already used.",
    PHONE_NUMBERS_ERROR_BUY                 : "The indicated phone number could not be purchased.",
    PHONE_NUMBERS_SUCCESS_ADD               : "The phone number was successfully added.",
    PHONE_NUMBERS_SUCCESS_IMPORT            : "The phone number was successfully imported.",
    PHONE_NUMBERS_SUCCESS_EDIT              : "The phone number was successfully edited.",
    PHONE_NUMBERS_SUCCESS_DELETE            : "The phone number was successfully deleted.",



    // Assistants AI
    ASSISTANTS_NAME                         : "AI Assistants",
    ASSISTANTS_SINGULAR                     : "AI Assistant",
    ASSISTANTS_CREATE_TITLE                 : "Create AI assistant",
    ASSISTANTS_EDIT_TITLE                   : "Edit IA Assistant",
    ASSISTANTS_DELETE_TITLE                 : "Delete IA assistant",
    ASSISTANTS_DELETE_TEXT                  : "Do you really want to delete the IA assistant <b>{0}</b>?",
    ASSISTANTS_MAIN_TEXT                    : "Name, data and status",
    ASSISTANTS_FILES_TEXT                   : "List of files that the assistant can use",
    ASSISTANTS_INSTRUCTIONS                 : "Instructions for the assistant",
    ASSISTANTS_INSTRUCTIONS_TIP             : "Ex: You are a hotel assistant. You must answer guest questions about the hotel or their stay.",
    ASSISTANTS_USE_BY_DEFAULT               : "Use by default",
    ASSISTANTS_FILES_SINGULAR               : "{0} file",
    ASSISTANTS_FILES_PLURAL                 : "{0} files",
    ASSISTANTS_FILE_TITLE                   : "File {0}",
    ASSISTANTS_ADD_FILE                     : "Add file",
    ASSISTANTS_IS_CONTENT                   : "It is a content",
    ASSISTANTS_CONTENT                      : "File content",
    ASSISTANTS_NONE_AVAILABLE               : "There are no AI assistants available.",
    ASSISTANTS_ERROR_CREATE                 : "You do not have permission to create the indicated AI assistant.",
    ASSISTANTS_ERROR_EDIT                   : "You do not have permission to edit the indicated AI assistant.",
    ASSISTANTS_ERROR_DELETE                 : "You do not have permission to delete the indicated IA assistant.",
    ASSISTANTS_ERROR_EXISTS                 : "The indicated IA assistant does not exist.",
    ASSISTANTS_ERROR_NAME                   : "You must indicate the name of the IA assistant.",
    ASSISTANTS_ERROR_MODEL_EMPTY            : "You must indicate the model of the IA assistant.",
    ASSISTANTS_ERROR_MODEL_EXISTS           : "The AI assistant model does not exist.",
    ASSISTANTS_ERROR_FLOW_DELETE            : "You cannot delete the IA assistant when it is used in a flow.",
    ASSISTANTS_ERROR_FILE_AMOUNT            : "You have exceeded the maximum number of files that can be added.",
    ASSISTANTS_ERROR_FILE_NAME              : "You must indicate the file name.",
    ASSISTANTS_ERROR_FILE_FILE              : "You must indicate the file.",
    ASSISTANTS_ERROR_FILE_TYPE              : "The file type must be text type.",
    ASSISTANTS_ERROR_FILE_CONTENT           : "You must indicate the content of the file.",
    ASSISTANTS_ERROR_CREATE_EXTERNAL        : "An error occurred while creating the assistant in OpenAI.",
    ASSISTANTS_ERROR_EDIT_EXTERNAL          : "An error occurred while editing the assistant in OpenAI.",
    ASSISTANTS_ERROR_DELETE_EXTERNAL        : "An error occurred while deleting the assistant in OpenAI.",
    ASSISTANTS_SUCCESS_CREATE               : "The IA assistant was successfully created.",
    ASSISTANTS_SUCCESS_EDIT                 : "The IA assistant was successfully edited.",
    ASSISTANTS_SUCCESS_DELETE               : "The IA assistant was successfully deleted.",

    // Assistants AI: Models
    ASSISTANTS_MODELS_NAME                  : "AI assistant models",
    ASSISTANTS_MODELS_SINGULAR              : "AI assistant model",
    ASSISTANTS_MODELS_CREATE_TITLE          : "Create AI assistant model",
    ASSISTANTS_MODELS_EDIT_TITLE            : "Edit AI assistant model",
    ASSISTANTS_MODELS_DELETE_TITLE          : "Delete AI assistant model",
    ASSISTANTS_MODELS_DELETE_TEXT           : "Do you really want to delete the AI assistant model <b>{0}</b>?",
    ASSISTANTS_MODELS_MODEL                 : "Model",
    ASSISTANTS_MODELS_INPUT_COST            : "Input cost",
    ASSISTANTS_MODELS_OUTPUT_COST           : "Output cost",
    ASSISTANTS_MODELS_TOKEN_PRICE           : "{0} /1k tokens",
    ASSISTANTS_MODELS_MINUTE_COST           : "Output cost per minute",
    ASSISTANTS_MODELS_MINUTE_PRICE          : "{0} /minute",
    ASSISTANTS_MODELS_NONE_AVAILABLE        : "There are no AI assistant models available.",
    ASSISTANTS_MODELS_ERROR_EXISTS          : "The indicated AI assistant model does not exist.",
    ASSISTANTS_MODELS_ERROR_NAME            : "You must indicate the name of the AI assistant model.",
    ASSISTANTS_MODELS_ERROR_TYPE_EMPTY      : "You must indicate the type of the IA assistant model.",
    ASSISTANTS_MODELS_ERROR_TYPE_EXISTS     : "The indicated type does not exist in Open AI.",
    ASSISTANTS_MODELS_ERROR_MODEL_EMPTY     : "You must indicate the model of the IA assistant model.",
    ASSISTANTS_MODELS_ERROR_MODEL_EXISTS    : "The indicated model does not exist in Open AI.",
    ASSISTANTS_MODELS_ERROR_INPUT_COST      : "The input cost per 1k tokens indicated incorrect.",
    ASSISTANTS_MODELS_ERROR_OUTPUT_COST     : "The output cost per 1k tokens indicated is incorrect.",
    ASSISTANTS_MODELS_ERROR_USED            : "Cannot delete an AI assistant model in use.",
    ASSISTANTS_MODELS_SUCCESS_CREATE        : "The AI assistant model was successfully created.",
    ASSISTANTS_MODELS_SUCCESS_EDIT          : "The AI assistant model was successfully edited.",
    ASSISTANTS_MODELS_SUCCESS_DELETE        : "The AI assistant model was successfully deleted.",

    // Assistants AI: Threads
    ASSISTANTS_THREADS_NAME                 : "AI",
    ASSISTANTS_THREADS_WRITE_TEXT           : "Write a question",
    ASSISTANTS_THREADS_RESTART_TITLE        : "Restart assistant",
    ASSISTANTS_THREADS_RESTART_TEXT         : "Do you really want to restart the conversation with the assistant?",
    ASSISTANTS_THREADS_ERROR_EXISTS         : "The indicated conversation with the assistant does not exist.",
    ASSISTANTS_THREADS_ERROR_MESSAGE        : "You must indicate a message to send.",
    ASSISTANTS_THREADS_ERROR_SEND           : "An error occurred while sending the message.",
    ASSISTANTS_THREADS_SUCCESS_RESTART      : "The conversation with the assistant was successfully restarted.",

    // Assistants AI: Runs
    ASSISTANTS_RUNS_NAME                    : "Assistants AI log",
    ASSISTANTS_RUNS_VIEW_TITLE              : "View assistant AI log",
    ASSISTANTS_RUNS_EXECUTION               : "Execution",
    ASSISTANTS_RUNS_INPUT                   : "Input tokens",
    ASSISTANTS_RUNS_OUTPUT                  : "Output tokens",
    ASSISTANTS_RUNS_NONE_AVAILABLE          : "There are no assistant logs available.",



    // Widgets
    WIDGETS_NAME                            : "Omnichannel widgets",
    WIDGETS_SINGULAR                        : "Omnichannel widget",
    WIDGETS_VIEW_TITLE                      : "View omnichannel widget",
    WIDGETS_CREATE_TITLE                    : "Create omnichannel widget",
    WIDGETS_EDIT_TITLE                      : "Edit omnichannel widget",
    WIDGETS_COPY_TITLE                      : "Copy omnichannel widget",
    WIDGETS_DELETE_TITLE                    : "Delete omnichannel widget",
    WIDGETS_DELETE_TEXT                     : "Do you really want to delete the omnichannel widget <b>{0}</b>?",
    WIDGETS_PUBLISH_NO_CHANGES              : "There are no changes to publish in the omnichannel widget <b>{0}</b>.",
    WIDGETS_ACTUAL_VERSION                  : "Current version",
    WIDGETS_MODIFIED_VERSION                : "Modified version",
    WIDGETS_CLEAR_CHANGES                   : "Do you really want to clear the changes for the omnichannel widget <b>{0}</b>?",
    WIDGETS_GENERAL                         : "General",
    WIDGETS_TEXTS                           : "Texts",
    WIDGETS_START                           : "Start",
    WIDGETS_RESTART                         : "Restart",
    WIDGETS_SHOW_START                      : "Show start",
    WIDGETS_CHAT                            : "Chat",
    WIDGETS_SHOW_CHAT                       : "Show the chat",
    WIDGETS_CHAT_FLOW                       : "Flow used in the chat",
    WIDGETS_REQUEST_NAME                    : "Request name",
    WIDGETS_REQUEST_MESSAGE                 : "Request message",
    WIDGETS_BIG_START_BUTTON                : "Show big start button",
    WIDGETS_AUTO_START_CHAT                 : "Auto start chat",
    WIDGETS_AUTO_OPEN_CHAT                  : "Open chat with new messages",
    WIDGETS_SHOW_CHAT_LOGO                  : "Show the logo in the header",
    WIDGETS_CHAT_TEXT                       : "Chat header text",
    WIDGETS_SHOW_NAMES                      : "Show names",
    WIDGETS_CONVERSATION_TEXT               : "Text to start conversation",
    WIDGETS_REQUEST_NAME_TEXT               : "Text to request the name",
    WIDGETS_REQUEST_MESSAGE_TEXT            : "Text for the message input",
    WIDGETS_START_BUTTON_TEXT               : "Text for the start button",
    WIDGETS_RESTART_TEXT                    : "Text to return to the conversation",
    WIDGETS_BOT_NAME                        : "Bot name",
    WIDGETS_UNREAD_TEXT                     : "Text when there are new messages",
    WIDGETS_WRITING_TEXT                    : "Text when the agent is writing",
    WIDGETS_SEND                            : "Send message",
    WIDGETS_SEND_TEXT                       : "Text to send messages",
    WIDGETS_SHOW_EMOJI_BUTTON               : "Show emoji button",
    WIDGETS_SHOW_FILE_BUTTON                : "Show file button",
    WIDGETS_SHOW_LOCATION_BUTTON            : "Show location button",
    WIDGETS_COLORS                          : "Colors",
    WIDGETS_BUTTON_BG_COLOR                 : "Button background",
    WIDGETS_BUTTON_ICON_COLOR               : "Button icon",
    WIDGETS_CONTACT_MESSAGE_BG_COLOR        : "Background of contact messages",
    WIDGETS_CONTACT_MESSAGE_TEXT_COLOR      : "Contact message text",
    WIDGETS_USER_MESSAGE_BG_COLOR           : "Background of user messages",
    WIDGETS_USER_MESSAGE_TEXT_COLOR         : "Text of user messages",
    WIDGETS_BUBBLE_BUTTON_COLOR             : "Text and border of the message buttons",
    WIDGETS_BUBBLE_BUTTON_HOVER             : "Text of the message buttons on hover",
    WIDGETS_SHOW_PLATFORMS                  : "Show platforms",
    WIDGETS_PLATFORMS_TEXT                  : "Text for other platforms",
    WIDGETS_CONNECT_TEXT                    : "Text to connect to the platform",
    WIDGETS_WEB_LINK_TEXT                   : "Text for the link",
    WIDGETS_QR_CODE_TEXT                    : "Text for the QR code",
    WIDGETS_ICON                            : "Icon",
    WIDGETS_SHOW_MESSAGE_COUNT              : "Show message counter",
    WIDGETS_ICON_SHOW_FILE                  : "Use an icon image",
    WIDGETS_ICON_FILE                       : "Icon image",
    WIDGETS_ICON_COLOR                      : "Icon color",
    WIDGETS_ICON_BG_COLOR                   : "Icon background color",
    WIDGETS_ICON_SHOW_BORDER                : "Show icon border",
    WIDGETS_ICON_BORDER_COLOR               : "Icon border color",
    WIDGETS_ICON_SHOW_SHADOW                : "Show icon shadow",
    WIDGETS_ICON_SIZE                       : "Size of the icon",
    WIDGETS_ICON_BOTTOM_SPACE               : "Space bellow the icon",
    WIDGETS_BUBBLE                          : "Bubble",
    WIDGETS_SHOW_BUBBLE                     : "Show bubble",
    WIDGETS_BUBBLE_TEXT                     : "Bubble text",
    WIDGETS_BUBBLE_BG_COLOR                 : "Bubble background color",
    WIDGETS_BUBBLE_TEXT_COLOR               : "Bubble text color",
    WIDGETS_BUBBLE_BORDER_COLOR             : "Bubble border color",
    WIDGETS_CHAT_MINE_NAME                  : "John",
    WIDGETS_CHAT_MINE_NAME_FULL             : "John Smith",
    WIDGETS_CHAT_MINE_FIRST                 : "Hello",
    WIDGETS_CHAT_MINE_SECOND                : "Hello, I would like to make a reservation",
    WIDGETS_CHAT_YOURS_BOT                  : "Bot",
    WIDGETS_CHAT_YOURS_NAME                 : "Anna",
    WIDGETS_CHAT_YOURS_NAME_FULL            : "Anna Connors",
    WIDGETS_CHAT_YOURS_FIRST                : "Hi John. How can I help you?",
    WIDGETS_CHAT_YOURS_SECOND               : "Perfect. An agent will take care of making the reservation in the next few minutes",
    WIDGETS_CHAT_YOURS_THIRD                : "Hi, I'm Ana. When would you like to make the reservation?",
    WIDGETS_CHAT_OPTION1                    : "Booking",
    WIDGETS_CHAT_OPTION2                    : "Quotation",
    WIDGETS_CHAT_OPTION3                    : "Help",
    WIDGETS_BRANDING                        : "Developed by <b>Conversana</b>",
    WIDGETS_NONE_AVAILABLE                  : "There are no omnichannel widgets available.",
    WIDGETS_ERROR_ADD                       : "Unable to add the indicated omnichannel widget.",
    WIDGETS_ERROR_EDIT                      : "You do not have permission to edit the indicated omnichannel widget.",
    WIDGETS_ERROR_PUBLISH                   : "You do not have permission to publish changes to the indicated omnichannel widget.",
    WIDGETS_ERROR_CLEAR                     : "You do not have permission to clear changes to the indicated omnichannel widget.",
    WIDGETS_ERROR_DELETE                    : "You do not have permission to delete the indicated omnichannel widget.",
    WIDGETS_ERROR_EXISTS                    : "The indicated omnichannel widget does not exist.",
    WIDGETS_SUCCESS_CREATE                  : "The omnichannel widget was successfully created.",
    WIDGETS_SUCCESS_COPY                    : "The omnichannel widget was successfully copied.",
    WIDGETS_SUCCESS_EDIT                    : "The omnichannel widget was successfully edited.",
    WIDGETS_SUCCESS_PUBLISH                 : "The omnichannel widget changes were successfully published.",
    WIDGETS_SUCCESS_CLEAR                   : "The omnichannel widget changes were successfully cleared.",
    WIDGETS_SUCCESS_DELETE                  : "The omnichannel widget was successfully deleted.",

    // Widgets: Platforms
    WIDGETS_PLATFORMS_NAME                  : "Platforms",
    WIDGETS_PLATFORMS_SINGULAR              : "Platform",
    WIDGETS_PLATFORMS_ADD_TITLE             : "Add platform",
    WIDGETS_PLATFORMS_EDIT_TITLE            : "Edit platform",
    WIDGETS_PLATFORMS_DELETE_TITLE          : "Delete platform",
    WIDGETS_PLATFORMS_DELETE_TEXT           : "Do you really want to delete the platform <b>{0}</b>?",
    WIDGETS_PLATFORMS_IS_LINK               : "It is a link",
    WIDGETS_PLATFORMS_LINK_URL              : "Link url",
    WIDGETS_PLATFORMS_SHOW_QR               : "Show QR code",
    WIDGETS_PLATFORMS_NONE_CHANNELS         : "There are no channels available. You can click here to create the first channel.",
    WIDGETS_PLATFORMS_NONE_AVAILABLE        : "There are no platforms available.",
    WIDGETS_PLATFORMS_ERROR_EXISTS          : "The indicated platform does not exist.",
    WIDGETS_PLATFORMS_ERROR_PROVIDER_EMPTY  : "You must indicate the platform.",
    WIDGETS_PLATFORMS_ERROR_PROVIDER_EXISTS : "The indicated platform does not exist.",
    WIDGETS_PLATFORMS_ERROR_URL             : "You must indicate the url of the link.",
    WIDGETS_PLATFORMS_ERROR_CHANNEL         : "You must indicate the channel of the platform.",
    WIDGETS_PLATFORMS_ERROR_REPEAT          : "You cannot use the same channel on more than one platform.",
    WIDGETS_PLATFORMS_ERROR_DELETE          : "You do not have permission to delete the indicated platform.",
    WIDGETS_PLATFORMS_SUCCESS_CREATE        : "The platform was successfully created.",
    WIDGETS_PLATFORMS_SUCCESS_EDIT          : "The platform was successfully edited.",
    WIDGETS_PLATFORMS_SUCCESS_SORT          : "The platforms were successfully sorted.",
    WIDGETS_PLATFORMS_SUCCESS_DELETE        : "The platform was successfully deleted.",



    // Flows
    FLOWS_NAME                              : "Flows",
    FLOWS_SINGULAR                          : "Flow",
    FLOWS_VIEW_TITLE                        : "View flow",
    FLOWS_CREATE_TITLE                      : "Create flow",
    FLOWS_EDIT_TITLE                        : "Edit flow",
    FLOWS_COPY_TITLE                        : "Copy flow",
    FLOWS_DELETE_TITLE                      : "Delete flow",
    FLOWS_DELETE_TEXT                       : "Do you really want to delete the flow <b>{0}</b>?",
    FLOWS_PUBLISH_NO_CHANGES                : "There are no changes to publish in the flow <b>{0}</b>.",
    FLOWS_PUBLISH_CHANGES                   : "Do you really want to publish the changes in the flow <b>{0}</b>?",
    FLOWS_PUBLISH_CHANGES_SINGULAR          : "Do you really want to publish the node with changes in the flow <b>{1}</b>?",
    FLOWS_PUBLISH_CHANGES_PLURAL            : "Do you really want to publish the <b>{0} nodes</b> with changes in the flow <b>{1}</b>?",
    FLOWS_CLEAR_CHANGES                     : "Do you really want to clear changes to flow <b>{0}</b>?",
    FLOWS_ERROR_TEXT                        : "There is a node with errors in the flow. You must fix them to publish the flow. You can see them in the side panel.",
    FLOWS_ERRORS_TEXT                       : "There are <b>{0}</b> nodes with errors in the flow. You must fix them to publish the flow. You can see them in the side panel.",
    FLOWS_TEMPLATE                          : "Create with flow template",
    FLOWS_PARENT_FLOW                       : "Parent flow",
    FLOWS_STARTED_AMOUNT                    : "Started",
    FLOWS_QUALIFICATION_AMOUNT              : "Qualified",
    FLOWS_ENDED_AMOUNT                      : "Ended",
    FLOWS_TIMED_OUT_AMOUNT                  : "Timed out",
    FLOWS_NONE_AVAILABLE                    : "There are no flows available.",
    FLOWS_NONE_OPTIONS                      : "There are no options available.",
    FLOWS_ERROR_EDIT                        : "You do not have permission to edit the indicated flow.",
    FLOWS_ERROR_PUBLISH                     : "You cannot publish the flow because it has errors.",
    FLOWS_ERROR_EXISTS                      : "The indicated flow does not exist.",
    FLOWS_ERROR_NAME                        : "You must indicate the name of the flow.",
    FLOWS_ERROR_PARENT                      : "The parent flow can't be the same as the current flow.",
    FLOWS_ERROR_FLOW                        : "You cannot delete the flow while it is used in another flow.",
    FLOWS_SUCCESS_CREATE                    : "The flow was successfully created.",
    FLOWS_SUCCESS_COPY                      : "The flow was successfully copied.",
    FLOWS_SUCCESS_EDIT                      : "The flow was successfully edited.",
    FLOWS_SUCCESS_PUBLISH                   : "The flow changes were successfully published.",
    FLOWS_SUCCESS_CLEAR                     : "The flow changes were successfully cleared.",
    FLOWS_SUCCESS_DELETE                    : "The flow was successfully deleted.",

    // Flows: Templates
    FLOWS_TEMPLATES_NAME                    : "Flow templates",
    FLOWS_TEMPLATES_SINGULAR                : "Flow template",
    FLOWS_TEMPLATES_VIEW_TITLE              : "View flow template",
    FLOWS_TEMPLATES_CREATE_TITLE            : "Create flow template",
    FLOWS_TEMPLATES_EDIT_TITLE              : "Edit flow template",
    FLOWS_TEMPLATES_COPY_TITLE              : "Copy flow template",
    FLOWS_TEMPLATES_DELETE_TITLE            : "Delete flow template",
    FLOWS_TEMPLATES_DELETE_TEXT             : "Do you really want to delete the flow template <b>{0}</b>?",
    FLOWS_TEMPLATES_PUBLISH_NO_CHANGES      : "There are no nodes with changes to publish in the flow template <b>{0}</b>.",
    FLOWS_TEMPLATES_PUBLISH_CHANGES         : "Do you really want to publish the changes in the flow template <b>{0}</b>?",
    FLOWS_TEMPLATES_PUBLISH_SINGULAR        : "Do you really want to publish the node with changes in the flow template <b>{1}</b>?",
    FLOWS_TEMPLATES_PUBLISH_PLURAL          : "Do you really want to publish the <b>{0} nodes</b> with changes in the flow template <b>{1}</b>?",
    FLOWS_TEMPLATES_CLEAR_CHANGES           : "Do you really want to clear the changes to flow template <b>{0}</b>?",
    FLOWS_TEMPLATES_ERROR_TEXT              : "There is a node with errors in the flow template. You must fix them to publish the flow template. You can see them in the side panel.",
    FLOWS_TEMPLATES_ERRORS_TEXT             : "There are <b>{0}</b> nodes with errors in the flow template. You must fix them to publish the flow template. You can see them in the side panel.",
    FLOWS_TEMPLATES_NONE_AVAILABLE          : "There are no flow templates available.",
    FLOWS_TEMPLATES_ERROR_NAME              : "You must indicate the name of the flow template.",
    FLOWS_TEMPLATES_ERROR_EXISTS            : "The indicated flow template does not exist.",
    FLOWS_TEMPLATES_ERROR_EDIT              : "You do not have permission to edit the indicated flow template.",
    FLOWS_TEMPLATES_ERROR_DELETE            : "You do not have permission to delete the indicated flow template.",
    FLOWS_TEMPLATES_ERROR_PUBLISH           : "You cannot publish the flow template because it has errors.",
    FLOWS_TEMPLATES_SUCCESS_CREATE          : "The flow template was successfully created.",
    FLOWS_TEMPLATES_SUCCESS_COPY            : "The flow template was successfully copied.",
    FLOWS_TEMPLATES_SUCCESS_EDIT            : "The flow template was successfully edited.",
    FLOWS_TEMPLATES_SUCCESS_PUBLISH         : "The flow template changes were successfully published.",
    FLOWS_TEMPLATES_SUCCESS_CLEAR           : "The flow template changes were successfully cleared.",
    FLOWS_TEMPLATES_SUCCESS_DELETE          : "The flow template was successfully deleted.",

    // Flows: Nodes
    FLOWS_NODES_SINGULAR                    : "Node",
    FLOWS_NODES_MOVE_TITLE                  : "Move nodes",
    FLOWS_NODES_MOVE_TEXT                   : "You can use this dialog to move or copy selected nodes with their links to another flow.",
    FLOWS_NODES_DELETE_TITLE                : "Delete node",
    FLOWS_NODES_DELETE_TEXT                 : "Do you really want to delete the selected node?",
    FLOWS_NODES_MULTI_DELETE_TITLE          : "Delete nodes",
    FLOWS_NODES_MULTI_DELETE_TEXT           : "Do you really want to delete the selected <b>{0}</b> nodes?",
    FLOWS_NODES_WARNING_TITLE               : "Unsaved changes",
    FLOWS_NODES_WARNING_TEXT                : "There are unsaved changes to node options. Do you want to discard them or continue editing and save them?",
    FLOWS_NODES_INVALID                     : "Invalid",
    FLOWS_NODES_START_FLOW                  : "Start flow",
    FLOWS_NODES_CONDITION                   : "Condition",
    FLOWS_NODES_SEND_MESSAGE                : "Send message",
    FLOWS_NODES_SEND_TEMPLATE               : "Send template",
    FLOWS_NODES_SEND_OPTIONS                : "Send options",
    FLOWS_NODES_ASSISTANT_REPLY             : "Reply with IA",
    FLOWS_NODES_ASSISTANT_OPTIONS           : "Options with IA",
    FLOWS_NODES_ASSISTANT_INTERPRET         : "Interpret with IA",
    FLOWS_NODES_ASSISTANT_SUMMARIZE         : "Summarize with IA",
    FLOWS_NODES_QUESTIONNAIRE               : "Frequent questions",
    FLOWS_NODES_REQUEST_DATA                : "Request data",
    FLOWS_NODES_ASSIGN_DATA                 : "Assign data",
    FLOWS_NODES_ADD_NOTE                    : "Add note",
    FLOWS_NODES_JUMP_TO                     : "Jump to",
    FLOWS_NODES_CHANGE_STATUS               : "Change status",
    FLOWS_NODES_SEND_TO_QUEUE               : "Send to queue if no agent assigned",
    FLOWS_NODES_WAIT_EVENT                  : "Wait for event",
    FLOWS_NODES_QUALIFICATION               : "Qualify",
    FLOWS_NODES_NOTIFICATION                : "Notify",
    FLOWS_NODES_BOOKING                     : "Booking",
    FLOWS_NODES_STORE                       : "Store",
    FLOWS_NODES_NOT_ALLOWED                 : "Node disabled for not having enough permissions. Every execution will end in an error.",
    FLOWS_NODES_NAME                        : "Node name",
    FLOWS_NODES_TEAM_SCHEDULE               : "Team schedule",
    FLOWS_NODES_KEYWORDS                    : "Phrases",
    FLOWS_NODES_ADD_KEYWORD                 : "Add phrase",
    FLOWS_NODES_JUMP_TO_NODE                : "Jump to node",
    FLOWS_NODES_JUMP_TO_FLOW                : "Jump to flow",
    FLOWS_NODES_JUMP_RETURN                 : "Return",
    FLOWS_NODES_CAN_RETURN                  : "Can return",
    FLOWS_NODES_INPUT                       : "Input",
    FLOWS_NODES_SUCCESS                     : "Success",
    FLOWS_NODES_OUTPUT                      : "Output",
    FLOWS_NODES_NO_RESPONSE                 : "No response",
    FLOWS_NODES_CONDITION_NONE              : "No coincidence",
    FLOWS_NODES_MESSAGE_WAITING             : "Message sent for waiting",
    FLOWS_NODES_MESSAGE_ERROR               : "Error message",
    FLOWS_NODES_OPTIONS_IN_MESSAGE          : "Show options in the message",
    FLOWS_NODES_ALLOW_TEXT_RESPONSE         : "Allow a text as a response",
    FLOWS_NODES_TEXT_RESPONSE               : "Text response",
    FLOWS_NODES_WAIT_RESPONSE               : "Wait for response",
    FLOWS_NODES_ASSISTANT_PROMPT            : "How should it respond",
    FLOWS_NODES_ASSISTANT_PROMPT_TIP        : "Ex: Reply to \"{conve-lastContactMessage}\"",
    FLOWS_NODES_INTERPRET_MESSAGE           : "Message to interpret",
    FLOWS_NODES_INTERPRET_ROOM_BOOKING      : "Interpret a room booking",
    FLOWS_NODES_OUT_ROOM_BOOKING            : "Room booking",
    FLOWS_NODES_INTERPRET_FIELD_TITLE       : "Interpret field {0}",
    FLOWS_NODES_INTERPRET_FIELD             : "Field to interpret",
    FLOWS_NODES_OUT_UNKNOWN                 : "Unknown",
    FLOWS_NODES_SHOW_EXTRA_OPTION           : "Show additional option",
    FLOWS_NODES_EXTRA_OPTION                : "Additional option",
    FLOWS_NODES_EXTRA_OPTION_TEXT           : "Text for additional option",
    FLOWS_NODES_ASK_IF_RESOLVED             : "Ask if the question was resolved",
    FLOWS_NODES_QUESTION_MESSAGE            : "Question if was useful",
    FLOWS_NODES_ANSWERED_OPTION             : "Option when resolved",
    FLOWS_NODES_NOT_ANSWERED_OPTION         : "Option when resolved",
    FLOWS_NODES_QUESTIONNAIRES_RESOLVED     : "Resolved",
    FLOWS_NODES_QUESTIONNAIRES_NOT_RESOLVED : "Not resolved",
    FLOWS_NODES_SHOW_CONTINUE_OPTION        : "Show continue option",
    FLOWS_NODES_SHOW_CONTINUE_OPTION_TIP    : "Show a button to continue with the flow without accessing the store.",
    FLOWS_NODES_CONTINUE_OPTION             : "Continue option",
    FLOWS_NODES_CONTINUE_OPTION_TEXT        : "Text for continue option",
    FLOWS_NODES_OUT_CONFIRMED_ORDER         : "Confirmed order",
    FLOWS_NODES_OUT_ABANDONED_ORDER         : "Abandoned order",
    FLOWS_NODES_SUMMARIZE_PROMPT            : "How should it summarize",
    FLOWS_NODES_SUMMARIZE_PROMPT_TIP        : "Give the instructions on how it should summarize all the messages in the conversation",
    FLOWS_NODES_SUMMARIZE_NOTES             : "Include notes",
    FLOWS_NODES_EVENT                       : "Event",
    FLOWS_NODES_EVENT_TITLE                 : "Event {0}",
    FLOWS_NODES_EVENT_RESOLVED              : "<b>Resolved:</b> When a conversation is resolved",
    FLOWS_NODES_ADD_MORE_EVENTS             : "Add more events",
    FLOWS_NODES_OUT_RESOLVED                : "Resolved",
    FLOWS_NODES_MODULE_ASK                  : "What data to request",
    FLOWS_NODES_MODULE_SAVE                 : "Where to save",
    FLOWS_NODES_MODULE_SAVE_KEY             : "Where to save the key",
    FLOWS_NODES_OPERATION                   : "Operation",
    FLOWS_NODES_ASK_NEW_ACCOUNT             : "Ask for new account",
    FLOWS_NODES_NEW_VALUE                   : "New value",
    FLOWS_NODES_ALLOWS_NEW_VALUE            : "Allows a new value",
    FLOWS_NODES_ALLOWS_NEW_OPTION           : "Text for new value option",
    FLOWS_NODES_SKIP_IF_FILLED              : "Skip if the field has a value",
    FLOWS_NODES_HAS_MAX_TRIES               : "Has maximum amount of tries",
    FLOWS_NODES_MAX_TRIES                   : "Maximum amount of tries",
    FLOWS_NODES_USE_TIMEOUT                 : "Use wait timeout",
    FLOWS_NODES_TIMEOUT                     : "Minutes to wait",
    FLOWS_NODES_RETRIES                     : "Number of retries",
    FLOWS_NODES_RETRY_MESSAGE               : "Retry message",
    FLOWS_NODES_RESOLVE_ON_TIMEOUT          : "Resolve on timeout",
    FLOWS_NODES_RESOLVED_MESSAGE            : "Resolved message",
    FLOWS_NODES_TRANSCRIBE_AUDIO            : "Transcribe audio",
    FLOWS_NODES_ASSIGN_TITLE                : "Assign {0}",
    FLOWS_NODES_ADD_ASSIGN                  : "Add assign",
    FLOWS_NODES_ASSIGN_TYPE                 : "Data to assign",
    FLOWS_NODES_DESKTOP_NOTIFICATION        : "Send desktop notification",
    FLOWS_NODES_EMAIL_NOTIFICATION          : "Send notification by email",
    FLOWS_NODES_EMAIL_USERS                 : "Send it to the assigned team/user",
    FLOWS_NODES_EMAIL_CONTACT               : "Send it to the contact",
    FLOWS_NODES_DESKTOP_MESSAGE             : "Desktop Notification Message",
    FLOWS_NODES_BOOKING_BOOKED              : "Booked",
    FLOWS_NODES_BOOKING_NO_ROOMS            : "No rooms",
    FLOWS_NODES_BOOKING_NOT_BOOKED          : "No booked",
    FLOWS_NODES_BOOKING_HOTEL               : "Assigned hotel",
    FLOWS_NODES_BOOKING_MESSAGE             : "Initial message",
    FLOWS_NODES_BOOKING_FILE                : "Initial message file",
    FLOWS_NODES_BOOKING_ARRIVAL_MESSAGE     : "Arrival date message",
    FLOWS_NODES_BOOKING_DEPARTURE_MESSAGE   : "Departure date message",
    FLOWS_NODES_BOOKING_GUESTS_MESSAGE      : "Amount of guests message",
    FLOWS_NODES_BOOKING_SEARCHING_MESSAGE   : "Message before searching",
    FLOWS_NODES_BOOK_ROOM_OPTION            : "Option to book a room",
    FLOWS_NODES_OTHER_ROOM_OPTION           : "Option to see another room",
    FLOWS_NODES_FIRST_ROOM_OPTION           : "Option to see the first room",
    FLOWS_NODES_END_BOOKING_OPTION          : "Option to end the booking",
    FLOWS_NODES_WITH_ERRORS                 : "Nodes with errors",
    FLOWS_NODES_FOUND_NODES                 : "Found nodes",
    FLOWS_NODES_NEW_FLOW_ID                 : "New flow for the nodes",
    FLOWS_NODES_NEW_FLOW                    : "Create new flow",
    FLOWS_NODES_NEW_FLOW_NAME               : "Name of the new flow",
    FLOWS_NODES_DELETE_NODES                : "Delete nodes from the current flow",
    FLOWS_NODES_GOTO_FLOW                   : "Go to flow",
    FLOWS_NODES_GOTO_PARENT                 : "Go to parent",
    FLOWS_NODES_ERROR_PERMISSION            : "You do not have permission to add this node.",
    FLOWS_NODES_ERROR_INPUT                 : "Input link missing",
    FLOWS_NODES_ERROR_CYCLE                 : "An infinite cycle was created",
    FLOWS_NODES_ERROR_EXISTS                : "The indicated node does not exist.",
    FLOWS_NODES_ERROR_TYPE                  : "The type of the node is incorrect.",
    FLOWS_NODES_ERROR_DELETE                : "There are no nodes to delete.",
    FLOWS_NODES_ERROR_MESSAGE               : "You must indicate the message.",
    FLOWS_NODES_ERROR_ASSISTANT_PROMPT      : "You must indicate how it should respond.",
    FLOWS_NODES_ERROR_INTERPRET_MESSAGE     : "You must indicate the message to interpret.",
    FLOWS_NODES_ERROR_INTERPRET_FIELDS      : "You must indicate the fields to interpret.",
    FLOWS_NODES_ERROR_CONDITIONS_NAME       : "You must indicate the name of the condition.",
    FLOWS_NODES_ERROR_CONDITIONS_OPERATOR   : "The operator is incorrect.",
    FLOWS_NODES_ERROR_CONDITIONS_KEYWORDS   : "You must indicate at least one phrase.",
    FLOWS_NODES_ERROR_OPTIONS_EMPTY         : "You must indicate at least one option.",
    FLOWS_NODES_ERROR_OPTIONS_NAME          : "You must indicate the name of the option.",
    FLOWS_NODES_ERROR_OPTIONS_DESCRIPTION   : "You must indicate the description of the option.",
    FLOWS_NODES_ERROR_OPTIONS_MAX           : "You must indicate less than 10 options.",
    FLOWS_NODES_ERROR_OPTIONS_LENGTH        : "The text of the options must not exceed 20 characters.",
    FLOWS_NODES_ERROR_OPERATION             : "You must indicate a valid operation.",
    FLOWS_NODES_ERROR_MODULE                : "You must indicate where to save the data.",
    FLOWS_NODES_ERROR_FIELD                 : "You must indicate a valid field.",
    FLOWS_NODES_ERROR_VALUE                 : "You must indicate a valid value.",
    FLOWS_NODES_ERROR_MAX_TRIES             : "The maximum amount of tries must be greater than 0.",
    FLOWS_NODES_ERROR_TIMEOUT               : "Timeout minutes must be greater than 0.",
    FLOWS_NODES_ERROR_RETRIES               : "The number of retries must be greater or equal to 0.",
    FLOWS_NODES_ERROR_ASSIGNS               : "You must indicate at least one assign.",
    FLOWS_NODES_ERROR_ASSIGN_TYPE           : "You must indicate a valid data to assign.",
    FLOWS_NODES_ERROR_JUMP                  : "You must indicate where to jump to.",
    FLOWS_NODES_ERROR_STATUS                : "You must indicate the status to change.",
    FLOWS_NODES_ERROR_EVENTS                : "Must indicate at least one event.",
    FLOWS_NODES_ERROR_EVENT_NAME            : "You must indicate the name of the event.",
    FLOWS_NODES_ERROR_EVENT_TIME            : "You must indicate the number of hours and/or minutes.",
    FLOWS_NODES_ERROR_EVENT_REPEAT          : "You cannot repeat this type of event.",
    FLOWS_NODES_ERROR_EVENT_TYPE            : "Must indicate a valid event.",
    FLOWS_NODES_ERROR_NOTIFICATION_TITLE    : "You must indicate the title of the notification.",
    FLOWS_NODES_ERROR_NOTIFICATION_MESSAGE  : "You must indicate the notification message.",
    FLOWS_NODES_ERROR_NOTIFICATION_NONE     : "You must select to send to the users/teams or add at least one email.",
    FLOWS_NODES_ERROR_NOTIFICATION_EMAIL    : "One of the indicated emails is invalid.",
    FLOWS_NODES_ERROR_NEW_FLOW_EMPTY        : "You must indicate a new flow.",
    FLOWS_NODES_ERROR_NEW_FLOW_EQUAL        : "The new flow cannot be equal to the current one.",
    FLOWS_NODES_ERROR_NEW_FLOW_NAME         : "You must indicate the name of the new flow.",
    FLOWS_NODES_ERROR_NEW_FLOW_EXISTS       : "The indicated new flow does not exist.",
    FLOWS_NODES_SUCCESS_EDIT                : "The node was successfully edited.",
    FLOWS_NODES_SUCCESS_MOVE                : "The node was successfully moved.",
    FLOWS_NODES_SUCCESS_MULTI_MOVE          : "The nodes were successfully moved.",
    FLOWS_NODES_SUCCESS_DELETE              : "The node was successfully deleted.",
    FLOWS_NODES_SUCCESS_MULTI_DELETE        : "The nodes were successfully deleted.",

    // Flows: Edges
    FLOWS_EDGES_ERROR_CREATE                : "You do not have permission to create a link.",
    FLOWS_EDGES_ERROR_EXISTS                : "The indicated link does not exist.",
    FLOWS_EDGES_ERROR_FROM                  : "A link already exists from the very beginning.",
    FLOWS_EDGES_ERROR_SELF                  : "The link cannot start and end on the same node.",
    FLOWS_EDGES_ERROR_CYCLE                 : "You cannot generate loops with links.",
    FLOWS_EDGES_ERROR_DELETE                : "You cannot delete the indicated link.",

    // Flows: Simulation
    FLOWS_SIMULATION_NAME                   : "Simulation",
    FLOWS_SIMULATION_START_TITLE            : "Simulate flow",
    FLOWS_SIMULATION_RESTART_TITLE          : "Restart flow simulation",
    FLOWS_SIMULATION_END_TITLE              : "End flow simulation",
    FLOWS_SIMULATION_END_TEXT               : "Do you really want to end the flow simulation? You can choose what to do with the used conversation and contact.",
    FLOWS_SIMULATION_END                    : "End",
    FLOWS_SIMULATION_RESTART                : "Restart",
    FLOWS_SIMULATION_START_HERE             : "Start from the current flow",
    FLOWS_SIMULATION_START_FLOW             : "Start from the flow",
    FLOWS_SIMULATION_SAME_FLOW              : "Start from the same flow",
    FLOWS_SIMULATION_NEW_CONTACT            : "Use a new contact",
    FLOWS_SIMULATION_SAME_CONTACT           : "Use the same contact",
    FLOWS_SIMULATION_CONTACT_NAME           : "Contact name",
    FLOWS_SIMULATION_USE_CURRENT_LANGUAGE   : "Use current language",
    FLOWS_SIMULATION_INITIAL_MESSAGE        : "Send initial message",
    FLOWS_SIMULATION_MESSAGE                : "Initial message",
    FLOWS_SIMULATION_ERROR_EXISTS           : "There is no simulation running.",
    FLOWS_SIMULATION_ERROR_STARTED          : "You already have a running simulation.",
    FLOWS_SIMULATION_SUCCESS_START          : "The flow simulation successfully started.",
    FLOWS_SIMULATION_SUCCESS_RESTART        : "The flow simulation successfully restarted.",
    FLOWS_SIMULATION_SUCCESS_END            : "The flow simulation successfully ended.",



    // Automations
    AUTOMATIONS_NAME                        : "Automations",
    AUTOMATIONS_SINGULAR                    : "Automation",
    AUTOMATIONS_VIEW_TITLE                  : "View automation",
    AUTOMATIONS_CREATE_TITLE                : "Create automation",
    AUTOMATIONS_EDIT_TITLE                  : "Edit automation",
    AUTOMATIONS_COPY_TITLE                  : "Copy automation",
    AUTOMATIONS_ACTIVATE_TITLE              : "Activate automation",
    AUTOMATIONS_ACTIVATE_TEXT               : "Do you really want to activate the automation <b>{0}</b>?",
    AUTOMATIONS_PAUSE_TITLE                 : "Pause automation",
    AUTOMATIONS_PAUSE_TEXT                  : "Do you really want to pause the automation <b>{0}</b>?",
    AUTOMATIONS_DELETE_TITLE                : "Delete automation",
    AUTOMATIONS_DELETE_TEXT                 : "Do you really want to delete the automation <b>{0}</b>?",
    AUTOMATIONS_PUBLISH_NO_CHANGES          : "There are no actions with changes to publish in the automation <b>{1}</b>.",
    AUTOMATIONS_PUBLISH_CHANGES_SINGULAR    : "Do you really want to publish the action with changes in the automation <b>{1}</b>?",
    AUTOMATIONS_PUBLISH_CHANGES_PLURAL      : "Do you really want to publish the <b>{0} actions</b> with changes in the automation <b>{1}</b>?",
    AUTOMATIONS_CLEAR_CHANGES               : "Do you really want to clear the changes to automation <b>{0}</b>?",
    AUTOMATIONS_ERRORS_AUTOMATION           : "There are errors in the automation.",
    AUTOMATIONS_ERRORS_FLOW                 : "There are errors in the automation flow.",
    AUTOMATIONS_ERRORS_ACTIVATE             : "You must fix them to activate the automation.",
    AUTOMATIONS_ERRORS_PUBLISH              : "You must fix them to publish the automation.",
    AUTOMATIONS_ERRORS_PANEL                : "You can see them in the side panel.",
    AUTOMATIONS_TEMPLATE                    : "Create with automation template",
    AUTOMATIONS_SUCCEEDED_AMOUNT            : "Succeeded executions",
    AUTOMATIONS_FAILED_AMOUNT               : "Failed executions",
    AUTOMATIONS_NONE_AVAILABLE              : "There are no automations available.",
    AUTOMATIONS_ERROR_EDIT                  : "You do not have permission to edit the indicated automation.",
    AUTOMATIONS_ERROR_EXISTS                : "The indicated automation does not exist.",
    AUTOMATIONS_ERROR_NAME                  : "You must indicate the name of the automation.",
    AUTOMATIONS_ERROR_PUBLISH               : "You cannot publish the automation because it has errors.",
    AUTOMATIONS_SUCCESS_CREATE              : "The automation was successfully created.",
    AUTOMATIONS_SUCCESS_COPY                : "The automation was successfully copied.",
    AUTOMATIONS_SUCCESS_EDIT                : "The automation was successfully edited.",
    AUTOMATIONS_SUCCESS_ACTIVATE            : "The automation was successfully activated.",
    AUTOMATIONS_SUCCESS_PUBLISH             : "The automation changes were successfully published.",
    AUTOMATIONS_SUCCESS_CLEAR               : "The automation changes were successfully cleared.",
    AUTOMATIONS_SUCCESS_PAUSE               : "The automation was successfully paused.",
    AUTOMATIONS_SUCCESS_DELETE              : "The automation was successfully deleted.",

    // Automations: Templates
    AUTOMATIONS_TEMPS_NAME                  : "Automation templates",
    AUTOMATIONS_TEMPS_SINGULAR              : "Automation template",
    AUTOMATIONS_TEMPS_VIEW_TITLE            : "View automation template",
    AUTOMATIONS_TEMPS_CREATE_TITLE          : "Create automation template",
    AUTOMATIONS_TEMPS_EDIT_TITLE            : "Edit automation template",
    AUTOMATIONS_TEMPS_COPY_TITLE            : "Copy automation template",
    AUTOMATIONS_TEMPS_ACTIVATE_TITLE        : "Activate automation template",
    AUTOMATIONS_TEMPS_ACTIVATE_TEXT         : "Do you really want to activate the automation template <b>{0}</b>?",
    AUTOMATIONS_TEMPS_PAUSE_TITLE           : "Pause automation template",
    AUTOMATIONS_TEMPS_PAUSE_TEXT            : "Do you really want to pause the automation template <b>{0}</b>?",
    AUTOMATIONS_TEMPS_DELETE_TITLE          : "Delete automation template",
    AUTOMATIONS_TEMPS_DELETE_TEXT           : "Do you really want to delete the automation template <b>{0}</b>?",
    AUTOMATIONS_TEMPS_PUBLISH_NO_CHANGES    : "There are no actions with changes to publish in the automation template <b>{1}</b>.",
    AUTOMATIONS_TEMPS_PUBLISH_SINGULAR      : "Do you really want to publish the action with changes in the automation template <b>{1}</b>?",
    AUTOMATIONS_TEMPS_PUBLISH_PLURAL        : "Do you really want to publish the <b>{0} actions</b> with changes in the automation template <b>{1}</b>?",
    AUTOMATIONS_TEMPS_CLEAR_CHANGES         : "Do you really want to clear the changes to automation template <b>{0}</b>?",
    AUTOMATIONS_TEMPS_ERRORS_TEXT           : "There are errors in the automation template.",
    AUTOMATIONS_TEMPS_ERRORS_ACTIVATE       : "You must fix them to activate the automation template.",
    AUTOMATIONS_TEMPS_ERRORS_PUBLISH        : "You must fix them to publish the automation template.",
    AUTOMATIONS_TEMPS_ERRORS_PANEL          : "You can see them in the side panel.",
    AUTOMATIONS_TEMPS_NONE_AVAILABLE        : "There are no automation templates available.",
    AUTOMATIONS_TEMPS_ERROR_NAME            : "You must indicate the name of the automation template.",
    AUTOMATIONS_TEMPS_ERROR_EXISTS          : "The indicated automation template does not exist.",
    AUTOMATIONS_TEMPS_ERROR_EDIT            : "You do not have permission to edit the indicated automation template.",
    AUTOMATIONS_TEMPS_ERROR_DELETE          : "You do not have permission to delete the indicated automation template.",
    AUTOMATIONS_TEMPS_ERROR_PUBLISH         : "You cannot publish the automation template because it has errors.",
    AUTOMATIONS_TEMPS_SUCCESS_CREATE        : "The automation template was successfully created.",
    AUTOMATIONS_TEMPS_SUCCESS_COPY          : "The automation template was successfully copied.",
    AUTOMATIONS_TEMPS_SUCCESS_EDIT          : "The automation template was successfully edited.",
    AUTOMATIONS_TEMPS_SUCCESS_ACTIVATE      : "The automation template was successfully activated.",
    AUTOMATIONS_TEMPS_SUCCESS_PUBLISH       : "The automation template changes were successfully published.",
    AUTOMATIONS_TEMPS_SUCCESS_CLEAR         : "The automation template changes were successfully cleared.",
    AUTOMATIONS_TEMPS_SUCCESS_DELETE        : "The automation template was successfully deleted.",

    // Automations: Actions
    AUTOMATIONS_ACTIONS_CREATE_TRIGGER      : "Create trigger",
    AUTOMATIONS_ACTIONS_CREATE_ACTION       : "Create action",
    AUTOMATIONS_ACTIONS_CREATE_TEXT         : "Select the type of action or event desired:",
    AUTOMATIONS_ACTIONS_DELETE_TITLE        : "Delete action",
    AUTOMATIONS_ACTIONS_DELETE_TEXT         : "Do you really want to delete the selected action?",
    AUTOMATIONS_ACTIONS_WARNING_TITLE       : "Unsaved changes",
    AUTOMATIONS_ACTIONS_WARNING_TEXT        : "There are unsaved changes to the node options. Do you want to discard them or continue editing and save them?",
    AUTOMATIONS_ACTIONS_TRIGGER_TITLE       : "Trigger",
    AUTOMATIONS_ACTIONS_TRIGGER_TEXT        : "An event that starts the automation",
    AUTOMATIONS_ACTIONS_ACTION_TEXT         : "An action performed after the automation has started",
    AUTOMATIONS_ACTIONS_ACCOUNT_EDIT        : "Edit account",
    AUTOMATIONS_ACTIONS_ACCOUNT_EDIT_DESC   : "Edit and/or obtain an account based on the indicated values.",
    AUTOMATIONS_ACTIONS_ACCOUNT_UPD         : "Account updated",
    AUTOMATIONS_ACTIONS_ACCOUNT_UPD_DESC    : "Starts an automation when an account is created, edited or deleted.",
    AUTOMATIONS_ACTIONS_CONTACT_DATE        : "Contact date",
    AUTOMATIONS_ACTIONS_CONTACT_DATE_DESC   : "Starts an automation before or after a certain amount of time of a date in a contact field.",
    AUTOMATIONS_ACTIONS_CONTACT_EDIT        : "Edit contact",
    AUTOMATIONS_ACTIONS_CONTACT_EDIT_DESC   : "Edit and/or obtain a contact based on the indicated values.",
    AUTOMATIONS_ACTIONS_CONTACT_UPD         : "Contact updated",
    AUTOMATIONS_ACTIONS_CONTACT_UPD_DESC    : "Starts an automation when a contact is created, edited or deleted.",
    AUTOMATIONS_ACTIONS_CONVERSATION_R      : "Conversation resolved",
    AUTOMATIONS_ACTIONS_CONVERSATION_RD     : "Starts an automation after a certain amount after a conversation is resolved.",
    AUTOMATIONS_ACTIONS_CONVERSATION_C      : "Create conversation",
    AUTOMATIONS_ACTIONS_CONVERSATION_CD     : "Creates a conversation using the given contact, message and file.",
    AUTOMATIONS_ACTIONS_FILTER              : "Filter",
    AUTOMATIONS_ACTIONS_FILTER_DESC         : "Filter the action based on the data obtained.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_DATE    : "Hospitality date",
    AUTOMATIONS_ACTIONS_HOSPITALITY_DATE_D  : "Starts an automation before or after a certain amount of time of a booking, check-in o check-out date.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_EDIT    : "Edit hospitality",
    AUTOMATIONS_ACTIONS_HOSPITALITY_EDIT_D  : "Edit and/or obtain a hospitality based on the indicated values.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_MOVE    : "Hospitality moved",
    AUTOMATIONS_ACTIONS_HOSPITALITY_MOVE_D  : "Starts an automation when a hospitality room is changed.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_UPD     : "Hospitality updated",
    AUTOMATIONS_ACTIONS_HOSPITALITY_UPD_D   : "Starts an automation when a hospitality is created, edited or deleted.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_SMDR    : "Hotel request",
    AUTOMATIONS_ACTIONS_HOSPITALITY_SMDR_D  : "Hotel guest charge details are requested.",
    AUTOMATIONS_ACTIONS_PRODUCT_EDIT        : "Edit a store product",
    AUTOMATIONS_ACTIONS_PRODUCT_EDIT_DESC   : "Edit and/or obtain a store product based on the indicated values.",
    AUTOMATIONS_ACTIONS_SEND_EMAIL          : "Send Email",
    AUTOMATIONS_ACTIONS_SEND_EMAIL_DESC     : "Send a message by Email to a contact or an email.",
    AUTOMATIONS_ACTIONS_SEND_SMS            : "Send SMS",
    AUTOMATIONS_ACTIONS_SEND_SMS_DESC       : "Send a message by SMS to a contact or a cellphone.",
    AUTOMATIONS_ACTIONS_SEND_WHATSAPP       : "Send WhatsApp",
    AUTOMATIONS_ACTIONS_SEND_WHATSAPP_DESC  : "Send a message via WhatsApp to a contact or a cellphone.",
    AUTOMATIONS_ACTIONS_WITH_ERRORS         : "Actions with errors",
    AUTOMATIONS_ACTIONS_NOT_ALLOWED         : "Action disabled for not having enough permissions.",
    AUTOMATIONS_ACTIONS_NAME                : "Action name",
    AUTOMATIONS_ACTIONS_IS_DISABLED         : "Is disabled",
    AUTOMATIONS_ACTIONS_ACTION              : "Action performed",
    AUTOMATIONS_ACTIONS_EVENTS              : "Listened events",
    AUTOMATIONS_ACTIONS_EVENTS_TIP          : "Indicate the events that will execute the automation.",
    AUTOMATIONS_ACTIONS_FIELD_TITLE         : "Field {0}",
    AUTOMATIONS_ACTIONS_SKIP_IF_SENT        : "Skip if sent",
    AUTOMATIONS_ACTIONS_SEND_TO_CONTACT     : "Send to the contact",
    AUTOMATIONS_ACTIONS_SEND_TO_PHONE       : "Send to the phone",
    AUTOMATIONS_ACTIONS_SEND_TO_EMAIL       : "Send to the email",
    AUTOMATIONS_ACTIONS_SEND_ISO_CODE       : "Language ISO code",
    AUTOMATIONS_ACTIONS_ERROR_EXISTS        : "The indicated action does not exist.",
    AUTOMATIONS_ACTIONS_ERROR_TRIGGER       : "You must add a trigger.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION        : "You must add at least one action.",
    AUTOMATIONS_ACTIONS_ERROR_TYPE          : "The action type is invalid.",
    AUTOMATIONS_ACTIONS_ERROR_PERMISSION    : "You do not have permission to add this action.",
    AUTOMATIONS_ACTIONS_ERROR_UNIQUE        : "There must only be one action of this type.",
    AUTOMATIONS_ACTIONS_ERROR_CONTACT       : "The action requires a previous action with a contact.",
    AUTOMATIONS_ACTIONS_ERROR_SEND_PHONE    : "A phone is required to send the message.",
    AUTOMATIONS_ACTIONS_ERROR_SEND_EMAIL    : "An email is required to send the message.",
    AUTOMATIONS_ACTIONS_ERROR_COPY_TRIGGER  : "Cannot copy a trigger.",
    AUTOMATIONS_ACTIONS_ERROR_MOVE_TRIGGER  : "Cannot move a trigger.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_NONE   : "You must indicate at least one field.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_EMPTY  : "There can't be empty fields.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_EXIST  : "The indicated field does not exist.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_VALUE  : "You must indicate the field value.",
    AUTOMATIONS_ACTIONS_ERROR_EVENTS_NONE   : "You must indicate at least one event.",
    AUTOMATIONS_ACTIONS_ERROR_EVENTS_INV    : "The type of on of the events is invalid.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION_EMPTY  : "You must indicate an action to perform.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION_INV    : "The indicated action is invalid.",
    AUTOMATIONS_ACTIONS_ERROR_CHANNEL       : "You must indicate the channel.",
    AUTOMATIONS_ACTIONS_ERROR_WABA_TEMP     : "You must indicate the WhatsApp template.",
    AUTOMATIONS_ACTIONS_ERROR_SUBJECT       : "You must indicate the subject.",
    AUTOMATIONS_ACTIONS_ERROR_MESSAGE       : "You must indicate the message.",
    AUTOMATIONS_ACTIONS_SUCCESS_COPY        : "The action was successfully copied.",
    AUTOMATIONS_ACTIONS_SUCCESS_EDIT        : "The action was successfully edited.",
    AUTOMATIONS_ACTIONS_SUCCESS_MOVE        : "The action was successfully moved.",
    AUTOMATIONS_ACTIONS_SUCCESS_DELETE      : "The action was successfully deleted.",

    // Automations: Executions
    AUTOMATIONS_EXECS_NAME                  : "Executions",
    AUTOMATIONS_EXECS_DATE                  : "Executed at",
    AUTOMATIONS_EXECS_NONE_AVAILABLE        : "There are no automation executions available.",
    AUTOMATIONS_EXECS_UNIMPLEMENTED         : "Action not implemented.",
    AUTOMATIONS_EXECS_MISSING_DATA          : "No data was received.",
    AUTOMATIONS_EXECS_FETCH                 : "There was an error doing the fetch.",
    AUTOMATIONS_EXECS_NO_MATCH              : "There were no matches in the filter.",
    AUTOMATIONS_EXECS_CONVERSATION_ID       : "The conversation ID is not available.",
    AUTOMATIONS_EXECS_CONVERSATION_FIND     : "The conversation was not found.",
    AUTOMATIONS_EXECS_CONVERSATION_CREATE   : "The conversation could not be created.",
    AUTOMATIONS_EXECS_CONTACT_ID            : "The contact ID is not available.",
    AUTOMATIONS_EXECS_CONTACT_FIND          : "The contact was not found.",
    AUTOMATIONS_EXECS_CONTACT_EDIT          : "The contact could not be edited.",
    AUTOMATIONS_EXECS_HOSPITALITY_ID        : "The hospitality ID is not available.",
    AUTOMATIONS_EXECS_HOSPITALITY_FIND      : "The hospitality was not found.",
    AUTOMATIONS_EXECS_HOSPITALITY_EDIT      : "The hospitality could not be edited.",
    AUTOMATIONS_EXECS_SALE_ID               : "The sale ID is not available.",
    AUTOMATIONS_EXECS_SALE_FIND             : "The sale was not found.",
    AUTOMATIONS_EXECS_ACCOUNT_ID            : "The account ID is not available.",
    AUTOMATIONS_EXECS_ACCOUNT_FIND          : "The account was not found.",
    AUTOMATIONS_EXECS_ACCOUNT_EDIT          : "The account could not be edited.",
    AUTOMATIONS_EXECS_PRODUCT_EDIT          : "The store product could not be edited.",
    AUTOMATIONS_EXECS_HOTEL_FIND            : "The hotel was not found.",
    AUTOMATIONS_EXECS_SEND_CONTACT          : "You don't have the contact to send to.",
    AUTOMATIONS_EXECS_SEND_WABA_TEMPLATE    : "WhatsApp template does not exist.",
    AUTOMATIONS_EXECS_SEND_MESSAGE          : "The message to send does not exist.",
    AUTOMATIONS_EXECS_SEND_ERROR            : "The message could not be sent.",



    // Applications
    APPLICATIONS_TITLE                      : "Marketplace",
    APPLICATIONS_NAME                       : "Applications",
    APPLICATIONS_SINGULAR                   : "Application",
    APPLICATIONS_ADD_TITLE                  : "Add application",
    APPLICATIONS_EDIT_TITLE                 : "Edit application",
    APPLICATIONS_DELETE_TITLE               : "Delete application",
    APPLICATIONS_DELETE_TEXT                : "Do you really want to delete the application <b>{0}</b>?",
    APPLICATIONS_PROVIDER_TEXT              : "Which application would you like to add?",
    APPLICATIONS_SELECT_TEXT                : "Click on a provider to add it as a application.",
    APPLICATIONS_CONFIG_TITLE               : "Configure",
    APPLICATIONS_CONFIG_TEXT                : "Configure the selected application",
    APPLICATIONS_NONE_AVAILABLE             : "There are no applications available.",
    APPLICATIONS_ERROR_EDIT                 : "You do not have permission to edit applications.",
    APPLICATIONS_ERROR_ADD                  : "You do not have permission to add a application of the given type.",
    APPLICATIONS_ERROR_EMPTY                : "You must indicate the application to use.",
    APPLICATIONS_ERROR_EXISTS               : "The indicated application does not exist.",
    APPLICATIONS_ERROR_TYPE                 : "The indicated application type does not exist.",
    APPLICATIONS_ERROR_NAME                 : "You must indicate the name of the application.",
    APPLICATIONS_ERROR_ACTIVE               : "The application is inactive.",
    APPLICATIONS_ERROR_USED_FLOW            : "You can't delete a application used in a flow.",
    APPLICATIONS_ERROR_USED_AUTOMATION      : "You can't delete a application used in a automation.",
    APPLICATIONS_SUCCESS_CREATE             : "The application was successfully created.",
    APPLICATIONS_SUCCESS_EDIT               : "The application was successfully edited.",
    APPLICATIONS_SUCCESS_DELETE             : "The application was successfully deleted.",

    // Applications: Fetch
    APPLICATIONS_FETCH_WEBHOOK_ACTION       : "Webhook",
    APPLICATIONS_FETCH_WEBHOOK_ACTION_DESC  : "Receive data from a url to start the automation.",
    APPLICATIONS_FETCH_WEBHOOK_URL          : "Your webhook url",
    APPLICATIONS_FETCH_WEBHOOK_URL_TIP      : "You can send data in JSON format by performing a POST to the url.",
    APPLICATIONS_FETCH_FIELDS               : "Response fields",
    APPLICATIONS_FETCH_FIELDS_TIP           : "Indicate each of the fields to use from the received JSON. You can use '.' to indicate a field in an internal object.",

    APPLICATIONS_FETCH_ACTION               : "HTTP call",
    APPLICATIONS_FETCH_ACTION_DESC          : "Request or publish data to the indicated url.",
    APPLICATIONS_FETCH_URL                  : "Your url",
    APPLICATIONS_FETCH_URL_TIP              : "The url to request or send data.",
    APPLICATIONS_FETCH_METHOD               : "Used method",
    APPLICATIONS_FETCH_HAS_HEADERS          : "Has header",
    APPLICATIONS_FETCH_USER_PASS            : "User and password",
    APPLICATIONS_FETCH_USER_PASS_TIP        : "User and password separated by | for basic authentication.",
    APPLICATIONS_FETCH_HEADER_TITLE         : "Header {0}",
    APPLICATIONS_FETCH_ADD_HEADER           : "Add header",
    APPLICATIONS_FETCH_PARAM_TITLE          : "Parameter {0}",
    APPLICATIONS_FETCH_ADD_PARAM            : "Add parameter",
    APPLICATIONS_FETCH_RESPONSE_TITLE       : "Response field {0}",
    APPLICATIONS_FETCH_RESPONSE_TIP         : "Add each used response field and were to save it. You can use '.' to indicate a field in an internal object.",
    APPLICATIONS_FETCH_ADD_RESPONSE         : "Add response field",
    APPLICATIONS_FETCH_LIST_KEY             : "Key for the list",
    APPLICATIONS_FETCH_LIST_VALUE           : "Value for the list",

    APPLICATIONS_FETCH_ERROR_URL_EMPTY      : "You must indicate a url.",
    APPLICATIONS_FETCH_ERROR_URL_INVALID    : "The url must start at https://",
    APPLICATIONS_FETCH_ERROR_METHOD_EMPTY   : "You must indicate the method to use.",
    APPLICATIONS_FETCH_ERROR_METHOD_INV     : "The indicated method is invalid.",
    APPLICATIONS_FETCH_ERROR_USER_PASS      : "The username and password must be separated by a |",
    APPLICATIONS_FETCH_ERROR_HEADER_KEY     : "You must complete the header key.",
    APPLICATIONS_FETCH_ERROR_HEADER_VALUE   : "You must complete the header value.",
    APPLICATIONS_FETCH_ERROR_KEY_EMPTY      : "You must indicate the field key.",
    APPLICATIONS_FETCH_ERROR_KEY_INVALID    : "The key can only have letters, numbers, periods, or hyphens.",
    APPLICATIONS_FETCH_ERROR_KEYS_INVALID   : "Each key can only have letters, numbers, periods, or hyphens.",
    APPLICATIONS_FETCH_ERROR_KEYS_REPEAT    : "The keys can not be repeated.",
    APPLICATIONS_FETCH_ERROR_VALUE_EMPTY    : "You must indicate the field value.",

    // Applications: Nubila
    APPLICATIONS_NUBILA_NAME                : "Nubila UCX - Cloud phone",
    APPLICATIONS_NUBILA_DESC                : "The application allows you to integrate some of the Nubila APIs as an action within the automations. You just need to add the API key and Tenant.",
    APPLICATIONS_NUBILA_API_KEY             : "API key",
    APPLICATIONS_NUBILA_SERVER              : "Server ID",
    APPLICATIONS_NUBILA_ACTION              : "Nubila",
    APPLICATIONS_NUBILA_ACTION_DESC         : "Execute some of the Nubila APIs.",
    APPLICATIONS_NUBILA_API                 : "Nubila API",
    APPLICATIONS_NUBILA_MINUTES             : "Minutes to fetch",
    APPLICATIONS_NUBILA_DELAY               : "Delay in minutes",
    APPLICATIONS_NUBILA_DELAY_HELP          : "Instead of getting data in real time, it will get data from X minutes ago.",
    APPLICATIONS_NUBILA_ERROR_API_KEY       : "You must indicate the API key",
    APPLICATIONS_NUBILA_ERROR_SERVER        : "You must indicate the ID of the server.",
    APPLICATIONS_NUBILA_ERROR_API           : "The indicate Nubila API is invalid.",
    APPLICATIONS_NUBILA_ERROR_RESULT        : "The Nubila API returned an error.",
    APPLICATIONS_NUBILA_ERROR_MINUTES       : "You must indicate the minutes to fetch.",
    APPLICATIONS_NUBILA_ERROR_DELAY         : "You must indicate the delay in minutes.",

    // Applications: Omnitec
    APPLICATIONS_OMNITEC_NAME               : "Omnitec Systems - High-Tech devices for Hotels",
    APPLICATIONS_OMNITEC_DESC               : "The application allows you to receive information about the key PINs to save and inform the guest. You only need to add the hotel ID.",
    APPLICATIONS_OMNITEC_CLIENT             : "Client ID",
    APPLICATIONS_OMNITEC_ACTION             : "Omnitec",
    APPLICATIONS_OMNITEC_ACTION_DESC        : "Receive key data from Omnitec to save or send.",
    APPLICATIONS_OMNITEC_KEY_TYPE           : "Key type",
    APPLICATIONS_OMNITEC_ERROR_HOTEL        : "You must indicate the Hotel ID.",
    APPLICATIONS_OMNITEC_ERROR_KEY_TYPE     : "You must indicate a valid key type.",

    // Applications: Forze
    APPLICATIONS_FORZE_NAME                 : "Forze - Electronic Lock",
    APPLICATIONS_FORZE_DESC                 : "The application allows you to generate a PIN for an electronic key that is saved in the guest's information. Requires entering the username and password.",
    APPLICATIONS_FORZE_ACTION               : "Forze",
    APPLICATIONS_FORZE_ACTION_DESC          : "Execute some of the Forze APIs.",
    APPLICATIONS_FORZE_API                  : "Forze API",
    APPLICATIONS_FORZE_ERROR_API            : "You must indicate a valid Forze API.",
    APPLICATIONS_FORZE_ERROR_RESULT         : "The Forze API returned an error.",

    // Application: Google Sheets
    APPLICATIONS_GOOGLE_SHEETS_NAME         : "Google Sheets",
    APPLICATIONS_GOOGLE_SHEETS_DESC         : "The application allows to add rows to a Google Sheet. You only need to give permission to a Google account.",
    APPLICATIONS_GOOGLE_SHEETS_ACTION       : "Embed to Google Sheets",
    APPLICATIONS_GOOGLE_SHEETS_ACTION_DESC  : "Insert a row into a Google Sheets sheet.",
    APPLICATIONS_GOOGLE_SHEETS_ID           : "Spreadsheet ID",
    APPLICATIONS_GOOGLE_SHEETS_ID_TIP       : "Can be obtained from the url between '/d/' and '/edit'.",
    APPLICATIONS_GOOGLE_SHEETS_SHEET        : "Sheet name",
    APPLICATIONS_GOOGLE_SHEETS_SHEET_TIP    : "You can leave it blank to insert on the first sheet.",
    APPLICATIONS_GOOGLE_SHEETS_VALUES       : "Values",
    APPLICATIONS_GOOGLE_SHEETS_ADD_VALUE    : "Add value",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_TOKEN  : "You must give permission to a Google account.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_ID     : "You must indicate the ID of the Spreadsheet.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_VALUES : "You must enter at least one value.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_RESULT : "Could not connect to Google Sheets.",

    // Applications: Gestion Bos
    APPLICATIONS_GESTION_BOS_NAME           : "Gestión Bos - Online Management System",
    APPLICATIONS_GESTION_BOS_DESC           : "The application uses the APIs of Gestión Bos. It requires entering the url and the request key.",
    APPLICATIONS_GESTION_BOS_BASE_URL       : "Base URL",
    APPLICATIONS_GESTION_BOS_REQUEST_KEY    : "Request Key",
    APPLICATIONS_GESTION_BOS_CLIENT_ID      : "Client ID",
    APPLICATIONS_GESTION_BOS_ACTION         : "Gestión Bos",
    APPLICATIONS_GESTION_BOS_ACTION_DESC    : "Execute any of the Gestión Bos APIs",
    APPLICATIONS_GESTION_BOS_API            : "Gestión Bos API",
    APPLICATIONS_GESTION_BOS_CONTACT        : "Contact code",
    APPLICATIONS_GESTION_BOS_ERROR_BASE_URL : "You must indicate the base url.",
    APPLICATIONS_GESTION_BOS_ERROR_REQUEST  : "You must indicate the request key.",
    APPLICATIONS_GESTION_BOS_ERROR_CLIENT   : "You must indicate the id of the client.",
    APPLICATIONS_GESTION_BOS_ERROR_API      : "You must indicate a valid Gestión Bos API.",
    APPLICATIONS_GESTION_BOS_ERROR_CONTACT  : "You must indicate the contact code.",
    APPLICATIONS_GESTION_BOS_ERROR_RESULT   : "The Gestión Bos API returned an error.",

    // Applications: Tienda Nube
    APPLICATIONS_TIENDA_NUBE_NAME           : "Tienda Nube",
    APPLICATIONS_TIENDA_NUBE_DESC           : "The application uses Tienda Nube APIs to integrate orders and customers into Conversana.",
    APPLICATIONS_TIENDA_NUBE_INSTALL        : "Install Tienda Nube",
    APPLICATIONS_TIENDA_NUBE_INSTALL_DESC   : "Click the following button and accept the requested permissions to continue.",
    APPLICATIONS_TIENDA_NUBE_INSTALL_END    : "Installation was successful! You can finish the setup.",
    APPLICATIONS_TIENDA_NUBE_ACTION_DESC    : "Executes after any state of a sale changes",
    APPLICATIONS_TIENDA_NUBE_ERROR_TOKEN    : "You must give permission to a Tienda Nube account.",



    // Support: Tickets
    TICKETS_NAME                            : "Tickets",
    TICKETS_SINGULAR                        : "Ticket",
    TICKETS_VIEW_TITLE                      : "View ticket",
    TICKETS_CREATE_TITLE                    : "Create ticket",
    TICKETS_EDIT_TITLE                      : "Edit ticket",
    TICKETS_DELETE_TITLE                    : "Delete ticket",
    TICKETS_DELETE_TEXT                     : "Do you really want to delete the ticket <b>{0}</b>?",
    TICKETS_OPEN_TITLE                      : "Open ticket",
    TICKETS_OPEN_TEXT                       : "Do you really want to open the ticket <b>{0}</b>?",
    TICKETS_OPEN_TAB                        : "Open tickets",
    TICKETS_WAITING_TITLE                   : "Move to waitlist",
    TICKETS_WAITING_TEXT                    : "Select the waitlist to which you want to move the ticket <b>{0}</b>.",
    TICKETS_WAITING_TAB                     : "Waiting tickets",
    TICKETS_WAIT                            : "Wait",
    TICKETS_CLOSE_TITLE                     : "Close ticket",
    TICKETS_CLOSE_TEXT                      : "Do you really want to close the ticket <b>{0}</b>?",
    TICKETS_CLOSE_TAB                       : "Closed tickets",
    TICKETS_CLOSE                           : "Close",
    TICKETS_ASSIGN_TITLE                    : "Assign ticket",
    TICKETS_ASSIGN                          : "Assign",
    TICKETS_MUTE_TITLE                      : "Mute ticket",
    TICKETS_MUTE                            : "Mute",
    TICKETS_UNMUTE_TITLE                    : "Notify ticket",
    TICKETS_UNMUTE                          : "Notify",
    TICKETS_ORGANIZATION                    : "Client / Partner",
    TICKETS_FOR_PARTNER                     : "For a partner",
    TICKETS_ALL_USERS                       : "All users can see it",
    TICKETS_ALL_USERS_HELP                  : "If you do not indicate that all users can see it, users with a higher role can still see it.",
    TICKETS_ASSIGNED                        : "Assigned to",
    TICKETS_LAST_REPLY                      : "Last reply",
    TICKETS_NONE_AVAILABLE                  : "There are no tickets available.",
    TICKETS_NONE_MESSAGES                   : "There are no messages available.",
    TICKETS_EDIT_MESSAGE                    : "Edit message",
    TICKETS_DELETE_MESSAGE                  : "Delete message",
    TICKETS_DELETE_MESSAGE_TEXT             : "Do you really want to delete the message <b>{0}</b>?",
    TICKETS_DELETE_IMAGE                    : "Delete image",
    TICKETS_DELETE_IMAGE_TEXT               : "Do you really want to delete the image of <b>{0}</b>?",
    TICKETS_ERROR_EDIT                      : "You do not have permission to edit the ticket.",
    TICKETS_ERROR_EXISTS                    : "The indicated ticket does not exist.",
    TICKETS_ERROR_TYPE                      : "You must indicate the type of the ticket.",
    TICKETS_ERROR_SUBJECT                   : "You must indicate the subject of the ticket.",
    TICKETS_ERROR_MESSAGE                   : "You must indicate the ticket message.",
    TICKETS_ERROR_DELETE                    : "You do not have permission to delete the ticket.",
    TICKETS_ERROR_USER_PERMISSION           : "You do not have permission to indicate a user the ticket.",
    TICKETS_ERROR_USER_EMPTY                : "You must indicate a user if not for all users.",
    TICKETS_ERROR_ASSIGN_PERMISSION         : "You do not have permission to assign the ticket.",
    TICKETS_ERROR_ASSIGN_EXISTS             : "The user you want to assign the ticket to does not exist.",
    TICKETS_ERROR_OPEN                      : "You do not have permission to open the ticket.",
    TICKETS_ERROR_WAIT                      : "You do not have permission to move the ticket to a waiting list.",
    TICKETS_ERROR_CLOSE                     : "You do not have permission to close the ticket.",
    TICKETS_ERROR_MESSAGE_EXISTS            : "The indicated message does not exist.",
    TICKETS_ERROR_MESSAGE_EDIT              : "You do not have permission to edit the indicated message.",
    TICKETS_ERROR_MESSAGE_DELETE            : "You do not have permission to delete the indicated message.",
    TICKETS_ERROR_IMAGE_DELETE              : "The indicated message does not have an image.",
    TICKETS_SUCCESS_CREATE                  : "The ticket was successfully created.",
    TICKETS_SUCCESS_EDIT                    : "The ticket was successfully edited.",
    TICKETS_SUCCESS_DELETE                  : "Ticket was successfully deleted.",
    TICKETS_SUCCESS_OPEN                    : "The ticket was successfully opened.",
    TICKETS_SUCCESS_WAIT                    : "The ticket was successfully moved to the waiting list.",
    TICKETS_SUCCESS_CLOSE                   : "The ticket was successfully closed.",
    TICKETS_SUCCESS_MUTE                    : "Ticket was successfully muted.",
    TICKETS_SUCCESS_UNMUTE                  : "Ticket was successfully un-silenced.",
    TICKETS_SUCCESS_ADD_MESSAGE             : "The message was successfully added.",
    TICKETS_SUCCESS_EDIT_MESSAGE            : "The message was successfully edited.",
    TICKETS_SUCCESS_DELETE_MESSAGE          : "The message was successfully deleted.",
    TICKETS_SUCCESS_DELETE_IMAGE            : "The image was successfully deleted.",

    // Support: Ticket Types
    TICKET_TYPES_NAME                       : "Ticket types",
    TICKET_TYPES_SINGULAR                   : "Ticket type",
    TICKET_TYPES_CREATE_TITLE               : "Create ticket type",
    TICKET_TYPES_EDIT_TITLE                 : "Edit ticket type",
    TICKET_TYPES_DELETE_TITLE               : "Delete ticket type",
    TICKET_TYPES_DELETE_TEXT                : "Do you really want to delete the ticket type <b>{0}</b>?",
    TICKET_TYPES_CREDENTIAL                 : "Automatically assign to",
    TICKET_TYPES_ASSIGNED                   : "Assigned to",
    TICKET_TYPES_NONE_AVAILABLE             : "There are no ticket types available.",
    TICKET_TYPES_ERROR_EXISTS               : "The indicated ticket type does not exist.",
    TICKET_TYPES_ERROR_NAME                 : "You must indicate the name of the ticket type.",
    TICKET_TYPES_SUCCESS_CREATE             : "The ticket type was successfully created.",
    TICKET_TYPES_SUCCESS_EDIT               : "The ticket type was successfully edited.",
    TICKET_TYPES_SUCCESS_DELETE             : "The ticket type was successfully deleted.",

    // Support: Ticket Alert Types
    TICKET_ALERT_TYPES_NAME                 : "Alert types",
    TICKET_ALERT_TYPES_SINGULAR             : "Alert type",
    TICKET_ALERT_TYPES_CREATE_TITLE         : "Create alert type",
    TICKET_ALERT_TYPES_EDIT_TITLE           : "Edit alert type",
    TICKET_ALERT_TYPES_DELETE_TITLE         : "Delete alert type",
    TICKET_ALERT_TYPES_DELETE_TEXT          : "Do you really want to delete alert type <b>{0}</b>?",
    TICKET_ALERT_TYPES_ALERT_CODE           : "Alert code",
    TICKET_ALERT_TYPES_ALERT_CODE_TIP       : "A code is generated in WhatsApp based on the alert type and can combine multiple data.",
    TICKET_ALERT_TYPES_VARIABLES_TIP        : "You can use the variables: {channel}",
    TICKET_ALERT_TYPES_NONE_AVAILABLE       : "There are no alert types available.",
    TICKET_ALERT_TYPES_ERROR_EXISTS         : "The indicated alert type does not exist.",
    TICKET_ALERT_TYPES_ERROR_CODE_EMPTY     : "You must indicate the code for the alert type.",
    TICKET_ALERT_TYPES_ERROR_CODE_EXISTS    : "Another alert type already exists using the indicated code.",
    TICKET_ALERT_TYPES_ERROR_TYPE           : "You must indicate the ticket type of the alert type.",
    TICKET_ALERT_TYPES_ERROR_SUBJECT        : "You must indicate the subject of the alert type.",
    TICKET_ALERT_TYPES_ERROR_MESSAGE        : "You must specify the message for the alert type.",
    TICKET_ALERT_TYPES_SUCCESS_CREATE       : "The alert type was successfully created.",
    TICKET_ALERT_TYPES_SUCCESS_EDIT         : "The alert type was successfully edited.",
    TICKET_ALERT_TYPES_SUCCESS_DELETE       : "The alert type was successfully deleted.",

    // Support: Ticket Waitlists
    TICKET_WAITLISTS_NAME                   : "Waitlists",
    TICKET_WAITLISTS_SINGULAR               : "Waitlist",
    TICKET_WAITLISTS_CREATE_TITLE           : "Create waitlist",
    TICKET_WAITLISTS_EDIT_TITLE             : "Edit waitlist",
    TICKET_WAITLISTS_DELETE_TITLE           : "Delete waitlist",
    TICKET_WAITLISTS_DELETE_TEXT            : "Do you really want to delete waitlist <b>{0}</b>?",
    TICKET_WAITLISTS_NONE_AVAILABLE         : "There are no waitlists available.",
    TICKET_WAITLISTS_ERROR_EXISTS           : "The specified waitlist does not exist.",
    TICKET_WAITLISTS_ERROR_NAME             : "You must enter the name of the waitlist.",
    TICKET_WAITLISTS_SUCCESS_CREATE         : "The waitlist was created successfully.",
    TICKET_WAITLISTS_SUCCESS_EDIT           : "The waitlist was edited successfully.",
    TICKET_WAITLISTS_SUCCESS_DELETE         : "The waitlist was deleted successfully.",

    // Support: Document
    DOCUMENTS_NAME                          : "Documentation",
    DOCUMENTS_SINGULAR                      : "Document",
    DOCUMENTS_CREATE_TITLE                  : "Create document",
    DOCUMENTS_EDIT_TITLE                    : "Edit document",
    DOCUMENTS_PRINT_TITLE                   : "Print document",
    DOCUMENTS_DELETE_TITLE                  : "Delete document",
    DOCUMENTS_DELETE_TEXT                   : "Do you really want to delete the document <b>{0}</b>?",
    DOCUMENTS_WARNING_TITLE                 : "Unsaved changes",
    DOCUMENTS_WARNING_TEXT                  : "There are unsaved changes to the document. Do you want to discard them or continue editing and save them?",
    DOCUMENTS_SELECT_ONE                    : "Select a document from the list to be able to get the information you need.",
    DOCUMENTS_HELP                          : "Module help",
    DOCUMENTS_URL                           : "URL of the module",
    DOCUMENTS_TYPE_MAIN                     : "Main",
    DOCUMENTS_TYPE_PARTNER                  : "Partner",
    DOCUMENTS_TYPE_ADMINS                   : "Administrative",
    DOCUMENTS_TYPE_RELEASES                 : "Releases",
    DOCUMENTS_NONE_AVAILABLE                : "There are no documents available.",
    DOCUMENTS_ERROR_EXISTS                  : "The indicated document does not exist.",
    DOCUMENTS_ERROR_TYPE                    : "The indicated document type does not exist.",
    DOCUMENTS_ERROR_NAME_EMPTY              : "You must indicate the name of the document.",
    DOCUMENTS_ERROR_NAME_REPEATED           : "You cannot repeat document names within the given parent.",
    DOCUMENTS_ERROR_URL                     : "The url must not be relative and not include the domain.",
    DOCUMENTS_ERROR_PARENT_EXISTS           : "The indicated parent does not exist.",
    DOCUMENTS_ERROR_PARENT_SELF             : "You cannot select the same document as the parent.",
    DOCUMENTS_ERROR_PARENT_CYCLE            : "You cannot create cycles with documents.",
    DOCUMENTS_SUCCESS_CREATE                : "The document was successfully created.",
    DOCUMENTS_SUCCESS_EDIT                  : "The document was successfully edited.",
    DOCUMENTS_SUCCESS_DELETE                : "The document was successfully deleted.",



    // Setup: Announcements
    ANNOUNCEMENTS_NAME                      : "Announcements",
    ANNOUNCEMENTS_CREATE_TITLE              : "Create announcement",
    ANNOUNCEMENTS_EDIT_TITLE                : "Edit announcement",
    ANNOUNCEMENTS_DELETE_TITLE              : "Delete announcement",
    ANNOUNCEMENTS_DELETE_TEXT               : "Do you really want to delete the announcement <b>{0}</b>?",
    ANNOUNCEMENTS_AUTO_CLOSE                : "Hide automatically (minutes)",
    ANNOUNCEMENTS_AUTO_CLOSE_TIP            : "If you indicate 0, the announcement will not be hidden automatically.",
    ANNOUNCEMENTS_IS_ACTIVE                 : "Is active",
    ANNOUNCEMENTS_RESET_DISCARDED           : "Show to all again",
    ANNOUNCEMENTS_NONE_AVAILABLE            : "There are no announcements available.",
    ANNOUNCEMENTS_ERROR_EDIT                : "You cannot edit the indicated announcement.",
    ANNOUNCEMENTS_ERROR_EXISTS              : "The indicated announcement does not exist.",
    ANNOUNCEMENTS_ERROR_MESSAGE             : "You must indicate the message of the announcement.",
    ANNOUNCEMENTS_ERROR_AUTO_CLOSE          : "The time to hide must be a number greater or equal to 0.",
    ANNOUNCEMENTS_SUCCESS_CREATE            : "The announcement was successfully created.",
    ANNOUNCEMENTS_SUCCESS_EDIT              : "The announcement was successfully edited.",
    ANNOUNCEMENTS_SUCCESS_DELETE            : "The announcement was successfully deleted.",

    // Setup: Plans
    PLANS_NAME                              : "Plans",
    PLANS_SINGULAR                          : "Plan",
    PLANS_CREATE_TITLE                      : "Create plan",
    PLANS_EDIT_TITLE                        : "Edit plan",
    PLANS_DELETE_TITLE                      : "Delete plan",
    PLANS_DELETE_TEXT                       : "Do you really want to delete the plan <b>{0}</b>?",
    PLANS_ROOM_AMOUNT                       : "Amount of rooms",
    PLANS_CONTACT_AMOUNT                    : "Amount of contacts",
    PLANS_PRICE                             : "Plan price",
    PLANS_ROOM_PRICE                        : "Price per room",
    PLANS_ROOM_PRICE_SHORT                  : "Per room",
    PLANS_ROOM_PRICE_TOTAL                  : "Total price for rooms",
    PLANS_ROOM_PRICE_TEXT                   : "{0} / room",
    PLANS_CONTACT_PRICE                     : "Price per additional contact",
    PLANS_CONTACT_PRICE_SHORT               : "Additional",
    PLANS_CONTACT_PRICE_LONG                : "Additional contacts",
    PLANS_CONTACT_PRICE_TEXT                : "{0} ({1} / add.)",
    PLANS_SETUP_PRICE                       : "Setup price",
    PLANS_NONE_AVAILABLE                    : "There are no plans available.",
    PLANS_ERROR_EXISTS                      : "The indicated plan does not exist.",
    PLANS_ERROR_TYPE_EMPTY                  : "You must indicate the type of plan.",
    PLANS_ERROR_TYPE_INVALID                : "The indicated plan type is incorrect.",
    PLANS_ERROR_NAME                        : "You must indicate the name of the plan.",
    PLANS_ERROR_ROOM_AMOUNT                 : "The number of rooms must be a number greater than 0.",
    PLANS_ERROR_CONTACT_AMOUNT_EMPTY        : "You must indicate the number of contacts.",
    PLANS_ERROR_CONTACT_AMOUNT_INVALID      : "The amount of contacts must be a number greater than 0.",
    PLANS_ERROR_ROOM_PRICE                  : "The price per room is incorrect.",
    PLANS_ERROR_CONTACT_PRICE               : "The price per additional contact is incorrect.",
    PLANS_ERROR_SETUP_PRICE                 : "The setup price is incorrect.",
    PLANS_ERROR_DELETE_LAST                 : "You can't delete the last plan.",
    PLANS_SUCCESS_CREATE                    : "The plan was successfully created.",
    PLANS_SUCCESS_EDIT                      : "The plan was successfully edited.",
    PLANS_SUCCESS_DELETE                    : "The plan was successfully deleted.",

    // Setup: Addons
    ADDONS_NAME                             : "Addons",
    ADDONS_SINGULAR                         : "Addon",
    ADDONS_EDIT_TITLE                       : "Edit addon",
    ADDONS_QUANTITY                         : "By quantity",
    ADDONS_NONE_AVAILABLE                   : "There are no addons available.",
    ADDONS_ERROR_EXISTS                     : "The indicated addon does not exist.",
    ADDONS_ERROR_NAME                       : "You must indicate the name of the addon.",
    ADDONS_SUCCESS_CREATE                   : "The addon was successfully created.",
    ADDONS_SUCCESS_EDIT                     : "The addon was successfully edited.",
    ADDONS_SUCCESS_DELETE                   : "The addon was successfully deleted.",

    // Setup: Admins
    ADMINS_NAME                             : "Admins",
    ADMINS_CREATE_TITLE                     : "Create admin",
    ADMINS_EDIT_TITLE                       : "Edit admin",
    ADMINS_DELETE_TITLE                     : "Delete admin",
    ADMINS_DELETE_TEXT                      : "Do you really want to delete the admin <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE                   : "There are no admins available.",
    ADMINS_ERROR_EDIT                       : "You cannot edit the indicated admin.",
    ADMINS_ERROR_EXISTS                     : "The indicated admin does not exist.",
    ADMINS_ERROR_DELETE                     : "You cannot delete the indicated admin.",
    ADMINS_SUCCESS_CREATE                   : "The admin was successfully created.",
    ADMINS_SUCCESS_EDIT                     : "The admin was successfully edited.",
    ADMINS_SUCCESS_DELETE                   : "The admin was successfully deleted.",

    // Setup: Languages
    LANGUAGES_NAME                          : "Languages",
    LANGUAGES_SINGULAR                      : "Language",
    LANGUAGES_CREATE_TITLE                  : "Create language",
    LANGUAGES_EDIT_TITLE                    : "Edit language",
    LANGUAGES_DELETE_TITLE                  : "Delete language",
    LANGUAGES_DELETE_TEXT                   : "Do you really want to delete the language <b>{0}</b>?",
    LANGUAGES_ENGLISH_NAME                  : "English name",
    LANGUAGES_ENGLISH_NAME_TIP              : "Used in the transcription of the audios.",
    LANGUAGES_WORDS                         : "Words or frases",
    LANGUAGES_CREATE_BY_DEFAULT             : "Create language when creating a client",
    LANGUAGES_CREATE_BY_DEFAULT_SHORT       : "Create with client",
    LANGUAGES_NONE_AVAILABLE                : "There are no languages available.",
    LANGUAGES_ERROR_EDIT                    : "You do not have permission to edit the indicated language.",
    LANGUAGES_ERROR_EXISTS                  : "The indicated language does not exist.",
    LANGUAGES_ERROR_NAME_EMPTY              : "You must indicate the name of the language.",
    LANGUAGES_ERROR_NAME_EXISTS             : "Another language already exists with the indicated name.",
    LANGUAGES_ERROR_ENGLISH_NAME_EMPTY      : "You must indicate the english name of the language.",
    LANGUAGES_ERROR_ENGLISH_NAME_EXISTS     : "Another language already exists with the indicated english name.",
    LANGUAGES_ERROR_ISO_CODE_EMPTY          : "You must indicate the ISO code of the language.",
    LANGUAGES_ERROR_ISO_CODE_EXISTS         : "Another language already exists with the indicated ISO code.",
    LANGUAGES_ERROR_DELETE                  : "You do not have permission to delete the indicated language.",
    LANGUAGES_ERROR_LAST                    : "You cannot delete last language.",
    LANGUAGES_ERROR_FLOW                    : "You cannot delete the language when it is used in a flow.",
    LANGUAGES_ERROR_AUTOMATION              : "You cannot delete the language when it is used in an integration.",
    LANGUAGES_SUCCESS_CREATE                : "The language was successfully created.",
    LANGUAGES_SUCCESS_EDIT                  : "The language was successfully edited.",
    LANGUAGES_SUCCESS_DELETE                : "The language was successfully deleted.",

    // Setup: Countries
    COUNTRIES_NAME                          : "Countries",
    COUNTRIES_SINGULAR                      : "Country",
    COUNTRIES_EDIT_TITLE                    : "Edit country",
    COUNTRIES_IDD                           : "Cellphone characteristic",
    COUNTRIES_IDD_SHORT                     : "Characteristic",
    COUNTRIES_NONE_AVAILABLE                : "There are no countries available.",
    COUNTRIES_ERROR_EXISTS                  : "The indicated country does not exist.",
    COUNTRIES_ERROR_NAME                    : "You must indicate the name of the country.",
    COUNTRIES_ERROR_ISO_CODE_EMPTY          : "You must indicate the country's ISO code.",
    COUNTRIES_ERROR_ISO_CODE_EXISTS         : "Another country already exists with the indicated ISO code.",
    COUNTRIES_ERROR_IDD_EMPTY               : "You must indicate the characteristic of the country.",
    COUNTRIES_ERROR_IDD_NUMBER              : "The characteristic must be a number greater than 0.",
    COUNTRIES_ERROR_IDD_EXISTS              : "Another country already exists with the indicated characteristic.",
    COUNTRIES_SUCCESS_EDIT                  : "The country was successfully edited.",

    // Setup: Provinces
    PROVINCES_NAME                          : "Provinces",
    PROVINCES_SINGULAR                      : "Province",
    PROVINCES_EDIT_TITLE                    : "Edit province",
    PROVINCES_NONE_AVAILABLE                : "There are no provinces available.",
    PROVINCES_ERROR_EXISTS                  : "The indicated province does not exist.",
    PROVINCES_ERROR_COUNTRY                 : "You must indicate the country of the province.",
    PROVINCES_ERROR_NAME                    : "You must indicate the name of the province.",
    PROVINCES_ERROR_ISO_CODE_EMPTY          : "You must indicate the ISO code of the province.",
    PROVINCES_ERROR_ISO_CODE_EXISTS         : "Another province already exists with the specified ISO code.",
    PROVINCES_SUCCESS_EDIT                  : "The province was successfully edited.",

    // Setup: Media
    MEDIA_NAME                              : "Files",
    MEDIA_SINGULAR                          : "File",
    MEDIA_SELECT_TITLE                      : "Select file",
    MEDIA_CREATE_TITLE                      : "Create directory",
    MEDIA_EDIT_DIR_TITLE                    : "Rename directory",
    MEDIA_EDIT_FILE_TITLE                   : "Rename file",
    MEDIA_DELETE_DIR_TITLE                  : "Delete directory",
    MEDIA_DELETE_FILE_TITLE                 : "Delete file",
    MEDIA_DELETE_DIR_TEXT                   : "Do you really want to delete the directory <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT                  : "Do you really want to delete the file <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE                    : "There are no files available.",
    MEDIA_ERROR_CREATE                      : "Could not create the indicated directory.",
    MEDIA_ERROR_CREATE_NAME                 : "You must specify the name of the directory.",
    MEDIA_ERROR_CREATE_EXISTS               : "A file/directory with that name already exists.",
    MEDIA_ERROR_UPLOAD                      : "An error occurred uploading the file.",
    MEDIA_ERROR_UPLOAD_EMPTY                : "You must specify a file to upload.",
    MEDIA_ERROR_UPLOAD_SIZE                 : "The file is larger than 3mb.",
    MEDIA_ERROR_UPLOAD_SIZES                : "There are files that are larger than 3mb.",
    MEDIA_ERROR_UPLOAD_EXISTS               : "A file with that name already exists.",
    MEDIA_ERROR_RENAME                      : "An error occurred renaming the indicated file/directory.",
    MEDIA_ERROR_RENAME_OLD_NAME             : "You must indicate the file/directory to rename.",
    MEDIA_ERROR_RENAME_NEW_NAME             : "You must indicate the new name of the file.",
    MEDIA_ERROR_RENAME_OLD_EXISTS           : "The file/directory to rename does not exist.",
    MEDIA_ERROR_RENAME_NEW_EXISTS           : "A file/directory with the new name already exists.",
    MEDIA_ERROR_MOVE                        : "An error occurred while moving the indicated file.",
    MEDIA_ERROR_MOVE_OLD_PATH               : "You must indicate the file to move.",
    MEDIA_ERROR_MOVE_NEW_PATH               : "You must indicate the new directory of the file.",
    MEDIA_ERROR_MOVE_OLD_EXISTS             : "The file to move does not exist.",
    MEDIA_ERROR_MOVE_NEW_EXISTS             : "A file with the same name already exists in the specified directory.",
    MEDIA_ERROR_DELETE                      : "An error occurred while deleting the indicated file/directory.",
    MEDIA_ERROR_DELETE_NAME                 : "You must indicate the file/directory to delete.",
    MEDIA_ERROR_DELETE_EXISTS               : "The file/directory to delete does not exist.",
    MEDIA_SUCCESS_CREATE                    : "The directory was successfully created.",
    MEDIA_SUCCESS_UPLOAD                    : "The file was successfully uploaded.",
    MEDIA_SUCCESS_RENAME                    : "The file/directory was successfully renamed.",
    MEDIA_SUCCESS_MOVE                      : "The file was successfully moved.",
    MEDIA_SUCCESS_DELETE                    : "The file/directory was successfully deleted.",



    // Email: Templates
    EMAIL_TEMPLATES_NAME                    : "Email templates",
    EMAIL_TEMPLATES_VIEW_TITLE              : "View email template",
    EMAIL_TEMPLATES_NONE_AVAILABLE          : "There are no email templates available.",

    // Email: Queue
    EMAIL_QUEUE_NAME                        : "Email queue",
    EMAIL_QUEUE_VIEW_TITLE                  : "View email",
    EMAIL_QUEUE_RESEND_TITLE                : "Resend email",
    EMAIL_QUEUE_RESEND_TEXT_SINGULAR        : "Do you really want to mark <b>{0}</b> email to be resent?",
    EMAIL_QUEUE_RESEND_TEXT_PLURAL          : "Do you really want to mark <b>{0}</b> emails to be resent?",
    EMAIL_QUEUE_NONE_AVAILABLE              : "There are no emails available in the email queue.",
    EMAIL_QUEUE_ERROR_RESEND                : "You must indicate at least one email to resend.",
    EMAIL_QUEUE_SUCCESS_RESEND              : "The email was successfully marked for resending.",
    EMAIL_QUEUE_SUCCESS_MULTI_RESEND        : "The emails were successfully marked for resending.",

    // Email: White List
    EMAIL_WHITE_LIST_NAME                   : "Email white list",
    EMAIL_WHITE_LIST_CREATE_TITLE           : "Add email",
    EMAIL_WHITE_LIST_EDIT_TITLE             : "Edit email",
    EMAIL_WHITE_LIST_DELETE_TITLE           : "Remove email",
    EMAIL_WHITE_LIST_DELETE_TEXT            : "Do you really want to remove the email <b>{0}</b>?",
    EMAIL_WHITE_LIST_EMAIL                  : "Email available for sending",
    EMAIL_WHITE_LIST_DESCRIPTION            : "Description about the email",
    EMAIL_WHITE_LIST_NONE_AVAILABLE         : "There are no emails available.",
    EMAIL_WHITE_LIST_ERROR_EXISTS           : "The specified email template does not exist.",
    EMAIL_WHITE_LIST_ERROR_EMPTY            : "You must indicate the email to add.",
    EMAIL_WHITE_LIST_ERROR_INVALID          : "The indicated email is incorrect.",
    EMAIL_WHITE_LIST_ERROR_USED             : "The indicated email is already added.",
    EMAIL_WHITE_LIST_SUCCESS_ADD            : "The email was successfully added.",
    EMAIL_WHITE_LIST_SUCCESS_EDIT           : "The email was successfully edited.",
    EMAIL_WHITE_LIST_SUCCESS_REMOVE         : "The email was successfully removed.",



    // Log: Notifications
    NOTIFICATIONS_NAME                      : "Notifications",
    NOTIFICATIONS_SINGULAR                  : "Notification",
    NOTIFICATIONS_VIEW_TITLE                : "View notification",
    NOTIFICATIONS_EXTERNAL_ID               : "Provider ID",
    NOTIFICATIONS_NONE_AVAILABLE            : "There are no notifications available.",

    // Log: Providers
    PROVIDERS_LOG_NAME                      : "Providers log",
    PROVIDERS_LOG_VIEW_TITLE                : "View provider log",
    PROVIDERS_LOG_DELETE_TITLE              : "Delete provider log",
    PROVIDERS_LOG_DELETE_TEXT_SINGULAR      : "Do you really want to delete <b>{0}</b> provider log?",
    PROVIDERS_LOG_DELETE_TEXT_PLURAL        : "Do you really want to delete <b>{0}</b> provider logs?",
    PROVIDERS_LOG_MODEL                     : "Channel / Hotel",
    PROVIDERS_LOG_REQUEST                   : "Request",
    PROVIDERS_LOG_NONE_AVAILABLE            : "There are no provider logs available.",

    // Log: Applications
    APPLICATIONS_LOG_NAME                   : "Applications log",
    APPLICATIONS_LOG_VIEW_TITLE             : "View application log",
    APPLICATIONS_LOG_MODEL                  : "Flow / Automation",
    APPLICATIONS_LOG_METHOD                 : "Method",
    APPLICATIONS_LOG_USER_PASSWORD          : "User / Password",
    APPLICATIONS_LOG_HEADERS                : "Headers",
    APPLICATIONS_LOG_PARAMS                 : "Parameters",
    APPLICATIONS_LOG_NONE_AVAILABLE         : "There are no application logs available.",

    // Log: Actions
    ACTIONS_LOG_NAME                        : "Actions log",
    ACTIONS_LOG_SESSION                     : "Session",
    ACTIONS_LOG_NONE_AVAILABLE              : "There are no actions available.",

    // Log: Errors
    ERRORS_LOG_NAME                         : "Error log",
    ERRORS_LOG_VIEW_TITLE                   : "View error",
    ERRORS_LOG_RESOLVE_TITLE                : "Mark as resolved",
    ERRORS_LOG_RESOLVE_TEXT_SINGULAR        : "Do you really want to mark <b>{0}</b> error as resolved?",
    ERRORS_LOG_RESOLVE_TEXT_PLURAL          : "Do you really want to mark <b>{0}</b> errors as resolved?",
    ERRORS_LOG_DELETE_TITLE                 : "Delete error",
    ERRORS_LOG_DELETE_TEXT_SINGULAR         : "Do you really want to delete <b>{0}</b> error?",
    ERRORS_LOG_DELETE_TEXT_PLURAL           : "Do you really want to delete <b>{0}</b> errors?",
    ERRORS_LOG_BACKTRACE                    : "Backtrace",
    ERRORS_LOG_RESOLVED                     : "Resolved",
    ERRORS_LOG_NONE_AVAILABLE               : "There are no errors available.",
    ERRORS_LOG_ERROR_RESOLVE                : "You must indicate at least one error to resolve.",
    ERRORS_LOG_ERROR_DELETE                 : "You must indicate at least one error to delete.",
    ERRORS_LOG_SUCCESS_RESOLVED             : "The error was successfully marked as resolved.",
    ERRORS_LOG_SUCCESS_MULTI_RESOLVED       : "The errors were successfully marked as resolved.",
    ERRORS_LOG_SUCCESS_DELETE               : "The error was successfully deleted.",
    ERRORS_LOG_SUCCESS_MULTI_DELETE         : "The errors were successfully deleted.",



    // DropZone
    DROPZONE_FILES_TITLE                    : "Drag files anywhere to upload them",
    DROPZONE_IMAGES_TITLE                   : "Drag images anywhere to upload them",
    DROPZONE_OR                             : "or",
    DROPZONE_FILES_BUTTON                   : "Select files",
    DROPZONE_IMAGES_BUTTON                  : "Select images",
    DROPZONE_FILES_DROP                     : "Drop files here to upload",
    DROPZONE_IMAGES_DROP                    : "Drop images here to upload",

    // emoji
    EMOJI_NAME                              : "Emoji",
    EMOJI_SUGGESTED                         : "Recently used",
    EMOJI_SMILEYS                           : "Emoticons & People",
    EMOJI_ANIMALS                           : "Animals & Nature",
    EMOJI_FOOD                              : "Food & Drinks",
    EMOJI_TRAVEL                            : "Travel & Places",
    EMOJI_ACTIVITIES                        : "Activities",
    EMOJI_OBJECTS                           : "Objects",
    EMOJI_SYMBOLS                           : "Symbols",
    EMOJI_FLAGS                             : "Flags",

    // Periods
    PERIOD_NAME                             : "Period",
    PERIOD_SELECT                           : "Select a period",
    PERIOD_TODAY                            : "Today",
    PERIOD_YESTERDAY                        : "Yesterday",
    PERIOD_LAST_7_DAYS                      : "Last 7 days",
    PERIOD_LAST_15_DAYS                     : "Last 15 days",
    PERIOD_LAST_30_DAYS                     : "Last 30 days",
    PERIOD_LAST_60_DAYS                     : "Last 60 days",
    PERIOD_LAST_90_DAYS                     : "Last 90 days",
    PERIOD_LAST_120_DAYS                    : "Last 120 days",
    PERIOD_LAST_YEAR                        : "Last year",
    PERIOD_THIS_WEEK                        : "This week",
    PERIOD_THIS_MONTH                       : "This month",
    PERIOD_THIS_YEAR                        : "This year",
    PERIOD_PAST_WEEK                        : "Last week",
    PERIOD_PAST_MONTH                       : "Last month",
    PERIOD_PAST_YEAR                        : "Last year",
    PERIOD_ALL_PERIOD                       : "All period",
    PERIOD_CUSTOM                           : "Custom",

    // Replaceable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                          : [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
    DATE_DAY_SHORTS                         : [ "Su", "Mo", "Tu", "Mi", "Th", "Fr", "Sa" ],
    DATE_DAY_POSITIONS                      : [ "First", "Second", "Third", "Fourth" ],
    DATE_MONTH_NAMES                        : [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],

    DATE_TIME                               : "{h}:{i}",
    DATE_DAY_TIME                           : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                          : "{d} of {mn}",
    DATE_DAY_MONTH_SHORT                    : "{d}, {m3}",
    DATE_DAY_MONTH_MEDIUM                   : "{d} of {m3}",
    DATE_DAY_MONTH_YEAR                     : "{d} of {mn}, {y}",
    DATE_DAY_YEAR_SHORT                     : "{d}, {m3} {y}",
    DATE_DAY_YEAR_MEDIUM                    : "{d} of {m3} {y}",
    DATE_MONTH_YEAR                         : "{mn} {y}",
    DATE_REDUCED                            : "{dn} {d0}/{m0}",
    DATE_COMPLETE                           : "{dn}, {d} of {mn}, {y}",
    DATE_COMPLETE_SHORT                     : "{d3}, {d} of {m3}, {y}",
    DATE_COMPLETE_MONTH                     : "{dn}, {d} of {mn}",
    DATE_DASHES                             : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE                     : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                        : "{d0}-{m0}-{y} at {h}:{i}",
    DATE_DASHES_HOUR                        : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_DASHES_SECONDS                     : "{d0}-{m0}-{y}, {h}:{i}:{s}",
    DATE_SLASHES                            : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                        : "{d0}/{m0}",
    DATE_SORTABLE                           : "{y}/{m0}/{d0}",

    DATE_NOW                                : "Now",
    DATE_MIN_AGO                            : "{i} minute ago",
    DATE_MINS_AGO                           : "{i} minutes ago",
    DATE_HOUR_AGO                           : "{h} hour ago",
    DATE_HOURS_AGO                          : "{h} hours ago",
    DATE_DAY_AGO                            : "{d} day ago",
    DATE_DAYS_AGO                           : "{d} days ago",
    DATE_IN_MIN                             : "In {i} minute",
    DATE_IN_MINS                            : "In {i} minutes",
    DATE_IN_HOUR                            : "In {h} hour",
    DATE_IN_HOURS                           : "In {h} hours",
    DATE_IN_DAY                             : "In {d} day",
    DATE_IN_DAYS                            : "In {d} days",
    DATE_TOMORROW_AT                        : "Tomorrow at {h}:{i}",
    DATE_TODAY_AT                           : "Today at {h}:{i}",
    DATE_YESTERDAY_AT                       : "Yesterday at {h}:{i}",
    DATE_THIS_WEEK_AT                       : "{dn} at {h}:{i}",
    DATE_THIS_YEAR_AT                       : "{d} of {m3} at {h}:{i}",
    DATE_OTHER_YEAR_AT                      : "{d} of {m3} {y} at {h}:{i}",

    DATE_YESTERDAY                          : "Yesterday",
    DATE_TODAY                              : "Today",
    DATE_TOMORROW                           : "Tomorrow",
    DATE_LAST_WEEK                          : "Last Week",
    DATE_THIS_WEEK                          : "This Week",
    DATE_NEXT_WEEK                          : "Next Week",
    DATE_PARSED_WEEK                        : "Week from {0} to {1}",
    DATE_DURATION                           : "{0} - {1}",

    DATE_TIME_ZONES                         : [
        { key : "-12",   value : "(GMT -12:00) Eniwetok, Kwajalein" },
        { key : "-11",   value : "(GMT -11:00) Midway Island, Samoa" },
        { key : "-10",   value : "(GMT -10:00) Hawaii" },
        { key : "-9.5",  value : "(GMT -9:30) Taiohae" },
        { key : "-9",    value : "(GMT -9:00) Alaska" },
        { key : "-8",    value : "(GMT -8:00) Pacific Time (US & Canada)" },
        { key : "-7",    value : "(GMT -7:00) Mountain Time (US & Canada)" },
        { key : "-6",    value : "(GMT -6:00) Central Time (US & Canada), Mexico" },
        { key : "-5",    value : "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima" },
        { key : "-4.5",  value : "(GMT -4:30) Caracas" },
        { key : "-4",    value : "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
        { key : "-3.5",  value : "(GMT -3:30) Newfoundland" },
        { key : "-3",    value : "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
        { key : "-2",    value : "(GMT -2:00) Mid-Atlantic" },
        { key : "-1",    value : "(GMT -1:00) Azores, Cape Verde Islands" },
        { key : "0",     value : "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
        { key : "1",     value : "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris" },
        { key : "2",     value : "(GMT +2:00) Kaliningrad, South Africa" },
        { key : "3",     value : "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
        { key : "3.5",   value : "(GMT +3:30) Tehran" },
        { key : "4",     value : "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
        { key : "4.5",   value : "(GMT +4:30) Kabul" },
        { key : "5",     value : "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
        { key : "5.5",   value : "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
        { key : "5.75",  value : "(GMT +5:45) Kathmandu, Pokhara" },
        { key : "6",     value : "(GMT +6:00) Almaty, Dhaka, Colombo" },
        { key : "6.5",   value : "(GMT +6:30) Yangon, Mandalay" },
        { key : "7",     value : "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
        { key : "8",     value : "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
        { key : "8.75",  value : "(GMT +8:45) Eucla" },
        { key : "9",     value : "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
        { key : "9.50",  value : "(GMT +9:30) Adelaide, Darwin" },
        { key : "10",    value : "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
        { key : "10.5",  value : "(GMT +10:30) Lord Howe Island" },
        { key : "11",    value : "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
        { key : "11.5",  value : "(GMT +11:30) Norfolk Island" },
        { key : "12",    value : "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
        { key : "12.75", value : "(GMT +12:45) Chatham Islands" },
        { key : "13",    value : "(GMT +13:00) Apia, Nukualofa" },
        { key : "14",    value : "(GMT +14:00) Line Islands, Tokelau" },
    ],

};

export default strings;
