import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Sale Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SaleItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;


    // Variables
    const hasSale = Boolean(elem.id);
    const items   = elem.items ? Object.values(elem.items) : [];


    // Do the Render
    return <DetailList
        isHidden={!hasSale}
        message={elem.name || ""}
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        <DetailItem
            icon="application"
            message={elem.applicationName}
            tooltip="STORES_SINGULAR"
        />

        {items.map((item) => <DetailItem
            key={item.saleItemID}
            icon="product"
            message={item.value}
        >
            <div>
                <b>{item.name}</b><br />
                {`${item.quantity} x `}
                <Html variant="span">{item.unitPriceText}</Html>
            </div>
        </DetailItem>)}

        <DetailItem
            icon="money"
            message={elem.totalPriceText}
            prefix="GENERAL_TOTAL"
        />
        <DetailItem
            icon="money"
            message={elem.totalDiscountText}
            prefix="SALES_DISCOUNT"
        />

        <DetailItem
            icon="status"
            message={elem.orderStatusName}
            textColor={elem.orderStatusColor}
            prefix="GENERAL_STATUS"
        />
        <DetailItem
            icon="status"
            message={elem.paymentStatusName}
            textColor={elem.paymentStatusColor}
            prefix="SALES_PAYMENT"
        />
        <DetailItem
            icon="status"
            message={elem.shippingStatusName}
            textColor={elem.shippingStatusColor}
            prefix="SALES_SHIPPING"
        />
        <DetailItem
            icon="info"
            message={elem.cancelReason}
            tooltip="GENERAL_CANCEL_REASON"
        />
        <DetailItem
            icon="time"
            message={elem.orderDateTime}
            tooltip="GENERAL_CREATED_TIME"
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SaleItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default SaleItem;
