import Ajax                 from "Dashboard/Core/Ajax";



// Auth actions
export const Login = {
    "login"                 : (data) => Ajax.post("/auth/login/login",                      data),
    "googleLogin"           : (data) => Ajax.post("/auth/login/googleLogin",                data),
    "microsoftLogin"        : (data) => Ajax.post("/auth/login/microsoftLogin",             data),
    "facebookLogin"         : (data) => Ajax.post("/auth/login/facebookLogin",              data),
    "tokenLogin"            : (data) => Ajax.post("/auth/login/tokenLogin",                 data),
    "refreshTokenLogin"     : (data) => Ajax.post("/auth/login/refreshTokenLogin",          data),
    "loginAs"               : (data) => Ajax.post("/auth/login/loginAs",                    data),
    "logout"                : (data) => Ajax.post("/auth/login/logout",                     data),
    "logoutAs"              : (data) => Ajax.post("/auth/login/logoutAs",                   data),
    "deleteRefreshToken"    : (data) => Ajax.post("/auth/login/deleteRefreshToken",         data),
};
export const Join = {
    "getOne"                : (data) => Ajax.post("/auth/join/getOne",                      data),
    "join"                  : (data) => Ajax.post("/auth/join/join",                        data),
    "googleJoin"            : (data) => Ajax.post("/auth/join/googleJoin",                  data),
    "microsoftJoin"         : (data) => Ajax.post("/auth/join/microsoftJoin",               data),
    "facebookJoin"          : (data) => Ajax.post("/auth/join/facebookJoin",                data),
};
export const Reset = {
    "requestReset"          : (data) => Ajax.post("/auth/reset/requestReset",               data),
    "verifyReset"           : (data) => Ajax.post("/auth/reset/verifyReset",                data),
    "resetPass"             : (data) => Ajax.post("/auth/reset/resetPass",                  data),
    "changePass"            : (data) => Ajax.post("/auth/reset/changePass",                 data),
};



// Profile actions
export const App = {
    "getInitial"            : (data) => Ajax.post("/app/getInitial",                        data),
    "getProgress"           : (data) => Ajax.post("/app/getProgress",                       data),
    "discardAnnouncement"   : (data) => Ajax.post("/app/discardAnnouncement",               data),
    "searchUser"            : (data) => Ajax.post("/app/searchUser",                        data),
    "saveColumns"           : (data) => Ajax.post("/app/saveColumns",                       data),
};
export const Profile = {
    "get"                   : (data) => Ajax.post("/profile/get",                           data),
    "edit"                  : (data) => Ajax.post("/profile/edit",                          data),
    "upload"                : (data) => Ajax.post("/profile/upload",                        data),
    "changeAppearance"      : (data) => Ajax.post("/profile/changeAppearance",              data),
    "delete"                : (data) => Ajax.post("/profile/delete",                        data),
};
export const Organization = {
    "getAll"                : (data) => Ajax.post("/profile/organization/getAll",           data),
    "accept"                : (data) => Ajax.post("/profile/organization/accept",           data),
    "access"                : (data) => Ajax.post("/profile/organization/access",           data),
};
export const Notification = {
    "addDevice"             : (data) => Ajax.post("/profile/notification/addDevice",        data),
    "removeDevice"          : (data) => Ajax.post("/profile/notification/removeDevice",     data),
    "stopAsking"            : (data) => Ajax.post("/profile/notification/stopAsking",       data),
};
export const EmailSignature = {
    "getAll"                : (data) => Ajax.post("/profile/emailSignature/getAll",         data),
    "edit"                  : (data) => Ajax.post("/profile/emailSignature/edit",           data),
};



// Dashboard Actions
export const Dashboard = {
    "getReports"            : (data) => Ajax.post("/dashboard/getReports",                  data),
    "getUsers"              : (data) => Ajax.post("/dashboard/getUsers",                    data),
    "getTeams"              : (data) => Ajax.post("/dashboard/getTeams",                    data),
    "saveFilters"           : (data) => Ajax.post("/dashboard/saveFilters",                 data),
    "saveReports"           : (data) => Ajax.post("/dashboard/saveReports",                 data),
};
export const Monitoring = {
    "getAll"                : (data) => Ajax.get("/monitoring/getAll",                      data),
};



// Admin Actions
export const Client = {
    "getAll"                : (data) => Ajax.post("/client/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/client/getOne",                         data),
    "getEditData"           : (data) => Ajax.post("/client/getEditData",                    data),
    "search"                : (data) => Ajax.post("/client/search",                         data),
    "export"                : (data) => Ajax.url("/client/export",                          data),
    "edit"                  : (data) => Ajax.post("/client/edit",                           data),
    "approve"               : (data) => Ajax.post("/client/approve",                        data),
    "delete"                : (data) => Ajax.post("/client/delete",                         data),
};
export const Partner = {
    "getAll"                : (data) => Ajax.post("/partner/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/partner/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/partner/getEditData",                   data),
    "search"                : (data) => Ajax.post("/partner/search",                        data),
    "export"                : (data) => Ajax.url("/partner/export",                         data),
    "edit"                  : (data) => Ajax.post("/partner/edit",                          data),
    "delete"                : (data) => Ajax.post("/partner/delete",                        data),
};
export const Subscription = {
    "getAll"                : (data) => Ajax.post("/subscription/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/subscription/getOne",                   data),
    "getFilterData"         : (data) => Ajax.post("/subscription/getFilterData",            data),
    "export"                : (data) => Ajax.url("/subscription/export",                    data),
    "delete"                : (data) => Ajax.post("/subscription/delete",                   data),
};



// Client Actions
export const User = {
    "getAll"                : (data) => Ajax.post("/user/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/user/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/user/getEditData",                      data),
    "export"                : (data) => Ajax.url("/user/export",                            data),
    "create"                : (data) => Ajax.post("/user/create",                           data),
    "invite"                : (data) => Ajax.post("/user/invite",                           data),
    "resendInvite"          : (data) => Ajax.post("/user/resendInvite",                     data),
    "edit"                  : (data) => Ajax.post("/user/edit",                             data),
    "delete"                : (data) => Ajax.post("/user/delete",                           data),
};
export const Team = {
    "getAll"                : (data) => Ajax.post("/team/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/team/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/team/getEditData",                      data),
    "edit"                  : (data) => Ajax.post("/team/edit",                             data),
    "editUser"              : (data) => Ajax.post("/team/editUser",                         data),
    "delete"                : (data) => Ajax.post("/team/delete",                           data),
};
export const Schedule = {
    "getAll"                : (data) => Ajax.post("/schedule/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/schedule/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/schedule/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/schedule/edit",                         data),
    "delete"                : (data) => Ajax.post("/schedule/delete",                       data),
};
export const MsgTemplate = {
    "getAll"                : (data) => Ajax.post("/msgTemplate/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/msgTemplate/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/msgTemplate/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/msgTemplate/edit",                      data),
    "delete"                : (data) => Ajax.post("/msgTemplate/delete",                    data),
};
export const WabaTemplate = {
    "getAll"                : (data) => Ajax.post("/wabaTemplate/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/wabaTemplate/getOne",                   data),
    "getEditData"           : (data) => Ajax.post("/wabaTemplate/getEditData",              data),
    "getImportData"         : (data) => Ajax.post("/wabaTemplate/getImportData",            data),
    "edit"                  : (data) => Ajax.post("/wabaTemplate/edit",                     data),
    "import"                : (data) => Ajax.post("/wabaTemplate/import",                   data),
    "delete"                : (data) => Ajax.post("/wabaTemplate/delete",                   data),
};
export const Questionnaire = {
    "getAll"                : (data) => Ajax.post("/questionnaire/getAll",                  data),
    "getOne"                : (data) => Ajax.post("/questionnaire/getOne",                  data),
    "getEditData"           : (data) => Ajax.post("/questionnaire/getEditData",             data),
    "edit"                  : (data) => Ajax.post("/questionnaire/edit",                    data),
    "delete"                : (data) => Ajax.post("/questionnaire/delete",                  data),
};
export const Hotel = {
    "getAll"                : (data) => Ajax.post("/hotel/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/hotel/getOne",                          data),
    "getEditData"           : (data) => Ajax.post("/hotel/getEditData",                     data),
    "edit"                  : (data) => Ajax.post("/hotel/edit",                            data),
    "delete"                : (data) => Ajax.post("/hotel/delete",                          data),
};
export const Channel = {
    "getAll"                : (data) => Ajax.post("/channel/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/channel/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/channel/getEditData",                   data),
    "getProfile"            : (data) => Ajax.post("/channel/getProfile",                    data),
    "add"                   : (data) => Ajax.post("/channel/add",                           data),
    "edit"                  : (data) => Ajax.post("/channel/edit",                          data),
    "editProfile"           : (data) => Ajax.post("/channel/editProfile",                   data),
    "delete"                : (data) => Ajax.post("/channel/delete",                        data),
};
export const PhoneNumber = {
    "getAll"                : (data) => Ajax.post("/phoneNumber/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/phoneNumber/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/phoneNumber/getEditData",               data),
    "getImportData"         : (data) => Ajax.post("/phoneNumber/getImportData",             data),
    "add"                   : (data) => Ajax.post("/phoneNumber/add",                       data),
    "import"                : (data) => Ajax.post("/phoneNumber/import",                    data),
    "edit"                  : (data) => Ajax.post("/phoneNumber/edit",                      data),
    "delete"                : (data) => Ajax.post("/phoneNumber/delete",                    data),
};



// Field Actions
export const Tag = {
    "getAll"                : (data) => Ajax.post("/tag/getAll",                            data),
    "getOne"                : (data) => Ajax.post("/tag/getOne",                            data),
    "edit"                  : (data) => Ajax.post("/tag/edit",                              data),
    "delete"                : (data) => Ajax.post("/tag/delete",                            data),
};
export const Tongue = {
    "getAll"                : (data) => Ajax.post("/tongue/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/tongue/getOne",                         data),
    "getEditData"           : (data) => Ajax.post("/tongue/getEditData",                    data),
    "edit"                  : (data) => Ajax.post("/tongue/edit",                           data),
    "delete"                : (data) => Ajax.post("/tongue/delete",                         data),
};
export const CustomField = {
    "getAll"                : (data) => Ajax.post("/field/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/field/getOne",                          data),
    "getEditData"           : (data) => Ajax.post("/field/getEditData",                     data),
    "edit"                  : (data) => Ajax.post("/field/edit",                            data),
    "delete"                : (data) => Ajax.post("/field/delete",                          data),
};
export const Variable = {
    "getAll"                : (data) => Ajax.post("/variable/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/variable/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/variable/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/variable/edit",                         data),
    "delete"                : (data) => Ajax.post("/variable/delete",                       data),
};
export const DefaultVariable = {
    "getAll"                : (data) => Ajax.post("/defaultVariable/getAll",                data),
    "getOne"                : (data) => Ajax.post("/defaultVariable/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/defaultVariable/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/defaultVariable/edit",                  data),
    "delete"                : (data) => Ajax.post("/defaultVariable/delete",                data),
};



// Conversation Actions
export const Conversation = {
    "getAll"                : (data) => Ajax.post("/conversation/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/conversation/getOne",                   data),
    "saveFilters"           : (data) => Ajax.post("/conversation/saveFilters",              data),
    "search"                : (data, controller) => Ajax.get("/conversation/search",        data, false, controller),
    "create"                : (data) => Ajax.post("/conversation/create",                   data),
    "getEditData"           : (data) => Ajax.post("/conversation/getEditData",              data),
    "edit"                  : (data) => Ajax.post("/conversation/edit",                     data),
    "setWriting"            : (data) => Ajax.post("/conversation/setWriting",               data),
    "setLastView"           : (data) => Ajax.post("/conversation/setLastView",              data),
    "markAsRead"            : (data) => Ajax.post("/conversation/markAsRead",               data),
    "markAsUnread"          : (data) => Ajax.post("/conversation/markAsUnread",             data),
    "accept"                : (data) => Ajax.post("/conversation/accept",                   data),
    "return"                : (data) => Ajax.post("/conversation/return",                   data),
    "followup"              : (data) => Ajax.post("/conversation/followup",                 data),
    "progress"              : (data) => Ajax.post("/conversation/progress",                 data),
    "resolve"               : (data) => Ajax.post("/conversation/resolve",                  data),
    "close"                 : (data) => Ajax.post("/conversation/close",                    data),
    "open"                  : (data) => Ajax.post("/conversation/open",                     data),
    "transferUser"          : (data) => Ajax.post("/conversation/transferUser",             data),
    "transferTeam"          : (data) => Ajax.post("/conversation/transferTeam",             data),
    "assignTag"             : (data) => Ajax.post("/conversation/assignTag",                data),
    "assignTongue"          : (data) => Ajax.post("/conversation/assignTongue",             data),
    "assignHotel"           : (data) => Ajax.post("/conversation/assignHotel",              data),
    "assignFlow"            : (data) => Ajax.post("/conversation/assignFlow",               data),
    "generate"              : (data) => Ajax.post("/conversation/generate",                 data),
    "summarize"             : (data) => Ajax.post("/conversation/summarize",                data),
    "delete"                : (data) => Ajax.post("/conversation/delete",                   data),
};
export const ConversationMessage = {
    "getAllOld"             : (data) => Ajax.post("/conversation/message/getAllOld",        data),
    "getAllNew"             : (data) => Ajax.post("/conversation/message/getAllNew",        data),
    "add"                   : (data) => Ajax.post("/conversation/message/add",              data),
    "send"                  : (data) => Ajax.post("/conversation/message/send",             data),
    "sendTemplate"          : (data) => Ajax.post("/conversation/message/sendTemplate",     data),
    "forward"               : (data) => Ajax.post("/conversation/message/forward",          data),
};
export const ConversationReaction = {
    "add"                   : (data) => Ajax.post("/conversation/reaction/add",             data),
    "remove"                : (data) => Ajax.post("/conversation/reaction/remove",          data),
};
export const ConversationNote = {
    "getOne"                : (data) => Ajax.post("/conversation/note/getOne",              data),
    "create"                : (data) => Ajax.post("/conversation/note/create",              data),
    "edit"                  : (data) => Ajax.post("/conversation/note/edit",                data),
    "delete"                : (data) => Ajax.post("/conversation/note/delete",              data),
};



// Store Actions
export const Store = {
    "getNav"                : (data) => Ajax.post("/store/getNav",                          data),
    "getAll"                : (data) => Ajax.post("/store/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/store/getOne",                          data),
    "getEditData"           : (data) => Ajax.post("/store/getEditData",                     data),
    "edit"                  : (data) => Ajax.post("/store/edit",                            data),
    "activate"              : (data) => Ajax.post("/store/activate",                        data),
    "publish"               : (data) => Ajax.post("/store/publish",                         data),
    "clear"                 : (data) => Ajax.post("/store/clear",                           data),
    "pause"                 : (data) => Ajax.post("/store/pause",                           data),
    "delete"                : (data) => Ajax.post("/store/delete",                          data),
};
export const StoreOrder = {
    "getAll"                : (data) => Ajax.post("/store/order/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/store/order/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/store/order/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/store/order/edit",                      data),
};
export const StoreProduct = {
    "getAll"                : (data) => Ajax.post("/store/product/getAll",                  data),
    "getOne"                : (data) => Ajax.post("/store/product/getOne",                  data),
    "getEditData"           : (data) => Ajax.post("/store/product/getEditData",             data),
    "edit"                  : (data) => Ajax.post("/store/product/edit",                    data),
    "delete"                : (data) => Ajax.post("/store/product/delete",                  data),
};
export const StoreCategory = {
    "getAll"                : (data) => Ajax.post("/store/category/getAll",                 data),
    "getOne"                : (data) => Ajax.post("/store/category/getOne",                 data),
    "getEditData"           : (data) => Ajax.post("/store/category/getEditData",            data),
    "edit"                  : (data) => Ajax.post("/store/category/edit",                   data),
    "delete"                : (data) => Ajax.post("/store/category/delete",                 data),
};
export const StoreBanner = {
    "getAll"                : (data) => Ajax.post("/store/banner/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/store/banner/getOne",                   data),
    "getEditData"           : (data) => Ajax.post("/store/banner/getEditData",              data),
    "edit"                  : (data) => Ajax.post("/store/banner/edit",                     data),
    "delete"                : (data) => Ajax.post("/store/banner/delete",                   data),
};
export const StoreOption = {
    "getAll"                : (data) => Ajax.post("/store/option/getAll",                   data),
    "edit"                  : (data) => Ajax.post("/store/option/edit",                     data),
};



// Contact Actions
export const Contact = {
    "getAll"                : (data) => Ajax.post("/contact/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/contact/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/contact/getEditData",                   data),
    "search"                : (data) => Ajax.post("/contact/search",                        data),
    "edit"                  : (data) => Ajax.post("/contact/edit",                          data),
    "block"                 : (data) => Ajax.post("/contact/block",                         data),
    "unblock"               : (data) => Ajax.post("/contact/unblock",                       data),
    "delete"                : (data) => Ajax.post("/contact/delete",                        data),
    "multiDelete"           : (data) => Ajax.post("/contact/multiDelete",                   data),
};
export const ContactMerge = {
    "getEditData"           : (data) => Ajax.post("/contact/merge/getEditData",             data),
    "getCombineData"        : (data) => Ajax.post("/contact/merge/getCombineData",          data),
    "getMergeData"          : (data) => Ajax.post("/contact/merge/getMergeData",            data),
    "merge"                 : (data) => Ajax.post("/contact/merge/merge",                   data),
};
export const ContactIO = {
    "export"                : (data) => Ajax.url("/contact/io/export",                      data),
    "uploadImport"          : (data) => Ajax.post("/contact/io/uploadImport",               data),
    "import"                : (data, controller) => Ajax.post("/contact/io/import",         data, false, controller),
    "downloadImport"        : (data) => Ajax.url("/contact/io/downloadImport",              data),
};



// Hospitality Actions
export const Hospitality = {
    "getAll"                : (data) => Ajax.post("/hospitality/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/hospitality/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/hospitality/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/hospitality/edit",                      data),
    "delete"                : (data) => Ajax.post("/hospitality/delete",                    data),
};
export const HospitalityIO = {
    "export"                : (data) => Ajax.url("/hospitality/io/export",                  data),
    "uploadImport"          : (data) => Ajax.post("/hospitality/io/uploadImport",           data),
    "import"                : (data, controller) => Ajax.post("/hospitality/io/import",     data, false, controller),
};



// Sale Actions
export const Sale = {
    "getAll"                : (data) => Ajax.post("/sale/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/sale/getOne",                           data),
    "getFilterData"         : (data) => Ajax.post("/sale/getFilterData",                    data),
};



// Account Actions
export const Account = {
    "getAll"                : (data) => Ajax.post("/account/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/account/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/account/getEditData",                   data),
    "search"                : (data) => Ajax.post("/account/search",                        data),
    "edit"                  : (data) => Ajax.post("/account/edit",                          data),
    "assign"                : (data) => Ajax.post("/account/assign",                        data),
    "unassign"              : (data) => Ajax.post("/account/unassign",                      data),
    "delete"                : (data) => Ajax.post("/account/delete",                        data),
};
export const AccountIO = {
    "export"                : (data) => Ajax.url("/account/io/export",                      data),
    "uploadImport"          : (data) => Ajax.post("/account/io/uploadImport",               data),
    "import"                : (data, controller) => Ajax.post("/account/io/import",         data, false, controller),
};



// Campaign Actions
export const Campaign = {
    "getAll"                : (data) => Ajax.post("/campaign/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/campaign/getOne",                       data),
    "create"                : (data) => Ajax.post("/campaign/create",                       data),
    "editOption"            : (data) => Ajax.post("/campaign/editOption",                   data),
    "activate"              : (data) => Ajax.post("/campaign/activate",                     data),
    "pause"                 : (data) => Ajax.post("/campaign/pause",                        data),
    "complete"              : (data) => Ajax.post("/campaign/complete",                     data),
    "publish"               : (data) => Ajax.post("/campaign/publish",                      data),
    "clear"                 : (data) => Ajax.post("/campaign/clear",                        data),
    "delete"                : (data) => Ajax.post("/campaign/delete",                       data),
};
export const CampaignReport = {
    "getAll"                : (data) => Ajax.post("/campaign/report/getAll",                data),
};
export const CampaignExecution = {
    "getAll"                : (data) => Ajax.post("/campaign/execution/getAll",             data),
    "delete"                : (data) => Ajax.post("/campaign/execution/delete",             data),
};
export const CampaignTrigger = {
    "create"                : (data) => Ajax.post("/campaign/trigger/create",               data),
    "edit"                  : (data) => Ajax.post("/campaign/trigger/edit",                 data),
    "delete"                : (data) => Ajax.post("/campaign/trigger/delete",               data),
};
export const CampaignCondition = {
    "create"                : (data) => Ajax.post("/campaign/condition/create",             data),
    "edit"                  : (data) => Ajax.post("/campaign/condition/edit",               data),
    "delete"                : (data) => Ajax.post("/campaign/condition/delete",             data),
};
export const CampaignGroup = {
    "create"                : (data) => Ajax.post("/campaign/group/create",                 data),
    "edit"                  : (data) => Ajax.post("/campaign/group/edit",                   data),
    "delete"                : (data) => Ajax.post("/campaign/group/delete",                 data),
};



// Email Design Actions
export const EmailDesign = {
    "getAll"                : (data) => Ajax.post("/emailDesign/getAll",                    data),
    "getData"               : (data) => Ajax.post("/emailDesign/getData",                   data),
    "getOne"                : (data) => Ajax.post("/emailDesign/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/emailDesign/getEditData",               data),
    "getEditor"             : (data) => Ajax.post("/emailDesign/getEditor",                 data),
    "edit"                  : (data) => Ajax.post("/emailDesign/edit",                      data),
    "copy"                  : (data) => Ajax.post("/emailDesign/copy",                      data),
    "save"                  : (data) => Ajax.post("/emailDesign/save",                      data),
    "publish"               : (data) => Ajax.post("/emailDesign/publish",                   data),
    "clear"                 : (data) => Ajax.post("/emailDesign/clear",                     data),
    "delete"                : (data) => Ajax.post("/emailDesign/delete",                    data),
};



// Assistant Actions
export const Assistant = {
    "getAll"                : (data) => Ajax.post("/assistant/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/assistant/getOne",                      data),
    "getEditData"           : (data) => Ajax.post("/assistant/getEditData",                 data),
    "edit"                  : (data) => Ajax.post("/assistant/edit",                        data),
    "delete"                : (data) => Ajax.post("/assistant/delete",                      data),
};
export const AssistantModel = {
    "getAll"                : (data) => Ajax.post("/assistant/model/getAll",                data),
    "getOne"                : (data) => Ajax.post("/assistant/model/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/assistant/model/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/assistant/model/edit",                  data),
    "delete"                : (data) => Ajax.post("/assistant/model/delete",                data),
};
export const AssistantRun = {
    "getAll"                : (data) => Ajax.post("/assistant/run/getAll",                  data),
    "getAllForClient"       : (data) => Ajax.post("/assistant/run/getAllForClient",         data),
    "getAllForFlow"         : (data) => Ajax.post("/assistant/run/getAllForFlow",           data),
    "getAllForOwner"        : (data) => Ajax.post("/assistant/run/getAllForOwner",          data),
    "getOne"                : (data) => Ajax.post("/assistant/run/getOne",                  data),
};
export const AssistantThread = {
    "getMessages"           : (data) => Ajax.post("/assistant/thread/getMessages",          data),
    "sendMessage"           : (data) => Ajax.post("/assistant/thread/sendMessage",          data),
    "restartMessages"       : (data) => Ajax.post("/assistant/thread/restartMessages",      data),
};



// Widget Actions
export const Widget = {
    "getAll"                : (data) => Ajax.post("/widget/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/widget/getOne",                         data),
    "create"                : (data) => Ajax.post("/widget/create",                         data),
    "copy"                  : (data) => Ajax.post("/widget/copy",                           data),
    "editOption"            : (data) => Ajax.post("/widget/editOption",                     data),
    "publish"               : (data) => Ajax.post("/widget/publish",                        data),
    "clear"                 : (data) => Ajax.post("/widget/clear",                          data),
    "delete"                : (data) => Ajax.post("/widget/delete",                         data),
};
export const WidgetPlatform = {
    "getOne"                : (data) => Ajax.post("/widget/platform/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/widget/platform/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/widget/platform/edit",                  data),
    "sort"                  : (data) => Ajax.post("/widget/platform/sort",                  data),
    "delete"                : (data) => Ajax.post("/widget/platform/delete",                data),
};



// Flow Actions
export const Flow = {
    "getAll"                : (data) => Ajax.post("/flow/getAll",                           data),
    "getData"               : (data) => Ajax.post("/flow/getData",                          data),
    "getOne"                : (data) => Ajax.post("/flow/getOne",                           data),
    "getEditData"           : (data) => Ajax.post("/flow/getEditData",                      data),
    "getConversations"      : (data) => Ajax.post("/flow/getConversations",                 data),
    "getEditor"             : (data) => Ajax.post("/flow/getEditor",                        data),
    "edit"                  : (data) => Ajax.post("/flow/edit",                             data),
    "copy"                  : (data) => Ajax.post("/flow/copy",                             data),
    "publish"               : (data) => Ajax.post("/flow/publish",                          data),
    "clear"                 : (data) => Ajax.post("/flow/clear",                            data),
    "delete"                : (data) => Ajax.post("/flow/delete",                           data),
};
export const FlowTemplate = {
    "getAll"                : (data) => Ajax.post("/flow/template/getAll",                  data),
    "getAllForPartner"      : (data) => Ajax.post("/flow/template/getAllForPartner",        data),
    "getOne"                : (data) => Ajax.post("/flow/template/getOne",                  data),
    "getEditData"           : (data) => Ajax.post("/flow/template/getEditData",             data),
    "edit"                  : (data) => Ajax.post("/flow/template/edit",                    data),
    "copy"                  : (data) => Ajax.post("/flow/template/copy",                    data),
    "publish"               : (data) => Ajax.post("/flow/template/publish",                 data),
    "clear"                 : (data) => Ajax.post("/flow/template/clear",                   data),
    "delete"                : (data) => Ajax.post("/flow/template/delete",                  data),
};
export const FlowReport = {
    "getAll"                : (data) => Ajax.post("/flow/report/getAll",                    data),
};
export const FlowNode = {
    "create"                : (data) => Ajax.post("/flow/node/create",                      data),
    "edit"                  : (data) => Ajax.post("/flow/node/edit",                        data),
    "drag"                  : (data) => Ajax.post("/flow/node/drag",                        data),
    "copy"                  : (data) => Ajax.post("/flow/node/copy",                        data),
    "move"                  : (data) => Ajax.post("/flow/node/move",                        data),
    "delete"                : (data) => Ajax.post("/flow/node/delete",                      data),
};
export const FlowEdge = {
    "create"                : (data) => Ajax.post("/flow/edge/create",                      data),
    "edit"                  : (data) => Ajax.post("/flow/edge/edit",                        data),
    "delete"                : (data) => Ajax.post("/flow/edge/delete",                      data),
};
export const FlowSimulation = {
    "getOne"                : (data) => Ajax.post("/flow/simulation/getOne",                data),
    "getMessages"           : (data) => Ajax.post("/flow/simulation/getMessages",           data),
    "start"                 : (data) => Ajax.post("/flow/simulation/start",                 data),
    "restart"               : (data) => Ajax.post("/flow/simulation/restart",               data),
    "end"                   : (data) => Ajax.post("/flow/simulation/end",                   data),
    "sendMessage"           : (data) => Ajax.post("/flow/simulation/sendMessage",           data),
};



// Automation Actions
export const Automation = {
    "getAll"                : (data) => Ajax.post("/automation/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/automation/getOne",                     data),
    "getEditData"           : (data) => Ajax.post("/automation/getEditData",                data),
    "getEditor"             : (data) => Ajax.post("/automation/getEditor",                  data),
    "copy"                  : (data) => Ajax.post("/automation/copy",                       data),
    "edit"                  : (data) => Ajax.post("/automation/edit",                       data),
    "activate"              : (data) => Ajax.post("/automation/activate",                   data),
    "publish"               : (data) => Ajax.post("/automation/publish",                    data),
    "clear"                 : (data) => Ajax.post("/automation/clear",                      data),
    "pause"                 : (data) => Ajax.post("/automation/pause",                      data),
    "delete"                : (data) => Ajax.post("/automation/delete",                     data),
};
export const AutomationTemplate = {
    "getAll"                : (data) => Ajax.post("/automation/template/getAll",            data),
    "getAllForPartner"      : (data) => Ajax.post("/automation/template/getAllForPartner",  data),
    "getOne"                : (data) => Ajax.post("/automation/template/getOne",            data),
    "getEditData"           : (data) => Ajax.post("/automation/template/getEditData",       data),
    "edit"                  : (data) => Ajax.post("/automation/template/edit",              data),
    "copy"                  : (data) => Ajax.post("/automation/template/copy",              data),
    "activate"              : (data) => Ajax.post("/automation/template/activate",          data),
    "publish"               : (data) => Ajax.post("/automation/template/publish",           data),
    "clear"                 : (data) => Ajax.post("/automation/template/clear",             data),
    "pause"                 : (data) => Ajax.post("/automation/template/pause",             data),
    "delete"                : (data) => Ajax.post("/automation/template/delete",            data),
};
export const AutomationReport = {
    "getAll"                : (data) => Ajax.post("/automation/report/getAll",              data),
};
export const AutomationExecution = {
    "getAll"                : (data) => Ajax.post("/automation/execution/getAll",           data),
};
export const AutomationAction = {
    "create"                : (data) => Ajax.post("/automation/action/create",              data),
    "edit"                  : (data) => Ajax.post("/automation/action/edit",                data),
    "copy"                  : (data) => Ajax.post("/automation/action/copy",                data),
    "move"                  : (data) => Ajax.post("/automation/action/move",                data),
    "delete"                : (data) => Ajax.post("/automation/action/delete",              data),
};



// Application Actions
export const Application = {
    "getAll"                : (data) => Ajax.post("/application/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/application/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/application/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/application/edit",                      data),
    "delete"                : (data) => Ajax.post("/application/delete",                    data),
};
export const ApplicationType = {
    "getAll"                : (data) => Ajax.post("/application/type/getAll",               data),
    "getOne"                : (data) => Ajax.post("/application/type/getOne",               data),
    "edit"                  : (data) => Ajax.post("/application/type/edit",                 data),
};



// Support Actions
export const Ticket = {
    "getAll"                : (data) => Ajax.post("/ticket/getAll",                         data),
    "getOne"                : (data) => Ajax.post("/ticket/getOne",                         data),
    "getEditData"           : (data) => Ajax.post("/ticket/getEditData",                    data),
    "create"                : (data) => Ajax.post("/ticket/create",                         data),
    "edit"                  : (data) => Ajax.post("/ticket/edit",                           data),
    "delete"                : (data) => Ajax.post("/ticket/delete",                         data),
    "assign"                : (data) => Ajax.post("/ticket/assign",                         data),
    "open"                  : (data) => Ajax.post("/ticket/open",                           data),
    "wait"                  : (data) => Ajax.post("/ticket/wait",                           data),
    "close"                 : (data) => Ajax.post("/ticket/close",                          data),
    "mute"                  : (data) => Ajax.post("/ticket/mute",                           data),
    "unmute"                : (data) => Ajax.post("/ticket/unmute",                         data),
};
export const TicketMessage = {
    "add"                   : (data) => Ajax.post("/ticket/message/add",                    data),
    "edit"                  : (data) => Ajax.post("/ticket/message/edit",                   data),
    "delete"                : (data) => Ajax.post("/ticket/message/delete",                 data),
    "deleteImage"           : (data) => Ajax.post("/ticket/message/deleteImage",            data),
};
export const TicketType = {
    "getAll"                : (data) => Ajax.post("/ticket/type/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/ticket/type/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/ticket/type/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/ticket/type/edit",                      data),
    "delete"                : (data) => Ajax.post("/ticket/type/delete",                    data),
};
export const TicketWaitlist = {
    "getAll"                : (data) => Ajax.post("/ticket/waitlist/getAll",                data),
    "getOne"                : (data) => Ajax.post("/ticket/waitlist/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/ticket/waitlist/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/ticket/waitlist/edit",                  data),
    "delete"                : (data) => Ajax.post("/ticket/waitlist/delete",                data),
};
export const TicketAlertType = {
    "getAll"                : (data) => Ajax.post("/ticket/alertType/getAll",               data),
    "getOne"                : (data) => Ajax.post("/ticket/alertType/getOne",               data),
    "getEditData"           : (data) => Ajax.post("/ticket/alertType/getEditData",          data),
    "edit"                  : (data) => Ajax.post("/ticket/alertType/edit",                 data),
    "delete"                : (data) => Ajax.post("/ticket/alertType/delete",               data),
};
export const Document = {
    "getAll"                : (data) => Ajax.post("/document/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/document/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/document/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/document/edit",                         data),
    "save"                  : (data) => Ajax.post("/document/save",                         data),
    "delete"                : (data) => Ajax.post("/document/delete",                       data),
};



// Setup Actions
export const Announcement = {
    "getAll"                : (data) => Ajax.post("/announcement/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/announcement/getOne",                   data),
    "edit"                  : (data) => Ajax.post("/announcement/edit",                     data),
    "delete"                : (data) => Ajax.post("/announcement/delete",                   data),
};
export const Plan = {
    "getAll"                : (data) => Ajax.post("/plan/getAll",                           data),
    "getOne"                : (data) => Ajax.post("/plan/getOne",                           data),
    "edit"                  : (data) => Ajax.post("/plan/edit",                             data),
    "delete"                : (data) => Ajax.post("/plan/delete",                           data),
};
export const Addon = {
    "getAll"                : (data) => Ajax.post("/addon/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/addon/getOne",                          data),
    "edit"                  : (data) => Ajax.post("/addon/edit",                            data),
};
export const Admin = {
    "getAll"                : (data) => Ajax.post("/admin/getAll",                          data),
    "getOne"                : (data) => Ajax.post("/admin/getOne",                          data),
    "edit"                  : (data) => Ajax.post("/admin/edit",                            data),
    "delete"                : (data) => Ajax.post("/admin/delete",                          data),
};
export const Language = {
    "getAll"                : (data) => Ajax.post("/language/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/language/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/language/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/language/edit",                         data),
    "delete"                : (data) => Ajax.post("/language/delete",                       data),
};
export const Country = {
    "getAll"                : (data) => Ajax.get("/country/getAll",                         data),
    "getOne"                : (data) => Ajax.get("/country/getOne",                         data),
    "edit"                  : (data) => Ajax.post("/country/edit",                          data),
};
export const Province = {
    "getAll"                : (data) => Ajax.get("/province/getAll",                        data),
    "getOne"                : (data) => Ajax.get("/province/getOne",                        data),
    "edit"                  : (data) => Ajax.post("/province/edit",                         data),
};
export const Media = {
    "getAll"                : (data) => Ajax.post("/media/getAll",                          data),
    "create"                : (data) => Ajax.post("/media/create",                          data),
    "upload"                : (data) => Ajax.post("/media/upload",                          data),
    "rename"                : (data) => Ajax.post("/media/rename",                          data),
    "move"                  : (data) => Ajax.post("/media/move",                            data),
    "delete"                : (data) => Ajax.post("/media/delete",                          data),
};



// Email Actions
export const EmailTemplate = {
    "getAll"                : (data) => Ajax.post("/email/template/getAll",                 data),
    "getOne"                : (data) => Ajax.post("/email/template/getOne",                 data),
};
export const EmailQueue = {
    "getAll"                : (data) => Ajax.get("/email/queue/getAll",                     data),
    "getOne"                : (data) => Ajax.get("/email/queue/getOne",                     data),
    "resend"                : (data) => Ajax.get("/email/queue/resend",                     data),
};
export const EmailWhiteList = {
    "getAll"                : (data) => Ajax.get("/email/whiteList/getAll",                 data),
    "getOne"                : (data) => Ajax.get("/email/whiteList/getOne",                 data),
    "edit"                  : (data) => Ajax.post("/email/whiteList/edit",                  data),
    "remove"                : (data) => Ajax.post("/email/whiteList/remove",                data),
};



// Log Actions
export const NotificationLog = {
    "getAll"                : (data) => Ajax.get("/log/notification/getAll",                data),
    "getOne"                : (data) => Ajax.get("/log/notification/getOne",                data),
};
export const ProviderLog = {
    "getAll"                : (data) => Ajax.post("/log/provider/getAll",                   data),
    "getAllForClient"       : (data) => Ajax.post("/log/provider/getAllForClient",          data),
    "getAllForOwner"        : (data) => Ajax.post("/log/provider/getAllForOwner",           data),
    "getAllForCampaign"     : (data) => Ajax.post("/log/provider/getAllForCampaign",        data),
    "getOne"                : (data) => Ajax.post("/log/provider/getOne",                   data),
};
export const ApplicationLog = {
    "getAll"                : (data) => Ajax.post("/log/application/getAll",                data),
    "getAllForClient"       : (data) => Ajax.post("/log/application/getAllForClient",       data),
    "getAllForFlow"         : (data) => Ajax.post("/log/application/getAllForFlow",         data),
    "getAllForAutomation"  : (data) => Ajax.post("/log/application/getAllForAutomation",    data),
    "getAllForOwner"        : (data) => Ajax.post("/log/application/getAllForOwner",        data),
    "getOne"                : (data) => Ajax.post("/log/application/getOne",                data),
};
export const ActionLog = {
    "getAll"                : (data) => Ajax.post("/log/action/getAll",                     data),
    "getAllForClient"       : (data) => Ajax.post("/log/action/getAllForClient",            data),
    "getAllForOwner"        : (data) => Ajax.post("/log/action/getAllForOwner",             data),
    "getAllForUser"         : (data) => Ajax.post("/log/action/getAllForUser",              data),
};
export const QueryLog = {
    "getAll"                : (data) => Ajax.post("/log/query/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/log/query/getOne",                      data),
    "markResolved"          : (data) => Ajax.post("/log/query/markResolved",                data),
    "delete"                : (data) => Ajax.post("/log/query/delete",                      data),
};
export const ErrorLog = {
    "getAll"                : (data) => Ajax.post("/log/error/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/log/error/getOne",                      data),
    "markResolved"          : (data) => Ajax.post("/log/error/markResolved",                data),
    "delete"                : (data) => Ajax.post("/log/error/delete",                      data),
};
