import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";
import { Sale }             from "Utils/API";



// The initial State
const initialState = {
    loading          : true,
    error            : false,
    edition          : 0,
    canCreate        : false,
    canEdit          : false,
    canImport        : false,
    canExport        : false,
    canFilter        : false,
    list             : [],
    total            : 0,
    elem             : {
        id : 0,
    },
    contact          : {
        id     : 0,
        fields : [],
        tags   : [],
    },
    conversations    : [],
    editElem         : {},
    orderStatuses    : [],
    paymentStatuses  : [],
    shippingStatuses : [],
    filters          : {},
    hasFilters       : false,
    sort             : {
        orderBy  : "orderTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SALE_LOADING" });
    },

    /**
     * Fetches the Sale List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await Sale.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "SALE_LIST", data });
    },

    /**
     * Fetches a single Sale
     * @param {Function} dispatch
     * @param {Number}   saleID
     * @returns {Promise}
     */
    async fetchElem(dispatch, saleID) {
        const data = await Sale.getOne({ saleID });
        dispatch({ type : "SALE_ELEM", data });
        return data;
    },

    /**
     * Removes the single Sale
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "SALE_REMOVE" });
    },

    /**
     * Fetches the Sale Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchFilterData(dispatch, clientID) {
        const data = await Sale.getFilterData({ clientID });
        dispatch({ type : "SALE_FILTER", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SALE_LIST", "SALE_ELEM", "SALE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SALE_LOADING":
        return {
            ...state,
            loading           : true,
        };

    case "SALE_LIST":
        return {
            ...state,
            loading           : false,
            error             : false,
            canCreate         : action.data.canCreate,
            canEdit           : action.data.canEdit,
            canImport         : action.data.canImport,
            canExport         : action.data.canExport,
            canFilter         : action.data.canFilter,
            list              : Utils.parseList(action.data.list, Commons.parseSale),
            total             : action.data.total,
            filters           : action.data.filters,
            hasFilters        : !Utils.isEmpty(action.data.filters),
            sort              : action.data.sort,
        };

    case "SALE_ELEM":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            elem             : Commons.parseSale(action.data.elem),
            contact          : Commons.parseContact(action.data.contact),
            conversations    : Conversations.parseList(action.data.conversations),
        };

    case "SALE_FILTER":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            orderStatuses    : action.data.orderStatuses,
            paymentStatuses  : action.data.paymentStatuses,
            shippingStatuses : action.data.shippingStatuses,
        };

    case "SALE_REMOVE":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            elem             : { ...initialState.elem },
            contact          : { ...initialState.contact },
            conversations    : [],
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
