import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ApplicationInput     from "./ApplicationInput";
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Container = Styled(Columns)`
    max-width: var(--provider-width);
    margin: 0 auto;
`;

const Title = Styled.h3`
    margin: var(--main-gap) 0;
    font-weight: 400;
`;



/**
 * The Application Config
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ApplicationConfig(props) {
    const { application, data, errors, onChange } = props;

    const { statuses } = Store.useState("application");


    // Do the Render
    if (!application.applicationCode) {
        return <React.Fragment />;
    }
    return <Container amount="1">
        <ProviderButton
            icon={application.icon}
            color={application.color}
            message={application.fullName}
        />
        <Title>{NLS.get(application.description)}</Title>

        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={onChange}
            isRequired
        />
        <>
            {application.inputs.map((item) => <ApplicationInput
                {...item}
                key={item.name}
                type={item.type}
                value={data[item.name] ?? ""}
                error={errors[item.name] ?? ""}
                onChange={onChange}
            />)}
        </>
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={onChange}
            hideClear
            isRequired
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ApplicationConfig.propTypes = {
    application : PropTypes.object.isRequired,
    data        : PropTypes.object.isRequired,
    errors      : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired,
};

export default ApplicationConfig;
