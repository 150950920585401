import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import CustomFields         from "Utils/CustomFields";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import TextItem             from "Components/Utils/Inputs/TextItem";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Email Design Input
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignInput(props) {
    const { action, item, data, errors, language, onChange } = props;

    const { elem } = Store.useState("emailDesisgn");
    const { variables, selects, channelLinks } = Store.useState("emailDesisgnEditor");


    // Variables
    const isHidden   = item.hide ? eval(item.hide)(data, selects) : false;
    const type       = CustomFields.getInputType(item, data, selects);
    const isView     = type === "view";
    const isMedia    = type === "media";
    const isText     = type === "text";
    const isTextarea = type === "textarea";
    const isEditor   = type === "editor";
    const isFields   = type === "fields";
    const isInput    = !isView && !isMedia && !isText && !isTextarea && !isEditor && !isFields;
    const items      = item.fields ? item.fields : [];


    // Handles the Change
    const handleChange = (name, value) => {
        if (!item.useLanguage) {
            onChange(name, value);
            return;
        }

        if (!data[name] || !Utils.isObject(data[name])) {
            data[name] = {};
        }
        data[name][language] = value;
        onChange(name, data[name]);
    };

    // Handles the Initial Value
    React.useEffect(() => {
        if (!isView && !isHidden && !value && item.getValue) {
            handleChange(item.name, eval(item.getValue)(data));
        }
    }, [ isHidden ]);


    // Returns the value for the given field
    const value = React.useMemo(() => {
        if (!item.useLanguage) {
            return data[item.name] || "";
        }
        if (!data[item.name] || !Utils.isObject(data[item.name])) {
            return "";
        }
        return data[item.name][language] || "";
    }, [ JSON.stringify(data), language, item.name ]);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <section>
        {isView && <ViewField
            {...item}
            value={eval(item.getValue)(elem)}
            showEmpty
        />}

        {isMedia && <MediaField
            {...item}
            clientID={action.clientID}
            value={value}
            error={errors[item.name] || ""}
            onChange={handleChange}
        />}

        {isText && <TextField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variables}
            onChange={handleChange}
            withVariable
        />}

        {isTextarea && <TextareaField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variables}
            channelLinks={channelLinks}
            onChange={handleChange}
        />}

        {isFields && <InputField
            {...item}
            type={type}
            value={value}
            error={errors[item.name] || ""}
            errors={errors}
            onChange={handleChange}
        >
            {items.map((field) => <InputItem
                {...field}
                key={field.key || field.name}
                subKey={field.key || field.name}
                hide={field.hide ? eval(field.hide) : undefined}
                getValue={field.getValue ? (elem) => eval(field.getValue)(elem) : undefined}
                getType={(elem) => CustomFields.getInputType(field, elem, selects)}
                getOptions={(elem) => CustomFields.getOptions(field, { ...data, ...elem }, selects)}
                withBorder
                hasClear
            >
                {field.withChildren && <TextItem
                    variables={variables}
                />}
            </InputItem>)}
        </InputField>}

        {isInput && <InputField
            {...item}
            type={type}
            value={value}
            error={errors[item.name] || ""}
            errors={errors}
            options={CustomFields.getOptions(item, data, selects)}
            onChange={handleChange}
        >
            {item.withChildren && <TextItem
                variables={variables}
            />}
        </InputField>}
    </section>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignInput.propTypes = {
    action   : PropTypes.object.isRequired,
    item     : PropTypes.object.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    language : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
    onMedia  : PropTypes.func.isRequired,
};

export default EmailDesignInput;
