import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Email Design Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignEdit(props) {
    const { open, isCopy, elemID, clientID, onClose, onSubmit } = props;

    const { editElem } = Store.useState("emailDesign");
    const { fetchEditData, editEmailDesign, copyEmailDesign } = Store.useAction("emailDesign");


    // The Initial Data
    const initialData = {
        clientID      : 0,
        emailDesignID : 0,
        name          : "",
    };

    // Handles the Set
    const handleSet = () => {
        setElem({ ...editElem, clientID });
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyEmailDesign(data);
        }
        return editEmailDesign(data);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("emailDesign", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("emailDesign", open, elemID, { elemID, clientID }, handleSet, fetchEditData);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "EMAIL_DESIGNS_COPY_TITLE";
        }
        if (elemID) {
            return "EMAIL_DESIGNS_EDIT_TITLE";
        }
        return "EMAIL_DESIGNS_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="email"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default EmailDesignEdit;
