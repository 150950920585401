import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import Hooks                from "Utils/Hooks";

// Components
import ApplicationHeader    from "./View/ApplicationHeader";
import ApplicationSelect    from "./View/ApplicationSelect";
import ApplicationConfig    from "./View/ApplicationConfig";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Application Page
 * @returns {React.ReactElement}
 */
function ApplicationPage() {
    const { applicationID } = Navigate.useParams();

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(applicationID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, applications } = Store.useState("application");
    const { fetchElem, fetchEditData, editApplication, deleteApplication } = Store.useAction("application");


    // The Initial Data
    const initialData = {
        applicationID   : 0,
        clientID        : clientID,
        applicationCode : "",
        name            : "",
        status          : "Active",
    };

    // The Current State
    const [ step,        setStep        ] = React.useState(!isEdit ? "select" : "config");
    const [ application, setApplication ] = React.useState({ applicationCode : "" });
    const [ showDelete,  setShowDelete  ] = React.useState(false);

    // Handles the Edit
    const handleEdit = (response) => {
        if (response.success) {
            navigate(parent);
        }
    };

    // The Form State
    const {
        loading, data, errors, setData,
        resetData, resetErrors, handleChange, handleSubmit,
    } = useForm("application", initialData, editApplication, handleEdit);


    // Load the Data
    React.useEffect(() => {
        if (applicationID) {
            fetchElem(applicationID);
        } else {
            fetchEditData(clientID);
        }
    }, []);

    // Store the Data
    React.useEffect(() => {
        if (isEdit) {
            const application = Utils.getValue(applications, "applicationCode", elem.applicationCode);
            if (application.applicationCode) {
                setApplication(application);
            } else {
                setApplication({ applicationCode : "" });
            }
            setData({ ...elem, ...elem.providerData });
        } else {
            resetErrors();
            resetData();
        }
    }, [ edition ]);


    // Handles the Select
    const handleSelect = (application) => {
        setData({ applicationCode : application.applicationCode });
        resetErrors();
        setApplication(application);
        setStep("config");
    };

    // Handles the Delete
    const handleDelete = async () => {
        setShowDelete(false);
        const response = await deleteApplication(elem.id);
        if (response.success) {
            navigate(goto);
        }
    };

    // Handles the Close
    const handleClose = () => {
        navigate(parent);
    };



    // Do the Render
    return <Main withNavigation>
        <ApplicationHeader
            data={data}
            href={goto}
            isEdit={isEdit}
        />

        <Content isLoading={loading || charging}>
            <PageContainer error={errors.form}>
                <PageAccordion
                    selected={step}
                    onChange={setStep}
                    noClose
                >
                    <AccordionItem
                        isHidden={isEdit}
                        value="select"
                        message="APPLICATIONS_SINGULAR"
                        description="APPLICATIONS_PROVIDER_TEXT"
                    >
                        <ApplicationSelect
                            selected={application.applicationCode}
                            onSelect={handleSelect}
                        />
                    </AccordionItem>

                    <AccordionItem
                        value="config"
                        message="APPLICATIONS_CONFIG_TITLE"
                        description="APPLICATIONS_CONFIG_TEXT"
                        isDisabled={!data.applicationCode}
                    >
                        <ApplicationConfig
                            application={application}
                            data={data}
                            errors={errors}
                            onChange={handleChange}
                        />
                    </AccordionItem>
                </PageAccordion>

                <PageButtons
                    isEdit={isEdit}
                    isDisabled={!data.applicationCode}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </PageContainer>
        </Content>

        <DeleteDialog
            open={showDelete}
            title="APPLICATIONS_DELETE_TITLE"
            message="APPLICATIONS_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default ApplicationPage;
