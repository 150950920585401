import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Application Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ApplicationLogView(props) {
    const { open, isAdmin, isFlow, isAutomation, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("applicationLog", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="application-log"
        title="APPLICATIONS_LOG_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns lastDouble>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdTimeText}
            />
            <ViewField
                label="GENERAL_RESULT"
                value={elem.errorText}
                viewClass={elem.errorClass}
            />
            <ViewField
                isHidden={!isAdmin}
                label="CLIENTS_SINGULAR"
                value={elem.clientName}
                showEmpty
            />
            <ViewField
                isHidden={isFlow || !elem.flowID}
                label="FLOWS_SINGULAR"
                value={elem.flowName}
            />
            <ViewField
                isHidden={isAutomation || !elem.automationID}
                label="AUTOMATIONS_SINGULAR"
                value={elem.automationName}
            />
            <ViewField
                isHidden={!elem.conversationID}
                label="CONVERSATIONS_SINGULAR"
                value={elem.conversationText}
                showEmpty
            />

            <ViewField
                isHidden={!elem.applicationID}
                label="APPLICATIONS_SINGULAR"
                value={elem.applicationName}
            />
            <ViewField
                label="APPLICATIONS_SINGULAR"
                value={elem.applicationTypeName}
            />
            <ViewField
                label="APPLICATIONS_LOG_METHOD"
                value={elem.method}
            />
        </Columns>

        <ViewField
            label="GENERAL_URL"
            value={elem.url}
        />
        <ViewField
            label="APPLICATIONS_LOG_USER_PASSWORD"
            value={elem.userPass}
        />
        <ViewField
            label="APPLICATIONS_LOG_HEADERS"
            value={Utils.jsonToHtml(elem.headers)}
        />
        <ViewField
            label="APPLICATIONS_LOG_PARAMS"
            value={Utils.jsonToHtml(elem.params)}
        />
        <ViewField
            label="GENERAL_RESPONSE"
            value={Utils.jsonToHtml(elem.response)}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ApplicationLogView.propTypes = {
    open         : PropTypes.bool.isRequired,
    isAdmin      : PropTypes.bool.isRequired,
    isFlow       : PropTypes.bool.isRequired,
    isAutomation : PropTypes.bool.isRequired,
    onClose      : PropTypes.func.isRequired,
    elemID       : PropTypes.number,
};

export default ApplicationLogView;
