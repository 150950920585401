import React                from "react";
import PropTypes            from "prop-types";

// Components
import ColumnsElement       from "../Element/ColumnsElement";
import TextElement          from "../Element/TextElement";
import ImageElement         from "../Element/ImageElement";
import ButtonElement        from "../Element/ButtonElement";
import DividerElement       from "../Element/DividerElement";
import SpacerElement        from "../Element/SpacerElement";
import SocialElement        from "../Element/SocialElement";
import UnsubscribeElement   from "../Element/UnsubscribeElement";



/**
 * The Email Design Element
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignElement(props) {
    const { type, elem, setDropElement } = props;

    switch (type) {
    case "Columns":
        return <ColumnsElement elem={elem} setDropElement={setDropElement} />;
    case "Text":
        return <TextElement elem={elem} />;
    case "Image":
        return <ImageElement elem={elem} />;
    case "Button":
        return <ButtonElement elem={elem} />;
    case "Divider":
        return <DividerElement elem={elem} />;
    case "Spacer":
        return <SpacerElement elem={elem} />;
    case "Social":
        return <SocialElement elem={elem} />;
    case "Unsubscribe":
        return <UnsubscribeElement elem={elem} />;
    default:
        return <React.Fragment />;
    }
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignElement.propTypes = {
    type           : PropTypes.string.isRequired,
    elem           : PropTypes.object,
    setDropElement : PropTypes.func.isRequired,
};

export default EmailDesignElement;
