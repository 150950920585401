import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Hooks                from "Utils/Hooks";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Widget Platform Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetPlatform(props) {
    const { open, elemID, widgetID, onClose, onSubmit } = props;

    const navigate = Hooks.useGoto();

    const { channels, providers } = Store.useState("widgetPlatform");
    const { editPlatform } = Store.useAction("widgetPlatform");


    // The Initial Data
    const initialData = {
        widgetID     : 0,
        platformID   : 0,
        channelID    : 0,
        providerCode : "",
        isLink       : 0,
        name         : "",
        url          : "",
        showQR       : 0,
        position     : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("widgetPlatform", initialData, editPlatform, onSubmit);

    // Load the Data
    const { loading } = useDialog("widgetPlatform", open, elemID, { widgetID }, setElem);

    // Handles the Click
    const handleClick = () => {
        navigate("SETTINGS", "CHANNELS", "ADD");
    };



    // Do the Render
    const showLink     = Boolean(data.isLink);
    const showChannels = Boolean(channels.length > 0);

    return <EditDialog
        open={open}
        icon="channel"
        title={elemID ? "WIDGETS_PLATFORMS_EDIT_TITLE" : "WIDGETS_PLATFORMS_ADD_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="isLink"
            label="WIDGETS_PLATFORMS_IS_LINK"
            value={data.isLink}
            error={errors.isLink}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={showLink || !showChannels}
            type="select"
            name="channelID"
            label="CHANNELS_SINGULAR"
            value={data.channelID}
            error={errors.channelID}
            options={channels}
            onChange={handleChange}
            isRequired
        />
        <ViewField
            isHidden={showLink || showChannels}
            label="CHANNELS_SINGULAR"
            message="WIDGETS_PLATFORMS_NONE_CHANNELS"
            onClick={handleClick}
        />
        <InputField
            isHidden={!showLink}
            type="select"
            name="providerCode"
            label="WIDGETS_PLATFORMS_SINGULAR"
            value={data.providerCode}
            error={errors.providerCode}
            options={providers}
            onChange={handleChange}
            isRequired
        />

        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />
        <InputField
            isHidden={!showLink}
            name="url"
            label="WIDGETS_PLATFORMS_LINK_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="toggle"
            name="showQR"
            label="WIDGETS_PLATFORMS_SHOW_QR"
            value={data.showQR}
            error={errors.showQR}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetPlatform.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    widgetID : PropTypes.number,
};

export default WidgetPlatform;
