import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import useFilter            from "Dashboard/Hooks/Filter";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Conversation Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationFilter(props) {
    const { open, clientID, initialData, onClose } = props;
    const { isAgent, isAnyAdmin } = Store.useState("auth");

    const {
        filters, sort, providers, tags, tongues, hotels, campaigns,
        myTeams, users, usersInTeams,
    } = Store.useState("conversation");

    const { saveFilters } = Store.useAction("conversation");


    // Handles the Filter Submit
    const onSubmit = (filters) => {
        saveFilters({
            clientID : clientID,
            status   : sort.filter,
            filters  : JSON.stringify({ ...initialData, ...filters }),
        });
        onClose();
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("conversation", open, initialData, filters, onSubmit, { clientID });


    // Variables
    const isQueue       = sort.filter === "Queue";
    const isProgress    = sort.filter === "Progress";
    const isFollowup    = sort.filter === "Followup";
    const isResolved    = sort.filter === "Resolved";

    const showProviders = providers.length > 1;
    const showHotels    = hotels.length > 0;
    const showCampaigns = campaigns.length > 0;
    const showUserID    = !isQueue && !isAgent && !data.onlyMine;
    const showOnlyMine  = !isAnyAdmin && !isQueue && (!isAgent || (!isProgress && !isFollowup && !isResolved));

    const getUsers      = Utils.useSelectList(loading, data.teamID, users, usersInTeams);


    // Do the Render
    return <FilterDialog
        open={open}
        title="CONVERSATIONS_FILTER_TITLE"
        initialData={initialData}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="select"
            name="order"
            label="CONVERSATIONS_ORDER_BY"
            options="SELECT_CONVERSATION_ORDERS"
            value={data.order}
            onChange={handleChange}
        />
        <InputField
            isHidden={!showProviders}
            type="chooser"
            name="providerName"
            label="WIDGETS_PLATFORMS_NAME"
            options={Utils.toSelect(providers)}
            value={data.providerName}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="tagID"
            label="TAGS_NAME"
            options={tags}
            value={data.tagID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="tongueID"
            label="LANGUAGES_NAME"
            options={tongues}
            value={data.tongueID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
        />
        <InputField
            isHidden={!showHotels}
            type="chooser"
            name="hotelID"
            label="HOTELS_NAME"
            options={hotels}
            value={data.hotelID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
        />
        <InputField
            isHidden={!showCampaigns}
            type="chooser"
            name="campaignID"
            label="CAMPAIGNS_NAME"
            options={campaigns}
            value={data.campaignID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
        />

        <InputField
            type="chooser"
            name="teamID"
            label="TEAMS_NAME"
            options={myTeams}
            value={data.teamID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
        />
        <InputField
            isHidden={!showUserID}
            type="chooser"
            name="userID"
            label="USERS_NAME"
            options={getUsers()}
            value={data.userID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!showOnlyMine}
            type="toggle"
            name="onlyMine"
            label="GENERAL_ONLY_MINE"
            value={!!data.onlyMine}
            onChange={handleChange}
            withBorder
        />

        <InputField
            type="chooser"
            name="qualification"
            label="CONVERSATIONS_QUALIFICATION"
            options="SELECT_CONVERSATION_QUALIFICATIONS"
            value={data.qualification}
            onChange={handleChange}
            noneText="GENERAL_NONE_FEM"
            noneValue="none"
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationFilter.propTypes = {
    open        : PropTypes.bool.isRequired,
    clientID    : PropTypes.number.isRequired,
    initialData : PropTypes.object.isRequired,
    onClose     : PropTypes.func.isRequired,
};

export default ConversationFilter;
