import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Prices
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizePrices(props) {
    const { onChange } = props;

    const { data } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="toggle"
            name="pricesInLists"
            label="PERSONALIZE_PRICES_IN_LISTS"
            value={Number(data.pricesInLists)}
            onChange={onChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="pricesInDialogs"
            label="PERSONALIZE_PRICES_IN_DIALOGS"
            value={Number(data.pricesInDialogs)}
            onChange={onChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="pricesInCart"
            label="PERSONALIZE_PRICES_IN_CART"
            value={Number(data.pricesInCart)}
            onChange={onChange}
            withBorder
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizePrices.propTypes = {
    onChange : PropTypes.func.isRequired,
};

export default PersonalizePrices;
