import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



// Styles
const Grid = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Client Edit Applications
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditApplications(props) {
    const { isHidden, data, errors, onChange } = props;

    const { applications } = Store.useState("client");


    // Handles the Application
    const handleApplication = (applicationCode, name, value) => {
        const oldData = data.applications[applicationCode];
        const newData = { ...oldData, [name] : value };

        if (name === "isActive") {
            const quantity = oldData?.quantity ?? 0;
            if (value !== 0 && quantity === 0) {
                newData.quantity = 1;
            } else if (value === 0 && quantity === 1) {
                newData.quantity = 0;
            }
        }

        const applications = { ...data.applications, [applicationCode] : newData };
        onChange("applications", applications);
    };


    // Do the Render
    return <Grid isHidden={isHidden}>
        <Columns amount="5">
            <>
                {applications.map(({ key, value }) => <React.Fragment key={key}>
                    <InputField
                        className="columns-double"
                        type="toggle"
                        name={`applicationActive-${key}`}
                        label={value}
                        value={data.applications[key]?.isActive || 0}
                        onChange={(name, value) => handleApplication(key, "isActive", value)}
                        withBorder
                    />
                    <InputField
                        className="columns-double"
                        type="double"
                        label="GENERAL_PRICE"
                        error={errors[`applicationPrice-${key}`]}
                    >
                        <InputItem
                            type="number"
                            name={`applicationPrice-${key}`}
                            icon="money"
                            value={data.applications[key]?.price || ""}
                            minValue={0}
                            onChange={(name, value) => handleApplication(key, "price", value)}
                        />
                        <InputItem
                            type="checkbox"
                            name={`applicationPriceFree-${key}`}
                            label="GENERAL_FREE"
                            value={data.applications[key]?.priceFree || 0}
                            onChange={(name, value) => handleApplication(key, "priceFree", value)}
                        />
                    </InputField>
                    <InputField
                        type="number"
                        name={`applicationQuantity-${key}`}
                        label="GENERAL_AMOUNT"
                        value={data.applications[key]?.quantity || 0}
                        error={errors[`applicationQuantity-${key}`]}
                        onChange={(name, value) => handleApplication(key, "quantity", value)}
                        minValue={0}
                    />
                </React.Fragment>)}
            </>
        </Columns>

        <Columns topSpace="12" amount="1">
            <InputField
                type="number"
                name="automationLimit"
                label="CLIENTS_AUTOMATION_LIMIT"
                value={data.automationLimit}
                error={errors.automationLimit}
                onChange={onChange}
            />
        </Columns>
    </Grid>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditApplications.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ClientEditApplications;
