const strings = {

    TITLE                                   : "Conversana",
    LANGUAGES                               : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ACCEPT                          : "Aceptar",
    GENERAL_ACTION                          : "Acción",
    GENERAL_ACTIONS                         : "Acciones",
    GENERAL_ACCESS                          : "Rol",
    GENERAL_ACTIVATE                        : "Activar",
    GENERAL_ADD                             : "Agregar",
    GENERAL_ADD_BUTTON                      : "Agregar botón",
    GENERAL_ADD_CONDITION                   : "Agregar condición",
    GENERAL_ADD_EMAIL                       : "Agregar email",
    GENERAL_ADD_EVENT                       : "Agregar evento",
    GENERAL_ADD_FIELD                       : "Agregar campo",
    GENERAL_ADD_ITEM                        : "Agregar elemento",
    GENERAL_ADD_OPTION                      : "Agregar opción",
    GENERAL_ADDRESS                         : "Domicilio",
    GENERAL_ALL                             : "Todos",
    GENERAL_ALL_FEM                         : "Todas",
    GENERAL_AMOUNT                          : "Cantidad",
    GENERAL_AND                             : "y",
    GENERAL_ANY                             : "Cualquiera",
    GENERAL_APPLY                           : "Aplicar",
    GENERAL_ASSIGN                          : "Asignar",
    GENERAL_BEFORE_OR_AFTER                 : "Antes o después",
    GENERAL_BUTTONS                         : "Botones",
    GENERAL_CANCEL                          : "Cancelar",
    GENERAL_CANCEL_REASON                   : "Motivo de cancelación",
    GENERAL_CELLPHONE                       : "Celular",
    GENERAL_CHARACTERS                      : "Caracteres: {0}",
    GENERAL_CHARACTERS_MAX                  : "Caracteres: {0}/{1}",
    GENERAL_CHAT                            : "Chat",
    GENERAL_CLEAR                           : "Borrar",
    GENERAL_CLEAR_CHANGES                   : "Borrar cambios",
    GENERAL_CLOSE                           : "Cerrar",
    GENERAL_CODE                            : "Código",
    GENERAL_COLOR                           : "Color",
    GENERAL_COMPLETE                        : "Completar",
    GENERAL_CONDITION                       : "Condición",
    GENERAL_CONDITION_TITLE                 : "Condición {0}",
    GENERAL_CONTINUE                        : "Continuar",
    GENERAL_COPY                            : "Copiar",
    GENERAL_CREATE                          : "Crear",
    GENERAL_CREATED                         : "Creado el",
    GENERAL_CREATED_FEM                     : "Creada el",
    GENERAL_CREATED_TIME                    : "Fecha de creación",
    GENERAL_DATE                            : "Fecha",
    GENERAL_DAY                             : "Día",
    GENERAL_DAYS                            : "Días",
    GENERAL_DELETE                          : "Eliminar",
    GENERAL_DESCRIPTION                     : "Descripción",
    GENERAL_DETAILS                         : "Detalles",
    GENERAL_DISCARD                         : "Descartar",
    GENERAL_DOWNLOAD                        : "Descargar",
    GENERAL_DURATION                        : "Duración",
    GENERAL_EDIT                            : "Editar",
    GENERAL_EDIT_COLUMNS                    : "Editar columnas",
    GENERAL_EDITOR                          : "Editor",
    GENERAL_EMAIL                           : "Email",
    GENERAL_EMAIL_CHANNEL                   : "Canal de Email",
    GENERAL_END                             : "Finalización",
    GENERAL_ENVIRONMENT                     : "Entorno",
    GENERAL_EXECUTE_AFTER                   : "Ejecutar luego de",
    GENERAL_EXECUTIONS                      : "Ejecuciones",
    GENERAL_EXPORT                          : "Exportar",
    GENERAL_EXTERNAL_ID                     : "Código",
    GENERAL_FAIL                            : "Fallido",
    GENERAL_FANTASY_NAME                    : "Nombre de fantasía",
    GENERAL_FIELD                           : "Campo",
    GENERAL_FIELD_SEARCH                    : "Campo donde buscar",
    GENERAL_FIELD_TITLE                     : "Campo {0}",
    GENERAL_FILE                            : "Archivo",
    GENERAL_FILTER                          : "Filtrar",
    GENERAL_FIRST_NAME                      : "Nombre",
    GENERAL_FORMAT                          : "Formato de texto",
    GENERAL_FORMAT_BOLD                     : "Negrita",
    GENERAL_FORMAT_ITALIC                   : "Cursiva",
    GENERAL_FORMAT_STRIKETHROUGH            : "Tachado",
    GENERAL_FORMAT_CODE                     : "Código",
    GENERAL_FREE                            : "Bonificado",
    GENERAL_FROM                            : "Desde",
    GENERAL_FROM_DATE                       : "Fecha desde",
    GENERAL_FROM_HOUR                       : "Hora desde",
    GENERAL_FULL_NAME                       : "Nombre y apellido",
    GENERAL_GET_CODE                        : "Obtener código",
    GENERAL_HELP                            : "Ayuda",
    GENERAL_HIDE                            : "Ocultar",
    GENERAL_HOUR                            : "Hora",
    GENERAL_HOURS                           : "Horas",
    GENERAL_ID                              : "ID",
    GENERAL_IMAGE                           : "Imagen",
    GENERAL_IMPORT                          : "Importar",
    GENERAL_IMPORTING                       : "Importando: <b>{0}</b> de <b>{1}</b>",
    GENERAL_IMPORT_LINES                    : "Se van a importar los filas de <b>{0}</b> &nbsp; a &nbsp; <b>{1}</b>.",
    GENERAL_INFO                            : "Información",
    GENERAL_IS_DEFAULT                      : "Usar por defecto",
    GENERAL_ISO_CODE                        : "Código ISO",
    GENERAL_KEY                             : "Clave",
    GENERAL_LAST_EXECUTION                  : "Última ejecución",
    GENERAL_LAST_NAME                       : "Apellido",
    GENERAL_LINK                            : "Vínculo",
    GENERAL_LOADING                         : "Cargando",
    GENERAL_LOADING_DOTS                    : "Cargando ...",
    GENERAL_LOG                             : "Log",
    GENERAL_LOGIN_AS                        : "Ingresar como usuario",
    GENERAL_LOGIN_TIME                      : "Último ingreso",
    GENERAL_LOGOUT                          : "Salir",
    GENERAL_LOGS                            : "Logs",
    GENERAL_LOCALITY                        : "Localidad / Barrio",
    GENERAL_MAIN                            : "Principal",
    GENERAL_MANAGE                          : "Administrar",
    GENERAL_MATCH                           : "Coincidir",
    GENERAL_MESSAGE                         : "Mensaje",
    GENERAL_MERGE                           : "Combinar",
    GENERAL_MINUTES                         : "Minutos",
    GENERAL_MODIFIED                        : "Modificado el",
    GENERAL_MODIFIED_FEM                    : "Modificada el",
    GENERAL_MODULE                          : "Módulo",
    GENERAL_MOVE                            : "Mover",
    GENERAL_NAME                            : "Nombre",
    GENERAL_NAMES                           : "Nombres",
    GENERAL_NEXT_EXECUTION                  : "Siguiente ejecución",
    GENERAL_NEW_PASSWORD                    : "Nueva contraseña",
    GENERAL_NO                              : "No",
    GENERAL_NO_NAME                         : "Sin nombre",
    GENERAL_NOBODY                          : "Nadie",
    GENERAL_NONE                            : "Ninguno",
    GENERAL_NONE_FEM                        : "Ninguna",
    GENERAL_NONE_RESULTS                    : "No se encontraron resultados",
    GENERAL_OBSERVATIONS                    : "Observaciones",
    GENERAL_ONE_ERROR                       : "Error",
    GENERAL_ONLY_MINE                       : "Solo mías",
    GENERAL_OPEN                            : "Abrir",
    GENERAl_OPERATOR                        : "Operador",
    GENERAL_OPTION                          : "Opción",
    GENERAL_OPTION_TITLE                    : "Opción {0}",
    GENERAL_OPTIONS                         : "Opciones",
    GENERAL_ORIGIN                          : "Origen",
    GENERAL_ORIGINS                         : "Orígenes",
    GENERAL_OTHER                           : "Otro",
    GENERAL_PAGE_OF                         : "{0}-{1} de {2}",
    GENERAL_PAUSE                           : "Pausar",
    GENERAL_PASSWORD                        : "Contraseña",
    GENERAL_PASSWORD_CONFIRM                : "Confirmar contraseña",
    GENERAL_PARENT                          : "Padre",
    GENERAL_PENDING                         : "Pendiente",
    GENERAL_PLATFORM                        : "Plataforma",
    GENERAL_POSITION                        : "Posición",
    GENERAL_PREVIEW                         : "Vista previa",
    GENERAL_PRICE                           : "Precio",
    GENERAL_PRINT                           : "Imprimir",
    GENERAL_PROVIDER                        : "Proveedor",
    GENERAL_PROVINCE                        : "Provincia / Estado",
    GENERAL_PUBLISH                         : "Publicar",
    GENERAL_PUBLISH_CHANGES                 : "Publicar cambios",
    GENERAL_QUANTITY                        : "Cantidad",
    GENERAL_REQ_PASS_CHANGE                 : "Requerir cambio de contraseña luego de ingresar",
    GENERAL_REMOVE                          : "Quitar",
    GENERAL_REMOVE_FILTER                   : "Quitar filtro",
    GENERAL_REPORT                          : "Reporte",
    GENERAL_RESPONSE                        : "Respuesta",
    GENERAL_RESULT                          : "Resultado",
    GENERAL_REQUEST                         : "Pedido",
    GENERAL_ROWS_PER_PAGE                   : "Filas a mostrar:",
    GENERAL_SAVE                            : "Guardar",
    GENERAL_SEARCH                          : "Buscar",
    GENERAL_SEARCH_ONE                      : "Buscar ...",
    GENERAL_SEARCHING                       : "Buscando ...",
    GENERAL_SECONDS                         : "{0} segundos",
    GENERAL_SELECT                          : "Seleccionar",
    GENERAL_SELECT_FILE                     : "Seleccionar archivo",
    GENERAL_SELECT_IMAGE                    : "Seleccionar imagen",
    GENERAL_SELECT_PDF                      : "Seleccionar PDF",
    GENERAL_SELECT_VIDEO                    : "Seleccionar video",
    GENERAL_SEND                            : "Enviar",
    GENERAL_SEND_AT                         : "Enviar el",
    GENERAL_SEND_EMAILS                     : "Enviar emails",
    GENERAL_SEND_EMAIL_NOTIS                : "Enviar notificaciones por email",
    GENERAL_SEND_TICKETS                    : "Enviar nuevos tickets",
    GENERAL_SEND_TO                         : "Enviar a",
    GENERAL_SEND_TO_EMAILS                  : "Emails a quien enviar",
    GENERAL_SENT                            : "Enviado",
    GENERAL_SENT_AT                         : "Enviado el",
    GENERAL_SENT_AT_FEM                     : "Enviada el",
    GENERAL_SETTINGS                        : "Configuración",
    GENERAL_SMS_CHANNEL                     : "Canal de SMS",
    GENERAL_SOCIAL_REASON                   : "Razón social",
    GENERAL_SUCCESS                         : "Exitoso",
    GENERAL_SUBJECT                         : "Asunto",
    GENERAL_SUMMARY                         : "Resumen",
    GENERAL_SUPPORT                         : "Soporte",
    GENERAL_START                           : "Inicio",
    GENERAL_STATUS                          : "Estado",
    GENERAL_TAX_ID                          : "ID fiscal",
    GENERAL_TEMPLATE                        : "Plantilla",
    GENERAL_TIME                            : "Tiempo",
    GENERAL_TIME_ZONE                       : "Huso horario",
    GENERAL_TITLE                           : "Título",
    GENERAL_TYPE                            : "Tipo",
    GENERAL_TO                              : "Hasta",
    GENERAL_TO_DATE                         : "Fecha hasta",
    GENERAL_TO_HOUR                         : "Hora hasta",
    GENERAL_TOTAL                           : "Total",
    GENERAL_TOTAL_DURATION                  : "Duración total",
    GENERAL_UPLOAD                          : "Subir",
    GENERAL_URL                             : "URL",
    GENERAL_USER                            : "Usuario",
    GENERAL_VALUE                           : "Valor",
    GENERAL_VARIABLES                       : "Variables",
    GENERAL_VIEW                            : "Ver",
    GENERAL_VIEW_MORE                       : "Ver más",
    GENERAL_VIEW_REFERRAL                   : "Ver referido",
    GENERAL_WHATSAPP_CHANNEL                : "Canal de WhatsApp",
    GENERAL_YES                             : "Si",
    GENERAL_ZIP_CODE                        : "Código postal",

    GENERAL_ERROR                           : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    GENERAL_ERROR_PATH                      : "Ruta incorrecta.",
    GENERAL_ERROR_FORMAT                    : "<b class='text-red'>{0}:</b> {1}",

    GENERAL_ERROR_ACCESS_EMPTY              : "Debe indicar un rol.",
    GENERAL_ERROR_ACCESS_INVALID            : "El rol indicado es incorrecto.",
    GENERAL_ERROR_BEFORE_OR_AFTER           : "Debe indicar antes o después.",
    GENERAL_ERROR_COLOR                     : "El color es incorrecto.",
    GENERAL_ERROR_CONDITION                 : "Debe indicar una condición válida.",
    GENERAL_ERROR_CONDITIONS                : "Debe indicar al menos una condición.",
    GENERAL_ERROR_CONDITION_MATCH           : "Debe indicar de coincidir todas o cualquier condición.",
    GENERAL_ERROR_DATE                      : "La fecha es incorrecta.",
    GENERAL_ERROR_DATE_AMOUNT_TYPE          : "Debe indicar un tipo de cantidad válido.",
    GENERAL_ERROR_DATE_FUTURE               : "La fecha debe ser futura.",
    GENERAL_ERROR_DATE_PERIOD               : "La fecha hasta debe ser posterior a la fecha desde.",
    GENERAL_ERROR_DAYS                      : "La cantidad de días debe ser mayor a 0.",
    GENERAL_ERROR_EMAIL_EMPTY               : "Debe completar el email.",
    GENERAL_ERROR_EMAIL_INVALID             : "El email indicado es incorrecto.",
    GENERAL_ERROR_EMAIL_USED                : "El email indicado esta usado por otra cuenta.",
    GENERAL_ERROR_FIELD                     : "No existe el campo indicado.",
    GENERAL_ERROR_FILE                      : "El archivo indicado no existe.",
    GENERAL_ERROR_FILE_EXCEL                : "El archivo indicado debe ser un Excel.",
    GENERAL_ERROR_FILE_IMAGE                : "El archivo indicado debe ser una imagen.",
    GENERAL_ERROR_FILE_IMPORT               : "Debe incluir el archivo a importar.",
    GENERAL_ERROR_FILE_PATH                 : "Debe indicar la ruta del archivo.",
    GENERAL_ERROR_FILE_PDF                  : "El archivo indicado debe ser un PDF.",
    GENERAL_ERROR_FILE_READ                 : "No se pudo leer el archivo indicado.",
    GENERAL_ERROR_FILE_EMPTY                : "El archivo debe tener una fila para el encabezado y una fila por contacto.",
    GENERAL_ERROR_FILE_SIZE                 : "El archivo es demasiado grande.",
    GENERAL_ERROR_FILE_VIDEO                : "El archivo indicado debe ser un video.",
    GENERAL_ERROR_FIRST_NAME                : "Debe completar el nombre.",
    GENERAL_ERROR_FROM_DATE_EMPTY           : "Debe indicar la fecha desde.",
    GENERAL_ERROR_FROM_DATE_INVALID         : "La fecha desde es inválida.",
    GENERAL_ERROR_FROM_HOUR_EMPTY           : "Debe indicar la hora desde.",
    GENERAL_ERROR_FROM_HOUR_INVALID         : "La hora desde es inválida.",
    GENERAL_ERROR_HOURS                     : "La cantidad de horas debe ser mayor a 0.",
    GENERAL_ERROR_IMPORT_COLUMNS            : "Debe indicar las columnas a importar.",
    GENERAL_ERROR_LANGUAGE_SINGULAR         : "Hay errores en el idioma: {0}.",
    GENERAL_ERROR_LANGUAGE_PLURAL           : "Hay errores en los idiomas: {0}.",
    GENERAL_ERROR_LAST_NAME                 : "Debe completar el apellido.",
    GENERAL_ERROR_MESSAGE_LENGTH            : "El mensaje no debe superar los {0} caracteres.",
    GENERAL_ERROR_MINUTES                   : "La cantidad de minutos debe ser mayor a 0.",
    GENERAL_ERROR_MODULE                    : "El módulo indicado es incorrecto.",
    GENERAL_ERROR_OPERATOR                  : "Debe completar un operador válido.",
    GENERAL_ERROR_OPTIONS_SINGULAR          : "Opción mal configurada",
    GENERAL_ERROR_OPTIONS_PLURAL            : "{0} opciones mal configuradas",
    GENERAL_ERROR_PASSWORD_EMPTY            : "Debe completar la contraseña.",
    GENERAL_ERROR_PASSWORD_INVALID          : "La contraseña debe ser entre 6 y 20 caracteres y contener al menos un número y una letra.",
    GENERAL_ERROR_PROVINCE                  : "La provincia/estado indicada no existe.",
    GENERAL_ERROR_POSITION                  : "La posición debe ser un número mayor a cero.",
    GENERAL_ERROR_PRICE                     : "El precio indicado es inválido.",
    GENERAL_ERROR_SECTION_SINGULAR          : "Hay 1 error en la sección",
    GENERAL_ERROR_SECTION_PLURAL            : "Hay {0} errores en la sección",
    GENERAL_ERROR_STATUS                    : "El estado indicado es incorrecto.",
    GENERAL_ERROR_TIME                      : "Debe indicar un tiempo válido.",
    GENERAL_ERROR_TIME_ZONE                 : "El uso horario indicado es inválido.",
    GENERAL_ERROR_TO_DATE_EMPTY             : "Debe indicar la fecha hasta.",
    GENERAL_ERROR_TO_DATE_INVALID           : "La fecha de hasta es inválida.",
    GENERAL_ERROR_TO_HOUR_EMPTY             : "Debe indicar la hora hasta.",
    GENERAL_ERROR_TO_HOUR_INVALID           : "La hora hasta es inválida.",
    GENERAL_ERROR_URL                       : "La url debe comenzar con 'https://'.",
    GENERAL_ERROR_VALUE                     : "Debe completar un valor.",



    // Selects
    GENERAL_YES_NO                          : {
        0 : "No",
        1 : "Si",
    },
    GENERAL_YES_BLANK                       : {
        0 : "",
        1 : "Si",
    },
    SELECT_ORGANIZATION                     : {
        "client"  : "Cliente",
        "partner" : "Partner",
    },
    SELECT_FONT_FAMILIES                    : {
        "Inter"      : "Inter",
        "Lato"       : "Lato",
        "Roboto"     : "Roboto",
        "Open Sans"  : "Open Sans",
        "Montserrat" : "Montserrat",
        "Poppins"    : "Poppins",
        "Raleway"    : "Raleway",
    },
    SELECT_INPUT_TYPES                      : {
        "text"     : "Texto",
        "textarea" : "Textarea",
        "number"   : "Número",
        "email"    : "Email",
        "tel"      : "Teléfono",
        "date"     : "Fecha",
        "time"     : "Tiempo",
        "select"   : "Selector",
        "list"     : "Lista",
        "fields"   : "Lista JSON",
        "file"     : "Archivo",
        "password" : "Contraseña",
        "toggle"   : "Toggle",
    },
    SELECT_FILE_TYPES                       : {
        "image" : "Imagen",
        "video" : "Video",
        "audio" : "Audio",
        "pdf"   : "PDF",
    },
    SELECT_SHOW_NAMES                       : {
        "None"      : "No mostrar",
        "FirstName" : "Mostrar solo nombre",
        "FullName"  : "Mostrar nombre y apellido",
    },
    SELECT_DATE_AMOUNT_TYPES                : {
        "Instant" : "En ese instante",
        "Minutes" : "Minutos",
        "Hours"   : "Horas",
        "Days"    : "Días",
    },

    SELECT_OPERATORS                        : {
        "HasValue"    : "Tiene un valor",
        "EqualTo"     : "Es igual a",
        "Contains"    : "Contiene",
        "StartsWith"  : "Empieza con",
        "EndsWith"    : "Termina con",
    },
    SELECT_OPERATORS_LIST                   : {
        "HasValue"    : "Tiene un valor",
        "Contains"    : "Contiene",
        "LengthIs"    : "Cantidad de elementos",
    },
    SELECT_OPERATORS_NUMBER                 : {
        "HasValue"    : "Tiene un valor",
        "EqualTo"     : "Es igual a",
        "GreaterThan" : "Es mayor a",
        "LowerThan"   : "Es menor a",
    },
    SELECT_OPERATORS_DATE                   : {
        "HasValue"    : "Tiene un valor",
        "EqualTo"     : "Es igual a",
        "LowerThan"   : "Es anterior a",
        "GreaterThan" : "Es posterior a",
    },
    SELECT_OPERATORS_BEFORE_OR_AFTER        : {
        "LowerThan"   : "Antes de la fecha",
        "GreaterThan" : "Luego de la fecha",
    },
    SELECT_OPERATORS_TOGGLE                 : {
        "HasValue"    : "Tiene un valor",
        "IsTrue"      : "Es verdadero",
        "IsFalse"     : "Es falso",
    },
    SELECT_CONDITION_MATCHES                : {
        "all" : "Coincidir todas las condiciones",
        "any" : "Coincidir cualquier condición",
    },

    SELECT_CONVERSATION_ORDERS              : {
        "lastMsgDesc" : "Último mensaje: nuevo a viejo ↑",
        "lastMsgAsc"  : "Último mensaje: viejo a nuevo ↓",
        "createdDesc" : "Fecha de creación: nuevo a viejo ↑",
        "createdAsc"  : "Fecha de creación: viejo a nuevo ↓",
    },
    SELECT_CONVERSATION_ACTIONS             : {
        "Edit"            : "<b>{0}</b> editó la conversación a las <b>{2}</b>",
        "Accept"          : "<b>{0}</b> aceptó la conversación a las <b>{2}</b>",
        "Return"          : "<b>{0}</b> devolvió la conversación a las <b>{2}</b>",
        "AssignUser"      : "<b>{0}</b> asignó el usuario <b>{1}</b> a las <b>{2}</b>",
        "AssignTeam"      : "<b>{0}</b> asignó el equipo <b>{1}</b> a las <b>{2}</b>",
        "UnassignTeam"    : "<b>{0}</b> quitó el equipo <b>{1}</b> a las <b>{2}</b>",
        "AssignTag"       : "<b>{0}</b> asignó la etiqueta <b>{1}</b> a las <b>{2}</b>",
        "UnassignTag"     : "<b>{0}</b> quitó la etiqueta <b>{1}</b> a las <b>{2}</b>",
        "AssignTongue"    : "<b>{0}</b> asignó el idioma <b>{1}</b> a las <b>{2}</b>",
        "AssignHotel"     : "<b>{0}</b> asignó el hotel <b>{1}</b> a las <b>{2}</b>",
        "UnassignHotel"   : "<b>{0}</b> quitó el hotel a las <b>{2}</b>",
        "AssignFlow"      : "<b>{0}</b> asignó el flujo <b>{1}</b> a las <b>{2}</b>",
        "EditContact"     : "<b>{0}</b> editó el contacto <b>{1}</b> a las <b>{2}</b>",
        "MergeContact"    : "<b>{0}</b> combinó el contacto a <b>{1}</b> a las <b>{2}</b>",
        "AddContactTag"   : "<b>{0}</b> asignó la etiqueta <b>{1}</b> al contacto a las <b>{2}</b>",
        "EditHospitality" : "<b>{0}</b> editó la hospitalidad <b>{1}</b> a las <b>{2}</b>",
        "EditOrder"       : "<b>{0}</b> editó el pedido <b>#{1}</b> a las <b>{2}</b>",
        "Followup"        : "<b>{0}</b> hizo seguimiento de la conversación a las <b>{2}</b>",
        "FollowupReason"  : "<b>{0}</b> hizo seguimiento de la conversación a las <b>{2}</b> con motivo <b>{1}</b>",
        "Progress"        : "<b>{0}</b> continuó la gestión de la conversación a las <b>{2}</b>",
        "Resolve"         : "<b>{0}</b> resolvió la conversación a las <b>{2}</b>",
        "Open"            : "<b>{0}</b> abrió la conversación a las <b>{2}</b>",
        "ClaimOpened"     : "El reclamo <b>#{1}</b> fue abierto a las <b>{2}</b>",
        "ClaimClosed"     : "El reclamo <b>#{1}</b> fue cerrado a las <b>{2}</b>",
    },
    SELECT_CONVERSATION_FOLLOWUPS           : {
        "Queue"    : "Devolver conversación",
        "Progress" : "Continuar gestionando",
        "Resolved" : "Resolver conversación",
    },
    SELECT_CONVERSATION_NOTIFICATIONS       : {
        "None"    : "No notificar",
        "Desktop" : "Enviar notificación de escritorio",
        "Email"   : "Enviar notificación por email",
        "All"     : "Enviar notificación de escritorio e email",
    },
    SELECT_CONVERSATION_QUALIFICATIONS      : {
        "bad"       : "Mala",
        "good"      : "Buena",
        "excellent" : "Excelente",
    },
    SELECT_STORE_LINK_TYPES                 : {
        "Category" : "Categoría",
        "Url"      : "Url",
    },
    SELECT_CAMPAIGN_REPORTS                 : {
        "campaign" : "Report de campaña",
        "flow"     : "Report de flujo",
    },
    SELECT_CAMPAIGN_PRIORITIES              : {
        "first"  : "Primera prioridad",
        "second" : "Segunda prioridad",
        "third"  : "Tercera prioridad",
    },
    SELECT_ASSISTANT_MODEL_TYPES            : {
        "Text"  : "Texto",
        "Audio" : "Audio",
        "Image" : "Imagen",
    },
    SELECT_WABA_TEMPLATES_CATEGORIES        : {
        "UTILITY"        : "Utilidad",
        "MARKETING"      : "Marketing",
        "AUTHENTICATION" : "Autenticación",
    },
    SELECT_WABA_TEMPLATES_CATEGORIES_DESC   : {
        "UTILITY"   : "<b>Utilidad</b> - Para mensajes sobre pedidos o la cuenta",
        "MARKETING" : "<b>Marketing</b> - Para promociones o información acerca de productos y servicios",
    },
    SELECT_WABA_TEMPLATES_HEADER_TYPES      : {
        "NONE"     : "Sin encabezado",
        "TEXT"     : "Encabezado como texto",
        "IMAGE"    : "Encabezado como imagen",
        "VIDEO"    : "Encabezado como video",
        "DOCUMENT" : "Encabezado como documento",
        "LOCATION" : "Encabezado como ubicación",
    },
    SELECT_WABA_TEMPLATES_LINK_TYPES        : {
        "PHONE_NUMBER" : "Llamar teléfono",
        "URL"          : "Visitar sitio",
    },
    SELECT_FLOWS_ACCOUNTS                   : {
        "1" : "Sólo una",
        "2" : "Más de una",
    },
    SELECT_FLOWS_COMBINATORS                : {
        "And" : "Tiene todas las frases",
        "Or"  : "Tiene alguna de las frases",
    },
    SELECT_FLOWS_OPERATIONS                 : {
        "Select" : "Seleccionar un valor",
        "Add"    : "Agregar un valor",
        "Remove" : "Quitar un valor",
        "Clear"  : "Borrar y agregar un valor",
    },
    SELECT_FLOWS_JUMPS                      : {
        "Node"   : "A un nodo en el flujo",
        "Flow"   : "A un flujo diferente",
        "Return" : "Al flujo anterior",
    },
    SELECT_FLOWS_EVENTS                     : {
        "ElapsedTime"     : "Pasó x tiempo",
        "QueueTime"       : "Pasó x tiempo en cola",
        "MessageTime"     : "Pasó x tiempo desde el último mensaje",
        "ContactTime"     : "Pasó x tiempo sin respuesta del contacto",
        "UserTime"        : "Pasó x tiempo sin respuesta del usuario",
        "MessageReceived" : "Llegó un mensaje nuevo",
        "MessageSent"     : "Se envió un mensaje nuevo",
        "Assigned"        : "Se asignó la conversación",
    },
    SELECT_AUTOMATIONS_EVENTS               : {
        "Create" : "Creado",
        "Edit"   : "Editado",
        "Delete" : "Eliminado",
    },
    SELECT_AUTOMATIONS_ACTIONS              : {
        "Create" : "Obtener o crear",
        "Edit"   : "Crear o editar",
        "Delete" : "Eliminar",
    },
    SELECT_AUTOMATIONS_HOSPITALITY_DATE     : {
        "AfterBooking"   : "Luego de una reserva",
        "BeforeCheckIn"  : "Antes de un Check IN",
        "AfterCheckIn"   : "Luego de un Check IN",
        "BeforeCheckOut" : "Antes de un Check OUT",
        "AfterCheckOut"  : "Luego de un Check OUT",
    },
    SELECT_APPLICATIONS_NUBILA_API          : {
        "EditExtension"     : "Editar extensión",
        "MoveRoomExtension" : "Cambiar habitación en la extensión",
        "ReleaseExtension"  : "Liberar extensión",
        "ReturnPhoneCalls"  : "Devolver llamadas de teléfono",
    },
    SELECT_APPLICATIONS_OMNITEC_HOOK        : {
        "EKey"     : "EKEY",
        "PassCode" : "PASSCODE",
    },
    SELECT_APPLICATIONS_FORZE_API           : {
        "CheckIn"  : "Generar PIN por Check IN",
        "Update"   : "Actualizar habitación y/o fechas",
        "CheckOut" : "Invalidar PIN por Check OUT",
    },
    SELECT_APPLICATIONS_GESTION_BOS_API     : {
        "GetContact"  : "Obtener los datos de un contacto",
        "EditContact" : "Editar los datos de un contacto",
    },
    SELECT_APPLICATIONS_GESTION_BOS_FIELDS  : {
        "contactCode"  : "Código",
        "socialReason" : "Razón social",
        "fantasyName"  : "Nombre de fantasía",
        "firstName"    : "Nombre",
        "lastName"     : "Apellido",
        "cuit"         : "CUIT",
        "email"        : "Email",
        "phone"        : "Teléfono",
    },
    SELECT_APPLICATIONS_TIENDA_NUBE_EVENTS  : {
        "abandoned" : "Checkout abandonado",
        "created"   : "Venta creada",
        "updated"   : "Venta actualizada",
        "paid"      : "Venta pagada",
        "packed"    : "Venta empaquetada",
        "fulfilled" : "Venta completada",
        "cancelled" : "Venta cancelada",
    },
    SELECT_PLAN_TYPES                       : {
        "Hotel"   : "Por habitación de hotel",
        "Contact" : "Por contacto",
    },
    SELECT_EMAIL_RESULTS                    : {
        "Sent"            : "Email enviado",
        "NotProcessed"    : "Email sin procesar",
        "InactiveSend"    : "Envío inactivo",
        "NoEmails"        : "Sin emails a enviar",
        "WhiteListFilter" : "Filtrado de lista blanca",
        "InvalidEmail"    : "Email inválido",
        "ProviderError"   : "Error en el proveedor",
    },
    SELECT_NOTIFICATION_RESULTS              : {
        "Sent"            : "Notificación enviada",
        "NotProcessed"    : "Notificación sin procesar",
        "InactiveSend"    : "Envío inactivo",
        "NoDevices"       : "Sin dispositivos a notificar",
        "ProviderError"   : "Error en el proveedor",
    },



    // Auth: Login
    LOGIN_TITLE                             : "Inicie sesión para continuar",
    LOGIN_RECOVER                           : "¿Olvidó su contraseña?",
    LOGIN_BUTTON                            : "Ingresar",
    LOGIN_SOCIAL                            : "O puede ingresar con",
    LOGIN_GOOGLE                            : "Iniciar sesión con Google",
    LOGIN_MICROSOFT                         : "Iniciar sesión con Microsoft",
    LOGIN_FACEBOOK                          : "Iniciar sesión con Facebook",
    LOGIN_TERMS                             : "Operar en Conversana implica aceptar<br> los <a class='simple-link' href='{0}' target='_blank'>términos y condiciones</a> y las <a class='simple-link' href='{1}' target='_blank'>políticas de privacidad</a><br> en los que se ofrece el servicio.",
    LOGIN_ERROR_SPAM                        : "Ha intentado demasiadas veces.",
    LOGIN_ERROR_CREDENTIALS                 : "El email o la contraseña es incorrecto.",
    LOGIN_ERROR_DISABLED                    : "El ingreso esta deshabilitado.",
    LOGIN_ERROR_GOOGLE                      : "No es posible ingresar con la cuenta de Google indicada.",
    LOGIN_ERROR_MICROSOFT                   : "No es posible ingresar con la cuenta de Microsoft indicada.",
    LOGIN_ERROR_FACEBOOK                    : "No es posible ingresar con la cuenta de Facebook indicada.",

    // Auth: Recover Password
    RECOVER_TITLE                           : "Recuperar contraseña",
    RECOVER_HELP                            : "Use el siguiente formulario para recuperar su contraseña. Un mail será enviado a la dirección indicada con instrucciones acerca de como completar el proceso.",
    RECOVER_ACCOUNT                         : "Ingresar con su cuenta",
    RECOVER_BUTTON                          : "Enviar Email",
    RECOVER_SUCCESS                         : "Se ha enviado el email correctamente.",

    // Auth: Code
    CODE_TITLE                              : "Completar código",
    CODE_HELP                               : "Se ha enviado un mail a la dirección de mail indicada con un vínculo que debe hacer click para poder generar una nueva contraseña para su cuenta, o copiar y pegar el código a continuación.",
    CODE_ACCOUNT                            : "Ingresar con su cuenta",
    CODE_BUTTON                             : "Verificar código",
    CODE_ERROR_EMPTY                        : "Debe completar el código.",
    CODE_ERROR_INVALID                      : "El código indicado es incorrecto o se creó hace más de 3 horas. Debe solicitar otro.",
    CODE_SUCCESS                            : "El código se ha verificado correctamente.",

    // Auth: Reset Password
    RESET_TITLE                             : "Resetear contraseña",
    RESET_HELP                              : "Ingrese la contraseña que desea utilizar para acceder a su cuenta.",
    RESET_ACCOUNT                           : "Ingresar con su cuenta",
    RESET_BUTTON                            : "Resetear",
    RESET_ERROR_CODE                        : "Debe proveer un código.",
    RESET_ERROR_INVALID                     : "El código indicado es incorrecto o ha expirado.",
    RESET_ERROR_PASSWORD                    : "Debe completar la nueva contraseña.",
    RESET_ERROR_CONFIRM                     : "Debe confirmar la nueva contraseña.",
    RESET_ERROR_MATCH                       : "Las contraseñas no coinciden.",
    RESET_SUCCESS                           : "Su contraseña fue modificada correctamente.",

    // Auth: Change Password
    CHANGE_TITLE                            : "Cambiar contraseña",
    CHANGE_HELP                             : "Debe cambiar su contraseña por una nueva.",
    CHANGE_OLD_PASSWORD                     : "Contraseña actual",
    CHANGE_NEW_PASSWORD                     : "Nueva contraseña",
    CHANGE_BUTTON                           : "Cambiar contraseña",
    CHANGE_ERROR_INVALID                    : "El email indicado es incorrecto o no requiere cambios.",
    CHANGE_ERROR_OLD                        : "Debe completar su contraseña actual.",
    CHANGE_ERROR_NEW                        : "Debe completar la nueva contraseña.",
    CHANGE_ERROR_CONFIRM                    : "Debe confirmar la nueva contraseña.",
    CHANGE_ERROR_PASSWORD                   : "La contraseña actual es incorrecta.",
    CHANGE_ERROR_DIFFERENT                  : "La nueva contraseña debe ser diferente a la actual.",
    CHANGE_ERROR_MATCH                      : "Las contraseñas no coinciden.",
    CHANGE_SUCCESS                          : "Su contraseña fue modificada correctamente.",

    // Auth: Join Organization
    JOIN_TITLE                              : "Registrarse",
    JOIN_HELP                               : "Ingrese el email con el cual fue invitado y su contraseña para unirse a {0}.",
    JOIN_BUTTON                             : "Unirse",
    JOIN_SOCIAL                             : "O puede registrarse con",
    JOIN_GOOGLE                             : "Registrarse con Google",
    JOIN_MICROSOFT                          : "Registrarse con Microsoft",
    JOIN_FACEBOOK                           : "Registrarse con Facebook",
    JOIN_ERROR_CODE                         : "Debe proveer un código.",
    JOIN_ERROR_TEAM                         : "El código indicado es incorrecto o ha expirado al haber pasado más de 72 horas. Puede pedir que le envíen uno nuevo.",
    JOIN_ERROR_EMAIL                        : "El email debe ser igual al cual se le envió la invitación.",
    JOIN_ERROR_GOOGLE                       : "No se pudo obtener la información de Google.",
    JOIN_ERROR_MICROSOFT                    : "No se pudo obtener la información de Microsoft.",
    JOIN_ERROR_FACEBOOK                     : "No se pudo obtener la información de Facebook.",

    // Auth: Authorized
    AUTHORIZED_CLOUDBEDS_TITLE              : "Integración con Cloudbeds",
    AUTHORIZED_CLOUDBEDS_TEXT               : "La integración con Cloudbeds fue exitosa. Gracias por completar el proceso de autorización. Ahora puede cerrar esta ventana o ir a Conversana.",
    AUTHORIZED_BUTTON                       : "Ir a Conversana",
    AUTHORIZED_CLOSE                        : "Cerrar ventana",



    // Profile: Organizations
    ORGANIZATIONS_NAME                      : "Organizaciones",
    ORGANIZATIONS_SINGULAR                  : "Organización",
    ORGANIZATIONS_MEMBERS_SINGULAR          : "{0} miembro",
    ORGANIZATIONS_MEMBERS_PLURAL            : "{0} miembros",
    ORGANIZATIONS_ACCESS                    : "Acceder",
    ORGANIZATIONS_PENDING                   : "Tienes una invitación pendiente a este organización.",
    ORGANIZATIONS_CURRENT                   : "Estas viendo este organización",
    ORGANIZATIONS_ACTIVE                    : "Puedes acceder a este organización.",
    ORGANIZATIONS_NONE_AVAILABLE            : "No hay organizaciones disponibles.",
    ORGANIZATIONS_ERROR_EXISTS              : "No existe la organización indicada.",
    ORGANIZATIONS_ERROR_ACCEPT              : "No es posible aceptar la invitación indicada.",
    ORGANIZATIONS_ERROR_ACCESS              : "No es posible acceder a la organización indicado.",
    ORGANIZATIONS_SUCCESS_ACCEPT            : "Ya eres miembro de la organización!",

    // Profile: Account
    ACCOUNT_NAME                            : "Cuenta",
    ACCOUNT_MINE                            : "Mi cuenta",
    ACCOUNT_CHANGE_AVATAR                   : "Cambiar foto",
    ACCOUNT_CONNECTIONS_TITLE               : "Dispositivos conectados",
    ACCOUNT_CONNECTIONS_BUTTON              : "Desconectar",
    ACCOUNT_APPEARANCE_TITLE                : "Apariencia",
    ACCOUNT_APPEARANCE_LIGHT                : "Modo claro",
    ACCOUNT_APPEARANCE_DARK                 : "Modo oscuro",
    ACCOUNT_DELETE_TITLE                    : "Eliminar mi cuenta",
    ACCOUNT_DELETE_TEXT                     : "¿Realmente desea eliminar su cuenta? Luego de eliminar su cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_DELETE_HELP                     : "Luego de eliminar la cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_ERROR_CONFIRM                   : "Debe confirmar su nueva contraseña.",
    ACCOUNT_ERROR_MATCH                     : "Las contraseñas son diferentes.",
    ACCOUNT_ERROR_AVATAR_EMPTY              : "Debe proporcionar una image.",
    ACCOUNT_ERROR_AVATAR_SIZE               : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_AVATAR_TYPE               : "El archivo provisto no es una imagen.",
    ACCOUNT_SUCCESS_EDIT                    : "La cuenta fue editada correctamente.",
    ACCOUNT_SUCCESS_UPLOAD                  : "El avatar se subió correctamente.",

    // Profile: Notifications
    NOTIFICATIONS_TITLE                     : "Notificaciones de escritorio",
    NOTIFICATIONS_INFO                      : "Si quiere activar las notificaciones de escritorio, haga click en el siguiente botón y luego haga click en aceptar en el popup.",
    NOTIFICATIONS_ACTIVATE                  : "Activar las notificaciones",
    NOTIFICATIONS_GRANTED                   : "Las notificaciones de escritorio están activas. Puede desactivarlas manualmente desde la configuración del sitio en el navegador.",
    NOTIFICATIONS_DENIED                    : "Las notificaciones de escritorio están denegadas. Debe activarlas manualmente desde configuración del sitio en el navegador.",
    NOTIFICATIONS_BAR_TEXT                  : "¿Quieres recibir notificaciones de conversaciones?",
    NOTIFICATIONS_BAR_ACTIVATE              : "Activar",
    NOTIFICATIONS_SUCCESS_ACTIVATE          : "Las notificaciones se activaron correctamente.",

    // Profile: Email Signatures
    EMAIL_SIGNATURES_NAME                   : "Firmas de emails",
    EMAIL_SIGNATURES_SINGULAR               : "Firma del email",
    EMAIL_SIGNATURES_TEXT                   : "Seleccione una organización y luego edite su firma de Email:",
    EMAIL_SIGNATURES_NONE_AVAILABLE         : "No hay organizaciones con un canal de email disponibles.",
    EMAIL_SIGNATURES_ERROR_EDIT             : "No es posible guardar la firma de email.",
    EMAIL_SIGNATURES_SUCCESS_EDIT           : "La firma de email se guardó correctamente.",



    // Dashboard
    DASHBOARD_NAME                          : "Dashboard",
    DASHBOARD_FILTER_TITLE                  : "Filtrar el dashboard",
    DASHBOARD_PERSONALIZE_TITLE             : "Personalizar el dashboard",
    DASHBOARD_REPORTS                       : "Reportes",
    DASHBOARD_TOTAL_QUEUE                   : "En cola",
    DASHBOARD_TOTAL_QUEUE_NOW               : "En cola ahora",
    DASHBOARD_TOTAL_PROGRESS                : "En curso",
    DASHBOARD_TOTAL_PROGRESS_NOW            : "En curso ahora",
    DASHBOARD_TOTAL_FOLLOWUP                : "En seguimiento",
    DASHBOARD_TOTAL_FOLLOWUP_NOW            : "En seguimiento ahora",
    DASHBOARD_TOTAL_RESOLVED                : "Resueltas",
    DASHBOARD_TOTAL_RESOLVED_NOW            : "Resueltas ahora",
    DASHBOARD_AVG_FIRST_MESSAGE             : "Primer respuesta",
    DASHBOARD_AVG_MESSAGE                   : "Respuesta",
    DASHBOARD_AVG_RESOLVED                  : "Resolución",

    // Dashboard: Monitoring
    MONITORING_NAME                         : "Monitoreo",
    MONITORING_CHANGES                      : "Cambios",
    MONITORING_UPDATES                      : "Actualizaciones",
    MONITORING_LAST_UPDATE                  : "Última actualización",
    MONITORING_NONE_AVAILABLE               : "No hay datos disponibles.",



    // Clients
    CLIENTS_NAME                            : "Clientes",
    CLIENTS_SINGULAR                        : "Cliente",
    CLIENTS_FILTER_TITLE                    : "Filtrar clientes",
    CLIENTS_VIEW_TITLE                      : "Ver cliente",
    CLIENTS_CREATE_TITLE                    : "Crear cliente",
    CLIENTS_EDIT_TITLE                      : "Editar cliente",
    CLIENTS_APPROVE_TITLE                   : "Aprobar cliente",
    CLIENTS_APPROVE_TEXT                    : "¿Realmente desea aprobar el cliente <b>{0}</b>?",
    CLIENTS_DELETE_TITLE                    : "Eliminar cliente",
    CLIENTS_DELETE_TEXT                     : "¿Realmente desea eliminar el cliente <b>{0}</b>?",
    CLIENTS_CONTACT                         : "Contacto administrativo",
    CLIENTS_FIRST_NAME                      : "Nombre del contacto",
    CLIENTS_LAST_NAME                       : "Apellido del contacto",
    CLIENTS_EMAIL                           : "Email administrativo",
    CLIENTS_PHONE                           : "Teléfono administrativo",
    CLIENTS_PLAN                            : "Plan del cliente",
    CLIENTS_PLAN_VALUE_TIP                  : "Puede sobre-escribir el valor del plan",
    CLIENTS_DISCOUNT_PERCENT                : "Porcentaje de descuento",
    CLIENTS_DONT_CHARGE                     : "No facturar",
    CLIENTS_CHARGE_START_DATE               : "Inicio de facturación",
    CLIENTS_CHARGE_START_SHORT              : "Inicio cobro",
    CLIENTS_CAN_EDIT_HOSPITALITY            : "Puede editar hospitality",
    CLIENTS_CAMPAIGN_LIMIT                  : "Avisar luego de enviar campañas",
    CLIENTS_CAMPAIGN_DISPATCHES             : "Envíos de campañas",
    CLIENTS_AUTOMATION_LIMIT                : "Avisar luego de ejecutar automatizaciones",
    CLIENTS_AUTOMATION_EXECUTIONS           : "Automatizaciones ejecutadas",
    CLIENTS_BASE_PRICE                      : "<b>Sin plan</b> + <b>{0}</b> de addons + <b>{1}</b> de aplicaciones =<br/> <b>{3}</b> /mes + <b>{2}</b> de setup",
    CLIENTS_HOTEL_PRICE                     : "<b>{0}</b> x {1} hab. + <b>{2}</b> de addons + <b>{3}</b> de aplicaciones =<br/> <b>{5}</b> /mes + <b>{4}</b> de setup",
    CLIENTS_CONTACT_PRICE                   : "<b>{0}</b> ({1} /ad.) + <b>{2}</b> de addons + <b>{3}</b> de aplicaciones =<br/> <b>{5}</b> /mes + <b>{4}</b> de setup",
    CLIENTS_NONE_AVAILABLE                  : "No hay clientes disponibles.",
    CLIENTS_ERROR_SINGULAR                  : "Hay {0} error en un campo. Revisa todas las pestañas.",
    CLIENTS_ERROR_PLURAL                    : "Hay {0} errores en los campos. Revisa todas las pestañas.",
    CLIENTS_ERROR_EXISTS                    : "No existe el cliente indicado.",
    CLIENTS_ERROR_NAME                      : "Debe indicar el nombre del cliente.",
    CLIENTS_ERROR_PLAN                      : "Debe indicar el plan del cliente.",
    CLIENTS_ERROR_DISCOUNT                  : "El valor de descuento es incorrecto.",
    CLIENTS_ERROR_CHARGE_START_DATE         : "La fecha de inicio de facturación es incorrecta.",
    CLIENTS_ERROR_QUANTITY                  : "Debe indicar una cantidad válida.",
    CLIENTS_ERROR_APPROVE                   : "No se puede aprobar el cliente indicado.",
    CLIENTS_SUCCESS_CREATE                  : "El cliente se creó correctamente.",
    CLIENTS_SUCCESS_EDIT                    : "El cliente se editó correctamente.",
    CLIENTS_SUCCESS_APPROVE                 : "El cliente se aprobó correctamente.",
    CLIENTS_SUCCESS_DELETE                  : "El cliente se eliminó correctamente.",

    // Partners
    PARTNERS_NAME                           : "Partners",
    PARTNERS_SINGULAR                       : "Partner",
    PARTNERS_FILTER_TITLE                   : "Filtrar partners",
    PARTNERS_CREATE_TITLE                   : "Crear partner",
    PARTNERS_EDIT_TITLE                     : "Editar partner",
    PARTNERS_VIEW_TITLE                     : "Ver partner",
    PARTNERS_DELETE_TITLE                   : "Eliminar partner",
    PARTNERS_DELETE_TEXT                    : "¿Realmente desea eliminar el partner <b>{0}</b>?",
    PARTNERS_COMMISSION                     : "Comisión",
    PARTNERS_CLIENTS_SINGULAR               : "1 cliente",
    PARTNERS_CLIENTS_PLURAL                 : "{0} clientes",
    PARTNERS_CONTACT                        : "Contacto administrativo",
    PARTNERS_FIRST_NAME                     : "Nombre del contacto",
    PARTNERS_LAST_NAME                      : "Apellido del contacto",
    PARTNERS_EMAIL                          : "Email administrativo",
    PARTNERS_PHONE                          : "Teléfono administrativo",
    PARTNERS_HIDE_TICKETS                   : "Ocultar tickets en los clientes",
    PARTNERS_NONE_AVAILABLE                 : "No hay partners disponibles.",
    PARTNERS_ERROR_EXISTS                   : "No existe el partner indicado.",
    PARTNERS_ERROR_NAME                     : "Debe indicar el nombre del partner.",
    PARTNERS_ERROR_COMMISSION               : "La comisión debe ser un valor entre 0 y 100.",
    PARTNERS_SUCCESS_CREATE                 : "El partner se creó correctamente.",
    PARTNERS_SUCCESS_EDIT                   : "El partner se editó correctamente.",
    PARTNERS_SUCCESS_DELETE                 : "El partner se eliminó correctamente.",

    // Subscriptions
    SUBSCRIPTIONS_NAME                      : "Suscripciones",
    SUBSCRIPTIONS_FILTER_TITLE              : "Filtrar suscripciones",
    SUBSCRIPTIONS_VIEW_TITLE                : "Ver suscripción",
    SUBSCRIPTIONS_DELETE_TITLE              : "Eliminar suscripción",
    SUBSCRIPTIONS_DELETE_TEXT               : "¿Realmente desea eliminar la suscripción indicada?",
    SUBSCRIPTIONS_ROOMS                     : "Habitaciones",
    SUBSCRIPTIONS_ADDON                     : "Addon: {0}",
    SUBSCRIPTIONS_APPLICATION               : "Aplicación: {0}",
    SUBSCRIPTIONS_DAYS_DISCOUNT_SINGULAR    : "Descuento por usar {0} día",
    SUBSCRIPTIONS_DAYS_DISCOUNT_PLURAL      : "Descuento por usar {0} días",
    SUBSCRIPTIONS_CLIENT_DISCOUNT           : "Descuento de cliente del {0}%",
    SUBSCRIPTIONS_MARKETING_CONVERSATIONS   : "Conv. de Marketing",
    SUBSCRIPTIONS_UTILITY_CONVERSATIONS     : "Conv. de Utilidad",
    SUBSCRIPTIONS_REFERRAL_CONVERSATIONS    : "Conv. de Referido",
    SUBSCRIPTIONS_SERVICE_CONVERSATIONS     : "Conv. de Usuario",
    SUBSCRIPTIONS_TOTAL_MESSAGES            : "Cantidad de mensajes",
    SUBSCRIPTIONS_TOTAL_SEGMENTS            : "Cantidad de segmentos",
    SUBSCRIPTIONS_TOTAL_COST                : "Costo total",
    SUBSCRIPTIONS_TOTAL_RUNS                : "Ejecuciones",
    SUBSCRIPTIONS_INPUT_TOKENS              : "Tokens de entrada",
    SUBSCRIPTIONS_OUTPUT_TOKENS             : "Tokens de salida",
    SUBSCRIPTIONS_TOKENS_COST               : "{0} por {1} tokens",
    SUBSCRIPTIONS_NONE_AVAILABLE            : "No hay suscripciones disponibles.",
    SUBSCRIPTIONS_ERROR_EXISTS              : "No existe la suscripción indicado.",
    SUBSCRIPTIONS_SUCCESS_DELETE            : "La suscripción se eliminó correctamente.",



    // Conversations
    CONVERSATIONS_NAME                      : "Conversaciones",
    CONVERSATIONS_SINGULAR                  : "Conversación",
    CONVERSATIONS_FILTER_TITLE              : "Filtrar conversaciones",
    CONVERSATIONS_VIEW_TITLE                : "Ver conversación",
    CONVERSATIONS_GOTO_TITLE                : "Ir a la conversación",
    CONVERSATIONS_CREATE_TITLE              : "Crear conversación",
    CONVERSATIONS_EDIT_TITLE                : "Editar conversación",
    CONVERSATIONS_FOLLOWUP_TITLE            : "Seguimiento de conversación",
    CONVERSATIONS_FOLLOWUP_TEXT             : "¿Qué desea hacer en el tiempo indicado con la conversación?",
    CONVERSATIONS_RETURN_TITLE              : "Devolver conversación",
    CONVERSATIONS_RESOLVE_TITLE             : "Resolver conversación",
    CONVERSATIONS_DELETE_TITLE              : "Eliminar conversación",
    CONVERSATIONS_DELETE_TEXT               : "¿Realmente desea eliminar la conversación con el contacto <b>{0}</b>?",
    CONVERSATIONS_FORWARD_TITLE             : "Reenviar mensaje",
    CONVERSATIONS_FORWARD_TEXT              : "Puede reenviar el mensaje seleccionado a quien desee.",
    CONVERSATIONS_GENERATE_REPLY_TITLE      : "Generar respuesta",
    CONVERSATIONS_GENERATE_NOTE_TITLE       : "Generar nota",
    CONVERSATIONS_SUMMARIZE_TITLE           : "Resumir conversación",
    CONVERSATIONS_SELECT_ONE                : "Selecciona una conversación del listado para poder hablar con el contacto o crea una nueva conversación para comenzar a hablar con un contacto.",
    CONVERSATIONS_SELECT_EMPTY              : "Cuando los contactos se comuniquen mediante un canal, podrá seleccionar una conversación del listado y hablar con el contacto. También puede crear una conversación con un contacto nuevo o ya creado.",
    CONVERSATIONS_SELECT_CONFIGURE          : "Configure su primer canal para poder recibir mensajes de los contactos y verlos aquí.",
    CONVERSATIONS_SELECT_NONE               : "No hay canales configurados. Cuando se termine de configurar al menos un canal, podrá seleccionar una conversación del listado o crear una nueva y hablar con el contacto.",
    CONVERSATIONS_NUMBER                    : "Número de conversación",
    CONVERSATIONS_LAST_MESSAGE              : "Último mensaje",
    CONVERSATIONS_LAST_CONTA_MESSAGE        : "Último mensaje contacto",
    CONVERSATIONS_LAST_CONTACT_MESSAGE      : "Último mensaje del contacto",
    CONVERSATIONS_LAST_USER_MESSAGE         : "Último mensaje del usuario",
    CONVERSATIONS_LAST_FILE                 : "Última url del archivo",
    CONVERSATIONS_EXTERNAL_AD_ID            : "ID de publicidad",
    CONVERSATIONS_QUALIFICATION             : "Calificación",
    CONVERSATIONS_QUALIFICATIONS            : "Calificaciones",
    CONVERSATIONS_QUALIFICATION_BAD         : "Mala",
    CONVERSATIONS_QUALIFICATION_GOOD        : "Buena",
    CONVERSATIONS_QUALIFICATION_EXCELLENT   : "Excelente",
    CONVERSATIONS_CURRENT_DATE              : "Fecha de hoy",
    CONVERSATIONS_NEXT_DATE                 : "Fecha de mañana",
    CONVERSATIONS_ORDER_BY                  : "Ordenar por",
    CONVERSATIONS_MARK_UNREAD               : "Marcar como no leído",
    CONVERSATIONS_TRANSFER_USER             : "Transferir a usuario",
    CONVERSATIONS_TRANSFER_TEAM             : "Transferir a equipo",
    CONVERSATIONS_ASSIGN_TAG                : "Asignar etiqueta",
    CONVERSATIONS_ASSIGN_LANGUAGE           : "Asignar idioma",
    CONVERSATIONS_ASSIGN_HOTEL              : "Asignar hotel",
    CONVERSATIONS_ASSIGN_FLOW               : "Asignar flujo",
    CONVERSATIONS_RETURN                    : "Devolver",
    CONVERSATIONS_PROGRESS                  : "Gestionar",
    CONVERSATIONS_RESOLVE                   : "Resolver",
    CONVERSATIONS_FOLLOWUP                  : "Seguimiento",
    CONVERSATIONS_FOLLOWUP_HOURS            : "Seguimiento en horas",
    CONVERSATIONS_FOLLOWUP_REASON           : "Motivo del seguimiento",
    CONVERSATIONS_SEND_TEMPLATE             : "Enviar plantilla",
    CONVERSATIONS_SEND_TEMPLATE_HELP        : "Han pasado más de 24 horas desde el último mensaje del contacto, y para seguir la conversación debe enviar una plantilla aprobada por WhatsApp.",
    CONVERSATIONS_SEND_TEMPLATE_CREATE      : "No hay plantillas aprobadas por WhatsApp. Puede crear una desde el siguiente botón:",
    CONVERSATIONS_REPLYING_TO               : "Respondiendo al mensaje:",
    CONVERSATIONS_ATTACHMENT                : "Archivo adjunto:",
    CONVERSATIONS_SENDING_LOCATION          : "Enviando ubicación",
    CONVERSATIONS_WRITE_TEXT                : "Escribe un mensaje",
    CONVERSATIONS_NEW_FILE                  : "Archivo nuevo",
    CONVERSATIONS_OLD_FILE                  : "Archivo existente",
    CONVERSATIONS_REACT_TO                  : "Reaccionar",
    CONVERSATIONS_REPLY_TO                  : "Responder",
    CONVERSATIONS_FORWARD_TO                : "Reenviar",
    CONVERSATIONS_FORWARD_MESSAGE           : "Mensaje adicional",
    CONVERSATIONS_GENERATE                  : "Generar",
    CONVERSATIONS_GENERATE_REPLY            : "Explique como debe generar una respuesta",
    CONVERSATIONS_GENERATE_NOTE             : "Explique como debe generar una nota",
    CONVERSATIONS_GENERATE_INCLUDE_MESSAGES : "Incluir los mensajes de la conversación",
    CONVERSATIONS_GENERATE_INCLUDE_NOTES    : "Incluir las notas de la conversación",
    CONVERSATIONS_GENERATED_REPLY           : "Respuesta generada",
    CONVERSATIONS_GENERATED_NOTE            : "Nota generada",
    CONVERSATIONS_USE_REPLY                 : "Usar respuesta",
    CONVERSATIONS_USE_NOTE                  : "Usar nota",
    CONVERSATIONS_UNREAD_SINGULAR           : "{0} mensaje no leído",
    CONVERSATIONS_UNREAD_PLURAL             : "{0} mensajes no leídos",
    CONVERSATIONS_CHANNEL_ERROR             : "El contacto no tiene datos requeridos para enviar un mensaje.",
    CONVERSATIONS_SMS_WARNING               : "El SMS se envía a un número corto al que el contacto no puede responder directamente. Le aconsejamos agregar un mensaje con un url indicando al contacto de responder desde otro canal.",
    CONVERSATIONS_SMS_COST                  : "Costo SMS: ${0}",
    CONVERSATIONS_SMS_COST_INFO             : "Cada SMS se envía y cobra por segmentos. Si el texto no tiene emojis o caracteres con tilde, cada segmento contiene 153 caracteres, en caso contrario solo 67 caracteres.",
    CONVERSATIONS_BOT                       : "Bot",
    CONVERSATIONS_MESSAGE_SCHEDULED         : "Mensaje sin enviar",
    CONVERSATIONS_MESSAGE_SENT              : "Mensaje enviado",
    CONVERSATIONS_MESSAGE_DELIVERED         : "Mensaje recibido",
    CONVERSATIONS_MESSAGE_READ              : "Mensaje leído",
    CONVERSATIONS_MESSAGE_ERROR             : "Mensaje con error",
    CONVERSATIONS_SUMMARY                   : "Resumen de la conversación",
    CONVERSATIONS_NONE_AVAILABLE            : "No hay conversaciones disponibles.",
    CONVERSATIONS_ERROR_FILTER              : "Debe indicar un filtro válido.",
    CONVERSATIONS_ERROR_REPLY               : "Debe completar el mensaje.",
    CONVERSATIONS_ERROR_CREATE              : "No tiene permiso para crear una conversación.",
    CONVERSATIONS_ERROR_CONTACT             : "Debe indicar el contacto con quien iniciar una conversación.",
    CONVERSATIONS_ERROR_CHANNEL             : "Debe indicar el canal por el cual iniciar una conversación.",
    CONVERSATIONS_ERROR_EXTERNAL_ID         : "Debe indicar a que campo enviar el mensaje.",
    CONVERSATIONS_ERROR_EXTERNAL_AD_ID      : "Debe indicar el ID de la publicidad.",
    CONVERSATIONS_ERROR_EXISTS              : "No existe la conversación indicada.",
    CONVERSATIONS_ERROR_LIMIT               : "No se puede tener más de una conversación con el mismo contacto en el mismo canal.",
    CONVERSATIONS_ERROR_ADD                 : "No tiene permiso para responder a la conversación.",
    CONVERSATIONS_ERROR_SEND                : "No se puede enviar el mensaje indicado.",
    CONVERSATIONS_ERROR_REACTION_ADD        : "No se puede enviar la reacción indicada.",
    CONVERSATIONS_ERROR_REACTION_REMOVE     : "No se puede eliminar la reacción indicado.",
    CONVERSATIONS_ERROR_TITLE               : "Debe indicar el asunto a enviar.",
    CONVERSATIONS_ERROR_EMAILS_EMPTY        : "Debe indicar al menos un email.",
    CONVERSATIONS_ERROR_EMAILS_INVALID      : "Uno de los emails indicados es inválido.",
    CONVERSATIONS_ERROR_MESSAGE             : "Debe indicar el mensaje a enviar.",
    CONVERSATIONS_ERROR_FILE_TYPE           : "El tipo de archivo debe ser una imagen, audio o video.",
    CONVERSATIONS_ERROR_REPLY_TO            : "No existe el mensaje al cual quiere responder.",
    CONVERSATIONS_ERROR_TEMPLATE_EMPTY      : "Debe indicar una plantilla de WhatsApp.",
    CONVERSATIONS_ERROR_TEMPLATE_INVALID    : "La plantilla de WhatsApp indicada es inválida.",
    CONVERSATIONS_ERROR_TEMPLATE_SEND       : "No fue posible enviar la plantilla de WhatsApp.",
    CONVERSATIONS_ERROR_FOLLOWUP            : "No es posible hacer seguimiento de la conversación indicada.",
    CONVERSATIONS_ERROR_FOLLOWUP_HOURS      : "Debe indicar la cantidad de horas para el seguimiento.",
    CONVERSATIONS_ERROR_FOLLOWUP_TYPE       : "Debe indicar un tipo de seguimiento válido.",
    CONVERSATIONS_ERROR_FOLLOWUP_NOTIFY     : "Debe indicar un tipo de notificación válida.",
    CONVERSATIONS_ERROR_FORWARD             : "No es posible reenviar el mensaje indicado.",
    CONVERSATIONS_ERROR_FORWARD_SEND_TO     : "Debe indicar a quien quiere enviar el mensaje.",
    CONVERSATIONS_ERROR_FORWARD_EMAIL       : "Uno de los emails indicados es inválido.",
    CONVERSATIONS_ERROR_FORWARD_TITLE       : "Debe indicar el asunto del mensaje.",
    CONVERSATIONS_ERROR_PROMPT              : "Debe indicar como generar el texto.",
    CONVERSATIONS_ERROR_GENERATE            : "No se pudo generar el texto. Revise que el Asistente IA no tenga problemas.",
    CONVERSATIONS_ERROR_SUMMARIZE           : "No se pudo generar el resumen. Revise que el Asistente IA no tenga problemas.",
    CONVERSATIONS_ERROR_DELETE              : "No tiene permiso para eliminar la conversación indicada.",
    CONVERSATIONS_SUCCESS_CREATE            : "La conversación se creó correctamente.",
    CONVERSATIONS_SUCCESS_RETURN            : "La conversación se devolvió correctamente.",
    CONVERSATIONS_SUCCESS_RESOLVE           : "La conversación se resolvió correctamente.",
    CONVERSATIONS_SUCCESS_DELETE            : "La conversación se eliminó correctamente.",
    CONVERSATIONS_SUCCESS_FORWARD           : "El mensaje fue reenviado correctamente.",

    // Conversations: Notes
    CONVERSATIONS_NOTES_NAME                : "Notas",
    CONVERSATIONS_NOTES_SINGULAR            : "Nota",
    CONVERSATIONS_NOTES_CREATE_TITLE        : "Crear nota",
    CONVERSATIONS_NOTES_EDIT_TITLE          : "Editar nota",
    CONVERSATIONS_NOTES_DELETE_TITLE        : "Eliminar nota",
    CONVERSATIONS_NOTES_DELETE_TEXT         : "¿Realmente desea eliminar la nota <b>{0}</b>?",
    CONVERSATIONS_NOTES_WRITE               : "Escribe una nota",
    CONVERSATIONS_NOTES_MESSAGE             : "Contenido de la nota",
    CONVERSATIONS_NOTES_USE_FILE            : "Usar un archivo nuevo",
    CONVERSATIONS_NOTES_ERROR_ADD           : "No tiene permiso crear una nota.",
    CONVERSATIONS_NOTES_ERROR_EDIT          : "No tiene permiso editar la nota indicada.",
    CONVERSATIONS_NOTES_ERROR_DELETE        : "No tiene permiso eliminar la nota indicada.",
    CONVERSATIONS_NOTES_ERROR_EXISTS        : "No existe la nota indicada.",
    CONVERSATIONS_NOTES_ERROR_MESSAGE       : "Debe indicar el contenido de la nota.",
    CONVERSATIONS_NOTES_SUCCESS_CREATE      : "La nota se creó correctamente.",
    CONVERSATIONS_NOTES_SUCCESS_EDIT        : "La nota se editó correctamente.",
    CONVERSATIONS_NOTES_SUCCESS_DELETE      : "La nota se eliminó correctamente.",



    // Stores
    STORES_NAME                             : "Tiendas",
    STORES_SINGULAR                         : "Tienda",
    STORES_VIEW_TITLE                       : "Ver tienda",
    STORES_CREATE_TITLE                     : "Crear tienda",
    STORES_EDIT_TITLE                       : "Editar tienda",
    STORES_ACTIVATE_TITLE                   : "Activar tienda",
    STORES_ACTIVATE_TEXT                    : "¿Realmente desea activar la tienda <b>{0}</b>?",
    STORES_PAUSE_TITLE                      : "Pausar tienda",
    STORES_PAUSE_TEXT                       : "¿Realmente desea pausar la tienda <b>{0}</b>?",
    STORES_DELETE_TITLE                     : "Eliminar tienda",
    STORES_DELETE_TEXT                      : "¿Realmente desea eliminar la tienda <b>{0}</b>?",
    STORES_PUBLISH_NO_CHANGES               : "No hay cambios para publicar en la tienda <b>{0}</b>.",
    STORES_PUBLISH_CHANGES                  : "¿Realmente desea publicar los cambios de la tienda <b>{0}</b>?",
    STORES_CLEAR_CHANGES                    : "¿Realmente desea borrar los cambios de la tienda <b>{0}</b>?",
    STORES_ITS_NAME                         : "Nombre de la tienda",
    STORES_URL                              : "Url de la tienda",
    STORES_NONE_AVAILABLE                   : "No hay tiendas disponibles.",
    STORES_ERROR_CREATE                     : "No tiene permiso para crear la tienda indicada.",
    STORES_ERROR_EDIT                       : "No tiene permiso para editar la tienda indicada.",
    STORES_ERROR_EMPTY                      : "Debe indicar una tienda.",
    STORES_ERROR_EXISTS                     : "No existe la tienda indicada.",
    STORES_ERROR_NAME                       : "Debe indicar el nombre de la tienda.",
    STORES_SUCCESS_CREATE                   : "La tienda se creó correctamente.",
    STORES_SUCCESS_EDIT                     : "La tienda se editó correctamente.",
    STORES_SUCCESS_ACTIVATE                 : "La tienda se activó correctamente.",
    STORES_SUCCESS_PUBLISH                  : "Los cambios de la tienda se publicaron correctamente.",
    STORES_SUCCESS_CLEAR                    : "Los cambios de la tienda se borraron correctamente.",
    STORES_SUCCESS_PAUSE                    : "La tienda se pausó correctamente.",
    STORES_SUCCESS_DELETE                   : "La tienda se eliminó correctamente.",

    // Stores: Orders
    ORDERS_NAME                             : "Pedidos",
    ORDERS_SINGULAR                         : "Pedido",
    ORDERS_FILTER_TITLE                     : "Filtrar pedidos",
    ORDERS_VIEW_TITLE                       : "Ver pedido",
    ORDERS_EDIT_TITLE                       : "Editar pedido",
    ORDERS_ITS_NAME                         : "Pedido #{0}",
    ORDERS_NUMBER                           : "Número de pedido",
    ORDERS_ITEMS                            : "Artículos",
    ORDERS_ITEMS_JSON                       : "Artículos (JSON)",
    ORDERS_TOTAL_PRODUCTS                   : "Total productos",
    ORDERS_TOTAL_QUANTITY                   : "Total cantidad",
    ORDERS_TOTAL_PRICE                      : "Total precio",
    ORDERS_NONE_AVAILABLE                   : "No hay pedidos disponibles.",
    ORDERS_ERROR_EXISTS                     : "No existe el pedido indicado.",
    ORDERS_ERROR_EDIT                       : "No tiene permiso para editar el pedido indicado.",
    ORDERS_ERROR_CANCEL_REASON              : "Debe indicar el motivo de cancelación.",
    ORDERS_SUCCESS_EDIT                     : "El pedido se editó correctamente.",

    // Stores: Products
    PRODUCTS_NAME                           : "Productos",
    PRODUCTS_CREATE_TITLE                   : "Crear producto",
    PRODUCTS_EDIT_TITLE                     : "Editar producto",
    PRODUCTS_COPY_TITLE                     : "Copiar producto",
    PRODUCTS_DELETE_TITLE                   : "Eliminar producto",
    PRODUCTS_DELETE_TEXT                    : "¿Realmente desea eliminar el producto <b>{0}</b>?",
    PRODUCTS_IMAGE_TIP                      : "Tamaño recomendado: 500 x 500px",
    PRODUCTS_ID                             : "ID del producto",
    PRODUCTS_STOCK                          : "Stock",
    PRODUCTS_UNLIMITED                      : "Infinito",
    PRODUCTS_UNLIMITED_STOCK                : "Stock infinito",
    PRODUCTS_NONE_AVAILABLE                 : "No hay productos disponibles.",
    PRODUCTS_ERROR_EXISTS                   : "No existe el producto indicado.",
    PRODUCTS_ERROR_EDIT                     : "No tiene permiso para editar el producto indicado.",
    PRODUCTS_ERROR_DELETE                   : "No tiene permiso para eliminar el producto indicado.",
    PRODUCTS_ERROR_NAME                     : "Debe indicar el nombre del producto.",
    PRODUCTS_ERROR_EXTERNAL_ID              : "Ya existe un producto con el código indicado.",
    PRODUCTS_ERROR_STOCK                    : "El stock del producto debe ser un valor numérico mayor o igual a 0.",
    PRODUCTS_SUCCESS_CREATE                 : "El producto se creó correctamente.",
    PRODUCTS_SUCCESS_EDIT                   : "El producto se editó correctamente.",
    PRODUCTS_SUCCESS_DELETE                 : "El producto se eliminó correctamente.",

    // Stores: Categories
    CATEGORIES_NAME                         : "Categorías",
    CATEGORIES_SINGULAR                     : "Categoría",
    CATEGORIES_CREATE_TITLE                 : "Crear categoría",
    CATEGORIES_EDIT_TITLE                   : "Editar categoría",
    CATEGORIES_DELETE_TITLE                 : "Eliminar categoría",
    CATEGORIES_DELETE_TEXT                  : "¿Realmente desea eliminar la categoría <b>{0}</b>?",
    CATEGORIES_NONE_AVAILABLE               : "No hay categorías disponibles.",
    CATEGORIES_ERROR_EDIT                   : "No tiene permiso para editar la categoría indicada.",
    CATEGORIES_ERROR_DELETE                 : "No tiene permiso para eliminar la categoría indicada.",
    CATEGORIES_ERROR_EXISTS                 : "No existe la categoría indicada.",
    CATEGORIES_ERROR_NAME                   : "Debe indicar el nombre de la categoría.",
    CATEGORIES_ERROR_EXTERNAL_ID            : "Ya existe una categoría con el código indicado.",
    CATEGORIES_SUCCESS_CREATE               : "La categoría se creó correctamente.",
    CATEGORIES_SUCCESS_EDIT                 : "La categoría se editó correctamente.",
    CATEGORIES_SUCCESS_DELETE               : "La categoría se eliminó correctamente.",

    // Stores: Banners
    BANNERS_NAME                            : "Banners",
    BANNERS_SINGULAR                        : "Banner",
    BANNERS_CREATE_TITLE                    : "Crear banner",
    BANNERS_EDIT_TITLE                      : "Editar banner",
    BANNERS_DELETE_TITLE                    : "Eliminar banner",
    BANNERS_DELETE_TEXT                     : "¿Realmente desea eliminar el banner <b>{0}</b>?",
    BANNERS_IMAGE_MOBILE                    : "Imagen para celular",
    BANNERS_IMAGE_MOBILE_TIP                : "Tamaño recomendado: 600 x 400px",
    BANNERS_IMAGE_DESKTOP                   : "Imagen para escritorio",
    BANNERS_IMAGE_DESKTOP_TIP               : "Tamaño recomendado: 800 x 250px",
    BANNERS_LINK_TYPE                       : "Tipo de vínculo",
    BANNERS_NONE_AVAILABLE                  : "No hay banners disponibles.",
    BANNERS_ERROR_EXISTS                    : "No existe el banner indicado.",
    BANNERS_ERROR_EDIT                      : "No tiene permiso para editar el banner indicado.",
    BANNERS_ERROR_DELETE                    : "No tiene permiso para eliminar el banner indicado.",
    BANNERS_ERROR_NAME                      : "Debe completar el nombre del banner.",
    BANNERS_ERROR_IMAGE_MOBILE              : "Debe completar la imagen para celular del banner.",
    BANNERS_ERROR_IMAGE_DESKTOP             : "Debe completar la imagen para escritorio del banner.",
    BANNERS_ERROR_LINK_TYPE_EMPTY           : "Debe completar el tipo de vínculo del banner.",
    BANNERS_ERROR_LINK_TYPE_INVALID         : "El tipo de vínculo del banner es inválido.",
    BANNERS_ERROR_CATEGORY                  : "Debe completar la categoría del banner.",
    BANNERS_ERROR_URL                       : "Debe completar la URL del banner.",
    BANNERS_SUCCESS_CREATE                  : "El banner se creó correctamente.",
    BANNERS_SUCCESS_EDIT                    : "El banner se editó correctamente.",
    BANNERS_SUCCESS_DELETE                  : "El banner se eliminó correctamente.",

    // Stores: Personalize
    PERSONALIZE_NAME                        : "Personalizar",
    PERSONALIZE_MAIN_TITLE                  : "Principal",
    PERSONALIZE_MAIN_DESC                   : "Opciones de diseño generales",
    PERSONALIZE_FONT_FAMILY                 : "Tipografía",
    PERSONALIZE_HEADER_TITLE                : "Encabezado",
    PERSONALIZE_HEADER_DESC                 : "Opciones de diseño del encabezado",
    PERSONALIZE_HEADER_LEFT_ALIGN           : "Alinear encabezado a la izquierda",
    PERSONALIZE_HEADER_LOGO_FILE            : "Imagen del logo",
    PERSONALIZE_HEADER_LOGO_HEIGHT          : "Altura del logo",
    PERSONALIZE_HEADER_TEXT                 : "Texto del encabezado",
    PERSONALIZE_HEADER_BG_COLOR             : "Color del fondo del encabezado",
    PERSONALIZE_HEADER_TEXT_COLOR           : "Color del texto del encabezado",
    PERSONALIZE_SEARCH_TITLE                : "Buscador",
    PERSONALIZE_SEARCH_DESC                 : "Opciones de diseño del buscador",
    PERSONALIZE_SHOW_SEARCH                 : "Mostrar buscador",
    PERSONALIZE_RIGHT_SEARCH                : "Buscar del lado derecho",
    PERSONALIZE_SEARCH_BG_COLOR             : "Color de fondo del buscador",
    PERSONALIZE_SEARCH_TEXT_COLOR           : "Color del texto del buscador",
    PERSONALIZE_SEARCH_PLACEHOLDER          : "Texto del buscador",
    PERSONALIZE_SEARCH_RESULTS_TITLE        : "Título de resultados de búsqueda",
    PERSONALIZE_SEARCH_NONE_RESULTS         : "Texto cuando no hay resultados",
    PERSONALIZE_HOME_TITLE                  : "Inicio",
    PERSONALIZE_HOME_DESC                   : "Opciones de diseño del inicio",
    PERSONALIZE_SHOW_BANNERS                : "Mostrar banners",
    PERSONALIZE_AUTO_SLIDE_BANNERS          : "Mover automáticamente los banners",
    PERSONALIZE_TOTAL_CATEGORIES            : "Cantidad de categorías a mostrar",
    PERSONALIZE_TOTAL_CATEGORIES_TIP        : "Usar 0 para mostrar todas.",
    PERSONALIZE_ROWS_PER_CATEGORY           : "Filas por categoría",
    PERSONALIZE_ROWS_PER_CATEGORY_TIP       : "Usar 0 para mostrar todas.",
    PERSONALIZE_PRODUCTS_PER_CATEGORY       : "Productos por categoría",
    PERSONALIZE_PRODUCTS_PER_CATEGORY_TIP   : "Usar 0 para mostrar todos.",
    PERSONALIZE_CATEGORY_TITLE              : "Categorías",
    PERSONALIZE_CATEGORY_DESC               : "Opciones de la barra de categorías",
    PERSONALIZE_CATEGORIES_IN_HOME          : "Mostrar en el inicio",
    PERSONALIZE_CATEGORIES_IN_PRODUCTS      : "Mostrar en los productos",
    PERSONALIZE_CATEGORIES_AS_SQUARES       : "Mostrar como cuadrados",
    PERSONALIZE_CATEGORIES_TWO_ROWS         : "Mostrar en dos filas",
    PERSONALIZE_PRICES_TITLE                : "Precios",
    PERSONALIZE_PRICES_DESC                 : "Opciones para mostrar precios",
    PERSONALIZE_PRICES_IN_LISTS             : "Mostrar en listados",
    PERSONALIZE_PRICES_IN_DIALOGS           : "Mostrar en diálogos",
    PERSONALIZE_PRICES_IN_CART              : "Mostrar en el carrito",
    PERSONALIZE_BUTTONS_TITLE               : "Botones",
    PERSONALIZE_BUTTONS_DESC                : "Colores de los botones",
    PERSONALIZE_BUTTON_BG_COLOR             : "Color de fondo del botón",
    PERSONALIZE_BUTTON_TEXT_COLOR           : "Color del texto del botón",
    PERSONALIZE_BUY_BG_COLOR                : "Color de fondo del botón de compra",
    PERSONALIZE_BUY_TEXT_COLOR              : "Color del texto del botón de compra",
    PERSONALIZE_ORDER_TITLE                 : "Pedido",
    PERSONALIZE_ORDER_DESC                  : "Textos del pedido",
    PERSONALIZE_ORDER_ITS_TITLE             : "Título del pedido",
    PERSONALIZE_ORDER_EMPTY                 : "Texto cuando el pedido esta vacío",
    PERSONALIZE_ORDER_NOTE                  : "Nota cuando el pedido tiene productos",
    PERSONALIZE_CONFIRM_ORDER               : "Texto para confirmar pedido",
    PERSONALIZE_CANCEL_ORDER                : "Texto para cancelar pedido",



    // Contacts
    CONTACTS_NAME                           : "Contactos",
    CONTACTS_SINGULAR                       : "Contacto",
    CONTACTS_FILTER_TITLE                   : "Filtrar contactos",
    CONTACTS_EXPORT_TITLE                   : "Exportar contactos",
    CONTACTS_IMPORT_TITLE                   : "Importar contactos",
    CONTACTS_CREATE_TITLE                   : "Crear contacto",
    CONTACTS_VIEW_TITLE                     : "Ver contacto",
    CONTACTS_EDIT_TITLE                     : "Editar contacto",
    CONTACTS_MERGE_TITLE                    : "Combinar contactos",
    CONTACTS_MERGE_TEXT                     : "Seleccione los campos que quiere de cada contacto",
    CONTACTS_COMBINE_TITLE                  : "Contactos para combinar",
    CONTACTS_COMBINE_TEXT                   : "Seleccione los contactos que desea combinar con el contacto actual.",
    CONTACTS_BLOCK_TITLE                    : "Bloquear contacto",
    CONTACTS_BLOCK_TEXT                     : "¿Realmente desea bloquear el contacto <b>{0}</b>?<br><br>Si el contacto está bloqueado no se reciben sus mensajes ni se puede enviarle mensajes.",
    CONTACTS_UNBLOCK_TITLE                  : "Desbloquear contacto",
    CONTACTS_UNBLOCK_TEXT                   : "¿Realmente desea desbloquear el contacto <b>{0}</b>?",
    CONTACTS_DELETE_TITLE                   : "Eliminar contacto",
    CONTACTS_DELETE_TEXT                    : "¿Realmente desea eliminar el contacto <b>{0}</b>?",
    CONTACTS_MULTI_DELETE_TITLE             : "Eliminar contactos",
    CONTACTS_MULTI_DELETE_TEXT_SINGULAR     : "¿Realmente desea eliminar <b>{0}</b> contacto?",
    CONTACTS_MULTI_DELETE_TEXT_PLURAL       : "¿Realmente desea eliminar <b>{0}</b> contactos?",
    CONTACTS_ID                             : "ID del contacto",
    CONTACTS_ADD_EMAIL                      : "Agregar email",
    CONTACTS_ADD_CELLPHONE                  : "Agregar celular",
    CONTACTS_INSTAGRAM                      : "Instagram",
    CONTACTS_TELEGRAM                       : "Telegram",
    CONTACTS_ADDRESS                        : "Domicilio",
    CONTACTS_LATITUDE                       : "Latitud",
    CONTACTS_LONGITUDE                      : "Longitud",
    CONTACTS_DONT_SEND_CAMPAIGNS            : "No enviar campañas",
    CONTACTS_BLOCKED                        : "Contacto bloqueado",
    CONTACTS_IMPORT_FILE                    : "• Se pueden importar contactos desde un archivo Excel (.xlsx).<br>• Los datos deben estar en la primer hoja del excel.<br>• La primer fila debe contener el encabezado de las columnas y luego tener una fila por contacto.",
    CONTACTS_IMPORT_COLUMNS                 : "Por cada campo del contacto indique la columna del archivo.",
    CONTACTS_IMPORT_WARNING                 : "Se importaron <b>{0}</b> contactos de los <b>{1}</b> indicados. Los siguientes contactos no se pudieron importar por tener un email o celular repetido.",
    CONTACTS_IMPORT_SUCCESS                 : "Se importaron <b>{0}</b> contactos correctamente.",
    CONTACTS_IMPORT_CANCELED                : "La importación se canceló luego de importar <b>{0}</b> de <b>{1}</b> contactos.",
    CONTACTS_NONE_AVAILABLE                 : "No hay contactos disponibles.",
    CONTACTS_ERROR_IMPORT                   : "No tiene permiso para importar contactos.",
    CONTACTS_ERROR_COLUMNS_NAME             : "Debe indicar una columna como el nombre.",
    CONTACTS_ERROR_EDIT                     : "No tiene permiso para editar el contacto indicado.",
    CONTACTS_ERROR_EXISTS                   : "No existe el contacto indicado.",
    CONTACTS_ERROR_NAME                     : "Debe indicar el nombre o el apellido.",
    CONTACTS_ERROR_EMAIL                    : "Ya existe otro contacto con el email indicado.",
    CONTACTS_ERROR_CELLPHONE_NUMBER         : "El celular debe ser numérico.",
    CONTACTS_ERROR_CELLPHONE_REPEAT         : "Ya existe otro contacto con el celular indicado.",
    CONTACTS_ERROR_COUNTRY_EMPTY            : "Debe completar el código para el celular.",
    CONTACTS_ERROR_COUNTRY_INVALID          : "El código para el celular indicado no existe.",
    CONTACTS_ERROR_ORIGIN                   : "No existe el origen indicado.",
    CONTACTS_ERROR_EXTERNAL_ID              : "Ya existe un contacto con el código indicado.",
    CONTACTS_ERROR_MERGE                    : "No es posible combinar los contactos indicados.",
    CONTACTS_ERROR_BLOCK                    : "No es posible bloquear un contacto bloqueado.",
    CONTACTS_ERROR_UNBLOCK                  : "No es posible desbloquear un contacto no bloqueado.",
    CONTACTS_ERROR_DELETE                   : "No tiene permiso para eliminar el contacto indicado.",
    CONTACTS_ERROR_MULTI_DELETE             : "No es posible eliminar los contactos indicados.",
    CONTACTS_WARNING_MULTI_DELETE           : "Se eliminaron algunos de los contactos indicados pero no todos.",
    CONTACTS_SUCCESS_CREATE                 : "El contacto se creó correctamente.",
    CONTACTS_SUCCESS_EDIT                   : "El contacto se editó correctamente.",
    CONTACTS_SUCCESS_MERGE                  : "Los contactos se combinaron correctamente.",
    CONTACTS_SUCCESS_BLOCK                  : "El contacto se bloqueó correctamente.",
    CONTACTS_SUCCESS_UNBLOCK                : "El contacto se desbloqueó correctamente.",
    CONTACTS_SUCCESS_DELETE                 : "El contacto se eliminó correctamente.",
    CONTACTS_SUCCESS_MULTI_DELETE           : "Los contactos se eliminaron correctamente.",

    // Hospitality
    HOSPITALITY_NAME                        : "Hospitality",
    HOSPITALITY_SINGULAR                    : "Hospitality",
    HOSPITALITY_FILTER_TITLE                : "Filtrar hospitality",
    HOSPITALITY_VIEW_TITLE                  : "Ver hospitality",
    HOSPITALITY_CREATE_TITLE                : "Crear hospitality",
    HOSPITALITY_EDIT_TITLE                  : "Editar hospitality",
    HOSPITALITY_DELETE_TITLE                : "Eliminar hospitality",
    HOSPITALITY_DELETE_TEXT                 : "¿Realmente desea eliminar la hospitality <b>{0}</b>?",
    HOSPITALITY_IMPORT_TITLE                : "Importar hospitality",
    HOSPITALITY_EXPORT_TITLE                : "Exportar hospitality",
    HOSPITALITY_ID                          : "ID de hospitality",
    HOSPITALITY_STATUS                      : "Estado de hospitality",
    HOSPITALITY_BOOKING                     : "Reserva",
    HOSPITALITY_CHECK_IN                    : "Check IN",
    HOSPITALITY_CHECK_OUT                   : "Check OUT",
    HOSPITALITY_ITS_NAME                    : "{0} de {1}",
    HOSPITALITY_EXTERNAL_ID                 : "ID del registro en PMS",
    HOSPITALITY_GUEST_EXTERNAL_ID           : "ID del huésped",
    HOSPITALITY_FIRST_NAME                  : "Nombre del huésped",
    HOSPITALITY_LAST_NAME                   : "Apellido del huésped",
    HOSPITALITY_EMAIL                       : "Email del huésped",
    HOSPITALITY_CELLPHONE                   : "Celular del huésped",
    HOSPITALITY_LANGUAGE                    : "Idioma del huésped",
    HOSPITALITY_ROOM                        : "Habitación",
    HOSPITALITY_ROOM_NUMBER                 : "Número de habitación",
    HOSPITALITY_ARRIVAL                     : "Llegada",
    HOSPITALITY_ARRIVAL_DATE                : "Fecha de llegada",
    HOSPITALITY_DEPARTURE                   : "Salida",
    HOSPITALITY_DEPARTURE_DATE              : "Fecha de salida",
    HOSPITALITY_PIN_EXTERNAL_ID             : "PIN ID externo",
    HOSPITALITY_PIN_MAIN_NUMBER             : "PIN llave principal",
    HOSPITALITY_PIN_SEC_NUMBER              : "PIN llave secundaria",
    HOSPITALITY_NIGHTS_AMOUNT               : "Cantidad de noches",
    HOSPITALITY_NIGHTS_SINGULAR             : "{0} noche",
    HOSPITALITY_NIGHTS_PLURAL               : "{0} noches",
    HOSPITALITY_IMPORT_FILE                 : "• Se pueden importar hospitalities desde un archivo Excel (.xlsx).<br>• Los datos deben estar en la primer hoja del excel.<br>• La primer fila debe contener el encabezado de las columnas y luego tener una fila por hospitality.<br>• Se debe incluir los datos del huésped (contact).",
    HOSPITALITY_IMPORT_COLUMNS              : "Por cada campo de hospitality indique la columna del archivo.",
    HOSPITALITY_IMPORT_SUCCESS              : "Se importaron <b>{0}</b> hospitalities correctamente.",
    HOSPITALITY_IMPORT_CANCELED             : "La importación se canceló luego de importar <b>{0}</b> de <b>{1}</b> hospitalities.",
    HOSPITALITY_NONE_AVAILABLE              : "No hay hospitalities disponibles.",
    HOSPITALITY_ERROR_IMPORT                : "No tiene permiso para importar hospitality.",
    HOSPITALITY_ERROR_EDIT                  : "No tiene permiso para editar el hospitality indicado.",
    HOSPITALITY_ERROR_EXISTS                : "No existe el hospitality indicado.",
    HOSPITALITY_ERROR_CONTACT               : "Debe indicar un contacto.",
    HOSPITALITY_ERROR_ARRIVAL_DATE          : "Debe indicar una fecha de llegada correcta.",
    HOSPITALITY_ERROR_DEPARTURE_DATE        : "Debe indicar una fecha de salida correcta.",
    HOSPITALITY_ERROR_PERIOD                : "La fecha de salida debe ser posterior a la fecha de llegada.",
    HOSPITALITY_ERROR_DELETE                : "No tiene permiso para eliminar el hospitality indicado.",
    HOSPITALITY_SUCCESS_CREATE              : "El hospitality se creó correctamente.",
    HOSPITALITY_SUCCESS_EDIT                : "El hospitality se editó correctamente.",
    HOSPITALITY_SUCCESS_DELETE              : "El hospitality se eliminó correctamente.",

    // Quotations
    QUOTATIONS_SINGULAR                     : "Reserva",
    QUOTATIONS_ARRIVAL_DATE                 : "Llegada",
    QUOTATIONS_DEPARTURE_DATE               : "Salida",
    QUOTATIONS_GUEST_AMOUNT                 : "Huéspedes",
    QUOTATIONS_REGULAR_PRICE                : "Precio",
    QUOTATIONS_OFFER_PRICE                  : "Oferta",
    QUOTATIONS_BOOKING_URL                  : "Url para reservar",

    // Sales
    SALES_NAME                              : "Ventas",
    SALES_SINGULAR                          : "Venta",
    SALES_VIEW_TITLE                        : "Ver venta",
    SALES_FILTER_TITLE                      : "Filtrar ventas",
    SALES_ITS_NAME                          : "Venta {0}",
    SALES_NUMBER                            : "Número de venta",
    SALES_DISCOUNT                          : "Descuento",
    SALES_PAYMENT                           : "Pago",
    SALES_PAYMENT_STATUS                    : "Estado de pago",
    SALES_SHIPPING                          : "Envío",
    SALES_SHIPPING_STATUS                   : "Estado de envío",
    SALES_CHECKOUT_URL                      : "Url de checkout",
    SALES_NONE_AVAILABLE                    : "No hay ventas disponibles.",



    // Accounts
    ACCOUNTS_NAME                           : "Cuentas",
    ACCOUNTS_SINGULAR                       : "Cuenta",
    ACCOUNTS_FILTER_TITLE                   : "Filtrar cuentas",
    ACCOUNTS_VIEW_TITLE                     : "Ver cuenta",
    ACCOUNTS_CREATE_TITLE                   : "Crear cuenta",
    ACCOUNTS_EDIT_TITLE                     : "Editar cuenta",
    ACCOUNTS_DELETE_TITLE                   : "Eliminar cuenta",
    ACCOUNTS_DELETE_TEXT                    : "¿Realmente desea eliminar la cuenta <b>{0}</b>?",
    ACCOUNTS_IMPORT_TITLE                   : "Importar cuentas",
    ACCOUNTS_EXPORT_TITLE                   : "Exportar cuentas",
    ACCOUNTS_ASSIGN_ACCOUNT_TITLE           : "Asignar cuenta",
    ACCOUNTS_ASSIGN_ACCOUNT_TEXT            : "Busque una cuenta para asignar al contacto: <b>{0}</b>",
    ACCOUNTS_ASSIGN_CONTACT_TITLE           : "Asignar contacto",
    ACCOUNTS_ASSIGN_CONTACT_TEXT            : "Busque un contacto para asignar a la cuenta: <b>{0}</b>",
    ACCOUNTS_UNASSIGN_ACCOUNT_TITLE         : "Quitar cuenta",
    ACCOUNTS_UNASSIGN_ACCOUNT_TEXT          : "¿Realmente desea quitar la cuenta del contacto?",
    ACCOUNTS_UNASSIGN_CONTACT_TITLE         : "Quitar contacto",
    ACCOUNTS_UNASSIGN_CONTACT_TEXT          : "¿Realmente desea quitar el contacto de la cuenta?",
    ACCOUNTS_ID                             : "ID de la cuenta",
    ACCOUNTS_EXTERNAL_ID                    : "Nº de cuenta",
    ACCOUNTS_ACTIVE_ID                      : "Cuenta activa",
    ACCOUNTS_AMOUNT                         : "Cantidad de cuentas",
    ACCOUNTS_CONTACTS_SINGULAR              : "{0} contacto",
    ACCOUNTS_CONTACTS_PLURAL                : "{0} contactos",
    ACCOUNTS_IMPORT_FILE                    : "• Se pueden importar cuentas desde un archivo Excel (.xlsx).<br>• Los datos deben estar en la primer hoja del excel.<br>• La primer fila debe contener el encabezado de las columnas y luego tener una fila por cuenta.",
    ACCOUNTS_IMPORT_COLUMNS                 : "Por cada campo de la cuenta indique la columna del archivo.",
    ACCOUNTS_IMPORT_SUCCESS                 : "Se importaron <b>{0}</b> cuentas correctamente.",
    ACCOUNTS_IMPORT_CANCELED                : "La importación se canceló luego de importar <b>{0}</b> de <b>{1}</b> cuentas.",
    ACCOUNTS_NONE_AVAILABLE                 : "No hay cuentas disponibles.",
    ACCOUNTS_ERROR_EXISTS                   : "No existe la cuenta indicada.",
    ACCOUNTS_ERROR_EDIT                     : "No tiene permiso para editar la cuenta indicada.",
    ACCOUNTS_ERROR_NAME                     : "Debe indicar el nombre de la cuenta.",
    ACCOUNTS_ERROR_EXTERNAL_ID              : "Ya existe una cuenta con el código indicado.",
    ACCOUNTS_ERROR_DELETE                   : "No tiene permiso para eliminar la cuenta indicada.",
    ACCOUNTS_ERROR_IMPORT                   : "No tiene permiso para importar cuentas.",
    ACCOUNTS_ERROR_COLUMNS_NAME             : "Debe indicar una columna como el nombre.",
    ACCOUNTS_ERROR_AMOUNT                   : "Debe indicar una cantidad de cuentas válida.",
    ACCOUNTS_SUCCESS_CREATE                 : "La cuenta se creó correctamente.",
    ACCOUNTS_SUCCESS_EDIT                   : "La cuenta se editó correctamente.",
    ACCOUNTS_SUCCESS_ASSIGN                 : "La cuenta se asignó correctamente.",
    ACCOUNTS_SUCCESS_UNASSIGN               : "La cuenta se quitó correctamente.",
    ACCOUNTS_SUCCESS_DELETE                 : "La cuenta se eliminó correctamente.",



    // Campaigns
    CAMPAIGNS_NAME                          : "Campañas",
    CAMPAIGNS_SINGULAR                      : "Campaña",
    CAMPAIGNS_VIEW_TITLE                    : "Ver campaña",
    CAMPAIGNS_CREATE_TITLE                  : "Crear campaña",
    CAMPAIGNS_CREATE_TEXT                   : "Indique el nombre de la campaña para iniciar la creación.",
    CAMPAIGNS_EDIT_TITLE                    : "Editar campaña",
    CAMPAIGNS_ACTIVATE_TITLE                : "Activar campaña",
    CAMPAIGNS_ACTIVATE_TEXT                 : "¿Realmente desea activar la campaña <b>{0}</b>?",
    CAMPAIGNS_ACTIVATE_SEND_MULTI           : "<br><br>Tenga en cuenta que esta campaña se enviara múltiples veces a un total de <b>{0}</b> contactos.",
    CAMPAIGNS_ACTIVATE_SEND_NOW             : "<br><br>Tenga en cuenta que esta campaña se enviara por única vez a un total de <b>{0}</b> contactos.",
    CAMPAIGNS_ACTIVATE_SEND_SMS             : "<br><br>Esta campaña tendrá un costo adicional por cada <b>SMS</b> enviado.",
    CAMPAIGNS_ACTIVATE_SEND_WHATSAPP        : "<br><br>Esta campaña tendrá un costo adicional por cada <b>WhatsApp</b> enviado.",
    CAMPAIGNS_PAUSE_TITLE                   : "Pausar campaña",
    CAMPAIGNS_PAUSE_TEXT                    : "¿Realmente desea pausar la campaña <b>{0}</b>?",
    CAMPAIGNS_COMPLETE_TITLE                : "Completar campaña",
    CAMPAIGNS_COMPLETE_TEXT                 : "¿Realmente desea completar la campaña <b>{0}</b>?",
    CAMPAIGNS_DELETE_TITLE                  : "Eliminar campaña",
    CAMPAIGNS_DELETE_TEXT                   : "¿Realmente desea eliminar la campaña <b>{0}</b>?",
    CAMPAIGNS_ERRORS_TITLE                  : "Errores en la campaña",
    CAMPAIGNS_ERRORS_TEXT                   : "Hay errores en la campaña. Por favor arregle los problemas antes de continuar.",
    CAMPAIGNS_ERRORS_FLOW                   : "Hay errores en el flujo de la campaña. Por favor arregle los problemas antes de continuar.",
    CAMPAIGNS_PUBLISH_NO_CHANGES            : "No hay cambios para publicar en la campaña <b>{0}</b>.",
    CAMPAIGNS_PUBLISH_CHANGES               : "¿Realmente desea publicar los cambios de la campaña <b>{0}</b>?",
    CAMPAIGNS_PUBLISH_FLOW_CHANGES          : "¿Realmente desea publicar los cambios del <b>flujo</b> de la campaña <b>{0}</b>?",
    CAMPAIGNS_CLEAR_CHANGES                 : "¿Realmente desea borrar los cambios de la campaña <b>{0}</b>?",
    CAMPAIGNS_CLEAR_FLOW_CHANGES            : "¿Realmente desea borrar los cambios del <b>flujo</b> de la campaña <b>{0}</b>?",
    CAMPAIGNS_MAIN_TEXT                     : "Nombre y fechas de inicio y cierre",
    CAMPAIGNS_START_TIME                    : "Fecha de inicio",
    CAMPAIGNS_END_TIME                      : "Fecha de finalización",
    CAMPAIGNS_CHANNELS_TITLE                : "Canales",
    CAMPAIGNS_CHANNELS_TEXT                 : "¿Cómo se envía la campaña?",
    CAMPAIGNS_EMAIL_TITLE                   : "Email",
    CAMPAIGNS_EMAIL_TEXT                    : "Permite enviar la campaña por Email a los contactos que tienen al menos un email.",
    CAMPAIGNS_EMAIL_WAIT                    : "Cuando se configure un canal de Email, podrá enviar la campaña por Email a los contactos que tienen al menos un email.",
    CAMPAIGNS_EMAIL_CONFIGURE               : "Configure su primer canal de Email para enviar la campaña por Email a los contactos que tienen al menos un email.",
    CAMPAIGNS_EMAIL_ACTIVATE                : "Activar envío por Email",
    CAMPAIGNS_SMS_TITLE                     : "SMS",
    CAMPAIGNS_SMS_TEXT                      : "Permite enviar la campaña por SMS a los contactos que tienen al menos un celular.",
    CAMPAIGNS_SMS_WAIT                      : "Cuando se configure un canal de SMS, podrá enviar la campaña por SMS a los contactos que tienen al menos un celular.",
    CAMPAIGNS_SMS_CONFIGURE                 : "Configure su primer canal de SMS para enviar la campaña por SMS a los contactos que tienen al menos un celular.",
    CAMPAIGNS_SMS_ACTIVATE                  : "Activar envío por SMS",
    CAMPAIGNS_WHATSAPP_TITLE                : "WhatsApp",
    CAMPAIGNS_WHATSAPP_TEXT                 : "Permite enviar la campaña por WhatsApp a los contactos que tienen al menos un celular.",
    CAMPAIGNS_WHATSAPP_WAIT                 : "Cuando se configure un canal de WhatsApp, podrá enviar la campaña por WhatsApp a los contactos que tienen al menos un celular.",
    CAMPAIGNS_WHATSAPP_CONFIGURE            : "Configure su primer canal de WhatsApp para enviar la campaña por WhatsApp a los contactos que tienen al menos un celular.",
    CAMPAIGNS_WHATSAPP_ACTIVATE             : "Activar envío por WhatsApp",
    CAMPAIGNS_PRIORITY                      : "Prioridad de envío",
    CAMPAIGNS_NONE_AVAILABLE                : "No hay campañas disponibles.",
    CAMPAIGNS_ERROR_EDIT                    : "No tiene permiso para editar la campaña indicada.",
    CAMPAIGNS_ERROR_EXISTS                  : "No existe la campaña indicada.",
    CAMPAIGNS_ERROR_NAME                    : "Debe indicar el nombre de la campaña.",
    CAMPAIGNS_ERROR_END_TIME_NOW            : "La fecha de finalización debe ser posterior al dia actual.",
    CAMPAIGNS_ERROR_END_TIME_START          : "La fecha de finalización debe ser posterior a la fecha de inicio.",
    CAMPAIGNS_ERROR_CHANNELS                : "Debe activar el envío por al menos un canal.",
    CAMPAIGNS_ERROR_PRIORITY                : "Debe indicar una prioridad válida.",
    CAMPAIGNS_ERROR_MESSAGE                 : "Debe indicar un mensaje para cada idioma.",
    CAMPAIGNS_ERROR_FILE                    : "Debe indicar un archivo para cada idioma.",
    CAMPAIGNS_ERROR_EMAIL_SUBJECT           : "Debe indicar el asunto del email para cada idioma.",
    CAMPAIGNS_ERROR_EMAIL_BODY              : "Debe indicar el cuerpo del email para cada idioma.",
    CAMPAIGNS_ERROR_WABA_TEMPLATE_ID        : "Debe indicar una plantilla de WhatsApp para cada idioma.",
    CAMPAIGNS_SUCCESS_CREATE                : "La campaña se creó correctamente.",
    CAMPAIGNS_SUCCESS_ACTIVATE              : "La campaña se activó correctamente.",
    CAMPAIGNS_SUCCESS_PAUSE                 : "La campaña se pausó correctamente.",
    CAMPAIGNS_SUCCESS_COMPLETE              : "La campaña se completó correctamente.",
    CAMPAIGNS_SUCCESS_PUBLISH               : "Los cambios de la campaña se publicaron correctamente.",
    CAMPAIGNS_SUCCESS_CLEAR                 : "Los cambios de la campaña se borraron correctamente.",
    CAMPAIGNS_SUCCESS_DELETE                : "La campaña se eliminó correctamente.",

    // Campaigns: Executions
    CAMPAIGNS_EXECUTIONS_NAME               : "Envíos",
    CAMPAIGNS_EXECUTIONS_VIEW_TITLE         : "Ver envío",
    CAMPAIGNS_EXECUTIONS_DELETE_TITLE       : "Eliminar envío",
    CAMPAIGNS_EXECUTIONS_DELETE_TEXT        : "¿Realmente desea eliminar el envío al contacto <b>{0}</b>?",
    CAMPAIGNS_EXECUTIONS_DELIVERED_AT       : "Entregado el",
    CAMPAIGNS_EXECUTIONS_READ_AT            : "Leído el",
    CAMPAIGNS_EXECUTIONS_RESPONDED_AT       : "Respondido el",
    CAMPAIGNS_EXECUTIONS_DONT_SEND          : "Pedido no enviar el",
    CAMPAIGNS_EXECUTIONS_ERROR_MESSAGE      : "Mensaje de error",
    CAMPAIGNS_EXECUTIONS_NONE_AVAILABLE     : "No hay envíos disponibles.",
    CAMPAIGNS_EXECUTIONS_ERROR_EXISTS       : "No existe el envío indicado.",
    CAMPAIGNS_EXECUTIONS_ERROR_DELETE       : "No puede eliminar un envío ya realizado.",
    CAMPAIGNS_EXECUTIONS_SUCCESS_DELETE     : "El envío se eliminó correctamente.",

    // Campaigns: Triggers
    CAMPAIGNS_TRIGGERS_NAME                 : "Triggers",
    CAMPAIGNS_TRIGGERS_SINGULAR             : "Trigger",
    CAMPAIGNS_TRIGGERS_TEXT                 : "¿Cuándo se envía la campaña?",
    CAMPAIGNS_TRIGGERS_ADD_TITLE            : "Agregar trigger",
    CAMPAIGNS_TRIGGERS_DAYS                 : "En x días",
    CAMPAIGNS_TRIGGERS_NONE_AVAILABLE       : "No hay triggers disponibles.",
    CAMPAIGNS_TRIGGERS_ERROR_EXISTS         : "No existe el trigger indicado.",
    CAMPAIGNS_TRIGGERS_ERROR_EMPTY          : "Debe indicar al menos un trigger.",
    CAMPAIGNS_TRIGGERS_ERROR_TRIGGER        : "Debe indicar un trigger válido.",
    CAMPAIGNS_TRIGGERS_ERROR_DAY            : "Debe indicar un día válido.",
    CAMPAIGNS_TRIGGERS_ERROR_DELETE         : "No tiene permiso para eliminar el trigger indicado.",

    // Campaigns: Conditions
    CAMPAIGNS_CONDITIONS_NAME               : "Condiciones",
    CAMPAIGNS_CONDITIONS_TEXT               : "¿A quienes se envía la campaña?",
    CAMPAIGNS_CONDITIONS_CONTACTS           : "Las condiciones indicadas suman un conjunto de <b>{0}</b> contactos",
    CAMPAIGNS_CONDITIONS_ADD_GROUP_TITLE    : "Agregar grupo",
    CAMPAIGNS_CONDITIONS_NONE_AVAILABLE     : "No hay condiciones disponibles.",
    CAMPAIGNS_CONDITIONS_ERROR_EXISTS       : "No existe la condición indicada.",
    CAMPAIGNS_CONDITIONS_ERROR_EMPTY        : "Debe indicar al menos una condición.",
    CAMPAIGNS_CONDITIONS_ERROR_EXISTS_GROUP : "No existe el grupo de condiciones indicado.",
    CAMPAIGNS_CONDITIONS_ERROR_DELETE       : "No tiene permiso para eliminar la condición indicada.",
    CAMPAIGNS_CONDITIONS_ERROR_DELETE_GROUP : "No tiene permiso para eliminar el grupo de condiciones indicado.",



    // Users
    USERS_NAME                              : "Usuarios",
    USERS_SINGULAR                          : "Usuario",
    USERS_FILTER_TITLE                      : "Filtrar usuarios",
    USERS_VIEW_TITLE                        : "Ver usuario",
    USERS_CREATE_TITLE                      : "Crear usuario",
    USERS_INVITE_TITLE                      : "Invitar usuario",
    USERS_INVITED_TITLE                     : "Usuario invitado",
    USERS_INVITED_TEXT                      : "Se envió un email al usuario con una url a la cual debe acceder para completar la invitación.<br><br>La invitación dura <b>7 días</b>. Cuando se vence el usuario no va a poder usar el vínculo de invitado, pero puede enviar uno nuevo.",
    USERS_EDIT_TITLE                        : "Editar usuario",
    USERS_EDIT_TEXT                         : "Estas editando el usuario: {0}",
    USERS_DELETE_TITLE                      : "Eliminar usuario",
    USERS_DELETE_TEXT                       : "¿Realmente desea eliminar el usuario <b>{0}</b>?",
    USERS_RESEND_TITLE                      : "Reenviar invitación",
    USERS_RESEND_TEXT                       : "¿Realmente desea reenviar la invitación al usuario <b>{0}</b>?",
    USERS_EXPORT_TITLE                      : "Exportar usuarios",
    USERS_SEARCH                            : "Buscar usuario",
    USERS_NEW                               : "Crear uno nuevo",
    USERS_FULL_NAME                         : "Nombre y apellido del usuario",
    USERS_FIRST_NAME                        : "Nombre del usuario",
    USERS_LAST_NAME                         : "Apellido del usuario",
    USERS_STATUS                            : "Desactivar el usuario",
    USERS_RANDOM_USER                       : "Usuario aleatorio",
    USERS_NONE_AVAILABLE                    : "No hay usuarios disponibles.",
    USERS_ERROR_EDIT                        : "No tiene permiso para editar el usuario indicado.",
    USERS_ERROR_EXISTS                      : "No existe el usuario indicado.",
    USERS_ERROR_EMPTY                       : "Debe indicar el usuario.",
    USERS_ERROR_EMAIL_USED                  : "Ya existe un usuario con el email indicado.",
    USERS_ERROR_EMAIL_ADMIN                 : "No se puede enviar la invitación al usuario indicado.",
    USERS_ERROR_CREDENTIAL_EMPTY            : "Debe indicar un usuario o crearlo.",
    USERS_ERROR_CREDENTIAL_USED             : "Ya existe un usuario con la credencial indicada.",
    USERS_ERROR_CREDENTIAL_ADMIN            : "No se puede asociar un admin a un usuario.",
    USERS_ERROR_FLOW_INACTIVE               : "No puede marcar el usuario como inactivo al estar usado en un flujo.",
    USERS_ERROR_FLOW_DELETE                 : "No puede eliminar el usuario al estar usado en un flujo.",
    USERS_ERROR_DELETE                      : "No tiene permiso para eliminar el usuario indicado.",
    USERS_SUCCESS_CREATE                    : "El usuario se creó correctamente.",
    USERS_SUCCESS_INVITE                    : "El usuario fué invitado correctamente.",
    USERS_SUCCESS_RESEND_INVITE             : "La invitación se envió al usuario correctamente.",
    USERS_SUCCESS_EDIT                      : "El usuario se editó correctamente.",
    USERS_SUCCESS_DELETE                    : "El usuario se eliminó correctamente.",

    // Teams
    TEAMS_NAME                              : "Equipos",
    TEAMS_SINGULAR                          : "Equipo",
    TEAMS_VIEW_TITLE                        : "Ver equipo",
    TEAMS_CREATE_TITLE                      : "Crear equipo",
    TEAMS_EDIT_TITLE                        : "Editar equipo",
    TEAMS_USER_TITLE                        : "Actualizar equipos",
    TEAMS_USER_TEXT                         : "Actualizar los equipos del usuario: {0}",
    TEAMS_DELETE_TITLE                      : "Eliminar equipo",
    TEAMS_DELETE_TEXT                       : "¿Realmente desea eliminar el equipo <b>{0}</b>?",
    TEAMS_USERS                             : "Usuarios del equipo",
    TEAMS_USERS_SINGULAR                    : "{0} usuario",
    TEAMS_USERS_PLURAL                      : "{0} usuarios",
    TEAMS_TEAMS_SINGULAR                    : "{0} equipo",
    TEAMS_TEAMS_PLURAL                      : "{0} equipos",
    TEAMS_NONE_AVAILABLE                    : "No hay equipos disponibles.",
    TEAMS_ERROR_EDIT                        : "No tiene permiso para editar el equipo indicado.",
    TEAMS_ERROR_EXISTS                      : "No existe el equipo indicado.",
    TEAMS_ERROR_SOME_EXISTS                 : "Alguno de los equipos indicados no existe.",
    TEAMS_ERROR_NAME                        : "Debe indicar el nombre del equipo.",
    TEAMS_ERROR_USER_EXISTS                 : "Alguno de los usuarios indicados no existe.",
    TEAMS_ERROR_FLOW_INACTIVE               : "No puede marcar el equipo como inactivo al estar usado en un flujo.",
    TEAMS_ERROR_FLOW_DELETE                 : "No puede eliminar el equipo al estar usado en un flujo.",
    TEAMS_ERROR_DELETE                      : "No tiene permiso para eliminar el equipo indicado.",
    TEAMS_SUCCESS_CREATE                    : "El equipo se creó correctamente.",
    TEAMS_SUCCESS_EDIT                      : "El equipo se editó correctamente.",
    TEAMS_SUCCESS_USER                      : "Se actualizaron los equipos del usuario correctamente.",
    TEAMS_SUCCESS_DELETE                    : "El equipo se eliminó correctamente.",

    // Tags
    TAGS_NAME                               : "Etiquetas",
    TAGS_SINGULAR                           : "Etiqueta",
    TAGS_ADD_TITLE                          : "Agregar etiqueta",
    TAGS_CREATE_TITLE                       : "Crear etiqueta",
    TAGS_EDIT_TITLE                         : "Editar etiqueta",
    TAGS_DELETE_TITLE                       : "Eliminar etiqueta",
    TAGS_DELETE_TEXT                        : "¿Realmente desea eliminar la etiqueta <b>{0}</b>?",
    TAGS_NONE_AVAILABLE                     : "No hay etiquetas disponibles.",
    TAGS_ERROR_EDIT                         : "No tiene permiso para editar la etiqueta indicada.",
    TAGS_ERROR_EXISTS                       : "No existe la etiqueta indicada.",
    TAGS_ERROR_NAME                         : "Debe indicar el nombre de la etiqueta.",
    TAGS_ERROR_DELETE                       : "No tiene permiso para eliminar la etiqueta indicada.",
    TAGS_ERROR_FLOW                         : "No puede eliminar la etiqueta al estar usada en un flujo.",
    TAGS_SUCCESS_CREATE                     : "La etiqueta se creó correctamente.",
    TAGS_SUCCESS_EDIT                       : "La etiqueta se editó correctamente.",
    TAGS_SUCCESS_DELETE                     : "La etiqueta se eliminó correctamente.",

    // Schedules
    SCHEDULES_NAME                          : "Horarios de atención",
    SCHEDULES_SINGULAR                      : "Horario de atención",
    SCHEDULES_SHORT                         : "Horario",
    SCHEDULES_CREATE_TITLE                  : "Crear horario de atención",
    SCHEDULES_EDIT_TITLE                    : "Editar horario de atención",
    SCHEDULES_COPY_TITLE                    : "Copiar horario de atención",
    SCHEDULES_DELETE_TITLE                  : "Eliminar horario de atención",
    SCHEDULES_DELETE_TEXT                   : "¿Realmente desea eliminar el horario de atención <b>{0}</b>?",
    SCHEDULES_MAIN_TEXT                     : "Nombre y usar por defecto",
    SCHEDULES_SHOW_TIME_ZONE                : "Mostrar uso horario en la variable",
    SCHEDULES_PERIODS_TITLE                 : "Periodos",
    SCHEDULES_PERIODS_TEXT                  : "Días y horarios",
    SCHEDULES_ASSIGNS_TITLE                 : "Asignaciones",
    SCHEDULES_ASSIGNS_TEXT                  : "Asignar equipos",
    SCHEDULES_PERIOD_TITLE                  : "Periodo {0}",
    SCHEDULES_ADD_PERIOD                    : "Agregar periodo",
    SCHEDULES_DATE_TIME                     : "{0} de {1} a {2}",
    SCHEDULES_NONE_AVAILABLE                : "No hay horarios de atención disponibles.",
    SCHEDULES_ERROR_EDIT                    : "No tiene permiso para editar el horario de atención indicado.",
    SCHEDULES_ERROR_EXISTS                  : "No existe el horario de atención indicado.",
    SCHEDULES_ERROR_NAME                    : "Debe indicar el nombre del horario de atención.",
    SCHEDULES_ERROR_DAYS_EMPTY              : "Debe indicar al menos un día.",
    SCHEDULES_ERROR_DAYS_INVALID            : "Uno de los días indicados es incorrecto.",
    SCHEDULES_ERROR_FROM_TIME               : "El tiempo desde indicado es incorrecto.",
    SCHEDULES_ERROR_TO_TIME                 : "El tiempo hasta indicado es incorrecto.",
    SCHEDULES_ERROR_PERIOD                  : "El tiempo desde debe ser anterior al tiempo hasta.",
    SCHEDULES_ERROR_REPEAT                  : "Alguno de los equipos indicados está usando otro horario.",
    SCHEDULES_ERROR_FLOW                    : "No puede eliminar el horario de atención al estar usado en un flujo.",
    SCHEDULES_ERROR_LAST                    : "No se puede eliminar el último horario de atención.",
    SCHEDULES_ERROR_DELETE                  : "No tiene permiso para eliminar el horario de atención indicado.",
    SCHEDULES_SUCCESS_CREATE                : "El horario de atención se creó correctamente.",
    SCHEDULES_SUCCESS_EDIT                  : "El horario de atención se editó correctamente.",
    SCHEDULES_SUCCESS_DELETE                : "El horario de atención se eliminó correctamente.",

    // Message Templates
    MSG_TEMPLATES_NAME                      : "Plantillas de mensajes",
    MSG_TEMPLATES_SINGULAR                  : "Plantilla de mensaje",
    MSG_TEMPLATES_CREATE_TITLE              : "Crear plantilla de mensaje",
    MSG_TEMPLATES_EDIT_TITLE                : "Editar plantilla de mensaje",
    MSG_TEMPLATES_COPY_TITLE                : "Copiar plantilla de mensaje",
    MSG_TEMPLATES_DELETE_TITLE              : "Eliminar plantilla de mensaje",
    MSG_TEMPLATES_DELETE_TEXT               : "¿Realmente desea eliminar la plantilla de mensaje <b>{0}</b>?",
    MSG_TEMPLATES_MAIN_TEXT                 : "Nombre, posición, estado.",
    MSG_TEMPLATES_MESSAGE_TEXT              : "Mensaje e imagen.",
    MSG_TEMPLATES_ASSIGN_TITLE              : "Asignación",
    MSG_TEMPLATES_ASSIGN_TEXT               : "Equipos asignados.",
    MSG_TEMPLATES_ASSIGN_HOTEL              : "Equipos y hoteles asignados.",
    MSG_TEMPLATES_NONE_AVAILABLE            : "No hay plantillas de mensajes disponibles.",
    MSG_TEMPLATES_ERROR_EDIT                : "No tiene permiso para editar la plantilla de mensaje indicada.",
    MSG_TEMPLATES_ERROR_EXISTS              : "No existe la plantilla de mensaje indicada.",
    MSG_TEMPLATES_ERROR_NAME                : "Debe indicar el nombre de la plantilla de mensaje.",
    MSG_TEMPLATES_ERROR_MESSAGE             : "Debe indicar el mensaje de la plantilla de mensaje.",
    MSG_TEMPLATES_ERROR_LENGTH              : "La longitud máxima del mensaje es <b>{0} caracteres</b> debido a los límites de los canales sociales.",
    MSG_TEMPLATES_ERROR_DELETE              : "No tiene permiso para eliminar la plantilla de mensaje indicada.",
    MSG_TEMPLATES_ERROR_FLOW                : "No puede eliminar la plantilla de mensaje al estar usado en un flujo.",
    MSG_TEMPLATES_SUCCESS_CREATE            : "La plantilla de mensaje se creó correctamente.",
    MSG_TEMPLATES_SUCCESS_EDIT              : "La plantilla de mensaje se editó correctamente.",
    MSG_TEMPLATES_SUCCESS_DELETE            : "La plantilla de mensaje se eliminó correctamente.",

    // WABA Templates
    WABA_TEMPLATES_NAME                     : "Plantillas de WhatsApp",
    WABA_TEMPLATES_SINGULAR                 : "Plantilla de WhatsApp",
    WABA_TEMPLATES_TEMPLATE                 : "Plantilla",
    WABA_TEMPLATES_CREATE_TITLE             : "Crear plantilla de WhatsApp",
    WABA_TEMPLATES_EDIT_TITLE               : "Editar plantilla de WhatsApp",
    WABA_TEMPLATES_COPY_TITLE               : "Copiar plantilla de WhatsApp",
    WABA_TEMPLATES_IMPORT_TITLE             : "Importar plantilla de WhatsApp",
    WABA_TEMPLATES_IMPORT_TEXT              : "Puede importar una plantilla de WhatsApp que está en la cuenta de Meta y no aquí.",
    WABA_TEMPLATES_DELETE_TITLE             : "Eliminar plantilla de WhatsApp",
    WABA_TEMPLATES_DELETE_TEXT              : "¿Realmente desea eliminar la plantilla de WhatsApp <b>{0}</b>?",
    WABA_TEMPLATES_CANT_EDIT                : "Solo puede editar las variables mientras la plantilla esta siendo revisada por WhatsApp.",
    WABA_TEMPLATES_CANT_HOURS               : "Una plantilla aprobada solamente se puede editar una vez cada 24 horas. Puede editar solamente las variables en este momento.",
    WABA_TEMPLATES_REJECTED                 : "<b>Se rechazó la plantilla por el motivo:</b> {0}.",
    WABA_TEMPLATES_MAIN_TEXT                : "Canal, categoría y nombre",
    WABA_TEMPLATES_FOR_AUTOMATIONS          : "Sólo para automatizaciones",
    WABA_TEMPLATES_HEADER_TITLE             : "Encabezado del mensaje",
    WABA_TEMPLATES_HEADER_TEXT              : "Agregue un título o elija un medio para el encabezado",
    WABA_TEMPLATES_BODY_TITLE               : "Cuerpo del mensaje",
    WABA_TEMPLATES_BODY_TEXT                : "Ingrese el texto para su mensaje",
    WABA_TEMPLATES_FOOTER_TITLE             : "Pie del mensaje",
    WABA_TEMPLATES_FOOTER_TEXT              : "Agregue una línea corta de texto al final del mensaje",
    WABA_TEMPLATES_BUTTONS_TITLE            : "Botones del mensaje",
    WABA_TEMPLATES_BUTTONS_TEXT             : "Cree botones que permitan a los clientes responder a su mensaje",
    WABA_TEMPLATES_HEADER_TYPE              : "Tipo de encabezado",
    WABA_TEMPLATES_HEADER_FILE              : "Archivo del encabezado",
    WABA_TEMPLATES_HEADER_LATITUDE          : "Latitud de la ubicación",
    WABA_TEMPLATES_HEADER_LONGITUDE         : "Longitud de la ubicación",
    WABA_TEMPLATES_HEADER_LOCATION          : "Nombre de la ubicación",
    WABA_TEMPLATES_HEADER_ADDRESS           : "Dirección de la ubicación",
    WABA_TEMPLATES_HEADER_MY_LOCATION       : "Usar mi ubicación",
    WABA_TEMPLATES_SAMPLE                   : "Ejemplo para la variable {{{0}}}",
    WABA_TEMPLATES_VALUE                    : "Valor para la variable {{{0}}}",
    WABA_TEMPLATES_LINKS                    : "Botones como vínculos",
    WABA_TEMPLATES_LINK_TITLE               : "Vínculo {0}",
    WABA_TEMPLATES_ADD_LINK                 : "Agregar vínculo",
    WABA_TEMPLATES_BUTTON_TEXT              : "Texto del vínculo",
    WABA_TEMPLATES_PHONE_NUMBER             : "Número de teléfono",
    WABA_TEMPLATES_URL                      : "Url del sitio",
    WABA_TEMPLATES_SHOW_LINKS_FIRST         : "Mostrar los vínculos primero",
    WABA_TEMPLATES_ADD_DONT_SEND_BUTTON     : "Agregar botón de no enviar",
    WABA_TEMPLATES_DONT_SEND_BUTTON_TEXT    : "Texto botón no enviar",
    WABA_TEMPLATES_DONT_SEND_TEXT           : "No me interesa",
    WABA_TEMPLATES_SEE_ALL_OPTIONS          : "Mostrar opciones",
    WABA_TEMPLATES_SAVE_DRAFT               : "Guardar borrador",
    WABA_TEMPLATES_EMPTY_IMPORT             : "No hay plantillas en WhatsApp que se pueden importar al canal.",
    WABA_TEMPLATES_EMPTY_SEND               : "No hay plantillas de WhatsApp aprobadas disponibles. Para enviar un nuevo mensaje debe usar una plantilla de WhatsApp. Estas pueden ser creadas desde la configuración.",
    WABA_TEMPLATES_NONE_AVAILABLE           : "No hay plantillas de WhatsApp disponibles.",
    WABA_TEMPLATES_ERROR_EDIT               : "No tiene permiso para editar la plantilla de WhatsApp indicada.",
    WABA_TEMPLATES_ERROR_EXISTS             : "No existe la plantilla de WhatsApp indicada.",
    WABA_TEMPLATES_ERROR_NAME_EMPTY         : "Debe indicar el nombre de la plantilla de WhatsApp.",
    WABA_TEMPLATES_ERROR_NAME_INVALID       : "El nombre solo puede contener letras minúsculas, números y guiones bajos.",
    WABA_TEMPLATES_ERROR_NAME_REPEATED      : "No puede repetir los nombres de las plantillas dentro de un canal.",
    WABA_TEMPLATES_ERROR_CATEGORY           : "Debe indicar la categoría de la plantilla de WhatsApp.",
    WABA_TEMPLATES_ERROR_CHANNEL            : "Debe indicar el canal de la plantilla de WhatsApp.",
    WABA_TEMPLATES_ERROR_COMPONENTS         : "Debe indicar el mensaje de al menos un idioma.",
    WABA_TEMPLATES_ERROR_HEADER_TEXT_EMPTY  : "Debe indicar el encabezado del mensaje.",
    WABA_TEMPLATES_ERROR_HEADER_TEXT_LENGTH : "El encabezado del mensaje debe contener menos de 60 caracteres.",
    WABA_TEMPLATES_ERROR_HEADER_FILE        : "Debe indicar un archivo para el encabezado.",
    WABA_TEMPLATES_ERROR_HEADER_LATITUDE    : "Debe indicar la latitud de la ubicación.",
    WABA_TEMPLATES_ERROR_HEADER_LONGITUDE   : "Debe indicar la longitud de la ubicación.",
    WABA_TEMPLATES_ERROR_HEADER_LOCATION    : "Debe indicar el nombre de la ubicación.",
    WABA_TEMPLATES_ERROR_HEADER_ADDRESS     : "Debe indicar la dirección de la ubicación.",
    WABA_TEMPLATES_ERROR_BODY_TEXT_EMPTY    : "Debe indicar el cuerpo del mensaje.",
    WABA_TEMPLATES_ERROR_BODY_TEXT_LENGTH   : "El cuerpo del mensaje debe contener menos de 1024 caracteres.",
    WABA_TEMPLATES_ERROR_FOOTER_TEXT_LENGTH : "El pie del mensaje debe contener menos de 60 caracteres.",
    WABA_TEMPLATES_ERROR_SAMPLE             : "Debe indicar un ejemplo para la variable.",
    WABA_TEMPLATES_ERROR_VARIABLE_EMPTY     : "Debe indicar un valor para la variable.",
    WABA_TEMPLATES_ERROR_VARIABLE_INVALID   : "El valor usado para la variable es incorrecto.",
    WABA_TEMPLATES_ERROR_BUTTONS_MAX        : "La cantidad máxima de botones es 3.",
    WABA_TEMPLATES_ERROR_BUTTONS_AMOUNT     : "La cantidad de botones debe ser la misma en todos los idiomas.",
    WABA_TEMPLATES_ERROR_BUTTONS_LENGTH     : "Cada botón debe contener menos de 25 caracteres.",
    WABA_TEMPLATES_ERROR_BUTTONS_EMOJI      : "Los botones no pueden contener Emojis.",
    WABA_TEMPLATES_ERROR_LINKS_TYPE         : "Debe indicar el tipo de botón válido.",
    WABA_TEMPLATES_ERROR_LINKS_PHONES       : "Sólo puede agregar 1 botón del tipo indicado.",
    WABA_TEMPLATES_ERROR_LINKS_PHONE        : "Debe indicar el teléfono a llamar con la entrada del país.",
    WABA_TEMPLATES_ERROR_LINKS_URLS         : "Sólo puede agregar 2 botones del tipo indicado.",
    WABA_TEMPLATES_ERROR_LINKS_URL          : "Debe indicar la url a usar iniciada en http.",
    WABA_TEMPLATES_ERROR_LINKS_TEXT         : "Debe indicar el texto del botón.",
    WABA_TEMPLATES_ERROR_DELETE             : "No tiene permiso para eliminar la plantilla de WhatsApp indicada.",
    WABA_TEMPLATES_ERROR_TEMPLATE_EMPTY     : "Debe indicar una plantilla de WhatsApp.",
    WABA_TEMPLATES_ERROR_TEMPLATE_INVALID   : "No se pudo obtener la plantilla indicada de WhatsApp.",
    WABA_TEMPLATES_WARNING_CREATE           : "Se creó la plantilla, pero no se pudo crear en WhatsApp.",
    WABA_TEMPLATES_WARNING_EDIT             : "Se editó la plantilla, pero no se pudo editar en WhatsApp.",
    WABA_TEMPLATES_SUCCESS_CREATE           : "La plantilla de WhatsApp se creó correctamente.",
    WABA_TEMPLATES_SUCCESS_EDIT             : "La plantilla de WhatsApp se editó correctamente.",
    WABA_TEMPLATES_SUCCESS_IMPORT           : "La plantilla de WhatsApp se importó correctamente.",
    WABA_TEMPLATES_SUCCESS_DELETE           : "La plantilla de WhatsApp se eliminó correctamente.",

    // Email Designs
    EMAIL_DESIGNS_NAME                      : "Diseños de email",
    EMAIL_DESIGNS_SINGULAR                  : "Diseño de email",
    EMAIL_DESIGNS_VIEW_TITLE                : "Ver diseño de email",
    EMAIL_DESIGNS_CREATE_TITLE              : "Crear diseño de email",
    EMAIL_DESIGNS_EDIT_TITLE                : "Editar diseño de email",
    EMAIL_DESIGNS_COPY_TITLE                : "Copiar diseño de email",
    EMAIL_DESIGNS_DELETE_TITLE              : "Eliminar diseño de email",
    EMAIL_DESIGNS_DELETE_TEXT               : "¿Realmente desea eliminar el diseño de email <b>{0}</b>?",
    EMAIL_DESIGNS_PUBLISH_NO_CHANGES        : "No hay cambios para publicar en el diseño de email <b>{0}</b>.",
    EMAIL_DESIGNS_PUBLISH_CHANGES           : "¿Realmente desea publicar los cambios en el diseño de email <b>{0}</b>?",
    EMAIL_DESIGNS_PUBLISH_CHANGES_SINGULAR  : "¿Realmente desea publicar el elemento con cambios en el diseño de email <b>{1}</b>?",
    EMAIL_DESIGNS_PUBLISH_CHANGES_PLURAL    : "¿Realmente desea publicar los <b>{0} elementos</b> con cambios en el diseño de email <b>{1}</b>?",
    EMAIL_DESIGNS_CLEAR_CHANGES             : "¿Realmente desea borrar los cambios del diseño de email <b>{0}</b>?",
    EMAIL_DESIGNS_START_HERE                : "Arrastre un elemento aquí para empezar",
    EMAIL_DESIGNS_DROP_ELEMENTS             : "Arrastre el elemento aquí",
    EMAIL_DESIGNS_NONE_AVAILABLE            : "No hay diseños de email disponibles.",
    EMAIL_DESIGNS_ERROR_EDIT                : "No tiene permiso para editar el diseño de email indicado.",
    EMAIL_DESIGNS_ERROR_EXISTS              : "No existe el diseño de email indicado.",
    EMAIL_DESIGNS_ERROR_NAME                : "Debe indicar el nombre del diseño de email.",
    EMAIL_DESIGNS_SUCCESS_CREATE            : "El diseño de email se creó correctamente.",
    EMAIL_DESIGNS_SUCCESS_COPY              : "El diseño de email se copió correctamente.",
    EMAIL_DESIGNS_SUCCESS_EDIT              : "El diseño de email se editó correctamente.",
    EMAIL_DESIGNS_SUCCESS_DELETE            : "El diseño de email se eliminó correctamente.",

    EMAIL_DESIGNS_ELEMENTS_SINGULAR         : "Elemento",
    EMAIL_DESIGNS_ELEMENTS_WITH_ERRORS      : "Elementos con errores",
    EMAIL_DESIGNS_ELEMENTS_NOT_ALLOWED      : "Elemento deshabilitado por no tener permisos suficientes.",
    EMAIL_DESIGNS_ELEMENTS_COLUMNS          : "Columnas",
    EMAIL_DESIGNS_ELEMENTS_TEXT             : "Texto",
    EMAIL_DESIGNS_ELEMENTS_IMAGE            : "Imagen",
    EMAIL_DESIGNS_ELEMENTS_BUTTON           : "Botón",
    EMAIL_DESIGNS_ELEMENTS_DIVIDER          : "Divisor",
    EMAIL_DESIGNS_ELEMENTS_SPACER           : "Espaciador",
    EMAIL_DESIGNS_ELEMENTS_SOCIAL           : "Redes sociales",
    EMAIL_DESIGNS_ELEMENTS_UNSUBSCRIBE      : "Darse de baja",

    // Questionnaires
    QUESTIONNAIRES_NAME                     : "Preguntas frecuentes",
    QUESTIONNAIRES_SINGULAR                 : "Pregunta frecuente",
    QUESTIONNAIRES_CREATE_TITLE             : "Crear preguntas frecuentes",
    QUESTIONNAIRES_EDIT_TITLE               : "Editar preguntas frecuentes",
    QUESTIONNAIRES_COPY_TITLE               : "Copiar preguntas frecuentes",
    QUESTIONNAIRES_DELETE_TITLE             : "Eliminar preguntas frecuentes",
    QUESTIONNAIRES_DELETE_TEXT              : "¿Realmente desea eliminar las preguntas frecuentes de <b>{0}</b>?",
    QUESTIONNAIRES_MAIN_TEXT                : "Nombre y estado.",
    QUESTIONNAIRES_QUESTION_TITLE           : "Pregunta {0}",
    QUESTIONNAIRES_ADD_QUESTION             : "Agregar pregunta",
    QUESTIONNAIRES_QUESTIONS                : "Preguntas",
    QUESTIONNAIRES_QUESTION                 : "Pregunta",
    QUESTIONNAIRES_ANSWER                   : "Respuesta",
    QUESTIONNAIRES_NONE_AVAILABLE           : "No hay preguntas frecuentes disponibles.",
    QUESTIONNAIRES_ERROR_EDIT               : "No tiene permiso para editar las preguntas frecuentes indicadas.",
    QUESTIONNAIRES_ERROR_EXISTS             : "No existen las preguntas frecuentes indicadas.",
    QUESTIONNAIRES_ERROR_NAME               : "Debe indicar el nombre de las preguntas frecuentes.",
    QUESTIONNAIRES_ERROR_EMPTY              : "Debe indicar las preguntas frecuentes.",
    QUESTIONNAIRES_ERROR_TITLE_EMPTY        : "Debe completar el título de la pregunta.",
    QUESTIONNAIRES_ERROR_TITLE_LENGTH       : "El título de la pregunta no debe superar los 20 caracteres.",
    QUESTIONNAIRES_ERROR_QUESTION_LENGTH    : "La pregunta no debe superar los 72 caracteres.",
    QUESTIONNAIRES_ERROR_ANSWER_EMPTY       : "Debe completar la respuesta de la pregunta.",
    QUESTIONNAIRES_ERROR_ANSWER_LENGTH      : "La respuesta de la pregunta no debe superar los {0} caracteres.",
    QUESTIONNAIRES_ERROR_DELETE             : "No tiene permiso para eliminar las preguntas frecuentes indicadas.",
    QUESTIONNAIRES_ERROR_FLOW               : "No puede eliminar las preguntas frecuentes al estar usadas en un flujo.",
    QUESTIONNAIRES_SUCCESS_CREATE           : "Las preguntas frecuentes se crearon correctamente.",
    QUESTIONNAIRES_SUCCESS_EDIT             : "Las preguntas frecuentes se editaron correctamente.",
    QUESTIONNAIRES_SUCCESS_DELETE           : "Las preguntas frecuentes se eliminaron correctamente.",

    // Custom Fields
    CUSTOM_FIELDS_NAME                      : "Campos personalizados",
    CUSTOM_FIELDS_SINGULAR                  : "Campo personalizado",
    CUSTOM_FIELDS_CREATE_TITLE              : "Agregar campo personalizado",
    CUSTOM_FIELDS_EDIT_TITLE                : "Editar campo personalizado",
    CUSTOM_FIELDS_DELETE_TITLE              : "Eliminar campo personalizado",
    CUSTOM_FIELDS_DELETE_TEXT               : "¿Realmente desea eliminar el campo personalizado <b>{0}</b>?",
    CUSTOM_FIELDS_FILE_TYPES                : "Tipos de archivos",
    CUSTOM_FIELDS_MIN_VALUE                 : "Valor mínimo",
    CUSTOM_FIELDS_MAX_VALUE                 : "Valor máximo",
    CUSTOM_FIELDS_MINUTE_INTERVAL           : "Intervalo de minutos",
    CUSTOM_FIELDS_MIN_HOUR                  : "Hora mínima",
    CUSTOM_FIELDS_MAX_HOUR                  : "Hora máxima",
    CUSTOM_FIELDS_HIDDEN                    : "Oculto",
    CUSTOM_FIELDS_IS_HIDDEN                 : "¿Es oculto?",
    CUSTOM_FIELDS_REQUIRED                  : "Requerido",
    CUSTOM_FIELDS_IS_REQUIRED               : "¿Es requerido?",
    CUSTOM_FIELDS_NONE_AVAILABLE            : "No hay campos personalizados disponibles.",
    CUSTOM_FIELDS_ERROR_EDIT                : "No tiene permiso para editar el campo personalizado indicado.",
    CUSTOM_FIELDS_ERROR_EXISTS              : "No existe el campo personalizado indicado.",
    CUSTOM_FIELDS_ERROR_NAME                : "Debe completar el nombre del campo personalizado.",
    CUSTOM_FIELDS_ERROR_INPUT_TYPE_EMPTY    : "Debe completar tipo del campo personalizado.",
    CUSTOM_FIELDS_ERROR_INPUT_TYPE_INVALID  : "El tipo del campo personalizado es incorrecto.",
    CUSTOM_FIELDS_ERROR_OPTIONS             : "Debe indicar las opciones del tipo indicado.",
    CUSTOM_FIELDS_ERROR_FILE_TYPES          : "Uno de los tipos de archivos indicados es incorrecto.",
    CUSTOM_FIELDS_ERROR_MIN_VALUE           : "El valor mínimo debe ser un número.",
    CUSTOM_FIELDS_ERROR_MAX_VALUE           : "El valor máximo debe ser un número.",
    CUSTOM_FIELDS_ERROR_MINUTE_INTERVAL     : "El intervalo de minutos debe ser un número.",
    CUSTOM_FIELDS_ERROR_MIN_HOUR            : "La hora mínima debe ser un número entre 0 y 23.",
    CUSTOM_FIELDS_ERROR_MAX_HOUR            : "La hora máxima debe ser un número entre 0 y 23.",
    CUSTOM_FIELDS_ERROR_HIDDEN_REQUIRED     : "El campo personalizado no puede ser oculto y requerido al mismo tiempo.",
    CUSTOM_FIELDS_ERROR_DELETE              : "No tiene permiso para eliminar el campo personalizado indicado.",
    CUSTOM_FIELDS_ERROR_FLOW                : "No puede eliminar el campo personalizado al estar usado en un flujo.",
    CUSTOM_FIELDS_ERROR_REQUIRED            : "Debe completar el valor.",
    CUSTOM_FIELDS_ERROR_NUMBER              : "Debe completar un número válido.",
    CUSTOM_FIELDS_ERROR_NUMBER_MIN          : "Debe completar un número mayor o igual a {0}.",
    CUSTOM_FIELDS_ERROR_NUMBER_MAX          : "Debe completar un número menor o igual a {0}.",
    CUSTOM_FIELDS_ERROR_EMAIL               : "Debe completar un email válido.",
    CUSTOM_FIELDS_ERROR_PHONE               : "Debe completar un teléfono válido.",
    CUSTOM_FIELDS_ERROR_DATE                : "Debe completar una fecha válida.",
    CUSTOM_FIELDS_ERROR_TIME                : "Debe completar una hora válida.",
    CUSTOM_FIELDS_ERROR_SELECT              : "Debe completar una opción válida.",
    CUSTOM_FIELDS_ERROR_LIST                : "Debe completar una lista válida.",
    CUSTOM_FIELDS_ERROR_FILE_TYPE           : "El tipo de archivo debe ser uno de: {0}.",
    CUSTOM_FIELDS_SUCCESS_CREATE            : "El campo personalizado se creó correctamente.",
    CUSTOM_FIELDS_SUCCESS_EDIT              : "El campo personalizado se editó correctamente.",
    CUSTOM_FIELDS_SUCCESS_DELETE            : "El campo personalizado se eliminó correctamente.",



    // Hotels
    HOTELS_NAME                             : "Hoteles",
    HOTELS_SINGULAR                         : "Hotel",
    HOTELS_VIEW_TITLE                       : "Ver hotel",
    HOTELS_ADD_TITLE                        : "Agregar hotel",
    HOTELS_EDIT_TITLE                       : "Editar hotel",
    HOTELS_DELETE_TITLE                     : "Eliminar hotel",
    HOTELS_DELETE_TEXT                      : "¿Realmente desea eliminar el hotel <b>{0}</b>?",
    HOTELS_MAIN_TEXT                        : "Nombre y estado",
    HOTELS_PMS_TITLE                        : "Integración con PMS",
    HOTELS_PMS_TEXT                         : "¿Cuál es la integración con el PMS?",
    HOTELS_FIELDS_TITLE                     : "Campos para el PMS",
    HOTELS_FIELDS_TEXT                      : "Por cada campos indique el nombre en el PMS.",
    HOTELS_FIELDS_ERRORS                    : "Los campos no están configurados correctamente según los datos obtenidos del reporte.",
    HOTELS_BOOKING_TITLE                    : "Integración con motor de reservas",
    HOTELS_BOOKING_TEXT                     : "¿Cuál es el motor de reservas?",
    HOTELS_ID                               : "ID del hotel",
    HOTELS_ITS_NAME                         : "Nombre del hotel",
    HOTELS_SITE_URL                         : "Url del sitio web",
    HOTELS_PMS_PROVIDER                     : "Proveedor de PMS",
    HOTELS_PMS_ID                           : "ID interno del PMS",
    HOTELS_PMS_LOG_ALL                      : "Log todos los eventos",
    HOTELS_PMS_IS_DISABLED                  : "Desactivar integración",
    HOTELS_PMS_IS_LINKED                    : "Integración vinculada",
    HOTELS_PMS_HAS_ERROR                    : "Error de campos",
    HOTELS_USE_FTP                          : "Usar FTP",
    HOTELS_FTP_TITLE                        : "Datos de FTP",
    HOTELS_FTP_HOSTNAME                     : "Host / Url",
    HOTELS_FTP_PORT                         : "Puerto",
    HOTELS_FTP_DIRECTORY                    : "Directorio",
    HOTELS_FTP_USERNAME                     : "Usuario de FTP",
    HOTELS_FTP_PASSWORD                     : "Contraseña de FTP",
    HOTELS_BOOKING_ENGINE                   : "Motor de reservas",
    HOTELS_BOOKING_TOKEN                    : "Token o key",
    HOTELS_BOOKING_REFRESH                  : "Token de refresco",
    HOTELS_BOOKING_HOTEL                    : "ID del hotel",
    HOTELS_BOOKING_URL                      : "Url para hacer una reserva",
    HOTELS_BOOKING_PROMO_CODE               : "Código de promoción",
    HOTELS_HOTELS_SINGULAR                  : "{0} hotel",
    HOTELS_HOTELS_PLURAL                    : "{0} hoteles",
    HOTELS_NONE_AVAILABLE                   : "No hay hoteles disponibles.",
    HOTELS_ERROR_EDIT                       : "No tiene permiso para editar el hotel indicado.",
    HOTELS_ERROR_EMPTY                      : "Debe indicar un hotel.",
    HOTELS_ERROR_EXISTS                     : "No existe el hotel indicado.",
    HOTELS_ERROR_SOME_EXISTS                : "Algunos de los hoteles indicados no existen.",
    HOTELS_ERROR_NAME                       : "Debe indicar el nombre del hotel.",
    HOTELS_ERROR_SITE_URL                   : "La url del sitio web debe iniciar con http o https.",
    HOTELS_ERROR_PROVIDER_ADD               : "No tiene permiso para agregar un proveedor de PMS.",
    HOTELS_ERROR_PROVIDER_TYPE              : "El proveedor de PMS indicado es incorrecto.",
    HOTELS_ERROR_PMS_ID_EMPTY               : "Debe indicar el ID interno del PMS.",
    HOTELS_ERROR_PMS_ID_INVALID             : "El ID interno del PMS debe ser único para el tipo indicado.",
    HOTELS_ERROR_FTP_USERNAME               : "Debe indicar un usuario de FTP con solo letras y números y empezar en una letra.",
    HOTELS_ERROR_FTP_PASSWORD               : "Debe indicar una contraseña de FTP con al menos 6 caracteres, una letra y un número.",
    HOTELS_ERROR_FIELD_EMPTY                : "Debe completar el nombre del campo.",
    HOTELS_ERROR_BOOKING_ADD                : "No tiene permiso para agregar un motor de reservas.",
    HOTELS_ERROR_BOOKING_ENGINE             : "El motor de reservas es incorrecto.",
    HOTELS_ERROR_BOOKING_TOKEN              : "Debe indicar el token o key del motor de reservas.",
    HOTELS_ERROR_BOOKING_REFRESH            : "Debe indicar el token de refresco del motor de reservas.",
    HOTELS_ERROR_BOOKING_HOTEL              : "Debe indicar el ID del hotel.",
    HOTELS_ERROR_BOOKING_URL_EMPTY          : "Debe indicar la url para hacer reservas.",
    HOTELS_ERROR_BOOKING_URL_INVALID        : "La url debe comenzar con http o https.",
    HOTELS_ERROR_DELETE                     : "No tiene permiso para eliminar el hotel indicado.",
    HOTELS_ERROR_FLOW_INACTIVE              : "No puede marcar el hotel como inactivo al estar usado en un flujo.",
    HOTELS_ERROR_FLOW_DELETE                : "No puede eliminar el hotel al estar usado en un flujo.",
    HOTELS_SUCCESS_ADD                      : "El hotel se agregó correctamente.",
    HOTELS_SUCCESS_EDIT                     : "El hotel se editó correctamente.",
    HOTELS_SUCCESS_DELETE                   : "El hotel se eliminó correctamente.",

    // Hotels: Opera
    HOTELS_OPERA_CLIENT_ID                  : "Client ID",
    HOTELS_OPERA_CLIENT_SECRET              : "Client secret",
    HOTELS_OPERA_GATEWAY_URL                : "Gateway URL",
    HOTELS_OPERA_USERNAME                   : "Integration ID",
    HOTELS_OPERA_PASSWORD                   : "Integration password",
    HOTELS_OPERA_EXTERNAL_SYSTEM            : "External system code",
    HOTELS_OPERA_ERROR_CLIENT_ID            : "Debe indicar el valor de client ID.",
    HOTELS_OPERA_ERROR_CLIENT_SECRET        : "Debe indicar el valor de client secret.",
    HOTELS_OPERA_ERROR_GATEWAY_URL          : "Debe indicar el valor de gateway URL.",
    HOTELS_OPERA_ERROR_USERNAME             : "Debe indicar el valor de integration ID.",
    HOTELS_OPERA_ERROR_PASSWORD             : "Debe indicar el valor de integration password.",
    HOTELS_OPERA_ERROR_EXTERNAL_SYSTEM      : "Debe indicar el valor de external system code.",



    // Channels
    CHANNELS_NAME                           : "Canales",
    CHANNELS_SINGULAR                       : "Canal",
    CHANNELS_ADD_TITLE                      : "Agregar canal",
    CHANNELS_EDIT_TITLE                     : "Editar canal",
    CHANNELS_DELETE_TITLE                   : "Eliminar canal",
    CHANNELS_DELETE_TEXT                    : "¿Realmente desea eliminar el canal <b>{0}</b>?",
    CHANNELS_PROFILE_TITLE                  : "Editar perfil",
    CHANNELS_VIEW_TITLE                     : "Ir al canal",
    CHANNELS_CHANNEL_TEXT                   : "¿Qué canal le gustaría agregar?",
    CHANNELS_INSTALL_TITLE                  : "Instalar",
    CHANNELS_INSTALL_TEXT                   : "Instale el canal seleccionado",
    CHANNELS_CONFIG_TITLE                   : "Configurar",
    CHANNELS_CONFIG_TEXT                    : "Configure el nombre y flujo",
    CHANNELS_SELECT_TEXT                    : "Haga click en una plataforma para agregarla como canal.",
    CHANNELS_WHATSAPP_BEFORE                : "Antes de poder instalar WhatsApp, debe tener:",
    CHANNELS_WHATSAPP_INFO1                 : "Acceso al administrador comercial de Facebook.",
    CHANNELS_WHATSAPP_INFO2                 : "Un número de teléfono viable para WhatsApp.",
    CHANNELS_WHATSAPP_INFO3                 : "La dirección legal y los detalles de su empresa.",
    CHANNELS_WHATSAPP_START                 : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con WhatsApp</b> y seguir los pasos en el diálogo.",
    CHANNELS_WHATSAPP_LOGIN                 : "Continuar con WhatsApp",
    CHANNELS_WHATSAPP_SELECT                : "Seleccione la cuenta de WhatsApp que desea agregar o puede crear otra cuenta.",
    CHANNELS_WHATSAPP_NONE                  : "No hay cuentas de WhatsApp disponibles. Intente ingresar con otro usuario.",
    CHANNELS_WHATSAPP_ACCOUNT               : "Cuenta de WhatsApp",
    CHANNELS_INSTAGRAM_BEFORE               : "Antes de poder instalar Instagram, debe tener:",
    CHANNELS_INSTAGRAM_INFO1                : "Una cuenta de Instagram vinculada a una página de Facebook.",
    CHANNELS_INSTAGRAM_INFO2                : "Una página de Facebook con acceso administrador.",
    CHANNELS_INSTAGRAM_START                : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con Facebook</b> y seguir los pasos en el diálogo.",
    CHANNELS_INSTAGRAM_LOGIN                : "Continuar con Facebook",
    CHANNELS_INSTAGRAM_SELECT               : "Seleccione la cuenta de Instagram que desea agregar o puede ingresar con otro usuario.",
    CHANNELS_INSTAGRAM_NONE                 : "No encontramos cuentas de Instagram disponibles. Puede intentar ingresar el ID de la página de Facebook asociada a la cuenta de Instagram o ingresar con otro usuario.",
    CHANNELS_INSTAGRAM_ACCOUNT              : "Cuenta de Instagram",
    CHANNELS_MESSENGER_BEFORE               : "Antes de poder instalar Facebook Messenger, debe tener:",
    CHANNELS_MESSENGER_INFO1                : "Un perfil de Facebook Messenger.",
    CHANNELS_MESSENGER_INFO2                : "Una página de Facebook con acceso administrador.",
    CHANNELS_MESSENGER_START                : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con Facebook</b> y seguir los pasos en el diálogo.",
    CHANNELS_MESSENGER_LOGIN                : "Continuar con Facebook",
    CHANNELS_MESSENGER_SELECT               : "Seleccione la página de Facebook que desea agregar o puede ingresar con otro usuario.",
    CHANNELS_MESSENGER_NONE                 : "No encontramos páginas de Facebook disponibles. Puede intentar ingresando el ID de la página de Facebook o ingresar con otro usuario.",
    CHANNELS_MESSENGER_PAGE                 : "Página de Facebook",
    CHANNELS_MESSENGER_PAGE_ID              : "ID de la página de Facebook",
    CHANNELS_SMS                            : "SMS",
    CHANNELS_SMS_SELECT                     : "Selecciona un número de teléfono para agregar como canal de SMS:",
    CHANNELS_SMS_NONE_AVAILABLE             : "No hay números de teléfonos disponibles para usar como canal de SMS.",
    CHANNELS_SMS_BUY_TEXT                   : "¿Le gustaría comprar un nuevo número de teléfono para usar con SMS?",
    CHANNELS_SMS_BUY_BUTTON                 : "Comprar número de teléfono",
    CHANNELS_SMS_TICKET_TEXT                : "Puede enviarnos un Ticket para permitirle comprar un nuevo número de teléfono.",
    CHANNELS_SMS_TICKET_BUTTON              : "Enviar ticket",
    CHANNELS_GMAIL_TEXT                     : "Antes de poder instalar Google Gmail, debe tener una cuenta de email en Gmail.",
    CHANNELS_GMAIL_START                    : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con Google</b> y seguir los pasos en el diálogo.",
    CHANNELS_GMAIL_LOGIN                    : "Continuar con Google",
    CHANNELS_GMAIL_TERMS_PRE                : "El uso de Conversana y la transferencia de información recibida de las API de Google a cualquier otra aplicación se adherirán a ",
    CHANNELS_GMAIL_TERMS_LINK               : "Política de datos de usuario de los servicios API de Google",
    CHANNELS_GMAIL_TERMS_POST               : ", incluidos los requisitos de uso limitado.",
    CHANNELS_OUTLOOK_TEXT                   : "Antes de poder instalar Microsoft Outlook, debe tener una cuenta de email en Microsoft.",
    CHANNELS_OUTLOOK_START                  : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con Microsoft</b> y seguir los pasos en el diálogo.",
    CHANNELS_OUTLOOK_PERMISSION             : "Debe aceptar los permisos solicitados para usar este canal.",
    CHANNELS_OUTLOOK_LOGIN                  : "Continuar con Microsoft",
    CHANNELS_TELEGRAM_BEFORE                : "Antes de poder instalar Telegram, debe tener:",
    CHANNELS_TELEGRAM_INFO1                 : "Un bot que puede crear enviando un mensaje a <a class='simple-link' href='https://t.me/botfather' target='_blank'>@BotFather</a>.",
    CHANNELS_TELEGRAM_INFO2                 : "El token de acceso del bot que puede obtener luego de crear el bot o enviando un mensaje a <a class='simple-link' href='https://t.me/botfather' target='_blank'>@BotFather</a>.",
    CHANNELS_TELEGRAM_START                 : "Una vez que tenga el token de acceso del bot, puede cargarlo a continuación.",
    CHANNELS_TELEGRAM_LABEL                 : "Token de acceso",
    CHANNELS_MERCADO_LIBRE_TEXT             : "Antes de poder instalar MercadoLibre, debe tener un usuario con permisos de administrador.",
    CHANNELS_MERCADO_LIBRE_START            : "Una vez que esté listo, puede hacer clic en el botón <b>Continuar con Mercado Libre</b> y seguir los pasos en el diálogo.",
    CHANNELS_MERCADO_LIBRE_LOGIN            : "Continuar con Mercado Libre",
    CHANNELS_MERCADO_LIBRE_PERMISSION       : "Debe aceptar los permisos solicitados para usar este canal.",
    CHANNELS_OTHER_USER                     : "Ingresar con otro usuario",
    CHANNELS_HELP_NAME                      : "Si lo deja en blanco, se obtendrá del canal.",
    CHANNELS_ADD_USER_NAME                  : "Agregar el nombre del usuario a los mensajes",
    CHANNELS_PROFILE_PROVIDER_ERROR         : "No hemos podido obtener los datos del proveedor. Estamos trabajando el resolver el problema.",
    CHANNELS_PROFILE_CURRENT_IMAGE          : "Imagen actual del perfil",
    CHANNELS_PROFILE_NEW_IMAGE              : "Nueva imagen del perfil",
    CHANNELS_PROFILE_IMAGE_HELP             : "La imagen debe ser cuadrada y más grande que 192 x 192 px.",
    CHANNELS_PROFILE_CATEGORY               : "Categoría de la Empresa",
    CHANNELS_PROFILE_NAME                   : "Nombre de la Empresa",
    CHANNELS_PROFILE_ABOUT                  : "Acerca de la Empresa",
    CHANNELS_PROFILE_DESCRIPTION            : "Descripción de la Empresa",
    CHANNELS_PROFILE_ADDRESS                : "Domicilio de la Empresa",
    CHANNELS_PROFILE_EMAIL                  : "Email de la Empresa",
    CHANNELS_PROFILE_WEBSITES               : "Urls asociadas a la Empresa",
    CHANNELS_PROFILE_ADD_WEBSITE            : "Agregar url",
    CHANNELS_NONE_AVAILABLE                 : "No hay canales disponibles.",
    CHANNELS_ERROR_ADD                      : "No es posible agregar el canal indicado.",
    CHANNELS_ERROR_EDIT                     : "No tiene permiso para editar el canal indicado.",
    CHANNELS_ERROR_CLIENT                   : "El canal pertenece a otro cliente.",
    CHANNELS_ERROR_EXISTS                   : "No existe el canal indicado.",
    CHANNELS_ERROR_TYPE_EMPTY               : "Debe indicar el tipo de canal.",
    CHANNELS_ERROR_TYPE_INVALID             : "El tipo de canal es incorrecto.",
    CHANNELS_ERROR_NAME                     : "Debe indicar el nombre del canal.",
    CHANNELS_ERROR_PROFILE_CANT             : "No puede editar el perfil del canal indicado.",
    CHANNELS_ERROR_PROFILE_IMAGE            : "La imagen debe ser más grande que 190px por 190px.",
    CHANNELS_ERROR_PROFILE_EDIT             : "Ocurrió un error al editar el perfil del canal indicado.",
    CHANNELS_ERROR_DELETE                   : "No tiene permiso para eliminar el canal indicado.",
    CHANNELS_SUCCESS_ADD                    : "El canal se configuró correctamente.",
    CHANNELS_SUCCESS_EDIT                   : "El canal se editó correctamente.",
    CHANNELS_SUCCESS_EDIT_PROFILE           : "El perfil del canal se editó correctamente.",
    CHANNELS_SUCCESS_DELETE                 : "El canal se eliminó correctamente.",

    // Phone Numbers
    PHONE_NUMBERS_NAME                      : "Números de teléfono",
    PHONE_NUMBERS_SINGULAR                  : "Número de teléfono",
    PHONE_NUMBERS_ADD_TITLE                 : "Agregar número de teléfono",
    PHONE_NUMBERS_IMPORT_TITLE              : "Importar número de teléfono",
    PHONE_NUMBERS_EDIT_TITLE                : "Editar número de teléfono",
    PHONE_NUMBERS_DELETE_TITLE              : "Eliminar número de teléfono",
    PHONE_NUMBERS_DELETE_TEXT               : "¿Realmente desea eliminar el número de teléfono <b>{0}</b>?<br>Tenga en cuenta que el número no se elimina de Twilio y no se eliminan los canales asociados al número de teléfono.",
    PHONE_NUMBERS_SELECT_PHONE              : "Selecciona un número para comprar",
    PHONE_NUMBERS_PHONE_NUMBER              : "Nombre descriptivo",
    PHONE_NUMBERS_FRIENDLY_NAME             : "Nombre amigable",
    PHONE_NUMBERS_DESCRIPTIVE_NAME          : "Nombre descriptivo",
    PHONE_NUMBERS_LOCATION                  : "Ubicación",
    PHONE_NUMBERS_EMPTY                     : "No hay números de teléfono disponibles para importar. Todos los números de teléfono están usados por los clientes.",
    PHONE_NUMBERS_NONE_AVAILABLE            : "No hay números de teléfono disponibles.",
    PHONE_NUMBERS_ERROR_ADD                 : "No es posible agregar más números de teléfono.",
    PHONE_NUMBERS_ERROR_EDIT                : "No tiene permiso para editar el número de teléfono indicado.",
    PHONE_NUMBERS_ERROR_EXISTS              : "No existe el número de teléfono indicado.",
    PHONE_NUMBERS_ERROR_SELECT              : "Debe seleccionar un número de teléfono del listado.",
    PHONE_NUMBERS_ERROR_USED                : "El número de teléfono indicado ya está usado.",
    PHONE_NUMBERS_ERROR_BUY                 : "No fue posible comprar el número de teléfono indicado.",
    PHONE_NUMBERS_SUCCESS_ADD               : "El número de teléfono se agregó correctamente.",
    PHONE_NUMBERS_SUCCESS_IMPORT            : "El número de teléfono se importó correctamente.",
    PHONE_NUMBERS_SUCCESS_EDIT              : "El número de teléfono se editó correctamente.",
    PHONE_NUMBERS_SUCCESS_DELETE            : "El número de teléfono se eliminó correctamente.",



    // Assistants AI
    ASSISTANTS_NAME                         : "Asistentes IA",
    ASSISTANTS_SINGULAR                     : "Asistente IA",
    ASSISTANTS_CREATE_TITLE                 : "Crear asistente IA",
    ASSISTANTS_EDIT_TITLE                   : "Editar asistente IA",
    ASSISTANTS_DELETE_TITLE                 : "Eliminar asistente IA",
    ASSISTANTS_DELETE_TEXT                  : "¿Realmente desea eliminar el asistente IA <b>{0}</b>?",
    ASSISTANTS_MAIN_TEXT                    : "Nombre, datos y estado",
    ASSISTANTS_FILES_TEXT                   : "Listado de archivos que puede usar el asistente",
    ASSISTANTS_INSTRUCTIONS                 : "Instrucciones para el asistente",
    ASSISTANTS_INSTRUCTIONS_TIP             : "Ej: Sos un asistente de un Hotel. Debes responder a las consultas de los huéspedes sobre el hotel o su estadía.",
    ASSISTANTS_USE_BY_DEFAULT               : "Usar por defecto",
    ASSISTANTS_FILES_SINGULAR               : "{0} archivo",
    ASSISTANTS_FILES_PLURAL                 : "{0} archivos",
    ASSISTANTS_FILE_TITLE                   : "Archivo {0}",
    ASSISTANTS_ADD_FILE                     : "Agregar archivo",
    ASSISTANTS_IS_CONTENT                   : "Es un contenido",
    ASSISTANTS_CONTENT                      : "Contenido del archivo",
    ASSISTANTS_NONE_AVAILABLE               : "No hay asistentes IA disponibles.",
    ASSISTANTS_ERROR_CREATE                 : "No tiene permiso para crear el asistente IA indicado.",
    ASSISTANTS_ERROR_EDIT                   : "No tiene permiso para editar el asistente IA indicado.",
    ASSISTANTS_ERROR_EXISTS                 : "No existe el asistente IA indicado.",
    ASSISTANTS_ERROR_DELETE                 : "No tiene permiso para eliminar el asistente IA indicado.",
    ASSISTANTS_ERROR_NAME                   : "Debe indicar el nombre del asistente IA.",
    ASSISTANTS_ERROR_MODEL_EMPTY            : "Debe indicar el model del asistente IA.",
    ASSISTANTS_ERROR_MODEL_EXISTS           : "El modelo del asistente IA no existe.",
    ASSISTANTS_ERROR_FLOW_DELETE            : "No puede eliminar el asistente IA al estar usado en un flujo.",
    ASSISTANTS_ERROR_FILE_AMOUNT            : "Superó la máxima cantidad de archivos que se pueden agregar.",
    ASSISTANTS_ERROR_FILE_NAME              : "Debe indicar el nombre del archivo.",
    ASSISTANTS_ERROR_FILE_FILE              : "Debe indicar el archivo.",
    ASSISTANTS_ERROR_FILE_TYPE              : "El tipo de archivo debe ser tipo texto.",
    ASSISTANTS_ERROR_FILE_CONTENT           : "Debe indicar el contenido del archivo.",
    ASSISTANTS_ERROR_CREATE_EXTERNAL        : "Ocurrió un error al crear el asistente en OpenAI.",
    ASSISTANTS_ERROR_EDIT_EXTERNAL          : "Ocurrió un error al editar el asistente en OpenAI.",
    ASSISTANTS_ERROR_DELETE_EXTERNAL        : "Ocurrió un error al eliminar el asistente en OpenAI.",
    ASSISTANTS_SUCCESS_CREATE               : "El asistente IA se creó correctamente.",
    ASSISTANTS_SUCCESS_EDIT                 : "El asistente IA se editó correctamente.",
    ASSISTANTS_SUCCESS_DELETE               : "El asistente IA se eliminó correctamente.",

    // Assistants AI: Models
    ASSISTANTS_MODELS_NAME                  : "Modelos de asistentes IA",
    ASSISTANTS_MODELS_SINGULAR              : "Modelo de asistente IA",
    ASSISTANTS_MODELS_CREATE_TITLE          : "Crear modelo de asistente IA",
    ASSISTANTS_MODELS_EDIT_TITLE            : "Editar modelo de asistente IA",
    ASSISTANTS_MODELS_DELETE_TITLE          : "Eliminar modelo de asistente IA",
    ASSISTANTS_MODELS_DELETE_TEXT           : "¿Realmente desea eliminar el modelo de asistente IA <b>{0}</b>?",
    ASSISTANTS_MODELS_MODEL                 : "Modelo",
    ASSISTANTS_MODELS_INPUT_COST            : "Costo entrada",
    ASSISTANTS_MODELS_OUTPUT_COST           : "Costo salida",
    ASSISTANTS_MODELS_TOKEN_PRICE           : "{0} /1k tokens",
    ASSISTANTS_MODELS_MINUTE_COST           : "Costo por minuto",
    ASSISTANTS_MODELS_MINUTE_PRICE          : "{0} /minuto",
    ASSISTANTS_MODELS_NONE_AVAILABLE        : "No hay modelos de asistentes IA disponibles.",
    ASSISTANTS_MODELS_ERROR_EXISTS          : "No existe el modelo de asistente IA indicado.",
    ASSISTANTS_MODELS_ERROR_NAME            : "Debe indicar el nombre del modelo de asistente IA.",
    ASSISTANTS_MODELS_ERROR_TYPE_EMPTY      : "Debe indicar el tipo del modelo de asistente IA.",
    ASSISTANTS_MODELS_ERROR_TYPE_EXISTS     : "No existe el tipo indicado en Open AI.",
    ASSISTANTS_MODELS_ERROR_MODEL_EMPTY     : "Debe indicar el modelo del modelo de asistente IA.",
    ASSISTANTS_MODELS_ERROR_MODEL_EXISTS    : "No existe el modelo indicado en Open AI.",
    ASSISTANTS_MODELS_ERROR_INPUT_COST      : "El costo de entrada por 1k tokens indicado el incorrecto.",
    ASSISTANTS_MODELS_ERROR_OUTPUT_COST     : "El costo de salida por 1k tokens indicado el incorrecto.",
    ASSISTANTS_MODELS_ERROR_USED            : "No se puede eliminar un modelo de asistente IA en uso.",
    ASSISTANTS_MODELS_SUCCESS_CREATE        : "El modelo de asistente IA se creó correctamente.",
    ASSISTANTS_MODELS_SUCCESS_EDIT          : "El modelo de asistente IA se editó correctamente.",
    ASSISTANTS_MODELS_SUCCESS_DELETE        : "El modelo de asistente IA se eliminó correctamente.",

    // Assistants AI: Threads
    ASSISTANTS_THREADS_NAME                 : "IA",
    ASSISTANTS_THREADS_WRITE_TEXT           : "Escribe una pregunta",
    ASSISTANTS_THREADS_RESTART_TITLE        : "Reiniciar asistente",
    ASSISTANTS_THREADS_RESTART_TEXT         : "¿Realmente desea reiniciar la conversación con el asistente?",
    ASSISTANTS_THREADS_ERROR_EXISTS         : "No existe la conversación con el asistente indicada.",
    ASSISTANTS_THREADS_ERROR_MESSAGE        : "Debe indicar un mensaje a enviar.",
    ASSISTANTS_THREADS_ERROR_SEND           : "Ocurrió un error al enviar el mensaje.",
    ASSISTANTS_THREADS_SUCCESS_RESTART      : "La conversación con el asistente se reinicio correctamente.",

    // Assistants AI: Runs
    ASSISTANTS_RUNS_NAME                    : "Log de asistentes IA",
    ASSISTANTS_RUNS_VIEW_TITLE              : "Ver log de asistente IA",
    ASSISTANTS_RUNS_EXECUTION               : "Ejecución",
    ASSISTANTS_RUNS_INPUT                   : "Input tokens",
    ASSISTANTS_RUNS_OUTPUT                  : "Output tokens",
    ASSISTANTS_RUNS_NONE_AVAILABLE          : "No hay logs de asistentes disponibles.",



    // Widgets
    WIDGETS_NAME                            : "Widgets omnicanal",
    WIDGETS_SINGULAR                        : "Widget omnicanal",
    WIDGETS_VIEW_TITLE                      : "Ver widget omnicanal",
    WIDGETS_CREATE_TITLE                    : "Crear widget omnicanal",
    WIDGETS_EDIT_TITLE                      : "Editar widget omnicanal",
    WIDGETS_COPY_TITLE                      : "Copiar widget omnicanal",
    WIDGETS_DELETE_TITLE                    : "Eliminar widget omnicanal",
    WIDGETS_DELETE_TEXT                     : "¿Realmente desea eliminar el widget omnicanal <b>{0}</b>?",
    WIDGETS_PUBLISH_NO_CHANGES              : "No hay cambios para publicar en el widget omnicanal <b>{0}</b>.",
    WIDGETS_ACTUAL_VERSION                  : "Versión actual",
    WIDGETS_MODIFIED_VERSION                : "Versión modificada",
    WIDGETS_CLEAR_CHANGES                   : "¿Realmente desea borrar los cambios del widget omnicanal <b>{0}</b>?",
    WIDGETS_GENERAL                         : "General",
    WIDGETS_TEXTS                           : "Textos",
    WIDGETS_START                           : "Inicio",
    WIDGETS_RESTART                         : "Reinicio",
    WIDGETS_SHOW_START                      : "Mostrar inicio",
    WIDGETS_CHAT                            : "Chat",
    WIDGETS_SHOW_CHAT                       : "Mostrar el chat",
    WIDGETS_CHAT_FLOW                       : "Flujo usado en el chat",
    WIDGETS_REQUEST_NAME                    : "Solicitar nombre",
    WIDGETS_REQUEST_MESSAGE                 : "Solicitar mensaje",
    WIDGETS_BIG_START_BUTTON                : "Mostrar botón grande de inicio",
    WIDGETS_AUTO_START_CHAT                 : "Iniciar chat automáticamente",
    WIDGETS_AUTO_OPEN_CHAT                  : "Abrir chat con nuevos mensajes",
    WIDGETS_SHOW_CHAT_LOGO                  : "Mostrar el logo en el encabezado",
    WIDGETS_CHAT_TEXT                       : "Texto del encabezado del chat",
    WIDGETS_SHOW_NAMES                      : "Mostrar nombres",
    WIDGETS_CONVERSATION_TEXT               : "Texto para iniciar conversación",
    WIDGETS_REQUEST_NAME_TEXT               : "Texto para solicitar el nombre",
    WIDGETS_REQUEST_MESSAGE_TEXT            : "Texto para el input del mensaje",
    WIDGETS_START_BUTTON_TEXT               : "Texto para el botón de inicio",
    WIDGETS_RESTART_TEXT                    : "Texto para volver a la conversación",
    WIDGETS_BOT_NAME                        : "Nombre del bot",
    WIDGETS_UNREAD_TEXT                     : "Texto cuando hay mensajes nuevos",
    WIDGETS_WRITING_TEXT                    : "Texto cuando el agente esta escribiendo",
    WIDGETS_SEND                            : "Enviar mensaje",
    WIDGETS_SEND_TEXT                       : "Texto para enviar mensajes",
    WIDGETS_SHOW_EMOJI_BUTTON               : "Mostrar botón de emoji",
    WIDGETS_SHOW_FILE_BUTTON                : "Mostrar botón de archivo",
    WIDGETS_SHOW_LOCATION_BUTTON            : "Mostrar botón de ubicación",
    WIDGETS_COLORS                          : "Colores",
    WIDGETS_BUTTON_BG_COLOR                 : "Fondo del botón",
    WIDGETS_BUTTON_ICON_COLOR               : "Ícono del botón",
    WIDGETS_CONTACT_MESSAGE_BG_COLOR        : "Fondo de los mensajes de los contactos",
    WIDGETS_CONTACT_MESSAGE_TEXT_COLOR      : "Texto de los mensajes de los contactos",
    WIDGETS_USER_MESSAGE_BG_COLOR           : "Fondo de los mensajes de los usuarios",
    WIDGETS_USER_MESSAGE_TEXT_COLOR         : "Texto de los mensajes de los usuarios",
    WIDGETS_BUBBLE_BUTTON_COLOR             : "Texto y borde de los botones de los mensajes",
    WIDGETS_BUBBLE_BUTTON_HOVER             : "Texto de los botones de los mensajes en hover",
    WIDGETS_SHOW_PLATFORMS                  : "Mostrar plataformas",
    WIDGETS_PLATFORMS_TEXT                  : "Texto para otras plataformas",
    WIDGETS_CONNECT_TEXT                    : "Texto para conectarse a la plataforma",
    WIDGETS_WEB_LINK_TEXT                   : "Texto para el vínculo",
    WIDGETS_QR_CODE_TEXT                    : "Texto para el código QR",
    WIDGETS_ICON                            : "Ícono",
    WIDGETS_SHOW_MESSAGE_COUNT              : "Mostrar contador de mensajes",
    WIDGETS_ICON_SHOW_FILE                  : "Usar una imagen en el ícono",
    WIDGETS_ICON_FILE                       : "Imagen del ícono",
    WIDGETS_ICON_COLOR                      : "Color del ícono",
    WIDGETS_ICON_BG_COLOR                   : "Color del fondo del ícono",
    WIDGETS_ICON_SHOW_BORDER                : "Mostrar borde en el ícono",
    WIDGETS_ICON_BORDER_COLOR               : "Color del borde del ícono",
    WIDGETS_ICON_SHOW_SHADOW                : "Mostrar sombra en el ícono",
    WIDGETS_ICON_SIZE                       : "Tamaño del ícono",
    WIDGETS_ICON_BOTTOM_SPACE               : "Espacio debajo del ícono",
    WIDGETS_BUBBLE                          : "Burbuja",
    WIDGETS_SHOW_BUBBLE                     : "Mostrar burbuja",
    WIDGETS_BUBBLE_TEXT                     : "Texto de la burbuja",
    WIDGETS_BUBBLE_BG_COLOR                 : "Color del fondo de la burbuja",
    WIDGETS_BUBBLE_TEXT_COLOR               : "Color del texto de la burbuja",
    WIDGETS_BUBBLE_BORDER_COLOR             : "Color del borde de la burbuja",
    WIDGETS_CHAT_MINE_NAME                  : "Juan",
    WIDGETS_CHAT_MINE_NAME_FULL             : "Juan Smith",
    WIDGETS_CHAT_MINE_FIRST                 : "Hola",
    WIDGETS_CHAT_MINE_SECOND                : "Hola, me gustaría hacer una reserva",
    WIDGETS_CHAT_YOURS_BOT                  : "Bot",
    WIDGETS_CHAT_YOURS_NAME                 : "Ana",
    WIDGETS_CHAT_YOURS_NAME_FULL            : "Ana Connors",
    WIDGETS_CHAT_YOURS_FIRST                : "Hola Juan. ¿En que te puedo ayudar?",
    WIDGETS_CHAT_YOURS_SECOND               : "Perfecto. Un agente se encargará de hacer la reserva en los próximos minutos",
    WIDGETS_CHAT_YOURS_THIRD                : "Hola soy Ana. ¿Cuando te gustaría hacer la reserva?",
    WIDGETS_CHAT_OPTION1                    : "Reserva",
    WIDGETS_CHAT_OPTION2                    : "Cotización",
    WIDGETS_CHAT_OPTION3                    : "Ayuda",
    WIDGETS_BRANDING                        : "Desarrollado por <b>Conversana</b>",
    WIDGETS_NONE_AVAILABLE                  : "No hay widgets omnicanales disponibles.",
    WIDGETS_ERROR_ADD                       : "No es posible agregar el widget omnicanal indicado.",
    WIDGETS_ERROR_EDIT                      : "No tiene permiso para editar el widget omnicanal indicado.",
    WIDGETS_ERROR_PUBLISH                   : "No tiene permiso para publicar los cambios del widget omnicanal indicado.",
    WIDGETS_ERROR_CLEAR                     : "No tiene permiso para borrar los cambios del widget omnicanal indicado.",
    WIDGETS_ERROR_DELETE                    : "No tiene permiso para eliminar el widget omnicanal indicado.",
    WIDGETS_ERROR_EXISTS                    : "No existe el widget omnicanal indicado.",
    WIDGETS_SUCCESS_CREATE                  : "El widget omnicanal se creó correctamente.",
    WIDGETS_SUCCESS_COPY                    : "El widget omnicanal se copió correctamente.",
    WIDGETS_SUCCESS_EDIT                    : "El widget omnicanal se editó correctamente.",
    WIDGETS_SUCCESS_PUBLISH                 : "Los cambios del widget omnicanal se publicaron correctamente.",
    WIDGETS_SUCCESS_CLEAR                   : "Los cambios del widget omnicanal se borraron correctamente.",
    WIDGETS_SUCCESS_DELETE                  : "El widget omnicanal se eliminó correctamente.",

    // Widgets: Platforms
    WIDGETS_PLATFORMS_NAME                  : "Plataformas",
    WIDGETS_PLATFORMS_SINGULAR              : "Plataforma",
    WIDGETS_PLATFORMS_ADD_TITLE             : "Agregar plataforma",
    WIDGETS_PLATFORMS_EDIT_TITLE            : "Editar plataforma",
    WIDGETS_PLATFORMS_DELETE_TITLE          : "Eliminar plataforma",
    WIDGETS_PLATFORMS_DELETE_TEXT           : "¿Realmente desea eliminar la plataforma <b>{0}</b>?",
    WIDGETS_PLATFORMS_IS_LINK               : "Es un vínculo",
    WIDGETS_PLATFORMS_LINK_URL              : "Url del vínculo",
    WIDGETS_PLATFORMS_SHOW_QR               : "Mostrar código QR",
    WIDGETS_PLATFORMS_NONE_CHANNELS         : "No hay canales disponibles. Puede hacer click aquí para crear el primer canal.",
    WIDGETS_PLATFORMS_NONE_AVAILABLE        : "No hay plataformas disponibles.",
    WIDGETS_PLATFORMS_ERROR_EXISTS          : "No existe la plataforma indicada.",
    WIDGETS_PLATFORMS_ERROR_PROVIDER_EMPTY  : "Debe indicar la plataforma.",
    WIDGETS_PLATFORMS_ERROR_PROVIDER_EXISTS : "No existe la plataforma indicada.",
    WIDGETS_PLATFORMS_ERROR_URL             : "Debe indicar la url del vínculo.",
    WIDGETS_PLATFORMS_ERROR_CHANNEL         : "Debe indicar el canal de la plataforma.",
    WIDGETS_PLATFORMS_ERROR_REPEAT          : "No puede usar el mismo canal en más de una plataforma.",
    WIDGETS_PLATFORMS_ERROR_DELETE          : "No tiene permiso para eliminar la plataforma indicada.",
    WIDGETS_PLATFORMS_SUCCESS_CREATE        : "La plataforma se creó correctamente.",
    WIDGETS_PLATFORMS_SUCCESS_EDIT          : "La plataforma se editó correctamente.",
    WIDGETS_PLATFORMS_SUCCESS_SORT          : "La plataformas se ordenaron correctamente.",
    WIDGETS_PLATFORMS_SUCCESS_DELETE        : "La plataforma se eliminó correctamente.",



    // Flows
    FLOWS_NAME                              : "Flujos",
    FLOWS_SINGULAR                          : "Flujo",
    FLOWS_VIEW_TITLE                        : "Ver flujo",
    FLOWS_CREATE_TITLE                      : "Crear flujo",
    FLOWS_EDIT_TITLE                        : "Editar flujo",
    FLOWS_COPY_TITLE                        : "Copiar flujo",
    FLOWS_DELETE_TITLE                      : "Eliminar flujo",
    FLOWS_DELETE_TEXT                       : "¿Realmente desea eliminar el flujo <b>{0}</b>?",
    FLOWS_PUBLISH_NO_CHANGES                : "No hay cambios para publicar en el flujo <b>{0}</b>.",
    FLOWS_PUBLISH_CHANGES                   : "¿Realmente desea publicar los cambios en el flujo <b>{0}</b>?",
    FLOWS_PUBLISH_CHANGES_SINGULAR          : "¿Realmente desea publicar el nodo con cambios en el flujo <b>{1}</b>?",
    FLOWS_PUBLISH_CHANGES_PLURAL            : "¿Realmente desea publicar los <b>{0} nodos</b> con cambios en el flujo <b>{1}</b>?",
    FLOWS_CLEAR_CHANGES                     : "¿Realmente desea borrar los cambios del flujo <b>{0}</b>?",
    FLOWS_ERROR_TEXT                        : "Hay un nodo con errores en el flujo. Debe corregirlos para publicar el flujo. Puede verlos en el panel lateral.",
    FLOWS_ERRORS_TEXT                       : "Hay <b>{0}</b> nodos con errores en el flujo. Debe corregirlos para publicar el flujo. Puede verlos en el panel lateral.",
    FLOWS_TEMPLATE                          : "Crear con la plantilla de flujo",
    FLOWS_PARENT_FLOW                       : "Flujo padre",
    FLOWS_STARTED_AMOUNT                    : "Iniciados",
    FLOWS_QUALIFICATION_AMOUNT              : "Calificados",
    FLOWS_ENDED_AMOUNT                      : "Finalizados",
    FLOWS_TIMED_OUT_AMOUNT                  : "Caducados",
    FLOWS_NONE_AVAILABLE                    : "No hay flujos disponibles.",
    FLOWS_NONE_OPTIONS                      : "Sin opciones disponibles.",
    FLOWS_ERROR_EDIT                        : "No tiene permiso para editar el flujo indicado.",
    FLOWS_ERROR_PUBLISH                     : "No puede publicar el flujo porque tiene errores.",
    FLOWS_ERROR_EXISTS                      : "No existe el flujo indicado.",
    FLOWS_ERROR_NAME                        : "Debe indicar el nombre del flujo.",
    FLOWS_ERROR_PARENT                      : "El flujo padre no puede ser el mismo al flujo actual.",
    FLOWS_ERROR_FLOW                        : "No puede eliminar el al estar usado en otro flujo.",
    FLOWS_SUCCESS_CREATE                    : "El flujo se creó correctamente.",
    FLOWS_SUCCESS_COPY                      : "El flujo se copió correctamente.",
    FLOWS_SUCCESS_EDIT                      : "El flujo se editó correctamente.",
    FLOWS_SUCCESS_PUBLISH                   : "Los cambios del flujo se publicaron correctamente.",
    FLOWS_SUCCESS_CLEAR                     : "Los cambios del flujo se borraron correctamente.",
    FLOWS_SUCCESS_DELETE                    : "El flujo se eliminó correctamente.",

    // Flows: Templates
    FLOWS_TEMPLATES_NAME                    : "Plantillas de flujos",
    FLOWS_TEMPLATES_SINGULAR                : "Plantilla de flujo",
    FLOWS_TEMPLATES_VIEW_TITLE              : "Ver plantilla de flujo",
    FLOWS_TEMPLATES_CREATE_TITLE            : "Crear plantilla de flujo",
    FLOWS_TEMPLATES_EDIT_TITLE              : "Editar plantilla de flujo",
    FLOWS_TEMPLATES_COPY_TITLE              : "Copiar plantilla de flujo",
    FLOWS_TEMPLATES_DELETE_TITLE            : "Eliminar plantilla de flujo",
    FLOWS_TEMPLATES_DELETE_TEXT             : "¿Realmente desea eliminar la plantilla de flujo <b>{0}</b>?",
    FLOWS_TEMPLATES_PUBLISH_NO_CHANGES      : "No hay nodos con cambios para publicar en la plantilla de flujo <b>{0}</b>.",
    FLOWS_TEMPLATES_PUBLISH_CHANGES         : "¿Realmente desea publicar los cambios en la plantilla de flujo <b>{0}</b>?",
    FLOWS_TEMPLATES_PUBLISH_SINGULAR        : "¿Realmente desea publicar el nodo con cambios en la plantilla de flujo <b>{1}</b>?",
    FLOWS_TEMPLATES_PUBLISH_PLURAL          : "¿Realmente desea publicar los <b>{0} nodos</b> con cambios en la plantilla de flujo <b>{1}</b>?",
    FLOWS_TEMPLATES_CLEAR_CHANGES           : "¿Realmente desea borrar los cambios de la plantilla de flujo <b>{0}</b>?",
    FLOWS_TEMPLATES_ERROR_TEXT              : "Hay un nodo con errores en la plantilla de flujo. Debe corregirlos para publicar la plantilla de flujo. Puede verlos en el panel lateral.",
    FLOWS_TEMPLATES_ERRORS_TEXT             : "Hay <b>{0}</b> nodos con errores en la plantilla de flujo. Debe corregirlos para publicar la plantilla de flujo. Puede verlos en el panel lateral.",
    FLOWS_TEMPLATES_NONE_AVAILABLE          : "No hay plantillas de flujos disponibles.",
    FLOWS_TEMPLATES_ERROR_NAME              : "Debe indicar el nombre de la plantilla de flujo.",
    FLOWS_TEMPLATES_ERROR_EXISTS            : "No existe la plantilla de flujo indicada.",
    FLOWS_TEMPLATES_ERROR_EDIT              : "No tiene permiso para editar la plantilla de flujo indicada.",
    FLOWS_TEMPLATES_ERROR_DELETE            : "No tiene permiso para eliminar la plantilla de flujo indicada.",
    FLOWS_TEMPLATES_ERROR_PUBLISH           : "No puede publicar la plantilla de flujo porque tiene errores.",
    FLOWS_TEMPLATES_SUCCESS_CREATE          : "La plantilla de flujo se creó correctamente.",
    FLOWS_TEMPLATES_SUCCESS_COPY            : "La plantilla de flujo se copió correctamente.",
    FLOWS_TEMPLATES_SUCCESS_EDIT            : "La plantilla de flujo se editó correctamente.",
    FLOWS_TEMPLATES_SUCCESS_PUBLISH         : "Los cambios de la plantilla de flujo se publicaron correctamente.",
    FLOWS_TEMPLATES_SUCCESS_CLEAR           : "Los cambios de la plantilla de flujo se borraron correctamente.",
    FLOWS_TEMPLATES_SUCCESS_DELETE          : "La plantilla de flujo se eliminó correctamente.",

    // Flows: Nodes
    FLOWS_NODES_SINGULAR                    : "Nodo",
    FLOWS_NODES_MOVE_TITLE                  : "Mover nodos",
    FLOWS_NODES_MOVE_TEXT                   : "Puede utilizar este diálogo para mover o copiar los nodos seleccionados con sus enlaces a otro flujo.",
    FLOWS_NODES_DELETE_TITLE                : "Eliminar nodo",
    FLOWS_NODES_DELETE_TEXT                 : "¿Realmente desea eliminar el nodo seleccionado?",
    FLOWS_NODES_MULTI_DELETE_TITLE          : "Eliminar nodos",
    FLOWS_NODES_MULTI_DELETE_TEXT           : "¿Realmente desea eliminar los nodos <b>{0}</b> seleccionados?",
    FLOWS_NODES_WARNING_TITLE               : "Cambios sin guardar",
    FLOWS_NODES_WARNING_TEXT                : "Hay cambios sin guardar en las opciones nodo. ¿Desea descartarlos o seguir editando y guardarlos?",
    FLOWS_NODES_INVALID                     : "Inválido",
    FLOWS_NODES_START_FLOW                  : "Iniciar flujo",
    FLOWS_NODES_CONDITION                   : "Condición",
    FLOWS_NODES_SEND_MESSAGE                : "Enviar mensaje",
    FLOWS_NODES_SEND_TEMPLATE               : "Enviar plantilla",
    FLOWS_NODES_SEND_OPTIONS                : "Enviar opciones",
    FLOWS_NODES_ASSISTANT_REPLY             : "Responder con IA",
    FLOWS_NODES_ASSISTANT_OPTIONS           : "Opciones con IA",
    FLOWS_NODES_ASSISTANT_INTERPRET         : "Interpretar con IA",
    FLOWS_NODES_ASSISTANT_SUMMARIZE         : "Resumir con IA",
    FLOWS_NODES_QUESTIONNAIRE               : "Preguntas frecuentes",
    FLOWS_NODES_REQUEST_DATA                : "Solicitar dato",
    FLOWS_NODES_ASSIGN_DATA                 : "Asignar dato",
    FLOWS_NODES_ADD_NOTE                    : "Agregar nota",
    FLOWS_NODES_JUMP_TO                     : "Saltar a",
    FLOWS_NODES_CHANGE_STATUS               : "Cambiar estado",
    FLOWS_NODES_SEND_TO_QUEUE               : "Enviar a la cola si no hay usuario asignado",
    FLOWS_NODES_WAIT_EVENT                  : "Esperar evento",
    FLOWS_NODES_QUALIFICATION               : "Calificar",
    FLOWS_NODES_NOTIFICATION                : "Notificar",
    FLOWS_NODES_BOOKING                     : "Reserva",
    FLOWS_NODES_STORE                       : "Tienda",
    FLOWS_NODES_NOT_ALLOWED                 : "Nodo deshabilitada por no tener permisos suficientes. Toda ejecución va a terminar como un error.",
    FLOWS_NODES_NAME                        : "Nombre del nodo",
    FLOWS_NODES_TEAM_SCHEDULE               : "Horario del equipo",
    FLOWS_NODES_KEYWORDS                    : "Frases",
    FLOWS_NODES_ADD_KEYWORD                 : "Agregar frase",
    FLOWS_NODES_JUMP_TO_NODE                : "Saltar al nodo",
    FLOWS_NODES_JUMP_TO_FLOW                : "Saltar al flujo",
    FLOWS_NODES_JUMP_RETURN                 : "Regreso",
    FLOWS_NODES_CAN_RETURN                  : "Puede regresar",
    FLOWS_NODES_INPUT                       : "Entrada",
    FLOWS_NODES_SUCCESS                     : "Correcto",
    FLOWS_NODES_OUTPUT                      : "Salida",
    FLOWS_NODES_NO_RESPONSE                 : "Sin respuesta",
    FLOWS_NODES_CONDITION_NONE              : "No coincide",
    FLOWS_NODES_MESSAGE_WAITING             : "Mensaje enviado para esperar",
    FLOWS_NODES_MESSAGE_ERROR               : "Mensaje de error",
    FLOWS_NODES_OPTIONS_IN_MESSAGE          : "Mostrar las opciones en el mensaje",
    FLOWS_NODES_ALLOW_TEXT_RESPONSE         : "Permitir una respuesta como texto",
    FLOWS_NODES_TEXT_RESPONSE               : "Respuesta en texto",
    FLOWS_NODES_WAIT_RESPONSE               : "Esperar respuesta",
    FLOWS_NODES_ASSISTANT_PROMPT            : "Indica como debe responder",
    FLOWS_NODES_ASSISTANT_PROMPT_TIP        : "Ej: Responde a \"{conve-lastContactMessage}\"",
    FLOWS_NODES_INTERPRET_MESSAGE           : "Mensaje a interpretar",
    FLOWS_NODES_INTERPRET_ROOM_BOOKING      : "Interpretar reserva de habitación",
    FLOWS_NODES_OUT_ROOM_BOOKING            : "Reserva habitación",
    FLOWS_NODES_INTERPRET_FIELD_TITLE       : "Interpretar campo {0}",
    FLOWS_NODES_INTERPRET_FIELD             : "Campo a interpretar",
    FLOWS_NODES_OUT_UNKNOWN                 : "Desconocido",
    FLOWS_NODES_SHOW_EXTRA_OPTION           : "Mostrar opción adicional",
    FLOWS_NODES_EXTRA_OPTION                : "Opción adicional",
    FLOWS_NODES_EXTRA_OPTION_TEXT           : "Texto opción adicional",
    FLOWS_NODES_ASK_IF_RESOLVED             : "Preguntar si se resolvió la pregunta",
    FLOWS_NODES_QUESTION_MESSAGE            : "Pregunta si fue útil",
    FLOWS_NODES_ANSWERED_OPTION             : "Opción cuando es resuelta",
    FLOWS_NODES_NOT_ANSWERED_OPTION         : "Opción cuando no es resuelta",
    FLOWS_NODES_QUESTIONNAIRES_RESOLVED     : "Resuelta",
    FLOWS_NODES_QUESTIONNAIRES_NOT_RESOLVED : "No resuelta",
    FLOWS_NODES_SHOW_CONTINUE_OPTION        : "Mostrar opción de continuar",
    FLOWS_NODES_SHOW_CONTINUE_OPTION_TIP    : "Muestra un botón para continuar con el flujo sin acceder a la tienda.",
    FLOWS_NODES_CONTINUE_OPTION             : "Opción continuar",
    FLOWS_NODES_CONTINUE_OPTION_TEXT        : "Texto opción de continuar",
    FLOWS_NODES_OUT_CONFIRMED_ORDER         : "Pedido confirmado",
    FLOWS_NODES_OUT_ABANDONED_ORDER         : "Pedido abandonado",
    FLOWS_NODES_SUMMARIZE_PROMPT            : "Como debe resumir",
    FLOWS_NODES_SUMMARIZE_PROMPT_TIP        : "Indique las instrucciones de como debe resumir todos los mensajes de la conversación.",
    FLOWS_NODES_SUMMARIZE_NOTES             : "Incluir notas",
    FLOWS_NODES_EVENT                       : "Evento",
    FLOWS_NODES_EVENT_TITLE                 : "Evento {0}",
    FLOWS_NODES_EVENT_RESOLVED              : "<b>Resuelta:</b> Cuando la conversación es resuelta",
    FLOWS_NODES_ADD_MORE_EVENTS             : "Agregar más eventos",
    FLOWS_NODES_OUT_RESOLVED                : "Resuelta",
    FLOWS_NODES_MODULE_ASK                  : "Que dato pedir",
    FLOWS_NODES_MODULE_SAVE                 : "Donde guardar",
    FLOWS_NODES_MODULE_SAVE_KEY             : "Donde guardar la clave",
    FLOWS_NODES_OPERATION                   : "Operación",
    FLOWS_NODES_NEW_VALUE                   : "Nuevo valor",
    FLOWS_NODES_ASK_NEW_ACCOUNT             : "Pedir cuenta nueva",
    FLOWS_NODES_ALLOWS_NEW_VALUE            : "Permitir valor nuevo",
    FLOWS_NODES_ALLOWS_NEW_OPTION           : "Texto opción nuevo valor",
    FLOWS_NODES_SKIP_IF_FILLED              : "Saltear si el campo tiene un valor",
    FLOWS_NODES_HAS_MAX_TRIES               : "Tiene cantidad máxima de intentos",
    FLOWS_NODES_MAX_TRIES                   : "Cantidad máxima de intentos",
    FLOWS_NODES_USE_TIMEOUT                 : "Usar tiempo de espera",
    FLOWS_NODES_TIMEOUT                     : "Minutos a esperar",
    FLOWS_NODES_RETRIES                     : "Cantidad reintentos",
    FLOWS_NODES_RETRY_MESSAGE               : "Mensaje de reintento",
    FLOWS_NODES_RESOLVE_ON_TIMEOUT          : "Resolver en tiempo de espera",
    FLOWS_NODES_RESOLVED_MESSAGE            : "Mensaje de resolución",
    FLOWS_NODES_TRANSCRIBE_AUDIO            : "Transcribir audio",
    FLOWS_NODES_ASSIGN_TITLE                : "Asignación {0}",
    FLOWS_NODES_ADD_ASSIGN                  : "Agregar asignación",
    FLOWS_NODES_ASSIGN_TYPE                 : "Dato a asignar",
    FLOWS_NODES_DESKTOP_NOTIFICATION        : "Enviar notificación de escritorio",
    FLOWS_NODES_EMAIL_NOTIFICATION          : "Enviar notificación por email",
    FLOWS_NODES_EMAIL_USERS                 : "Enviar al equipo/usuario asignado",
    FLOWS_NODES_EMAIL_CONTACT               : "Enviar al contacto",
    FLOWS_NODES_DESKTOP_MESSAGE             : "Mensaje de la notificación de escritorio",
    FLOWS_NODES_BOOKING_BOOKED              : "Reservado",
    FLOWS_NODES_BOOKING_NO_ROOMS            : "Sin habitaciones",
    FLOWS_NODES_BOOKING_NOT_BOOKED          : "No reservado",
    FLOWS_NODES_BOOKING_HOTEL               : "Hotel asignado",
    FLOWS_NODES_BOOKING_MESSAGE             : "Mensaje inicial",
    FLOWS_NODES_BOOKING_FILE                : "Archivo del mensaje inicial",
    FLOWS_NODES_BOOKING_ARRIVAL_MESSAGE     : "Mensaje para la fecha de arribo",
    FLOWS_NODES_BOOKING_DEPARTURE_MESSAGE   : "Mensaje para la fecha de salida",
    FLOWS_NODES_BOOKING_GUESTS_MESSAGE      : "Mensaje para la cantidad de huéspedes",
    FLOWS_NODES_BOOKING_SEARCHING_MESSAGE   : "Mensaje al buscar habitaciones",
    FLOWS_NODES_BOOK_ROOM_OPTION            : "Opción de reservar habitación",
    FLOWS_NODES_OTHER_ROOM_OPTION           : "Opción de ver otra habitación",
    FLOWS_NODES_FIRST_ROOM_OPTION           : "Opción de ver primera habitación",
    FLOWS_NODES_END_BOOKING_OPTION          : "Opción de finalizar la reserva",
    FLOWS_NODES_WITH_ERRORS                 : "Nodos con errores",
    FLOWS_NODES_FOUND_NODES                 : "Nodos encontrados",
    FLOWS_NODES_NEW_FLOW_ID                 : "Nuevo flujo para los nodos",
    FLOWS_NODES_NEW_FLOW                    : "Crear flujo nuevo",
    FLOWS_NODES_NEW_FLOW_NAME               : "Nombre del flujo nuevo",
    FLOWS_NODES_DELETE_NODES                : "Eliminar nodos del flujo actual",
    FLOWS_NODES_GOTO_FLOW                   : "Ir al flujo",
    FLOWS_NODES_GOTO_PARENT                 : "Ir al padre",
    FLOWS_NODES_ERROR_PERMISSION            : "No tiene permiso para agregar este nodo.",
    FLOWS_NODES_ERROR_INPUT                 : "Falta enlace de entrada",
    FLOWS_NODES_ERROR_CYCLE                 : "Se generó un ciclo infinito.",
    FLOWS_NODES_ERROR_EXISTS                : "No existe el nodo indicado.",
    FLOWS_NODES_ERROR_TYPE                  : "El tipo del nodo es incorrecto.",
    FLOWS_NODES_ERROR_DELETE                : "No hay nodos para eliminar.",
    FLOWS_NODES_ERROR_MESSAGE               : "Debe indicar el mensaje.",
    FLOWS_NODES_ERROR_ASSISTANT_PROMPT      : "Debe indicar como debe responder.",
    FLOWS_NODES_ERROR_INTERPRET_MESSAGE     : "Debe indicar el mensaje a interpretar.",
    FLOWS_NODES_ERROR_INTERPRET_FIELDS      : "Debe indicar los campos a interpretar.",
    FLOWS_NODES_ERROR_CONDITIONS_NAME       : "Debe indicar el nombre de la condición.",
    FLOWS_NODES_ERROR_CONDITIONS_OPERATOR   : "El operador es incorrecto.",
    FLOWS_NODES_ERROR_CONDITIONS_KEYWORDS   : "Debe indicar al menos una frase.",
    FLOWS_NODES_ERROR_OPTIONS_EMPTY         : "Debe indicar al menos una opción.",
    FLOWS_NODES_ERROR_OPTIONS_NAME          : "Debe indicar el nombre de la opción.",
    FLOWS_NODES_ERROR_OPTIONS_DESCRIPTION   : "Debe indicar la descripción de la opción.",
    FLOWS_NODES_ERROR_OPTIONS_MAX           : "Debe indicar menos de 10 opciones.",
    FLOWS_NODES_ERROR_OPTIONS_LENGTH        : "El texto de las opciones no debe superar 20 caracteres.",
    FLOWS_NODES_ERROR_OPERATION             : "Debe indicar una operación válida.",
    FLOWS_NODES_ERROR_MODULE                : "Debe indicar donde guardar el dato.",
    FLOWS_NODES_ERROR_FIELD                 : "Debe indicar un campo válido.",
    FLOWS_NODES_ERROR_VALUE                 : "Debe indicar un valor válido.",
    FLOWS_NODES_ERROR_MAX_TRIES             : "La máxima cantidad de intentos debe ser mayor a 0.",
    FLOWS_NODES_ERROR_TIMEOUT               : "Los minutos de espera deben ser mayor a 0.",
    FLOWS_NODES_ERROR_RETRIES               : "La cantidad de reintentos deben ser mayor o igual a 0.",
    FLOWS_NODES_ERROR_ASSIGNS               : "Debe indicar al menos una asignación.",
    FLOWS_NODES_ERROR_ASSIGN_TYPE           : "Debe indicar un dato a asignar válido.",
    FLOWS_NODES_ERROR_JUMP                  : "Debe indicar a donde saltar.",
    FLOWS_NODES_ERROR_STATUS                : "Debe indicar el estado al cual cambiar.",
    FLOWS_NODES_ERROR_EVENTS                : "Debe indicar al menos un evento.",
    FLOWS_NODES_ERROR_EVENT_NAME            : "Debe indicar el nombre del evento.",
    FLOWS_NODES_ERROR_EVENT_TIME            : "Debe indicar la cantidad de horas y/o minutos.",
    FLOWS_NODES_ERROR_EVENT_REPEAT          : "No puede repetir este tipo de evento.",
    FLOWS_NODES_ERROR_EVENT_TYPE            : "Debe indicar un evento válido.",
    FLOWS_NODES_ERROR_NOTIFICATION_TITLE    : "Debe indicar el título de la notificación.",
    FLOWS_NODES_ERROR_NOTIFICATION_MESSAGE  : "Debe indicar el mensaje de la notificación.",
    FLOWS_NODES_ERROR_NOTIFICATION_NONE     : "Debe indicar enviar a los usuarios/equipo o al menos un email.",
    FLOWS_NODES_ERROR_NOTIFICATION_EMAIL    : "Uno de los emails indicados es inválido.",
    FLOWS_NODES_ERROR_NEW_FLOW_EMPTY        : "Debe indicar el nuevo flujo.",
    FLOWS_NODES_ERROR_NEW_FLOW_EQUAL        : "El nuevo flujo no puede ser igual al actual.",
    FLOWS_NODES_ERROR_NEW_FLOW_NAME         : "Debe indicar el nombre del nuevo flujo.",
    FLOWS_NODES_ERROR_NEW_FLOW_EXISTS       : "No existe el nuevo flujo indicado.",
    FLOWS_NODES_SUCCESS_EDIT                : "El nodo se editó correctamente.",
    FLOWS_NODES_SUCCESS_MOVE                : "El nodo se movió correctamente.",
    FLOWS_NODES_SUCCESS_MULTI_MOVE          : "Los nodos se movieron correctamente.",
    FLOWS_NODES_SUCCESS_DELETE              : "El nodo se eliminó correctamente.",
    FLOWS_NODES_SUCCESS_MULTI_DELETE        : "Los nodos se eliminaron correctamente.",

    // Flows: Edges
    FLOWS_EDGES_ERROR_CREATE                : "No tiene permiso para crear un enlace.",
    FLOWS_EDGES_ERROR_EXISTS                : "No existe el enlace indicado.",
    FLOWS_EDGES_ERROR_FROM                  : "Ya existe un enlace desde el mismo inicio.",
    FLOWS_EDGES_ERROR_SELF                  : "El enlace no puede iniciar y terminar en el mismo nodo.",
    FLOWS_EDGES_ERROR_CYCLE                 : "No puede generar ciclos con los enlaces.",
    FLOWS_EDGES_ERROR_DELETE                : "No puede eliminar el enlace indicado.",

    // Flows: Simulation
    FLOWS_SIMULATION_NAME                   : "Simulación",
    FLOWS_SIMULATION_START_TITLE            : "Simular flujo",
    FLOWS_SIMULATION_RESTART_TITLE          : "Reiniciar simulación de flujo",
    FLOWS_SIMULATION_END_TITLE              : "Terminar simulación de flujo",
    FLOWS_SIMULATION_END_TEXT               : "¿Realmente desea terminar la simulación del flujo? Puede indicar que hacer con la conversación y el contacto usado.",
    FLOWS_SIMULATION_END                    : "Terminar",
    FLOWS_SIMULATION_RESTART                : "Reiniciar",
    FLOWS_SIMULATION_START_HERE             : "Iniciar en el flujo actual",
    FLOWS_SIMULATION_START_FLOW             : "Iniciar en el flujo",
    FLOWS_SIMULATION_SAME_FLOW              : "Iniciar en el mismo flujo",
    FLOWS_SIMULATION_NEW_CONTACT            : "Usar un nuevo contacto",
    FLOWS_SIMULATION_SAME_CONTACT           : "Usar el mismo contacto",
    FLOWS_SIMULATION_CONTACT_NAME           : "Nombre del contacto",
    FLOWS_SIMULATION_USE_CURRENT_LANGUAGE   : "Usar idioma actual",
    FLOWS_SIMULATION_INITIAL_MESSAGE        : "Enviar mensaje inicial",
    FLOWS_SIMULATION_MESSAGE                : "Mensaje inicial",
    FLOWS_SIMULATION_ERROR_EXISTS           : "No hay una simulación corriendo.",
    FLOWS_SIMULATION_ERROR_STARTED          : "Ya tiene una simulación corriendo.",
    FLOWS_SIMULATION_SUCCESS_START          : "La simulación del flujo inició correctamente.",
    FLOWS_SIMULATION_SUCCESS_RESTART        : "La simulación del flujo reinició correctamente.",
    FLOWS_SIMULATION_SUCCESS_END            : "La simulación del flujo terminó correctamente.",



    // Automations
    AUTOMATIONS_NAME                        : "Automatizaciones",
    AUTOMATIONS_SINGULAR                    : "Automatización",
    AUTOMATIONS_VIEW_TITLE                  : "Ver automatización",
    AUTOMATIONS_CREATE_TITLE                : "Crear automatización",
    AUTOMATIONS_EDIT_TITLE                  : "Editar automatización",
    AUTOMATIONS_COPY_TITLE                  : "Copiar automatización",
    AUTOMATIONS_ACTIVATE_TITLE              : "Activar automatización",
    AUTOMATIONS_ACTIVATE_TEXT               : "¿Realmente desea activar la automatización <b>{0}</b>?",
    AUTOMATIONS_PAUSE_TITLE                 : "Pausar automatización",
    AUTOMATIONS_PAUSE_TEXT                  : "¿Realmente desea pausar la automatización <b>{0}</b>?",
    AUTOMATIONS_DELETE_TITLE                : "Eliminar automatización",
    AUTOMATIONS_DELETE_TEXT                 : "¿Realmente desea eliminar la automatización <b>{0}</b>?",
    AUTOMATIONS_PUBLISH_NO_CHANGES          : "No hay acciones con cambios para publicar en la automatización <b>{0}</b>.",
    AUTOMATIONS_PUBLISH_CHANGES_SINGULAR    : "¿Realmente desea publicar la acción con cambios en la automatización <b>{1}</b>?",
    AUTOMATIONS_PUBLISH_CHANGES_PLURAL      : "¿Realmente desea publicar las <b>{0} acciones</b> con cambios en la automatización <b>{1}</b>?",
    AUTOMATIONS_CLEAR_CHANGES               : "¿Realmente desea borrar los cambios de la automatización <b>{0}</b>?",
    AUTOMATIONS_ERRORS_AUTOMATION           : "Hay errores en la automatización.",
    AUTOMATIONS_ERRORS_FLOW                 : "Hay errores en el flujo de la automatización.",
    AUTOMATIONS_ERRORS_ACTIVATE             : "Debe corregirlos para activar la automatización.",
    AUTOMATIONS_ERRORS_PUBLISH              : "Debe corregirlos para publicar la automatización.",
    AUTOMATIONS_ERRORS_PANEL                : "Puede verlos en el panel lateral.",
    AUTOMATIONS_TEMPLATE                    : "Crear con la plantilla de automatización",
    AUTOMATIONS_SUCCEEDED_AMOUNT            : "Ejecuciones exitosas",
    AUTOMATIONS_FAILED_AMOUNT               : "Ejecuciones fallidas",
    AUTOMATIONS_NONE_AVAILABLE              : "No hay automatizaciones disponibles.",
    AUTOMATIONS_ERROR_EDIT                  : "No tiene permiso para editar la automatización indicada.",
    AUTOMATIONS_ERROR_EXISTS                : "No existe la automatización indicada.",
    AUTOMATIONS_ERROR_NAME                  : "Debe indicar el nombre de la automatización.",
    AUTOMATIONS_ERROR_PUBLISH               : "No puede publicar la automatización porque tiene errores.",
    AUTOMATIONS_SUCCESS_CREATE              : "La automatización se creó correctamente.",
    AUTOMATIONS_SUCCESS_COPY                : "La automatización se copió correctamente.",
    AUTOMATIONS_SUCCESS_EDIT                : "La automatización se editó correctamente.",
    AUTOMATIONS_SUCCESS_ACTIVATE            : "La automatización se activó correctamente.",
    AUTOMATIONS_SUCCESS_PUBLISH             : "Los cambios de la automatización se publicaron correctamente.",
    AUTOMATIONS_SUCCESS_CLEAR               : "Los cambios de la automatización se borraron correctamente.",
    AUTOMATIONS_SUCCESS_PAUSE               : "La automatización se pausó correctamente.",
    AUTOMATIONS_SUCCESS_DELETE              : "La automatización se eliminó correctamente.",

    // Automations: Templates
    AUTOMATIONS_TEMPS_NAME                  : "Plantillas de automatizaciones",
    AUTOMATIONS_TEMPS_SINGULAR              : "Plantilla de automatización",
    AUTOMATIONS_TEMPS_VIEW_TITLE            : "Ver plantilla de automatización",
    AUTOMATIONS_TEMPS_CREATE_TITLE          : "Crear plantilla de automatización",
    AUTOMATIONS_TEMPS_EDIT_TITLE            : "Editar plantilla de automatización",
    AUTOMATIONS_TEMPS_COPY_TITLE            : "Copiar plantilla de automatización",
    AUTOMATIONS_TEMPS_ACTIVATE_TITLE        : "Activar plantilla de automatización",
    AUTOMATIONS_TEMPS_ACTIVATE_TEXT         : "¿Realmente desea activar la plantilla de automatización <b>{0}</b>?",
    AUTOMATIONS_TEMPS_PAUSE_TITLE           : "Pausar plantilla de automatización",
    AUTOMATIONS_TEMPS_PAUSE_TEXT            : "¿Realmente desea pausar la plantilla de automatización <b>{0}</b>?",
    AUTOMATIONS_TEMPS_DELETE_TITLE          : "Eliminar plantilla de automatización",
    AUTOMATIONS_TEMPS_DELETE_TEXT           : "¿Realmente desea eliminar la plantilla de automatización <b>{0}</b>?",
    AUTOMATIONS_TEMPS_PUBLISH_NO_CHANGES    : "No hay acciones con cambios para publicar en la plantilla de automatización <b>{0}</b>.",
    AUTOMATIONS_TEMPS_PUBLISH_SINGULAR      : "¿Realmente desea publicar la acción con cambios en la plantilla de automatización <b>{1}</b>?",
    AUTOMATIONS_TEMPS_PUBLISH_PLURAL        : "¿Realmente desea publicar las <b>{0} acciones</b> con cambios en la plantilla de automatización <b>{1}</b>?",
    AUTOMATIONS_TEMPS_CLEAR_CHANGES         : "¿Realmente desea borrar los cambios de la plantilla de automatización <b>{0}</b>?",
    AUTOMATIONS_TEMPS_ERRORS_TEXT           : "Hay errores en la plantilla de automatización.",
    AUTOMATIONS_TEMPS_ERRORS_ACTIVATE       : "Debe corregirlos para activar la plantilla de automatización.",
    AUTOMATIONS_TEMPS_ERRORS_PUBLISH        : "Debe corregirlos para publicar la plantilla de automatización.",
    AUTOMATIONS_TEMPS_ERRORS_PANEL          : "Puede verlos en el panel lateral.",
    AUTOMATIONS_TEMPS_NONE_AVAILABLE        : "No hay plantillas de automatizaciones disponibles.",
    AUTOMATIONS_TEMPS_ERROR_NAME            : "Debe indicar el nombre de la plantilla de automatización.",
    AUTOMATIONS_TEMPS_ERROR_EXISTS          : "No existe la plantilla de automatización indicada.",
    AUTOMATIONS_TEMPS_ERROR_EDIT            : "No tiene permiso para editar la plantilla de automatización indicada.",
    AUTOMATIONS_TEMPS_ERROR_DELETE          : "No tiene permiso para eliminar la plantilla de automatización indicada.",
    AUTOMATIONS_TEMPS_ERROR_PUBLISH         : "No puede publicar la plantilla de automatización porque tiene errores.",
    AUTOMATIONS_TEMPS_SUCCESS_CREATE        : "La plantilla de automatización se creó correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_COPY          : "La plantilla de automatización se copió correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_EDIT          : "La plantilla de automatización se editó correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_ACTIVATE      : "La plantilla de automatización se activó correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_PUBLISH       : "Los cambios de la plantilla de automatización se publicaron correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_CLEAR         : "Los cambios de la plantilla de automatización se borraron correctamente.",
    AUTOMATIONS_TEMPS_SUCCESS_DELETE        : "La plantilla de automatización se eliminó correctamente.",

    // Automations: Actions
    AUTOMATIONS_ACTIONS_CREATE_TRIGGER      : "Crear trigger",
    AUTOMATIONS_ACTIONS_CREATE_ACTION       : "Crear acción",
    AUTOMATIONS_ACTIONS_CREATE_TEXT         : "Seleccione el tipo de acción o evento deseado:",
    AUTOMATIONS_ACTIONS_DELETE_TITLE        : "Eliminar acción",
    AUTOMATIONS_ACTIONS_DELETE_TEXT         : "¿Realmente desea eliminar el acción seleccionada?",
    AUTOMATIONS_ACTIONS_WARNING_TITLE       : "Cambios sin guardar",
    AUTOMATIONS_ACTIONS_WARNING_TEXT        : "Hay cambios sin guardar en las opciones nodo. ¿Desea descartarlos o seguir editando y guardarlos?",
    AUTOMATIONS_ACTIONS_TRIGGER_TITLE       : "Trigger",
    AUTOMATIONS_ACTIONS_TRIGGER_TEXT        : "Un evento que inicia la automatización",
    AUTOMATIONS_ACTIONS_ACTION_TEXT         : "Una acción realizada luego de iniciada la automatización",
    AUTOMATIONS_ACTIONS_ACCOUNT_EDIT        : "Editar cuenta",
    AUTOMATIONS_ACTIONS_ACCOUNT_EDIT_DESC   : "Edita y/o obtiene una cuenta en base a los valores indicados.",
    AUTOMATIONS_ACTIONS_ACCOUNT_UPD         : "Cuenta actualizada",
    AUTOMATIONS_ACTIONS_ACCOUNT_UPD_DESC    : "Se ejecuta cuando una cuenta es creada, editada o eliminada.",
    AUTOMATIONS_ACTIONS_CONTACT_DATE        : "Fecha de contacto",
    AUTOMATIONS_ACTIONS_CONTACT_DATE_DESC   : "Se ejecuta antes o luego de cierta cantidad de tiempo de una fecha en un campo del contacto.",
    AUTOMATIONS_ACTIONS_CONTACT_EDIT        : "Editar contacto",
    AUTOMATIONS_ACTIONS_CONTACT_EDIT_DESC   : "Edita y/o obtiene un contacto en base a los valores indicados.",
    AUTOMATIONS_ACTIONS_CONTACT_UPD         : "Contacto actualizado",
    AUTOMATIONS_ACTIONS_CONTACT_UPD_DESC    : "Se ejecuta cuando un contacto es creado, editado o eliminado.",
    AUTOMATIONS_ACTIONS_CONVERSATION_R      : "Conversación resuelta",
    AUTOMATIONS_ACTIONS_CONVERSATION_RD     : "Se ejecuta luego de cierta cantidad de tiempo de resuelta una conversación.",
    AUTOMATIONS_ACTIONS_CONVERSATION_C      : "Crear conversación",
    AUTOMATIONS_ACTIONS_CONVERSATION_CD     : "Crea una conversación usando el contacto, el mensaje y archivo indicados.",
    AUTOMATIONS_ACTIONS_FILTER              : "Filtrar",
    AUTOMATIONS_ACTIONS_FILTER_DESC         : "Filtra la acción en base a los datos obtenidos.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_DATE    : "Fecha de hospitality",
    AUTOMATIONS_ACTIONS_HOSPITALITY_DATE_D  : "Se ejecuta antes o luego de cierta cantidad de tiempo de una fecha de reserva, check-in o check-out.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_EDIT    : "Editar hospitality",
    AUTOMATIONS_ACTIONS_HOSPITALITY_EDIT_D  : "Edita y/o obtiene un hospitality en base a los valores indicados.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_MOVE    : "Hospitality movido",
    AUTOMATIONS_ACTIONS_HOSPITALITY_MOVE_D  : "Se ejecuta cuando la habitación de un hospitality cambia.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_UPD     : "Hospitality actualizado",
    AUTOMATIONS_ACTIONS_HOSPITALITY_UPD_D   : "Se ejecuta cuando un hospitality es creado, editado o eliminado.",
    AUTOMATIONS_ACTIONS_HOSPITALITY_SMDR    : "Pedido del hotel",
    AUTOMATIONS_ACTIONS_HOSPITALITY_SMDR_D  : "Se solicitan los datos de los cargos de los huéspedes del hotel.",
    AUTOMATIONS_ACTIONS_PRODUCT_EDIT        : "Editar producto de tienda",
    AUTOMATIONS_ACTIONS_PRODUCT_EDIT_DESC   : "Edita y/o obtiene un producto de una tienda en base a los valores indicados.",
    AUTOMATIONS_ACTIONS_SEND_EMAIL          : "Enviar Email",
    AUTOMATIONS_ACTIONS_SEND_EMAIL_DESC     : "Enviar un mensaje por Email a un contacto o a un email.",
    AUTOMATIONS_ACTIONS_SEND_SMS            : "Enviar SMS",
    AUTOMATIONS_ACTIONS_SEND_SMS_DESC       : "Enviar un mensaje por SMS a un contacto o a un celular.",
    AUTOMATIONS_ACTIONS_SEND_WHATSAPP       : "Enviar WhatsApp",
    AUTOMATIONS_ACTIONS_SEND_WHATSAPP_DESC  : "Enviar un mensaje por WhatsApp a un contacto o a un celular.",
    AUTOMATIONS_ACTIONS_WITH_ERRORS         : "Acciones con errores",
    AUTOMATIONS_ACTIONS_NOT_ALLOWED         : "Acción deshabilitada por no tener permisos suficientes.",
    AUTOMATIONS_ACTIONS_NAME                : "Nombre de la acción",
    AUTOMATIONS_ACTIONS_IS_DISABLED         : "Está deshabilitada",
    AUTOMATIONS_ACTIONS_ACTION              : "Acción realizada",
    AUTOMATIONS_ACTIONS_EVENTS              : "Eventos escuchados",
    AUTOMATIONS_ACTIONS_EVENTS_TIP          : "Indique los eventos que ejecutan la automatización.",
    AUTOMATIONS_ACTIONS_FIELD_TITLE         : "Campo {0}",
    AUTOMATIONS_ACTIONS_SKIP_IF_SENT        : "Saltear si envió",
    AUTOMATIONS_ACTIONS_SEND_TO_CONTACT     : "Enviar al contacto",
    AUTOMATIONS_ACTIONS_SEND_TO_PHONE       : "Enviar al celular",
    AUTOMATIONS_ACTIONS_SEND_TO_EMAIL       : "Enviar al email",
    AUTOMATIONS_ACTIONS_SEND_ISO_CODE       : "Código ISO del idioma",
    AUTOMATIONS_ACTIONS_ERROR_EXISTS        : "No existe la acción indicada.",
    AUTOMATIONS_ACTIONS_ERROR_TYPE          : "El tipo de acción es inválida.",
    AUTOMATIONS_ACTIONS_ERROR_TRIGGER       : "Debe agregar un trigger.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION        : "Debe agregar al menos una acción.",
    AUTOMATIONS_ACTIONS_ERROR_PERMISSION    : "No tiene permiso para agregar esta acción.",
    AUTOMATIONS_ACTIONS_ERROR_UNIQUE        : "Solo puede existir una acción con este tipo.",
    AUTOMATIONS_ACTIONS_ERROR_CONTACT       : "La acción requiere una acción previa con un contacto.",
    AUTOMATIONS_ACTIONS_ERROR_SEND_PHONE    : "Se requiere un celular para enviar el mensaje.",
    AUTOMATIONS_ACTIONS_ERROR_SEND_EMAIL    : "Se requiere un email para enviar el mensaje.",
    AUTOMATIONS_ACTIONS_ERROR_COPY_TRIGGER  : "No se puede copiar un trigger.",
    AUTOMATIONS_ACTIONS_ERROR_MOVE_TRIGGER  : "No se puede mover un trigger.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_NONE   : "Debe indicar al menos un campo.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_EMPTY  : "No deben haber campos vacíos.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_EXIST  : "No existe el campo indicado.",
    AUTOMATIONS_ACTIONS_ERROR_FIELDS_VALUE  : "Debe indicar el valor del campos vacíos.",
    AUTOMATIONS_ACTIONS_ERROR_EVENTS_NONE   : "Debe indicar al menos un evento.",
    AUTOMATIONS_ACTIONS_ERROR_EVENTS_INV    : "El tipo de alguno de los eventos es incorrecto.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION_EMPTY  : "Debe indicar la acción a realizar.",
    AUTOMATIONS_ACTIONS_ERROR_ACTION_INV    : "La acción indicada es incorrecta.",
    AUTOMATIONS_ACTIONS_ERROR_CHANNEL       : "Debe indicar el canal.",
    AUTOMATIONS_ACTIONS_ERROR_WABA_TEMP     : "Debe indicar la plantilla de WhatsApp.",
    AUTOMATIONS_ACTIONS_ERROR_SUBJECT       : "Debe indicar el asunto.",
    AUTOMATIONS_ACTIONS_ERROR_MESSAGE       : "Debe indicar el mensaje.",
    AUTOMATIONS_ACTIONS_SUCCESS_COPY        : "La acción se copió correctamente.",
    AUTOMATIONS_ACTIONS_SUCCESS_EDIT        : "La acción se editó correctamente.",
    AUTOMATIONS_ACTIONS_SUCCESS_MOVE        : "La acción se movió correctamente.",
    AUTOMATIONS_ACTIONS_SUCCESS_DELETE      : "La acción se eliminó correctamente.",

    // Automations: Executions
    AUTOMATIONS_EXECS_NAME                  : "Ejecuciones",
    AUTOMATIONS_EXECS_DATE                  : "Ejecutado el",
    AUTOMATIONS_EXECS_NONE_AVAILABLE        : "No hay ejecuciones de automatizaciones disponibles.",
    AUTOMATIONS_EXECS_UNIMPLEMENTED         : "Acción no implementada.",
    AUTOMATIONS_EXECS_MISSING_DATA          : "No se recibieron datos.",
    AUTOMATIONS_EXECS_FETCH                 : "Ocurrió un error al hacer la llamada HTTP.",
    AUTOMATIONS_EXECS_NO_MATCH              : "No hubieron coincidencias en el filtro.",
    AUTOMATIONS_EXECS_CONVERSATION_ID       : "No se tiene el ID de la conversación.",
    AUTOMATIONS_EXECS_CONVERSATION_FIND     : "No se encontró la conversación.",
    AUTOMATIONS_EXECS_CONVERSATION_CREATE   : "No se pudo crear la conversación.",
    AUTOMATIONS_EXECS_CONTACT_ID            : "No se tiene el ID del contacto.",
    AUTOMATIONS_EXECS_CONTACT_FIND          : "No se encontró el contacto.",
    AUTOMATIONS_EXECS_CONTACT_EDIT          : "No se pudo editar el contacto.",
    AUTOMATIONS_EXECS_HOSPITALITY_ID        : "No se tiene el ID del hospitality.",
    AUTOMATIONS_EXECS_HOSPITALITY_FIND      : "No se encontró el hospitality.",
    AUTOMATIONS_EXECS_HOSPITALITY_EDIT      : "No se pudo editar el hospitality.",
    AUTOMATIONS_EXECS_SALE_ID               : "No se tiene el ID de la venta.",
    AUTOMATIONS_EXECS_SALE_FIND             : "No se encontró la venta.",
    AUTOMATIONS_EXECS_ACCOUNT_ID            : "No se tiene el ID de la cuenta.",
    AUTOMATIONS_EXECS_ACCOUNT_FIND          : "No se encontró la cuenta.",
    AUTOMATIONS_EXECS_ACCOUNT_EDIT          : "No se pudo editar la cuenta.",
    AUTOMATIONS_EXECS_PRODUCT_EDIT          : "No se pudo editar el producto.",
    AUTOMATIONS_EXECS_HOTEL_FIND            : "No se encontró el hotel.",
    AUTOMATIONS_EXECS_SEND_CONTACT          : "No se tiene el contacto a quien enviar.",
    AUTOMATIONS_EXECS_SEND_WABA_TEMPLATE    : "No existe la plantilla de WhatsApp.",
    AUTOMATIONS_EXECS_SEND_MESSAGE          : "No se tiene un mensaje a enviar.",
    AUTOMATIONS_EXECS_SEND_ERROR            : "No se pudo enviar el mensaje.",



    // Applications
    APPLICATIONS_TITLE                      : "Marketplace",
    APPLICATIONS_NAME                       : "Aplicaciones",
    APPLICATIONS_SINGULAR                   : "Aplicación",
    APPLICATIONS_ADD_TITLE                  : "Agregar aplicación",
    APPLICATIONS_EDIT_TITLE                 : "Editar aplicación",
    APPLICATIONS_DELETE_TITLE               : "Eliminar aplicación",
    APPLICATIONS_DELETE_TEXT                : "¿Realmente desea eliminar la aplicación <b>{0}</b>?",
    APPLICATIONS_PROVIDER_TEXT              : "¿Qué aplicación le gustaría agregar?",
    APPLICATIONS_SELECT_TEXT                : "Haga click en un proveedor para agregarlo como aplicación.",
    APPLICATIONS_CONFIG_TITLE               : "Configurar",
    APPLICATIONS_CONFIG_TEXT                : "Configure la aplicación seleccionada",
    APPLICATIONS_NONE_AVAILABLE             : "No hay aplicaciones disponibles.",
    APPLICATIONS_ERROR_EDIT                 : "No tiene permiso para editar aplicaciones.",
    APPLICATIONS_ERROR_ADD                  : "No tiene permiso para agregar la aplicación indicada.",
    APPLICATIONS_ERROR_EMPTY                : "Debe indicar la aplicación a usar.",
    APPLICATIONS_ERROR_EXISTS               : "No existe la aplicación indicada.",
    APPLICATIONS_ERROR_TYPE                 : "No existe el tipo de aplicación indicado.",
    APPLICATIONS_ERROR_NAME                 : "Debe indicar el nombre de la aplicación.",
    APPLICATIONS_ERROR_ACTIVE               : "La aplicación está inactiva.",
    APPLICATIONS_ERROR_USED_FLOW            : "No puede eliminar una aplicación usada en un flujo.",
    APPLICATIONS_ERROR_USED_AUTOMATION      : "No puede eliminar una aplicación usada en una automatización.",
    APPLICATIONS_SUCCESS_CREATE             : "La aplicación se creó correctamente.",
    APPLICATIONS_SUCCESS_EDIT               : "La aplicación se editó correctamente.",
    APPLICATIONS_SUCCESS_DELETE             : "La aplicación se eliminó correctamente.",

    // Applications: Fetch
    APPLICATIONS_FETCH_WEBHOOK_ACTION       : "Webhook",
    APPLICATIONS_FETCH_WEBHOOK_ACTION_DESC  : "Recibe datos de una url para iniciar la automatización.",
    APPLICATIONS_FETCH_WEBHOOK_URL          : "Tu webhook url",
    APPLICATIONS_FETCH_WEBHOOK_URL_TIP      : "Puedes enviar datos en formato JSON realizando un POST a la url.",
    APPLICATIONS_FETCH_FIELDS               : "Campos de la respuesta",
    APPLICATIONS_FETCH_FIELDS_TIP           : "Indique cada uno de los campos a usar del JSON recibido. Puede usar '.' para indicar un campo en un objeto interno.",

    APPLICATIONS_FETCH_ACTION               : "Llamada HTTP",
    APPLICATIONS_FETCH_ACTION_DESC          : "Pide o publica datos a la url indicada.",
    APPLICATIONS_FETCH_URL                  : "Tu url",
    APPLICATIONS_FETCH_URL_TIP              : "La url a la cual solicitar o enviar datos.",
    APPLICATIONS_FETCH_METHOD               : "Método usado",
    APPLICATIONS_FETCH_HAS_HEADERS          : "Tiene encabezado",
    APPLICATIONS_FETCH_USER_PASS            : "Usuario y contraseña",
    APPLICATIONS_FETCH_USER_PASS_TIP        : "Usuario y contraseña separados por | para una autenticación básica.",
    APPLICATIONS_FETCH_HEADER_TITLE         : "Encabezado {0}",
    APPLICATIONS_FETCH_ADD_HEADER           : "Agregar encabezado",
    APPLICATIONS_FETCH_PARAM_TITLE          : "Parámetro {0}",
    APPLICATIONS_FETCH_ADD_PARAM            : "Agregar parámetro",
    APPLICATIONS_FETCH_RESPONSE_TITLE       : "Campo de respuesta {0}",
    APPLICATIONS_FETCH_RESPONSE_TIP         : "Agregue cada campo de respuesta usada y donde guardarlo. Puede usar '.' para indicar un campo en un objeto interno.",
    APPLICATIONS_FETCH_ADD_RESPONSE         : "Agregar campo de respuesta",
    APPLICATIONS_FETCH_LIST_KEY             : "Clave para el listado",
    APPLICATIONS_FETCH_LIST_VALUE           : "Valor para el listado",

    APPLICATIONS_FETCH_ERROR_URL_EMPTY      : "Debe indicar una url.",
    APPLICATIONS_FETCH_ERROR_URL_INVALID    : "La url debe comenzar en https://",
    APPLICATIONS_FETCH_ERROR_METHOD_EMPTY   : "Debe indicar el método a usar.",
    APPLICATIONS_FETCH_ERROR_METHOD_INV     : "El método indicado es inválido.",
    APPLICATIONS_FETCH_ERROR_USER_PASS      : "El usuario y contraseña debe estar separado por una |",
    APPLICATIONS_FETCH_ERROR_HEADER_KEY     : "Debe completar la clave del encabezado.",
    APPLICATIONS_FETCH_ERROR_HEADER_VALUE   : "Debe completar el valor del encabezado.",
    APPLICATIONS_FETCH_ERROR_KEY_EMPTY      : "Debe indicar la clave del campo.",
    APPLICATIONS_FETCH_ERROR_KEY_INVALID    : "La clave solo puede tener letras, números, puntos o guiones.",
    APPLICATIONS_FETCH_ERROR_KEYS_INVALID   : "Cada clave solo puede tener letras, números, puntos o guiones.",
    APPLICATIONS_FETCH_ERROR_KEYS_REPEAT    : "Las claves no se pueden repetir.",
    APPLICATIONS_FETCH_ERROR_VALUE_EMPTY    : "Debe indicar el valor del campo.",

    // Applications: Nubila
    APPLICATIONS_NUBILA_NAME                : "Nubila UCX - Telefonía en la nube",
    APPLICATIONS_NUBILA_DESC                : "El aplicación permite integrar algunas de las APIs de Nubila como una acción dentro de las automatizaciones. Solo necesita agregar la Clave de API key y el ID del servidor.",
    APPLICATIONS_NUBILA_API_KEY             : "Clave de API",
    APPLICATIONS_NUBILA_SERVER              : "ID del servidor",
    APPLICATIONS_NUBILA_ACTION              : "Nubila",
    APPLICATIONS_NUBILA_ACTION_DESC         : "Ejecuta alguno de los APIs de Nubila.",
    APPLICATIONS_NUBILA_API                 : "API de Nubila",
    APPLICATIONS_NUBILA_MINUTES             : "Minutos a obtener",
    APPLICATIONS_NUBILA_DELAY               : "Delay en minutos",
    APPLICATIONS_NUBILA_DELAY_HELP          : "En vez de obtener los datos en tiempo real, se obtienen los datos de hace X minutos.",
    APPLICATIONS_NUBILA_ERROR_API_KEY       : "Debe indicar la clave de API.",
    APPLICATIONS_NUBILA_ERROR_SERVER        : "Debe indicar el ID del servidor.",
    APPLICATIONS_NUBILA_ERROR_API           : "Debe indicar un API de Nubila válido.",
    APPLICATIONS_NUBILA_ERROR_RESULT        : "El API de Nubila devolvió un error.",
    APPLICATIONS_NUBILA_ERROR_MINUTES       : "Debe indicar los minutos a obtener.",
    APPLICATIONS_NUBILA_ERROR_DELAY         : "Debe indicar el delay en minutos.",

    // Applications: Omnitec
    APPLICATIONS_OMNITEC_NAME               : "Omnitec Systems - Equipamiento para hoteles",
    APPLICATIONS_OMNITEC_DESC               : "El aplicación permite recibir información sobre los PINs de las llaves para guardar e informar al huésped. Solo necesita agregar el ID del hotel.",
    APPLICATIONS_OMNITEC_CLIENT             : "ID del cliente",
    APPLICATIONS_OMNITEC_ACTION             : "Omnitec",
    APPLICATIONS_OMNITEC_ACTION_DESC        : "Recibe los datos de las llaves de Omnitec para guardarlos o enviarlos.",
    APPLICATIONS_OMNITEC_KEY_TYPE           : "Tipo de llave",
    APPLICATIONS_OMNITEC_ERROR_HOTEL        : "Debe indicar el ID del Hotel.",
    APPLICATIONS_OMNITEC_ERROR_KEY_TYPE     : "Debe indicar un tipo de llave válida.",

    // Applications: Forze
    APPLICATIONS_FORZE_NAME                 : "Forze - Cerradura Electronica",
    APPLICATIONS_FORZE_DESC                 : "El aplicación permite generar un PIN para una llave electrónica que se guarda en la información del huésped. Requiere ingresar el usuario y contraseña.",
    APPLICATIONS_FORZE_ACTION               : "Forze",
    APPLICATIONS_FORZE_ACTION_DESC          : "Ejecuta alguno de los APIs de Forze.",
    APPLICATIONS_FORZE_API                  : "API de Forze",
    APPLICATIONS_FORZE_ERROR_API            : "Debe indicar un API de Forze válido.",
    APPLICATIONS_FORZE_ERROR_RESULT         : "El API de Forze devolvió un error.",

    // Application: Google Sheets
    APPLICATIONS_GOOGLE_SHEETS_NAME         : "Google Sheets",
    APPLICATIONS_GOOGLE_SHEETS_DESC         : "El aplicación permite agregar filas a una hoja de Google. Solo necesita dar permiso a una cuenta de Google.",
    APPLICATIONS_GOOGLE_SHEETS_ACTION       : "Insertar en Google Sheets",
    APPLICATIONS_GOOGLE_SHEETS_ACTION_DESC  : "Inserta una fila en una hoja de Google Sheets.",
    APPLICATIONS_GOOGLE_SHEETS_ID           : "ID del Spreadsheet",
    APPLICATIONS_GOOGLE_SHEETS_ID_TIP       : "Se puede obtener en la url entre '/d/' y '/edit'.",
    APPLICATIONS_GOOGLE_SHEETS_SHEET        : "Nombre de la hoja",
    APPLICATIONS_GOOGLE_SHEETS_SHEET_TIP    : "Puede dejar en blanco para insertar en la primer hoja.",
    APPLICATIONS_GOOGLE_SHEETS_VALUES       : "Valores",
    APPLICATIONS_GOOGLE_SHEETS_ADD_VALUE    : "Agregar valor",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_TOKEN  : "Debe dar permiso a una cuenta de Google.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_ID     : "Debe indicar el ID del Spreadsheet.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_VALUES : "Debe ingresar al menos un valor.",
    APPLICATIONS_GOOGLE_SHEETS_ERROR_RESULT : "No fue posible conectarse con Google Sheets.",

    // Applications: Gestion Bos
    APPLICATIONS_GESTION_BOS_NAME           : "Gestión Bos - Sistema de Gestion Online",
    APPLICATIONS_GESTION_BOS_DESC           : "El aplicación utilizar las APIs de Gestión Bos. Requiere ingresar la url y la clave de solicitud.",
    APPLICATIONS_GESTION_BOS_BASE_URL       : "Url base",
    APPLICATIONS_GESTION_BOS_REQUEST_KEY    : "Clave de solicitud",
    APPLICATIONS_GESTION_BOS_CLIENT_ID      : "ID del cliente",
    APPLICATIONS_GESTION_BOS_ACTION         : "Gestión Bos",
    APPLICATIONS_GESTION_BOS_ACTION_DESC    : "Ejecuta alguno de los APIs de Gestión Bos.",
    APPLICATIONS_GESTION_BOS_API            : "API de Gestión Bos",
    APPLICATIONS_GESTION_BOS_CONTACT        : "Código del contacto",
    APPLICATIONS_GESTION_BOS_ERROR_BASE_URL : "Debe indicar la url base.",
    APPLICATIONS_GESTION_BOS_ERROR_REQUEST  : "Debe indicar la clave de solicitud.",
    APPLICATIONS_GESTION_BOS_ERROR_CLIENT   : "Debe indicar el ID del cliente.",
    APPLICATIONS_GESTION_BOS_ERROR_API      : "Debe indicar un API de Gestión Bos válido.",
    APPLICATIONS_GESTION_BOS_ERROR_CONTACT  : "Debe indicar el código del contacto.",
    APPLICATIONS_GESTION_BOS_ERROR_RESULT   : "El API de Gestión Bos devolvió un error.",

    // Applications: Tienda Nube
    APPLICATIONS_TIENDA_NUBE_NAME           : "Tienda Nube",
    APPLICATIONS_TIENDA_NUBE_DESC           : "El aplicación utiliza las APIs de Tienda Nube para integrar los pedidos y clientes en Conversana.",
    APPLICATIONS_TIENDA_NUBE_INSTALL        : "Instalar Tienda Nube",
    APPLICATIONS_TIENDA_NUBE_INSTALL_DESC   : "Haz click en el siguiente botón y acepta los permisos solicitados continuar.",
    APPLICATIONS_TIENDA_NUBE_INSTALL_END    : "¡La instalación fue exitosa! Puede finalizar la configuración.",
    APPLICATIONS_TIENDA_NUBE_ACTION_DESC    : "Se ejecuta cuando una venta en Tienda Nube cambia de estado.",
    APPLICATIONS_TIENDA_NUBE_ERROR_TOKEN    : "Debe dar permiso a una cuenta de Tienda Nube.",



    // Support: Tickets
    TICKETS_NAME                            : "Tickets",
    TICKETS_SINGULAR                        : "Ticket",
    TICKETS_VIEW_TITLE                      : "Ver ticket",
    TICKETS_CREATE_TITLE                    : "Crear ticket",
    TICKETS_EDIT_TITLE                      : "Editar ticket",
    TICKETS_DELETE_TITLE                    : "Eliminar ticket",
    TICKETS_DELETE_TEXT                     : "¿Realmente desea eliminar el ticket <b>{0}</b>?",
    TICKETS_OPEN_TITLE                      : "Abrir ticket",
    TICKETS_OPEN_TEXT                       : "¿Realmente desea abrir el ticket <b>{0}</b>?",
    TICKETS_OPEN_TAB                        : "Tickets abiertos",
    TICKETS_WAITING_TITLE                   : "Mover a lista de espera",
    TICKETS_WAITING_TEXT                    : "Seleccione la lista de espera a la cual desea mover el ticket <b>{0}</b>.",
    TICKETS_WAITING_TAB                     : "Tickets esperando",
    TICKETS_WAIT                            : "Esperar",
    TICKETS_CLOSE_TITLE                     : "Cerrar ticket",
    TICKETS_CLOSE_TEXT                      : "¿Realmente desea cerrar el ticket <b>{0}</b>?",
    TICKETS_CLOSE_TAB                       : "Tickets cerrados",
    TICKETS_CLOSE                           : "Cerrar",
    TICKETS_ASSIGN_TITLE                    : "Asignar ticket",
    TICKETS_ASSIGN                          : "Asignar",
    TICKETS_MUTE_TITLE                      : "Silenciar ticket",
    TICKETS_MUTE                            : "Silenciar",
    TICKETS_UNMUTE_TITLE                    : "Notificar ticket",
    TICKETS_UNMUTE                          : "Notificar",
    TICKETS_ORGANIZATION                    : "Cliente / Partner",
    TICKETS_FOR_PARTNER                     : "Para un partner",
    TICKETS_ALL_USERS                       : "Todos los usuarios puede verlo",
    TICKETS_ALL_USERS_HELP                  : "Si no se activa solo pueden ver el ticket el usuario asignado y los administradores.",
    TICKETS_ASSIGNED                        : "Asignado a",
    TICKETS_LAST_REPLY                      : "Última respuesta",
    TICKETS_NONE_AVAILABLE                  : "No hay tickets disponibles.",
    TICKETS_NONE_MESSAGES                   : "No hay mensajes disponibles.",
    TICKETS_EDIT_MESSAGE                    : "Editar mensaje",
    TICKETS_DELETE_MESSAGE                  : "Eliminar mensaje",
    TICKETS_DELETE_MESSAGE_TEXT             : "¿Realmente desea eliminar el mensaje <b>{0}</b>?",
    TICKETS_DELETE_IMAGE                    : "Eliminar imagen",
    TICKETS_DELETE_IMAGE_TEXT               : "¿Realmente desea eliminar la imagen de <b>{0}</b>?",
    TICKETS_ERROR_EDIT                      : "No tiene permiso para editar el ticket.",
    TICKETS_ERROR_EXISTS                    : "No existe el ticket indicado.",
    TICKETS_ERROR_TYPE                      : "Debe indicar el tipo del ticket.",
    TICKETS_ERROR_SUBJECT                   : "Debe indicar el asunto del ticket.",
    TICKETS_ERROR_MESSAGE                   : "Debe indicar el mensaje del ticket.",
    TICKETS_ERROR_DELETE                    : "No tiene permiso para eliminar el ticket.",
    TICKETS_ERROR_USER_PERMISSION           : "No tiene permiso para indicar un usuario el ticket.",
    TICKETS_ERROR_USER_EMPTY                : "Debe indicar un usuario si no es para todos los usuarios.",
    TICKETS_ERROR_ASSIGN_PERMISSION         : "No tiene permiso para asignar el ticket.",
    TICKETS_ERROR_ASSIGN_EXISTS             : "No existe el usuario a quien desea asignar el ticket.",
    TICKETS_ERROR_OPEN                      : "No tiene permiso para abrir el ticket.",
    TICKETS_ERROR_WAIT                      : "No tiene permiso para mover el ticket a una lista de espera.",
    TICKETS_ERROR_CLOSE                     : "No tiene permiso para cerrar el ticket.",
    TICKETS_ERROR_MESSAGE_EXISTS            : "No existe el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_EDIT              : "No tiene permiso para editar el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_DELETE            : "No tiene permiso para eliminar el mensaje indicado.",
    TICKETS_ERROR_IMAGE_DELETE              : "El mensaje indicado no tiene una imagen.",
    TICKETS_SUCCESS_CREATE                  : "El ticket se creó correctamente.",
    TICKETS_SUCCESS_EDIT                    : "El ticket se editó correctamente.",
    TICKETS_SUCCESS_DELETE                  : "El ticket se eliminó correctamente.",
    TICKETS_SUCCESS_OPEN                    : "El ticket se abrió correctamente.",
    TICKETS_SUCCESS_WAIT                    : "El ticket se movió a la lista de espera correctamente.",
    TICKETS_SUCCESS_CLOSE                   : "El ticket se cerró correctamente.",
    TICKETS_SUCCESS_MUTE                    : "El ticket se silenció correctamente.",
    TICKETS_SUCCESS_UNMUTE                  : "El ticket se de-silenció correctamente.",
    TICKETS_SUCCESS_ADD_MESSAGE             : "El mensaje se agregó correctamente.",
    TICKETS_SUCCESS_EDIT_MESSAGE            : "El mensaje se editó correctamente.",
    TICKETS_SUCCESS_DELETE_MESSAGE          : "El mensaje se eliminó correctamente.",
    TICKETS_SUCCESS_DELETE_IMAGE            : "La imagen se eliminó correctamente.",

    // Support: Ticket Types
    TICKET_TYPES_NAME                       : "Tipos de ticket",
    TICKET_TYPES_SINGULAR                   : "Tipo de ticket",
    TICKET_TYPES_CREATE_TITLE               : "Crear tipo de ticket",
    TICKET_TYPES_EDIT_TITLE                 : "Editar tipo de ticket",
    TICKET_TYPES_DELETE_TITLE               : "Eliminar tipo de ticket",
    TICKET_TYPES_DELETE_TEXT                : "¿Realmente desea eliminar el tipo de ticket <b>{0}</b>?",
    TICKET_TYPES_CREDENTIAL                 : "Asignar automáticamente a",
    TICKET_TYPES_ASSIGNED                   : "Asignado a",
    TICKET_TYPES_NONE_AVAILABLE             : "No hay tipos de tickets disponibles.",
    TICKET_TYPES_ERROR_EXISTS               : "No existe el tipo de ticket indicado.",
    TICKET_TYPES_ERROR_NAME                 : "Debe indicar el nombre del tipo de ticket.",
    TICKET_TYPES_SUCCESS_CREATE             : "El tipo de ticket se creó correctamente.",
    TICKET_TYPES_SUCCESS_EDIT               : "El tipo de ticket se editó correctamente.",
    TICKET_TYPES_SUCCESS_DELETE             : "El tipo de ticket se eliminó correctamente.",

    // Support: Ticket Alert Types
    TICKET_ALERT_TYPES_NAME                 : "Tipos de alertas",
    TICKET_ALERT_TYPES_SINGULAR             : "Tipo de alerta",
    TICKET_ALERT_TYPES_CREATE_TITLE         : "Crear tipo de alerta",
    TICKET_ALERT_TYPES_EDIT_TITLE           : "Editar tipo de alerta",
    TICKET_ALERT_TYPES_DELETE_TITLE         : "Eliminar tipo de alerta",
    TICKET_ALERT_TYPES_DELETE_TEXT          : "¿Realmente desea eliminar el tipo de alerta <b>{0}</b>?",
    TICKET_ALERT_TYPES_ALERT_CODE           : "Código de alerta",
    TICKET_ALERT_TYPES_ALERT_CODE_TIP       : "En WhatsApp se genera un código según el tipo de alert y puede combinar varios datos.",
    TICKET_ALERT_TYPES_VARIABLES_TIP        : "Puede usar las variables: {channel}",
    TICKET_ALERT_TYPES_NONE_AVAILABLE       : "No hay tipos de alertas disponibles.",
    TICKET_ALERT_TYPES_ERROR_EXISTS         : "No existe el tipo de alerta indicada.",
    TICKET_ALERT_TYPES_ERROR_CODE_EMPTY     : "Debe indicar el código del tipo de alerta.",
    TICKET_ALERT_TYPES_ERROR_CODE_EXISTS    : "Ya existe otro tipo de alerta usando el código indicado.",
    TICKET_ALERT_TYPES_ERROR_TYPE           : "Debe indicar el tipo de ticket del tipo de alerta.",
    TICKET_ALERT_TYPES_ERROR_SUBJECT        : "Debe indicar el asunto del tipo de alerta.",
    TICKET_ALERT_TYPES_ERROR_MESSAGE        : "Debe indicar el mensaje del tipo de alerta.",
    TICKET_ALERT_TYPES_SUCCESS_CREATE       : "El tipo de alerta se creó correctamente.",
    TICKET_ALERT_TYPES_SUCCESS_EDIT         : "El tipo de alerta se editó correctamente.",
    TICKET_ALERT_TYPES_SUCCESS_DELETE       : "El tipo de alerta se eliminó correctamente.",

    // Support: Ticket Waitlists
    TICKET_WAITLISTS_NAME                   : "Listas de espera",
    TICKET_WAITLISTS_SINGULAR               : "Lista de espera",
    TICKET_WAITLISTS_CREATE_TITLE           : "Crear lista de espera",
    TICKET_WAITLISTS_EDIT_TITLE             : "Editar lista de espera",
    TICKET_WAITLISTS_DELETE_TITLE           : "Eliminar lista de espera",
    TICKET_WAITLISTS_DELETE_TEXT            : "¿Realmente desea eliminar la lista de espera <b>{0}</b>?",
    TICKET_WAITLISTS_NONE_AVAILABLE         : "No hay listas de espera disponibles.",
    TICKET_WAITLISTS_ERROR_EXISTS           : "No existe la lista de espera indicada.",
    TICKET_WAITLISTS_ERROR_NAME             : "Debe indicar el nombre de la lista de espera.",
    TICKET_WAITLISTS_SUCCESS_CREATE         : "La lista de espera se creó correctamente.",
    TICKET_WAITLISTS_SUCCESS_EDIT           : "La lista de espera se editó correctamente.",
    TICKET_WAITLISTS_SUCCESS_DELETE         : "La lista de espera se eliminó correctamente.",

    // Support: Document
    DOCUMENTS_NAME                          : "Documentación",
    DOCUMENTS_SINGULAR                      : "Documento",
    DOCUMENTS_CREATE_TITLE                  : "Crear documento",
    DOCUMENTS_EDIT_TITLE                    : "Editar documento",
    DOCUMENTS_PRINT_TITLE                   : "Imprimir documento",
    DOCUMENTS_DELETE_TITLE                  : "Eliminar documento",
    DOCUMENTS_DELETE_TEXT                   : "¿Realmente desea eliminar el documento <b>{0}</b>?",
    DOCUMENTS_WARNING_TITLE                 : "Cambios sin guardar",
    DOCUMENTS_WARNING_TEXT                  : "Hay cambios sin guardar en el documento. ¿Desea descartarlos o seguir editando y guardarlos?",
    DOCUMENTS_SELECT_ONE                    : "Selecciona un documento del listado para poder obtener la información que necesita.",
    DOCUMENTS_HELP                          : "Ayuda del módulo",
    DOCUMENTS_URL                           : "URL del módulo",
    DOCUMENTS_TYPE_MAIN                     : "Principal",
    DOCUMENTS_TYPE_PARTNER                  : "Partner",
    DOCUMENTS_TYPE_ADMINS                   : "Administrativo",
    DOCUMENTS_TYPE_RELEASES                 : "Releases",
    DOCUMENTS_NONE_AVAILABLE                : "No hay documentos disponibles.",
    DOCUMENTS_ERROR_EXISTS                  : "No existe el documento indicado.",
    DOCUMENTS_ERROR_TYPE                    : "No existe el tipo de documento indicado.",
    DOCUMENTS_ERROR_NAME_EMPTY              : "Debe indicar el nombre del documento.",
    DOCUMENTS_ERROR_NAME_REPEATED           : "No puede repetir los nombres de los documentos dentro del padre indicado.",
    DOCUMENTS_ERROR_URL                     : "La url no debe ser relativa y no incluir el dominio.",
    DOCUMENTS_ERROR_PARENT_EXISTS           : "No existe el padre indicado.",
    DOCUMENTS_ERROR_PARENT_SELF             : "No puede seleccionar el mismo documento como el padre.",
    DOCUMENTS_ERROR_PARENT_CYCLE            : "No puede crear ciclos con los documentos.",
    DOCUMENTS_SUCCESS_CREATE                : "El documento se creó correctamente.",
    DOCUMENTS_SUCCESS_EDIT                  : "El documento se editó correctamente.",
    DOCUMENTS_SUCCESS_DELETE                : "El documento se eliminó correctamente.",



    // Setup: Announcements
    ANNOUNCEMENTS_NAME                      : "Anuncios",
    ANNOUNCEMENTS_CREATE_TITLE              : "Crear anuncio",
    ANNOUNCEMENTS_EDIT_TITLE                : "Editar anuncio",
    ANNOUNCEMENTS_DELETE_TITLE              : "Eliminar anuncio",
    ANNOUNCEMENTS_DELETE_TEXT               : "¿Realmente desea eliminar el anuncio <b>{0}</b>?",
    ANNOUNCEMENTS_AUTO_CLOSE                : "Ocultar automáticamente (minutos)",
    ANNOUNCEMENTS_AUTO_CLOSE_TIP            : "Puede dejar en 0 para que nunca se oculte automáticamente.",
    ANNOUNCEMENTS_IS_ACTIVE                 : "Está activo",
    ANNOUNCEMENTS_RESET_DISCARDED           : "Mostrar a todos nuevamente",
    ANNOUNCEMENTS_NONE_AVAILABLE            : "No hay anuncios disponibles.",
    ANNOUNCEMENTS_ERROR_EDIT                : "No puede editar el anuncio indicado.",
    ANNOUNCEMENTS_ERROR_EXISTS              : "No existe el anuncio indicado.",
    ANNOUNCEMENTS_ERROR_MESSAGE             : "Debe indicar el mensaje del anuncio.",
    ANNOUNCEMENTS_ERROR_AUTO_CLOSE          : "El tiempo de ocultar debe ser un número mayor o igual a 0.",
    ANNOUNCEMENTS_SUCCESS_CREATE            : "El anuncio se creó correctamente.",
    ANNOUNCEMENTS_SUCCESS_EDIT              : "El anuncio se editó correctamente.",
    ANNOUNCEMENTS_SUCCESS_DELETE            : "El anuncio se eliminó correctamente.",

    // Setup: Plans
    PLANS_NAME                              : "Planes",
    PLANS_SINGULAR                          : "Plan",
    PLANS_CREATE_TITLE                      : "Crear plan",
    PLANS_EDIT_TITLE                        : "Editar plan",
    PLANS_DELETE_TITLE                      : "Eliminar plan",
    PLANS_DELETE_TEXT                       : "¿Realmente desea eliminar el plan <b>{0}</b>?",
    PLANS_ROOM_AMOUNT                       : "Cantidad de habitaciones",
    PLANS_CONTACT_AMOUNT                    : "Cantidad de contactos",
    PLANS_PRICE                             : "Precio del plan",
    PLANS_ROOM_PRICE                        : "Precio por habitación",
    PLANS_ROOM_PRICE_SHORT                  : "Por habitación",
    PLANS_ROOM_PRICE_TOTAL                  : "Precio total por habitaciones",
    PLANS_ROOM_PRICE_TEXT                   : "{0} / hab.",
    PLANS_CONTACT_PRICE                     : "Precio por contacto adicional",
    PLANS_CONTACT_PRICE_SHORT               : "Adicional",
    PLANS_CONTACT_PRICE_LONG                : "Contactos adicionales",
    PLANS_CONTACT_PRICE_TEXT                : "{0} ({1} / ad.)",
    PLANS_SETUP_PRICE                       : "Precio de setup",
    PLANS_NONE_AVAILABLE                    : "No hay planes disponibles.",
    PLANS_ERROR_EXISTS                      : "No existe el plan indicado.",
    PLANS_ERROR_TYPE_EMPTY                  : "Debe indicar el tipo de plan.",
    PLANS_ERROR_TYPE_INVALID                : "El tipo de plan indicado es incorrecto.",
    PLANS_ERROR_NAME                        : "Debe indicar el nombre del plan.",
    PLANS_ERROR_ROOM_AMOUNT                 : "La cantidad de habitaciones debe ser un número mayor a 0.",
    PLANS_ERROR_CONTACT_AMOUNT_EMPTY        : "Debe indicar la cantidad de contactos.",
    PLANS_ERROR_CONTACT_AMOUNT_INVALID      : "La cantidad de contactos debe ser un número mayor a 0.",
    PLANS_ERROR_ROOM_PRICE                  : "El precio por habitación es incorrecto.",
    PLANS_ERROR_CONTACT_PRICE               : "El precio por contacto adicional es incorrecto.",
    PLANS_ERROR_SETUP_PRICE                 : "El precio de setup es incorrecto.",
    PLANS_ERROR_DELETE_LAST                 : "No puede eliminar el último plan.",
    PLANS_SUCCESS_CREATE                    : "El plan se creó correctamente.",
    PLANS_SUCCESS_EDIT                      : "El plan se editó correctamente.",
    PLANS_SUCCESS_DELETE                    : "El plan se eliminó correctamente.",

    // Setup: Addons
    ADDONS_NAME                             : "Addons",
    ADDONS_SINGULAR                         : "Addon",
    ADDONS_EDIT_TITLE                       : "Editar addon",
    ADDONS_QUANTITY                         : "Por cantidad",
    ADDONS_NONE_AVAILABLE                   : "No hay addons disponibles.",
    ADDONS_ERROR_EXISTS                     : "No existe el addon indicado.",
    ADDONS_ERROR_NAME                       : "Debe indicar el nombre del addon.",
    ADDONS_SUCCESS_CREATE                   : "El addon se creó correctamente.",
    ADDONS_SUCCESS_EDIT                     : "El addon se editó correctamente.",
    ADDONS_SUCCESS_DELETE                   : "El addon se eliminó correctamente.",

    // Setup: Admins
    ADMINS_NAME                             : "Admins",
    ADMINS_CREATE_TITLE                     : "Crear admin",
    ADMINS_EDIT_TITLE                       : "Editar admin",
    ADMINS_DELETE_TITLE                     : "Eliminar admin",
    ADMINS_DELETE_TEXT                      : "¿Realmente desea eliminar el admin <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE                   : "No hay admins disponibles.",
    ADMINS_ERROR_EDIT                       : "No puede editar el admin indicado.",
    ADMINS_ERROR_EXISTS                     : "No existe el admin indicado.",
    ADMINS_ERROR_DELETE                     : "No puede eliminar el admin indicado.",
    ADMINS_SUCCESS_CREATE                   : "El admin se creó correctamente.",
    ADMINS_SUCCESS_EDIT                     : "El admin se editó correctamente.",
    ADMINS_SUCCESS_DELETE                   : "El admin se eliminó correctamente.",

    // Setup: Languages
    LANGUAGES_NAME                          : "Idiomas",
    LANGUAGES_SINGULAR                      : "Idioma",
    LANGUAGES_CREATE_TITLE                  : "Crear idioma",
    LANGUAGES_EDIT_TITLE                    : "Editar idioma",
    LANGUAGES_DELETE_TITLE                  : "Eliminar idioma",
    LANGUAGES_DELETE_TEXT                   : "¿Realmente desea eliminar el idioma <b>{0}</b>?",
    LANGUAGES_ENGLISH_NAME                  : "Nombre en inglés",
    LANGUAGES_ENGLISH_NAME_TIP              : "Usado en la transcripción de los audios.",
    LANGUAGES_WORDS                         : "Palabras o frases",
    LANGUAGES_CREATE_BY_DEFAULT             : "Crear idioma al crear un cliente",
    LANGUAGES_CREATE_BY_DEFAULT_SHORT       : "Crear con cliente",
    LANGUAGES_NONE_AVAILABLE                : "No hay idiomas disponibles.",
    LANGUAGES_ERROR_EDIT                    : "No tiene permiso para editar el idioma indicado.",
    LANGUAGES_ERROR_EXISTS                  : "No existe el idioma indicado.",
    LANGUAGES_ERROR_NAME_EMPTY              : "Debe indicar el nombre del idioma.",
    LANGUAGES_ERROR_NAME_EXISTS             : "Ya existe otro idioma con el nombre indicado.",
    LANGUAGES_ERROR_ENGLISH_NAME_EMPTY      : "Debe indicar el nombre en inglés del idioma.",
    LANGUAGES_ERROR_ENGLISH_NAME_EXISTS     : "Ya existe otro idioma con el nombre en inglés indicado.",
    LANGUAGES_ERROR_ISO_CODE_EMPTY          : "Debe indicar el código ISO del idioma.",
    LANGUAGES_ERROR_ISO_CODE_EXISTS         : "Ya existe otro idioma con el código ISO indicado.",
    LANGUAGES_ERROR_DELETE                  : "No tiene permiso para eliminar el idioma indicado.",
    LANGUAGES_ERROR_LAST                    : "No se puede eliminar el último idioma.",
    LANGUAGES_ERROR_FLOW                    : "No puede eliminar el idioma al estar usado en un flujo.",
    LANGUAGES_ERROR_AUTOMATION              : "No puede eliminar el idioma al estar usado en una automatización.",
    LANGUAGES_SUCCESS_CREATE                : "El idioma se creó correctamente.",
    LANGUAGES_SUCCESS_EDIT                  : "El idioma se editó correctamente.",
    LANGUAGES_SUCCESS_DELETE                : "El idioma se eliminó correctamente.",

    // Setup: Countries
    COUNTRIES_NAME                          : "Países",
    COUNTRIES_SINGULAR                      : "País",
    COUNTRIES_EDIT_TITLE                    : "Editar país",
    COUNTRIES_IDD                           : "Característica para celulares",
    COUNTRIES_IDD_SHORT                     : "Característica",
    COUNTRIES_NONE_AVAILABLE                : "No hay países disponibles.",
    COUNTRIES_ERROR_EXISTS                  : "No existe el país indicado.",
    COUNTRIES_ERROR_NAME                    : "Debe indicar el nombre del país.",
    COUNTRIES_ERROR_ISO_CODE_EMPTY          : "Debe indicar el código ISO del país.",
    COUNTRIES_ERROR_ISO_CODE_EXISTS         : "Ya existe otro país con el código ISO indicado.",
    COUNTRIES_ERROR_IDD_EMPTY               : "Debe indicar la característica del país.",
    COUNTRIES_ERROR_IDD_NUMBER              : "La característica debe ser un número mayor a 0.",
    COUNTRIES_ERROR_IDD_EXISTS              : "Ya existe otro país con la característica indicada.",
    COUNTRIES_SUCCESS_EDIT                  : "El país se editó correctamente.",

    // Setup: Provinces
    PROVINCES_NAME                          : "Provincias",
    PROVINCES_SINGULAR                      : "Provincia",
    PROVINCES_EDIT_TITLE                    : "Editar provincia",
    PROVINCES_NONE_AVAILABLE                : "No hay provincias disponibles.",
    PROVINCES_ERROR_EXISTS                  : "No existe la provincia indicada.",
    PROVINCES_ERROR_COUNTRY                 : "Debe indicar el país de la provincia.",
    PROVINCES_ERROR_NAME                    : "Debe indicar el nombre de la provincia.",
    PROVINCES_ERROR_ISO_CODE_EMPTY          : "Debe indicar el código ISO de la provincia.",
    PROVINCES_ERROR_ISO_CODE_EXISTS         : "Ya existe otra provincia con el código ISO indicado.",
    PROVINCES_SUCCESS_EDIT                  : "La provincia se editó correctamente.",

    // Setup: Media
    MEDIA_NAME                              : "Archivos",
    MEDIA_SINGULAR                          : "Archivo",
    MEDIA_SELECT_TITLE                      : "Seleccionar archivo",
    MEDIA_CREATE_TITLE                      : "Crear directorio",
    MEDIA_EDIT_DIR_TITLE                    : "Renombrar directorio",
    MEDIA_EDIT_FILE_TITLE                   : "Renombrar archivo",
    MEDIA_DELETE_DIR_TITLE                  : "Eliminar directorio",
    MEDIA_DELETE_FILE_TITLE                 : "Eliminar archivo",
    MEDIA_DELETE_DIR_TEXT                   : "¿Realmente desea eliminar el directorio <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT                  : "¿Realmente desea eliminar el archivo <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE                    : "No hay archivos disponibles.",
    MEDIA_ERROR_CREATE                      : "No fue posible crear el directorio indicado.",
    MEDIA_ERROR_CREATE_NAME                 : "Debe indicar el nombre del directorio.",
    MEDIA_ERROR_CREATE_EXISTS               : "Ya existe un archivo/directorio con ese nombre.",
    MEDIA_ERROR_UPLOAD                      : "Ocurrió un error al subir el archivo.",
    MEDIA_ERROR_UPLOAD_EMPTY                : "Debe indicar un archivo para subir.",
    MEDIA_ERROR_UPLOAD_SIZE                 : "El archivo pesa más de 3mb.",
    MEDIA_ERROR_UPLOAD_SIZES                : "Hay archivos que pesan más de 3mb.",
    MEDIA_ERROR_UPLOAD_EXISTS               : "Ya existe un archivo con ese nombre.",
    MEDIA_ERROR_RENAME                      : "Ocurrió un error al renombrar el archivo/directorio indicado.",
    MEDIA_ERROR_RENAME_OLD_NAME             : "Debe indicar el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_NAME             : "Debe indicar el nuevo nombre del archivo.",
    MEDIA_ERROR_RENAME_OLD_EXISTS           : "No existe el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_EXISTS           : "Ya existe un archivo/directorio con el nuevo nombre.",
    MEDIA_ERROR_MOVE                        : "Ocurrió un error al mover el archivo indicado.",
    MEDIA_ERROR_MOVE_OLD_PATH               : "Debe indicar el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_PATH               : "Debe indicar el nuevo directorio del archivo.",
    MEDIA_ERROR_MOVE_OLD_EXISTS             : "No existe el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_EXISTS             : "Ya existe un archivo con el mismo nombre en el directorio indicado.",
    MEDIA_ERROR_DELETE                      : "Ocurrió un error al eliminar el archivo/directorio indicado.",
    MEDIA_ERROR_DELETE_NAME                 : "Debe indicar el archivo/directorio para eliminar.",
    MEDIA_ERROR_DELETE_EXISTS               : "No existe el archivo/directorio para eliminar.",
    MEDIA_SUCCESS_CREATE                    : "El directorio se creó correctamente.",
    MEDIA_SUCCESS_UPLOAD                    : "El archivo se subió correctamente.",
    MEDIA_SUCCESS_RENAME                    : "El archivo/directorio se renombró correctamente.",
    MEDIA_SUCCESS_MOVE                      : "El archivo se movió correctamente.",
    MEDIA_SUCCESS_DELETE                    : "El archivo/directorio se eliminó correctamente.",



    // Email: Templates
    EMAIL_TEMPLATES_NAME                    : "Plantillas de emails",
    EMAIL_TEMPLATES_VIEW_TITLE              : "Ver plantilla de email",
    EMAIL_TEMPLATES_NONE_AVAILABLE          : "No hay plantillas de emails disponibles.",

    // Email: Queue
    EMAIL_QUEUE_NAME                        : "Cola de emails",
    EMAIL_QUEUE_VIEW_TITLE                  : "Ver email",
    EMAIL_QUEUE_RESEND_TITLE                : "Reenviar email",
    EMAIL_QUEUE_RESEND_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> email para envío?",
    EMAIL_QUEUE_RESEND_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> emails para envío?",
    EMAIL_QUEUE_NONE_AVAILABLE              : "No hay emails disponibles en la cola de emails.",
    EMAIL_QUEUE_ERROR_RESEND                : "Debe indicar al menos un email a para reenviar.",
    EMAIL_QUEUE_SUCCESS_RESEND              : "El email se marcó para envió correctamente.",
    EMAIL_QUEUE_SUCCESS_MULTI_RESEND        : "Los emails se marcaron para envío correctamente.",

    // Email: White List
    EMAIL_WHITE_LIST_NAME                   : "Lista blanca de emails",
    EMAIL_WHITE_LIST_CREATE_TITLE           : "Agregar email",
    EMAIL_WHITE_LIST_EDIT_TITLE             : "Editar email",
    EMAIL_WHITE_LIST_DELETE_TITLE           : "Quitar email",
    EMAIL_WHITE_LIST_DELETE_TEXT            : "¿Realmente desea quitar el email <b>{0}</b>?",
    EMAIL_WHITE_LIST_EMAIL                  : "Email disponible para envíos",
    EMAIL_WHITE_LIST_DESCRIPTION            : "Descripción sobre el email",
    EMAIL_WHITE_LIST_NONE_AVAILABLE         : "No hay emails disponibles.",
    EMAIL_WHITE_LIST_ERROR_EXISTS           : "No existe la plantilla de email indicada.",
    EMAIL_WHITE_LIST_ERROR_EMPTY            : "Debe indicar el email a agregar.",
    EMAIL_WHITE_LIST_ERROR_INVALID          : "El email indicado es incorrecto.",
    EMAIL_WHITE_LIST_ERROR_USED             : "El email indicado ya esta agregado.",
    EMAIL_WHITE_LIST_SUCCESS_ADD            : "El email se agregó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_EDIT           : "El email se editó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_REMOVE         : "El email se quitó correctamente.",



    // Log: Notifications
    NOTIFICATIONS_NAME                      : "Notificaciones",
    NOTIFICATIONS_SINGULAR                  : "Notificación",
    NOTIFICATIONS_VIEW_TITLE                : "Ver notificación",
    NOTIFICATIONS_EXTERNAL_ID               : "ID del proveedor",
    NOTIFICATIONS_NONE_AVAILABLE            : "No hay notificaciones disponibles.",

    // Log: Providers
    PROVIDERS_LOG_NAME                      : "Log de proveedores",
    PROVIDERS_LOG_VIEW_TITLE                : "Ver log de proveedor",
    PROVIDERS_LOG_DELETE_TITLE              : "Eliminar log de proveedor",
    PROVIDERS_LOG_DELETE_TEXT_SINGULAR      : "¿Realmente desea eliminar <b>{0}</b> log de proveedores?",
    PROVIDERS_LOG_DELETE_TEXT_PLURAL        : "¿Realmente desea eliminar <b>{0}</b> logs de proveedores?",
    PROVIDERS_LOG_MODEL                     : "Canal / Hotel",
    PROVIDERS_LOG_REQUEST                   : "Request",
    PROVIDERS_LOG_NONE_AVAILABLE            : "No hay logs de proveedores disponibles.",

    // Log: Applications
    APPLICATIONS_LOG_NAME                   : "Log de aplicaciones",
    APPLICATIONS_LOG_VIEW_TITLE             : "Ver log de aplicación",
    APPLICATIONS_LOG_MODEL                  : "Flujo / Integración",
    APPLICATIONS_LOG_METHOD                 : "Método",
    APPLICATIONS_LOG_USER_PASSWORD          : "Usuario / Contraseña",
    APPLICATIONS_LOG_HEADERS                : "Encabezados",
    APPLICATIONS_LOG_PARAMS                 : "Parámetros",
    APPLICATIONS_LOG_NONE_AVAILABLE         : "No hay logs de aplicaciones disponibles.",

    // Log: Actions
    ACTIONS_LOG_NAME                        : "Log de acciones",
    ACTIONS_LOG_SESSION                     : "Sesión",
    ACTIONS_LOG_NONE_AVAILABLE              : "No hay acciones disponibles.",

    // Log: Queries
    QUERIES_LOG_NAME                        : "Log de queries",
    QUERIES_LOG_VIEW_TITLE                  : "Ver query",
    QUERIES_LOG_RESOLVE_TITLE               : "Marcar como resuelta",
    QUERIES_LOG_RESOLVE_TEXT_SINGULAR       : "¿Realmente desea marcar <b>{0}</b> query como resuelta?",
    QUERIES_LOG_RESOLVE_TEXT_PLURAL         : "¿Realmente desea marcar <b>{0}</b> queries como resueltas?",
    QUERIES_LOG_DELETE_TITLE                : "Eliminar query",
    QUERIES_LOG_DELETE_TEXT_SINGULAR        : "¿Realmente desea eliminar <b>{0}</b> query?",
    QUERIES_LOG_DELETE_TEXT_PLURAL          : "¿Realmente desea eliminar <b>{0}</b> queries?",
    QUERIES_LOG_EXPRESSION                  : "Expresión",
    QUERIES_LOG_RESOLVED                    : "Resuelta",
    QUERIES_LOG_NONE_AVAILABLE              : "No hay errores disponibles.",
    QUERIES_LOG_ERROR_RESOLVE               : "Debe indicar al menos una query a para resolver.",
    QUERIES_LOG_ERROR_DELETE                : "Debe indicar al menos una query a eliminar.",
    QUERIES_LOG_SUCCESS_RESOLVED            : "La query se marcó como resuelta correctamente.",
    QUERIES_LOG_SUCCESS_MULTI_RESOLVED      : "Las queries se marcaron como resueltas correctamente.",
    QUERIES_LOG_SUCCESS_DELETE              : "La query se eliminó correctamente.",
    QUERIES_LOG_SUCCESS_MULTI_DELETE        : "Las queries se eliminaron correctamente.",

    // Log: Errors
    ERRORS_LOG_NAME                         : "Log de errores",
    ERRORS_LOG_VIEW_TITLE                   : "Ver error",
    ERRORS_LOG_RESOLVE_TITLE                : "Marcar como resuelto",
    ERRORS_LOG_RESOLVE_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> error como resuelto?",
    ERRORS_LOG_RESOLVE_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> errores como resueltos?",
    ERRORS_LOG_DELETE_TITLE                 : "Eliminar error",
    ERRORS_LOG_DELETE_TEXT_SINGULAR         : "¿Realmente desea eliminar <b>{0}</b> error?",
    ERRORS_LOG_DELETE_TEXT_PLURAL           : "¿Realmente desea eliminar <b>{0}</b> errores?",
    ERRORS_LOG_BACKTRACE                    : "Backtrace",
    ERRORS_LOG_RESOLVED                     : "Resuelto",
    ERRORS_LOG_NONE_AVAILABLE               : "No hay errores disponibles.",
    ERRORS_LOG_ERROR_RESOLVE                : "Debe indicar al menos un error a para resolver.",
    ERRORS_LOG_ERROR_DELETE                 : "Debe indicar al menos un error a eliminar.",
    ERRORS_LOG_SUCCESS_RESOLVED             : "El error se marcó como resuelto correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_RESOLVED       : "Los errores se marcaron como resueltos correctamente.",
    ERRORS_LOG_SUCCESS_DELETE               : "El error se eliminó correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_DELETE         : "Los errores se eliminaron correctamente.",



    // DropZone
    DROPZONE_FILES_TITLE                    : "Arrastra archivos a cualquier lugar para subirlos",
    DROPZONE_IMAGES_TITLE                   : "Arrastra imágenes a cualquier lugar para subirlas",
    DROPZONE_OR                             : "ó",
    DROPZONE_FILES_BUTTON                   : "Seleccionar archivos",
    DROPZONE_IMAGES_BUTTON                  : "Seleccionar imágenes",
    DROPZONE_FILES_DROP                     : "Arrastra los archivos aquí para subirlos",
    DROPZONE_IMAGES_DROP                    : "Arrastra las imágenes aquí para subirlas",

    // Emojis
    EMOJI_NAME                              : "Emoji",
    EMOJI_SUGGESTED                         : "Recientemente usados",
    EMOJI_SMILEYS                           : "Emoticonos & Personas",
    EMOJI_ANIMALS                           : "Animales & Naturaleza",
    EMOJI_FOOD                              : "Comidas & Bebidas",
    EMOJI_TRAVEL                            : "Viaje & Lugares",
    EMOJI_ACTIVITIES                        : "Actividades",
    EMOJI_OBJECTS                           : "Objetos",
    EMOJI_SYMBOLS                           : "Símbolos",
    EMOJI_FLAGS                             : "Banderas",

    // Periods
    PERIOD_NAME                             : "Periodo",
    PERIOD_SELECT                           : "Seleccionar periodo",
    PERIOD_TODAY                            : "Hoy",
    PERIOD_YESTERDAY                        : "Ayer",
    PERIOD_LAST_7_DAYS                      : "Últimos 7 días",
    PERIOD_LAST_15_DAYS                     : "Últimos 15 días",
    PERIOD_LAST_30_DAYS                     : "Últimos 30 días",
    PERIOD_LAST_60_DAYS                     : "Últimos 60 días",
    PERIOD_LAST_90_DAYS                     : "Últimos 90 días",
    PERIOD_LAST_120_DAYS                    : "Últimos 120 días",
    PERIOD_LAST_YEAR                        : "Último año",
    PERIOD_THIS_WEEK                        : "Esta semana",
    PERIOD_THIS_MONTH                       : "Este mes",
    PERIOD_THIS_YEAR                        : "Este año",
    PERIOD_PAST_WEEK                        : "La semana pasada",
    PERIOD_PAST_MONTH                       : "El mes pasado",
    PERIOD_PAST_YEAR                        : "El año pasado",
    PERIOD_ALL_PERIOD                       : "Todo el periodo",
    PERIOD_CUSTOM                           : "Personalizado",

    // Replaceable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                          : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_DAY_SHORTS                         : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    DATE_DAY_POSITIONS                      : [ "Primer", "Segundo", "Tercer", "Cuarto" ],
    DATE_MONTH_NAMES                        : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                               : "{h}:{i}",
    DATE_DAY_TIME                           : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                          : "{d} de {mn}",
    DATE_DAY_MONTH_SHORT                    : "{d}, {m3}",
    DATE_DAY_MONTH_MEDIUM                   : "{d} de {m3}",
    DATE_DAY_MONTH_YEAR                     : "{d} de {mn}, {y}",
    DATE_DAY_YEAR_SHORT                     : "{d}, {m3} {y}",
    DATE_DAY_YEAR_MEDIUM                    : "{d} de {m3} {y}",
    DATE_MONTH_YEAR                         : "{mn} {y}",
    DATE_REDUCED                            : "{dn} {d0}/{m0}",
    DATE_COMPLETE                           : "{dn}, {d} de {mn}, {y}",
    DATE_COMPLETE_SHORT                     : "{d3}, {d} de {m3}, {y}",
    DATE_COMPLETE_MONTH                     : "{dn}, {d} de {mn}",
    DATE_DASHES                             : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE                     : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                        : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_DASHES_HOUR                        : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_DASHES_SECONDS                     : "{d0}-{m0}-{y}, {h}:{i}:{s}",
    DATE_SLASHES                            : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                        : "{d0}/{m0}",
    DATE_SORTABLE                           : "{y}/{m0}/{d0}",

    DATE_NOW                                : "Ahora",
    DATE_MIN_AGO                            : "{i} minuto atrás",
    DATE_MINS_AGO                           : "{i} minutos atrás",
    DATE_HOUR_AGO                           : "{h} hora atrás",
    DATE_HOURS_AGO                          : "{h} horas atrás",
    DATE_DAY_AGO                            : "{d} día atrás",
    DATE_DAYS_AGO                           : "{d} días atrás",
    DATE_IN_MIN                             : "En {i} minuto",
    DATE_IN_MINS                            : "En {i} minutos",
    DATE_IN_HOUR                            : "En {h} hora",
    DATE_IN_HOURS                           : "En {h} horas",
    DATE_IN_DAY                             : "En {d} día",
    DATE_IN_DAYS                            : "En {d} días",
    DATE_TOMORROW_AT                        : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                           : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT                       : "Ayer a las {h}:{i}",
    DATE_THIS_WEEK_AT                       : "{dn} a las {h}:{i}",
    DATE_THIS_YEAR_AT                       : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR_AT                      : "{d} de {m3} {y} a las {h}:{i}",

    DATE_YESTERDAY                          : "Ayer",
    DATE_TODAY                              : "Hoy",
    DATE_TOMORROW                           : "Mañana",
    DATE_LAST_WEEK                          : "La Semana Pasada",
    DATE_THIS_WEEK                          : "Esta Semana",
    DATE_NEXT_WEEK                          : "La Semana Próxima",
    DATE_PARSED_WEEK                        : "Semana del {0} al {1}",
    DATE_DURATION                           : "{0} - {1}",

    DATE_TIME_ZONES                         : [
        { key : "-12",   value : "(GMT -12:00) Eniwetok, Kwajalein" },
        { key : "-11",   value : "(GMT -11:00) Midway Island, Samoa" },
        { key : "-10",   value : "(GMT -10:00) Hawaii" },
        { key : "-9.5",  value : "(GMT -9:30) Taiohae" },
        { key : "-9",    value : "(GMT -9:00) Alaska" },
        { key : "-8",    value : "(GMT -8:00) Pacific Time (US & Canada)" },
        { key : "-7",    value : "(GMT -7:00) Mountain Time (US & Canada)" },
        { key : "-6",    value : "(GMT -6:00) Central Time (US & Canada), Mexico" },
        { key : "-5",    value : "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima" },
        { key : "-4.5",  value : "(GMT -4:30) Caracas" },
        { key : "-4",    value : "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
        { key : "-3.5",  value : "(GMT -3:30) Newfoundland" },
        { key : "-3",    value : "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
        { key : "-2",    value : "(GMT -2:00) Mid-Atlantic" },
        { key : "-1",    value : "(GMT -1:00) Azores, Cape Verde Islands" },
        { key : "0",     value : "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
        { key : "1",     value : "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris" },
        { key : "2",     value : "(GMT +2:00) Kaliningrad, South Africa" },
        { key : "3",     value : "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
        { key : "3.5",   value : "(GMT +3:30) Tehran" },
        { key : "4",     value : "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
        { key : "4.5",   value : "(GMT +4:30) Kabul" },
        { key : "5",     value : "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
        { key : "5.5",   value : "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
        { key : "5.75",  value : "(GMT +5:45) Kathmandu, Pokhara" },
        { key : "6",     value : "(GMT +6:00) Almaty, Dhaka, Colombo" },
        { key : "6.5",   value : "(GMT +6:30) Yangon, Mandalay" },
        { key : "7",     value : "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
        { key : "8",     value : "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
        { key : "8.75",  value : "(GMT +8:45) Eucla" },
        { key : "9",     value : "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
        { key : "9.50",  value : "(GMT +9:30) Adelaide, Darwin" },
        { key : "10",    value : "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
        { key : "10.5",  value : "(GMT +10:30) Lord Howe Island" },
        { key : "11",    value : "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
        { key : "11.5",  value : "(GMT +11:30) Norfolk Island" },
        { key : "12",    value : "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
        { key : "12.75", value : "(GMT +12:45) Chatham Islands" },
        { key : "13",    value : "(GMT +13:00) Apia, Nukualofa" },
        { key : "14",    value : "(GMT +14:00) Line Islands, Tokelau" },
    ],

};

export default strings;
