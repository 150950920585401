import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import FlowSimChat          from "./FlowSimChat";
import FlowSimSend          from "./FlowSimSend";
import FlowSimEnd           from "./FlowSimEnd";
import FlowSimRestart       from "./FlowSimRestart";

// Dashboard
import DetailList            from "Dashboard/Components/Details/DetailList";
import Button                from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(DetailList)`
    --simulation-extras: calc(var(--details-spacing) * 2 + var(--tabs-dialog));

    .details-content {
        display: flex;
        flex-direction: column;
        height: calc(var(--details-height) - var(--simulation-extras));
        padding: 0;
        padding-top: var(--details-spacing);
    }

    @media (max-width: 1200px) {
        .details-content {
            height: calc(100dvh - var(--simulation-extras));
        }
    }
`;

const Footer = Styled.footer`
    display: flex;
    padding: 0 1px 8px 1px;
    gap: 8px;

    .btn {
        flex-grow: 2;
    }
`;



/**
 * The Flow Sim Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimContainer(props) {
    const { open, onEnd } = props;

    const { closeDetails } = Store.useAction("core");

    const { simulationID, currentNodeID, lastUpdate } = Store.useState("flowSimulation");
    const { fetchSimulation, fetchMessages, sendMessage } = Store.useAction("flowSimulation");


    // The References
    const timerRef = React.useRef(null);

    // The Current State
    const [ submits, setSubmits ] = React.useState(0);
    const [ dialog,  setDialog  ] = React.useState("");
    const [ update,  setUpdate  ] = React.useState(0);


    // Add/Remove the Auto-update
    React.useEffect(() => {
        if (open) {
            setAutoUpdate();
        } else {
            Utils.clearTimeout(timerRef);
        }
        return () => {
            if (open) {
                Utils.clearTimeout(timerRef);
            }
        };
    }, [ open ]);

    // Load the Data
    React.useEffect(() => {
        if (simulationID) {
            fetchSimulation(simulationID);
        }
    }, [ simulationID ]);

    // Handle the Update
    React.useEffect(() => {
        if (update && simulationID && lastUpdate) {
            fetchMessages(simulationID, lastUpdate);
            setAutoUpdate();
        }
    }, [ update ]);

    // Handle the Follow
    React.useEffect(() => {
        if (currentNodeID) {
            Commons.scrollToNode(currentNodeID, "instant");
        }
    }, [ currentNodeID ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };

    // Handles the Submit
    const handleSubmit = ({ message, file, latitude = 0, longitude = 0 }) => {
        setSubmits(submits + 1);
        sendMessage({
            simulationID, lastUpdate,
            message, file,
            latitude, longitude,
        });
        window.setTimeout(() => {
            setUpdate(update + 1);
        }, 1000);
    };

    // Handles the Option
    const handleOption = (payload, message, replyToID) => {
        setSubmits(submits + 1);
        sendMessage({
            simulationID, lastUpdate,
            message, payload, replyToID,
        });
    };

    // Handles the Simulation End
    const handleEnd = () => {
        setDialog("");
        closeDetails();
        onEnd();
        Utils.clearTimeout(timerRef);
    };



    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    return <>
        <Container
            hasInternalTabs
        >
            <FlowSimChat
                submits={submits}
                onOption={handleOption}
            />
            <FlowSimSend
                onSubmit={handleSubmit}
            />

            <Footer>
                <Button
                    variant="outlined"
                    message="FLOWS_SIMULATION_RESTART"
                    icon="restart"
                    onClick={() => setDialog("restart")}
                    isSmall
                />
                <Button
                    variant="outlined"
                    message="FLOWS_SIMULATION_END"
                    icon="delete"
                    onClick={() => setDialog("end")}
                    isSmall
                />
            </Footer>
        </Container>

        <FlowSimRestart
            open={dialog === "restart"}
            onSubmit={() => setDialog("")}
            onClose={() => setDialog("")}
        />
        <FlowSimEnd
            open={dialog === "end"}
            onSubmit={handleEnd}
            onClose={() => setDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimContainer.propTypes = {
    open  : PropTypes.bool.isRequired,
    onEnd : PropTypes.func.isRequired,
};

export default FlowSimContainer;
