import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import EmailDesignEdit      from "./Dialogs/EmailDesignEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Email Design List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignList(props) {
    const { type } = props;
    const { fetch, load } = useList("emailDesign", type);

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const data = Store.useState("emailDesign");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteEmailDesign } = Store.useAction("emailDesign");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Goes to the Email Design
    const gotoEmailDesign = (elemID) => {
        navigate("SETTINGS", "EMAIL_DESIGNS", elemID);
    };

    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isView) {
            gotoEmailDesign(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = (response) => {
        if ((action.isCreate || action.isCopy) && response.emailDesignID) {
            gotoEmailDesign(response.emailDesignID);
        } else {
            fetch();
        }
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteEmailDesign(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "emailDesignID", elemID, "name");

    return <Main>
        <Header message="EMAIL_DESIGNS_NAME" icon="email">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="EMAIL_DESIGNS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"         message="GENERAL_NAME"         isTitle bigMobile />
                    <TableHeader field="status"       message="GENERAL_STATUS"       maxWidth="70"  />
                    <TableHeader field="modifiedTime" message="GENERAL_MODIFIED_FEM" minWidth="160" maxWidth="160" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.emailDesignID} elemID={elem.emailDesignID}>
                        <TableCell message={elem.name}             />
                        <TableCell message={elem.statusName}       textColor={elem.statusColor} />
                        <TableCell message={elem.modifiedDateTime} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="EMAIL_DESIGNS_VIEW_TITLE"   navigate />
                    <TableAction action="EDIT"   message="EMAIL_DESIGNS_EDIT_TITLE"   />
                    <TableAction action="COPY"   message="EMAIL_DESIGNS_COPY_TITLE"   />
                    <TableAction action="DELETE" message="EMAIL_DESIGNS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <EmailDesignEdit
            open={action.isCCE}
            isCopy={action.isCopy}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="EMAIL_DESIGNS_DELETE_TITLE"
            message="EMAIL_DESIGNS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default EmailDesignList;
