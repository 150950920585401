import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import EmailDesignElemIcon  from "./EmailDesignElemIcon";

// Dashboard
import Menu                 from "Dashboard/Components/Menu/Menu";



// Styles
const Container = Styled(Menu).attrs(({ columns }) => ({ columns }))`
    box-sizing: border-box;
    max-width: calc(100vw - 44px);
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    grid-gap: 12px 6px;
    padding: 16px 8px;

    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
    }
`;



/**
 * The Email Design Add Element
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignAddElem(props) {
    const { addElementRef } = props;

    const { elementTypes } = Store.useState("emailDesignEditor");
    const { action } = Store.useState("emailDesignState");

    const { setAction, setElementCreating, setSelectedElement } = Store.useAction("emailDesignState");


    // Handles the Create
    const handleCreate = (e, element) => {
        const bounds = e.target.closest(".elem-icon").getBoundingClientRect();

        setSelectedElement(0);
        setElementCreating({
            elementType : element.key,
            icon        : element.icon,
            message     : element.message,
            isLayout    : element.isLayout,
            startX      : e.clientX,
            startY      : e.clientY,
            diffX       : e.clientX - bounds.left,
            diffY       : e.clientY - bounds.top,
        });
        setAction();
    };


    // Generate the Elements List
    const list = React.useMemo(() => {
        return Object.values(elementTypes).filter(({ isAllowed }) => isAllowed);
    }, [ JSON.stringify(elementTypes) ]);


    // Do the Render
    const columns = Math.ceil(list.length / 2);

    return <Container
        columns={columns}
        open={action.isCreate}
        targetRef={addElementRef}
        direction="bottom right"
        onClose={() => setAction()}
    >
        {list.map((elem) => <EmailDesignElemIcon
            key={elem.key}
            className="elem-icon"
            icon={elem.icon}
            message={elem.message}
            onClick={(e) => handleCreate(e, elem)}
        />)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignAddElem.propTypes = {
    addElementRef : PropTypes.object.isRequired,
};

export default EmailDesignAddElem;
