import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import ColorPreview         from "Components/Utils/Common/ColorPreview";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Schedule Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SchedulePreview(props) {
    const { data } = props;

    const { teams } = Store.useState("schedule");


    // Variables
    const timeZoneName   = NLS.getValue("DATE_TIME_ZONES", data.timeZone);
    const periodTimeZone = data.showTimeZone ? data.timeZone : 0;
    const periodsText    = Commons.parseSchedule(JSON.parse(data.periods), periodTimeZone);
    const teamIDs        = Array.isArray(data.teamIDs) ? data.teamIDs : JSON.parse(data.teamIDs);
    const teamList       = teamIDs.map((teamID) => Utils.getValue(teams, "key", teamID));


    // Do the Render
    return <Details>
        <DetailList
            message="SCHEDULES_SINGULAR"
            collapsible="schedule"
        >
            <DetailItem
                message={data.name}
                prefix="GENERAL_NAME"
            />
            <DetailItem
                message={timeZoneName}
                prefix="GENERAL_TIME_ZONE"
            />
            <DetailItem
                message={Utils.toYesNo(data.showTimeZone)}
                prefix="SCHEDULES_SHOW_TIME_ZONE"
            />
            <DetailItem
                message={Utils.toYesNo(data.isDefault)}
                prefix="GENERAL_IS_DEFAULT"
            />
            <DetailItem
                message={periodsText}
                prefix="SCHEDULES_SHORT"
            />
        </DetailList>

        <DetailList
            isHidden={!teamIDs.length}
            message="TEAMS_NAME"
            collapsible="teams"
        >
            {teamList.map((elem) => <DetailItem
                key={elem.key}
                gap="8"
            >
                <ColorPreview color={elem.color} size="18" />
                {elem.value}
            </DetailItem>)}
        </DetailList>
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SchedulePreview.propTypes = {
    data : PropTypes.object.isRequired,
};

export default SchedulePreview;
