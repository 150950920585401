import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import EmailDesignDrop      from "./EmailDesignDrop";
import EmailDesignEmpty     from "./EmailDesignEmpty";
import EmailDesignBaseElem  from "./EmailDesignBaseElem";
import EmailDesignElement   from "./EmailDesignElement";



/**
 * The Email Design Elements
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignElements(props) {
    const { parentElement, parentColumn, setDropElement } = props;

    const { history, currentHistory } = Store.useState("emailDesignState");


    // The Elements
    const elements = React.useMemo(() => {
        if (!history.length) {
            return [];
        }

        const elements = history[currentHistory];
        const list     = [];
        for (const elem of Object.values(elements)) {
            if (elem.parentElement === parentElement && elem.parentColumn === parentColumn) {
                list.push(elem);
            }
        }

        const sorted     = [];
        let   lastNumber = 0;
        for (let i = 0; i < list.length; i++) {
            for (const elem of list) {
                if (elem.afterElement === lastNumber) {
                    lastNumber = elem.elementNumber;
                    sorted.push(elem);
                    break;
                }
            }
        }
        return sorted;
    }, [ currentHistory ]);


    // Do the Render
    if (!elements.length) {
        return <EmailDesignEmpty
            parentElement={parentElement}
            parentColumn={parentColumn}
            setDropElement={setDropElement}
        />;
    }
    return <>
        <EmailDesignDrop
            parentElement={parentElement}
            parentColumn={parentColumn}
            afterElement={0}
            setDropElement={setDropElement}
        />

        {elements.map((elem) => <React.Fragment key={elem.elementNumber}>
            <EmailDesignBaseElem elementNumber={elem.elementNumber}>
                <EmailDesignElement
                    type={elem.elementType}
                    elem={elem}
                    setDropElement={setDropElement}
                />
            </EmailDesignBaseElem>
            <EmailDesignDrop
                parentElement={parentElement}
                parentColumn={parentColumn}
                afterElement={elem.elementNumber}
                setDropElement={setDropElement}
            />
        </React.Fragment>)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignElements.propTypes = {
    parentElement  : PropTypes.number.isRequired,
    parentColumn   : PropTypes.number.isRequired,
    setDropElement : PropTypes.func.isRequired,
};

export default EmailDesignElements;
