import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section.attrs(({ forColumn, isDragging }) => ({ forColumn, isDragging }))`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border: 1px dashed var(--dark-gray);
    padding: calc(var(--main-padding) * 2);
    gap: var(--main-gap);

    ${(props) => props.forColumn && `
        flex-direction: row;
        padding: var(--main-gap);

        .icon {
            font-size: 32px;
            height: 32px;
        }
        h3 {
            font-size: 13px;
        }
    `}

    ${(props) => props.forColumn && props.isDragging && `
        background: var(--lighter-gray);
    `}

    ${(props) => props.isDragging && `:hover {
        background: rgb(234, 248, 241);
        border-color: rgb(2, 188, 78);
    }`}
`;

const Iconography = Styled(Icon)`
    height: 64px;
    font-size: 64px;
    color: var(--darkest-gray);
    opacity: 0.8;
`;

const Title = Styled.h3`
    margin: 0 auto;
    max-width: 400px;
    font-size: 16px;
    font-weight: normal;
    color: var(--darkest-gray);
`;



/**
 * The Email Design Empty
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignEmpty(props) {
    const { parentElement, parentColumn, setDropElement } = props;

    const { isDragging, isDraggingLayout } = Store.useState("emailDesignState");


    // Handles the Mouse Enter
    const handleMouseEnter = () => {
        setDropElement({ parentElement, parentColumn, afterElement : 0 });
    };

    // Handles the Mouse Leave
    const handleMouseLeave = () => {
        setDropElement(null);
    };


    // Do the Render
    if (parentElement !== 0 && isDraggingLayout) {
        return <React.Fragment />;
    }
    return <Container
        forColumn={parentElement !== 0}
        isDragging={isDragging}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        <Iconography icon="element" />
        <Title>{NLS.get("EMAIL_DESIGNS_START_HERE")}</Title>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignEmpty.propTypes = {
    parentElement  : PropTypes.number.isRequired,
    parentColumn   : PropTypes.number.isRequired,
    setDropElement : PropTypes.func.isRequired,
};

export default EmailDesignEmpty;
