import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Monitoring }       from "Utils/API";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    pmsProviders : [],
    filters      : {
        pmsProviderCode : "",
        fromDate        : DateTime.create().toInputString(),
        toDate          : "",
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_MONITORING_LOADING" });
    },

    /**
     * Fetches the Notification List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const data   = await Monitoring.getAll({ ...filters, ...sort });
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "DASHBOARD_MONITORING_LIST", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.lastPMSUpdateDate = DateTime.formatIf(elem.lastPMSUpdate, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_MONITORING_LIST", "DASHBOARD_MONITORING_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_MONITORING_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "DASHBOARD_MONITORING_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : Utils.parseList(action.data.list, parseElem),
            pmsProviders : action.data.pmsProviders,
            filters      : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
