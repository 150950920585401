import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(var(--email-elements-size) + 24px);
    cursor: pointer;
`;

const ElementIcon = Styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--email-elements-size);
    height: var(--email-elements-size);
    background-color: var(--light-gray);
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    font-size: 24px;
    transition: all 0.2s;

    :hover {
        background-color: var(--dark-gray);
    }
`;

const Name = Styled.span`
    font-size: 12px;
    text-align: center;
`;



/**
 * The Email Design Element Icon
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailDesignElemIcon(props) {
    const { className, icon, message, onClick } = props;


    // Do the Render
    return <Container className={className} onClick={onClick}>
        <ElementIcon icon={icon} />
        <Name>{NLS.get(message)}</Name>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailDesignElemIcon.propTypes = {
    className : PropTypes.string,
    icon      : PropTypes.string.isRequired,
    message   : PropTypes.string.isRequired,
    onClick   : PropTypes.func,
};

export default EmailDesignElemIcon;
